import React, {Component, useState} from "react";
import Chart from "react-apexcharts";

const ColumnChart = () => {
    const [options, setObjects] = useState({
        chart: {
            id: "basic-bar"
        },
        xaxis: {
            type: "datetime",
            categories: [
                "2021-09-19T00:00:00.000Z",
                "2021-10-19T01:30:00.000Z",
                "2021-11-19T02:30:00.000Z",
                "2021-12-19T03:30:00.000Z",
                "2022-01-19T04:30:00.000Z",
                "2022-02-19T05:30:00.000Z",
                "2022-03-19T06:30:00.000Z"
            ]
        }
    })
    const [series, setSeries] = useState([
        {
            name: "test",
            data: [30, 40, 45, 50, 49, 60, 70]
        }
    ])


    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={options}
                        series={series}
                        type="bar"
                        width="100%"
                        height="345px"
                    />
                </div>
            </div>
        </div>
    );
}

export default ColumnChart;
