import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import viLocale from "date-fns/locale/vi";
import moment from 'moment';
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
const format1 = "YYYY-MM-DD HH:mm:ss"
const initialValues = {
    start_time: moment(new Date()).format(format1),
    end_time: moment(new Date()).format(format1),
    category: '',
    notes: '',
}
const SurbodinateOvertimeUpdate = (props) => {
    const [dataUpdate, setDataUpdate] = useState()
    const category = props.category;
    const [values, setValues] = useState(initialValues)
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const classes = useStyles();
    // console.log (initialFValues)
    // const isSameOrBefore = (start, end) => {
    //     return moment(start, 'HH:mm:ss').isSameOrBefore(moment(end, 'HH:mm:ss'));
    // }
    const validationSchema = yup.object().shape({
        // description: yup
        //     .string('Nhập vào mô tả'),
        // name: yup
        //     .string('Nhập vào loại phòng ban')
        //     .required('Dữ liệu bắt buộc nhập'),

    });

    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/overtimerequest`
            const result = await axios.post(api, dataUpdate, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataUpdate])
    const handleUpdate = (dataSubmit) => {
        // console.log(JSON.stringify(values))
        dataSubmit.category = values.category
        const dataNew = {"message": {...dataSubmit}, "action": "update", "type_message": "map"}
        console.log(JSON.stringify(dataNew))
        // setDataUpdate(dataNew)
    }
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleUpdate(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={6} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="category"
                            name="category"
                            label="Loại làm thêm giờ"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.category}
                            onChange={handleInputChange}
                            error={formik.touched.category && Boolean(formik.errors.category)}
                            helperText={formik.touched.category && formik.errors.category}
                            multiline
                        >
                            {category.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            style={{minWidth: "250px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="notes"
                            name="notes"
                            label="Mô Tả"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            error={formik.touched.notes && Boolean(formik.errors.notes)}
                            helperText={formik.touched.notes && formik.errors.notes}
                            multiline
                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid item>
                        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{minWidth: "250px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                className={classes.textField}
                                label="Thời gian bắt đầu"
                                name="start_time"
                                id="start_time"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                ampm = {false}
                                ampmInClock = {false}
                                format="dd-MM-yyyy HH:mm:ss"
                                placeholder="ngày-tháng-năm giờ:phút:giây"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.start_time}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={sdate => formik.setFieldValue('start_time',moment(sdate).format("YYYY-MM-DD HH:mm:ss"))}
                                error={formik.touched.start_time && Boolean(formik.errors.start_time)}
                                helperText={formik.touched.start_time && formik.errors.start_time}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{minWidth: "250px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                className={classes.textField}
                                label="Thời gian bắt đầu"
                                name="end_time"
                                id="end_time"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                ampm = {false}
                                ampmInClock = {false}
                                format="dd-MM-yyyy HH:mm:ss"
                                placeholder="ngày-tháng-năm giờ:phút:giây"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.end_time}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={edate => formik.setFieldValue('end_time',moment(edate).format("YYYY-MM-DD HH:mm:ss"))}
                                error={formik.touched.end_time && Boolean(formik.errors.end_time)}
                                helperText={formik.touched.end_time && formik.errors.end_time}
                            />

                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Thêm mới
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default SurbodinateOvertimeUpdate;
