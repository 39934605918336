import React, {useEffect, useState} from 'react';
import {DataGrid, GridApi, GridCellValue} from '@mui/x-data-grid';
import CustomToolbar from "../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Button as MuiButton, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import LeaveTypeSetupView from "../../components/Setting/LeaveTypeSetupView";
import LeaveTypeSetupAdd from "../../components/Setting/LeaveTypeSetupAdd";
import LeaveTypeSetupUpdate from "../../components/Setting/LeaveTypeSetupUpdate";
import LeaveTypeSetupDelete from "../../components/Setting/LeaveTypeSetupDelete";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const rootEl = document.getElementById('root');
const initialFValues = {
    startTime: new Date(),
    endTime: new Date(),
}
// const StyledDataGrid = withStyles({
//     root: {
//
//     },
// })(DataGrid);
const useStyles = makeStyles(theme => ({
    buttonsAdd:{
        marginTop: "15px",
        marginLeft: "20px",
    },
    lightText: {
        color: 'white'
    },
    dataGrid: {
        // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        // background:
        //     `radial-gradient(black 15%, transparent 16%) 0 0,
        //     radial-gradient(black 15%, transparent 16%) 8px 8px,
        //     radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
        //     radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
        //     background-color:#282828;
        //     background-size:16px 16px;`,
        borderRadius: 3,
        border: 0,
        color:"inherit!important",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        width: "100%"
    },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    root: {
        padding: "20px",
        '& .super-app-theme--header': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: theme.palette.info.main,
            '&:hover': {
                backgroundColor: theme.palette.info.main,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.main,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        // '& .MuiDataGrid-renderingZone': {
        //     maxHeight: 'none !important',
        // },
        // '& .MuiDataGrid-cell': {
        //     lineHeight: 'unset !important',
        //     maxHeight: 'none !important',
        //     whiteSpace: 'normal',
        // },
        // '& .MuiDataGrid-row': {
        //     maxHeight: 'none !important',
        // },
    },

}));

const dateFormater = (cell) => {
    return (
        <Moment format="DD-MM-YYYY HH:mm:ss">{cell}</Moment>
    );
};


function addOrEdit(thisRow) {

}

const forwardLeaveType = [
    {"id":"1 Month","name":"1 Tháng"},
    {"id":"2 Months","name":"2 Tháng"},
    {"id":"3 Months","name":"3 Tháng"},
    {"id":"4 Months","name":"4 Tháng"},
    {"id":"5 Months","name":"5 Tháng"},
    {"id":"6 Months","name":"6 Tháng"},
    {"id":"7 Months","name":"7 Tháng"},
    {"id":"8 Months","name":"8 Tháng"},
    {"id":"9 Months","name":"9 Tháng"},
    {"id":"10 Months","name":"10 Tháng"},
    {"id":"11 Months","name":"11 Tháng"},
    {"id":"1 Year","name":"1 Năm"},
    {"id":"No Limit","name":"Không giới hạn"}
]
export default function LeaveTypeSetup() {

    const classes = useStyles();
    // const {addOrEdit, recordForEdit} = props
    const [dataCategory, setDataCategory] = useState([])
    // const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [dataRows, setDataRows] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const handleEdit = () => {
        setOpenEdit(false);
    };
    const handleDelete= () => {
        setOpenDelete(false);
    };
    const handleAdd = () => {
        setOpenAdd(false);
    };
    const handleClose = () => {
        setOpen(false);
        // setDataRows({});
    };

    useEffect(async () => {
        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/leavetype`
            const result = await  axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message===null){
                        res.data.message = {};
                    }
                    console.log(res.data.message)
                    setDataCategory(res.data.message)
                }

            })
        } catch(e) {
        }
    }, [openAdd,openDelete,openEdit, isExpired])


    const data = {
        "columns":
            [{"field": "id",  "flex":1,"minWidth":250,headerName:"ID","hide": true, headerClassName: 'super-app-theme--header', headerAlign: 'center',},

                {
                    "field": "name",
                    "headerName": "Loại nghĩ phép",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "apply_beyond_current",
                    "headerName": "apply_beyond_current",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "carried_forward",
                    "headerName": "carried_forward",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "carried_forward_leave_availability",
                    "headerName": "carried_forward_leave_availability",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "carried_forward_percentage",
                    "headerName": "carried_forward_percentage",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "employee_can_apply",
                    "headerName": "employee_can_apply",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "leave_accrue",
                    "headerName": "leave_accrue",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "leave_accrue_beyond_current",
                    "headerName": "leave_accrue_beyond_current",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "leave_color",
                    "headerName": "leave_color",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "leave_group",
                    "headerName": "leave_group",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "leaves_per_leave_type",
                    "headerName": "leaves_per_leave_type",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "max_carried_forward_amount",
                    "headerName": "max_carried_forward_amount",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "propotionate_on_joined_date",
                    "headerName": "propotionate_on_joined_date",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "suppervisor_can_assign",
                    "headerName": "suppervisor_can_assign",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "use_employee_leave_period",
                    "headerName": "use_employee_leave_period",
                    "flex":1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "actions",
                    "headerName": "Thao tác",
                    "sortTable": false,
                    "type": "string",
                    "width": 160,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpen(true);
                                // viewRows(thisRow)
                                // return alert(JSON.stringify(thisRow, null, 4));
                            }

                            }>
                                <VisibilityIcon/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenEdit(true);
                            }
                            }>
                                <EditIcon/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenDelete(true);
                            }}>
                                <DeleteForeverIcon/>
                            </IconButton>
                        </div>
                    )
                }],
        "rows": dataCategory,
        rowLength: 100,
        maxColumns: 6,
    }

    return (
        <>
            {/*<PageHeader*/}
            {/*    title="DANH MỤC CHỨC DANH CÔNG VIỆC"*/}
            {/*    // subTitle=""*/}
            {/*    // icon={<PeopleOutlineTwoToneIcon fontSize="large"/>}*/}
            {/*/>*/}
            <Grid item xs={2} className={classes.buttonsAdd}>
                <div>
                    <Button color="primary" variant="contained"
                            onClick={() => {
                                setOpenAdd(true)
                            }}
                    >
                        <AddIcon/>
                        Thêm mới
                    </Button>
                </div>
            </Grid>
            {/*<div style={{ height: '75%', width: '100%' }}>*/}
            <div style={{height: '100%', width: '97%'}} className={classes.root}>
                <DataGrid
                    className={classes.dataGrid}
                    disableExtendRowFullWidth={true}
                    {...data}
                    getRowClassName={(params) =>
                        `super-app-theme--${params.getValue(params.id, 'status')}`}
                    hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                    localeText={
                        {
                            noRowsLabel: 'Không có dữ liệu',
                            noResultsOverlayLabel: 'Không tìm thấy kết quả.',
                            errorOverlayDefaultLabel: 'An error occurred.',
                            // Density selector toolbar button text
                            toolbarDensity: 'Độ cao dòng',
                            toolbarDensityLabel: 'Độ cao dòng',
                            toolbarDensityCompact: 'Thu gọn',
                            toolbarDensityStandard: 'Bình thường',
                            toolbarDensityComfortable: 'Mở rộng',

                            // Columns selector toolbar button text
                            toolbarColumns: 'Cột',
                            toolbarColumnsLabel: 'Chọn cột',

                            // Filters toolbar button text
                            toolbarFilters: 'Lọc',
                            toolbarFiltersLabel: 'Hiển thị Lọc',
                            toolbarFiltersTooltipHide: 'Ẩn Lọc',
                            toolbarFiltersTooltipShow: 'Hiển thị Lọc',
                            toolbarFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,

                            // Export selector toolbar button text
                            toolbarExport: 'Xuất dữ liệu',
                            toolbarExportLabel: 'Xuất dữ liệu',
                            toolbarExportCSV: 'Tải xuống định dạng CSV',
                            toolbarExportPrint: 'In',

                            // Columns panel text
                            columnsPanelTextFieldLabel: 'Tìm kiếm cột',
                            columnsPanelTextFieldPlaceholder: 'Tên cột',
                            columnsPanelDragIconLabel: 'Reorder column',
                            columnsPanelShowAllButton: 'Hiển thị tất cả',
                            columnsPanelHideAllButton: 'Ẩn tất cả',

                            // Filter panel text
                            filterPanelAddFilter: 'Thêm lọc',
                            filterPanelDeleteIconLabel: 'Xóa',
                            filterPanelOperators: 'Lọc theo:',
                            filterPanelOperatorAnd: 'Và',
                            filterPanelOperatorOr: 'Hoặc',
                            filterPanelColumns: 'Cột',
                            filterPanelInputLabel: 'Giá trị',
                            filterPanelInputPlaceholder: 'Giá trị lọc',

                            // Filter operators text
                            filterOperatorContains: 'chứa',
                            filterOperatorEquals: 'bằng',
                            filterOperatorStartsWith: 'bắt đầu với',
                            filterOperatorEndsWith: 'kết thúc với',
                            filterOperatorIs: 'is',
                            filterOperatorNot: 'is not',
                            filterOperatorAfter: 'is after',
                            filterOperatorOnOrAfter: 'is on or after',
                            filterOperatorBefore: 'is before',
                            filterOperatorOnOrBefore: 'is on or before',
                            filterOperatorIsEmpty: 'giá trị rỗng',
                            filterOperatorIsNotEmpty: 'giá trị không rỗng',

                            // Filter values text
                            filterValueAny: 'any',
                            filterValueTrue: 'true',
                            filterValueFalse: 'false',

                            // Column menu text
                            columnMenuLabel: 'Danh mục',
                            columnMenuShowColumns: 'Hiển thị cột',
                            columnMenuFilter: 'Lọc',
                            columnMenuHideColumn: 'Ẩn',
                            columnMenuUnsort: 'Chưa sắp xếp',
                            columnMenuSortAsc: 'Sắp xếp tăng dần',
                            columnMenuSortDesc: 'Sắp xếp giảm dần',

                            // Column header text
                            columnHeaderFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,
                            columnHeaderFiltersLabel: 'Hiển thị lọc',
                            columnHeaderSortIconLabel: 'Sắp xếp',

                            // Rows selected footer text
                            footerRowSelected: (count) =>
                                count !== 1
                                    ? `${count.toLocaleString()} dòng đang chọn`
                                    : `${count.toLocaleString()} dòng đang chọn`,

                            // Total rows footer text
                            footerTotalRows: 'Tổng dòng:',

                            // Total visible rows footer text
                            footerTotalVisibleRows: (visibleCount, totalCount) =>
                                `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
                            // Checkbox selection text
                            checkboxSelectionHeaderName: 'Checkbox selection',

                            // Boolean cell text
                            booleanCellTrueLabel: 'true',
                            booleanCellFalseLabel: 'false',

                            // Actions cell more text
                            actionsCellMore: 'more',

                            // Tree Data
                            treeDataGroupingHeaderName: 'Nhóm',
                            treeDataExpand: 'Hiện danh sách con',
                            treeDataCollapse: 'Ẩn danh sách con',
                            TablePagination: 'ok'
                        }
                    }
                    components={{
                        Toolbar: CustomToolbar,
                        title: "Danh mục chức danh"
                    }}
                />

            </div>
            <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
                <DialogTitle>CHI TIẾT LOẠI NGHĨ PHÉP</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"xs"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <LeaveTypeSetupView data={dataRows} forwardLeaveType={forwardLeaveType}/>
                        <MuiButton onClick={handleClose} style={{width: "25%"}}>
                            Đóng
                        </MuiButton>
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} fullWidth={true} maxWidth={"xl"} align={'center'}
            >
                <DialogTitle>CẬP NHẬT LOẠI NGHĨ PHÉP</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"xl"} align={'left'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <LeaveTypeSetupUpdate
                            data={dataRows}
                            forwardLeaveType={forwardLeaveType}
                            onClick={() => {
                                setOpenEdit(false)
                            }}/>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} fullWidth={true} maxWidth={"md"} algin={'center'}
            >
                <DialogTitle>XÓA LOẠI NGHĨ PHÉP</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}  algin={'center'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <LeaveTypeSetupDelete
                            data={dataRows}
                            forwardLeaveType={forwardLeaveType}
                            onClick={() => {
                                setOpenDelete(false)
                            }}/>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={openAdd} fullWidth={true} maxWidth={"xl"} algin={'center'}
            >
                <DialogTitle>THÊM LOẠI NGHĨ PHÉP</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"xl"} algin={'center'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <LeaveTypeSetupAdd
                            leaveGroups={dataCategory}
                            forwardLeaveType={forwardLeaveType}
                            onClick={() => {
                                setOpenAdd(false)
                            }}/>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

