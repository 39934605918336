import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {makeStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {capitalize} from "../../helpers";
import classNames from "classnames";
import {Tooltip} from "@material-ui/core";
// const MenuItem = styled(MuiMenuItem)`
//   justify-content: flex-end;
// `;
const useStyles = makeStyles(theme => ({

    menu_tooltip: {
        // justifyContent:'flex-end',
        marginLeft: '50px',
        // width:'250px',
        // marginTop:'15%',

    },
    badge: {
        width: '20px',
        height: '20px',
        display: 'flex',
        zIndex: 1,
        flexWrap: 'wrap',
        fontSize: '0.75rem',
        alignItems: 'center',
        borderRadius: '50%',
        alignContent: 'center',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    menuLink: {
        position: 'relative',
        display: 'block'
    },
    menuItem: {
        // width: '40px',
        // paddingLeft: 0,
        // paddingRight: 0,
        // paddingTop: theme.spacing(1.5),
        // paddingBottom: theme.spacing(1.5)
    },
    menuIcon: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        zIndex: -500
    },
    listMenu: {
        alignItems: 'center',
        // paddingLeft: '20px',
        // paddingRight: '20px',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center'

    },
    menuSubItem: {
        paddingLeft: '20px',
        // paddingRight: '20px',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),

    },
    menuCollapsed: {
        backgroundColor: theme.palette.action.hover
    },
    menuActive: {
        backgroundColor: theme.palette.action.hover
    },
    menuClosed: {
        backgroundColor: 'transparent'
    },
    caret: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: 0
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    // tooltip_menu: {
    //   marginLeft:"50px"
    // }
}));
const timeoutLength = 400;
const badge = badge => {
    if (!badge) return;
    const badgeClassName = classNames(classes.badge, {
        [classes[`${badge.type}`]]: badge.type !== 'default'
    });
    return (
        <Typography
            className={classNames(classes.badge, badgeClassName)}
            component="div"
        >
            {badge.value}
        </Typography>
    );
};

function SimpleMenu(props) {
    // console.log(props)
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const route = props.route
    // const [mouseOverButton,setMouseOverButton] = useState(null)
    // const [mouseOverMenu,setMouseOverMenu] = useState(null)
    function enterButton() {
        // this.setState({ mouseOverButton: true });
        setMouseOverButton(true)
    }

    // function leaveButton (event) {
    //     // Set a timeout so that the menu doesn't close before the user has time to
    //     // move their mouse over it
    //     setTimeout(() => {
    //         setMouseOverButton(false)
    //     }, timeoutLength-300);
    //
    // }
    //
    // function enterMenu ()  {
    //     setMouseOverMenu(true)
    // }

    // function leaveMenu () {
    //     setTimeout(() => {
    //         setMouseOverMenu(false)
    //     }, timeoutLength - 450);
    // }


    async function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
           await setAnchorEl(event.currentTarget);
        }
    }

    async function handleClose() {
       await setAnchorEl(null);
    }

    return (
        <>

            <Button
                aria-owns={anchorEl ? "tooltip-minimenu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                style={{zIndex:1}}
                // onMouseOver={handleClick}
                // onMouseLeave= {handleClose}
            >
                <Tooltip title={route.name} arrow>
                    <ListItemIcon className={classes.listMenu}>
                        <route.icon className={classes.menuIcon}/>
                    </ListItemIcon>
                </Tooltip>
            </Button>


            <Menu
                className={classes.menu_tooltip}
                id="tooltip-minimenu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    onMouseLeave: handleClose,
                    onClick: handleClose
                }}
            >
                {route.children.map((subitem, index) => (
                    <NavLink
                        to={`${route.path ? route.path : ''}${
                            subitem.path ? subitem.path : ''
                        }`}
                        exact
                        className={classes.menuLink}
                        activeClassName={classes.menuActive}
                        key={index}
                    >
                        <ListItem className={classes.menuSubItem} button>
                            <Typography variant="body1" className="flexSpacer">
                                {capitalize(subitem.name)}
                            </Typography>
                            {badge(subitem.badge)}
                        </ListItem>
                    </NavLink>
                ))}
                {/*<MenuItem onClick={handleClose}>Menu 1</MenuItem>*/}
                {/*<MenuItem onClick={handleClose}>Menu 2</MenuItem>*/}
                {/*<MenuItem onClick={handleClose}>Menu 3</MenuItem>*/}
            </Menu>
        </>
    );
}

export default SimpleMenu;
