
import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import 'moment-timezone';
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    root: {
    fontSize: '24px'
},

body: {
    // background: 'linear-gradient(to left, #283593, #1976d2)',
    // height: '100vh',
    // fontFamily: 'Josefin Sans, sans-serif',
},

/* Background circles start */
circle: {
    position: 'absolute',
    borderRadius: '50%',
    background: 'radial-gradient(#006db3, #29b6f6)'
},
circles :{
    position: 'absolute',
    height: '270px',
    width: '450px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
},
circle1: {
    position: 'absolute',
    borderRadius: '50%',
    background: 'radial-gradient(#006db3, #29b6f6)',
    height: '180px',
    width: '180px',
    top: '-50px',
    left: '-60px'
},
circle2: {
    position: 'absolute',
    borderRadius: '50%',
    background: 'radial-gradient(#006db3, #29b6f6)',
    height: '200px',
    width: '200px',
    bottom: '-90px',
    right: '-90px',
    opacity: '0.8'
},
/* Background circles end */

cardGroup: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
},

card: {
    position: 'relative',
    height: '270px',
    width: '450px',
    borderRadius: '25px',
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(30px)',
    border: '2px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0 0 80px rgba(0, 0, 0, 0.2)',
    overflow: 'hidden',
},

img: {
    position: 'absolute', /* All items inside card should have absolute position */
    // top: '120px',
    // left: '40px',
    // width: '50px',
    // height: 'auto',
    // opacity: 0.8,
},

    imgChip:{
        top: '120px',
        left: '40px',
        width: '50px',
        height: 'auto',
        opacity: 0.8
    },
    imgLogo:{
        top: '120px',
        left: '40px',
        width: '200px',
        height: 'auto',
        opacity: 0.8
    },
logo: {
    position: 'absolute',
    top: '35px',
    right: '150px',
    width: '80px',
    height: 'auto',
    opacity: 0.8,
},

chip: {
    position:'absolute',
    top: '120px',
    left: '40px',
    width: '50px',
    height: 'auto',
    opacity: 0.8
},
// 'number, name, from, to': {
//     color: 'rgba(255, 255, 255, 0.8)',
//     fontWeight: '400',
//     letterSpacing: '2px',
//     textShadow: '0 0 2px rgba(0, 0, 0, 0.6)',
// },

number: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontWeight: '400',
    letterSpacing: '2px',
    textShadow: '0 0 2px rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    left: '40px',
    bottom: '65px',
    fontFamily: 'Nunito, sans-serif'
},

name: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontWeight: '400',
    letterSpacing: '2px',
    textShadow: '0 0 2px rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    fontSize: '0.5rem',
    left: '40px',
    bottom: '35px'
},

from : {
    color: 'rgba(255, 255, 255, 0.8)',
    fontWeight: '400',
    letterSpacing: '2px',
    textShadow: '0 0 2px rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    fontSize: '0.5rem',
    bottom: '35px',
    right: '110px'
},

to: {
    color: 'rgba(255, 255, 255, 0.8)',
    fontWeight: '400',
    letterSpacing: '2px',
    textShadow: '0 0 2px rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    fontSize: '0.5rem',
    bottom:'35px',
    right: '40px'
},
ring: {
    position: 'absolute',
    height: '500px',
    width: '500px',
    borderRadius: '50%',
    background: 'transparent',
    border: '50px solid rgba(255, 255, 255, 0.1)',
    bottom: '-250px',
    right: '-250px',
    boxSizing: 'border-box'
},
'ring::after': {
    content: "",
    position: 'absolute',
    height:'600px',
    width: '600px',
    borderRadius: '50%',
    background: 'transparent',
    border: '30px solid rgba(255, 255, 255, 0.1)',
    bottom: '-80px',
    right: '-110px',
    boxSizing: 'border-box',
}
}));
const BankCard = (props) => {
    const classes = useStyles();
    const userInfo = props.userInfo
    // const arrStatus = props.arrStatus
    // const categories = props.categories
    return (
        <div className={classes.body}>
            <div className={classes.circles}>
                <div className={classes.circle1}></div>
                <div className={classes.circle2}></div>
            </div>

            <div className={classes.cardGroup}>
                <div className={classes.card}>
                    <div className={classes.logo}><img className={classes.imgLogo}
                      src={`${process.env.PUBLIC_URL}/static/images/Vietcombank.png`}
                        alt={`${userInfo.bank_type}`}/>
                    </div>
                    <div className={classes.chip}><img className={classes.imgChip}
                      src={`${process.env.PUBLIC_URL}/static/images/ChipBankCard.png`} alt="chip"/>
                    </div>
                    <div className={classes.number}>STK: {userInfo.bank_number ? userInfo.bank_number : "(Trống)"}</div>
                    <div className={classes.name}>{userInfo.bank_name ? userInfo.bank_name : "(Trống)"}</div>
                    <div className={classes.from}>{userInfo.bank_date ? userInfo.bank_date : "(Trống)"}</div>
                    <div className={classes.to}>{userInfo.bank_expired ? userInfo.bank_expired : "(Trống)"}</div>
                    <div className={classes.ring}></div>
                </div>
            </div>
        </div>
    )
}
export default BankCard;



