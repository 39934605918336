import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {UserForm} from "./UserForm";

export default class FormUpdateUser extends React.Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        let permission = "EditEmployees";
        permission === "EditEmployees2" ?  this.setState({ open: true }) :  this.setState({ open: false}) ;

    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div>
                <Button onClick={this.handleClickOpen} variant="contained" color="primary" >Sửa thông tin cá nhân</Button>
                <Dialog
                    open={this.state.open}
                    // onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Cập nhật nhân viên</DialogTitle>
                    <DialogContent>
                        <UserForm/>
                        {/*<DialogContentText>*/}
                        {/*    To subscribe to this website, please enter your email address here. We will send*/}
                        {/*    updates occasionally.*/}
                        {/*</DialogContentText>*/}
                        {/*<TextField*/}
                        {/*    autoFocus*/}
                        {/*    margin="dense"*/}
                        {/*    id="name"*/}
                        {/*    label="Email Address"*/}
                        {/*    type="email"*/}
                        {/*    fullWidth*/}
                        {/*/>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Subscribe
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
