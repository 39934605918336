import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const format1 = "YYYY-MM-DD HH:mm:ss"
const initialValues = {
    travel_type: '',
    purpose: '',
    travel_from: '',
    travel_to: '',
    travel_date: moment(new Date()).format(format1),
    return_date: moment(new Date()).format(format1)
}
const SubordinateTravelRequestAdd = (props) => {
    const [dataAdd, setDataAdd] = useState()
    const classes = useStyles();
    const subordinateEmployee = props.subordinateEmployee
    const [values, setValues] = useState(initialValues)
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const validationSchema = yup.object().shape({
        details: yup
            .string('Nhập vào mô tả'),
        travel_type: yup
            .string('Nhập vào loại phòng ban')
            .required('Dữ liệu bắt buộc nhập'),
        travel_to: yup
            .string('Nhập vào loại phòng ban')
            .required('Dữ liệu bắt buộc nhập'),
        travel_from: yup
            .string('Nhập vào loại phòng ban')
            .required('Dữ liệu bắt buộc nhập'),

    });

    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/travelrequest`
            const result = await axios.post(api, dataAdd, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 201) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataAdd])
    const handleAdd = (dataSubmit) => {
        dataSubmit.employee = values.employee
        const dataNew = {"message": {...dataSubmit}, "action": "create", "type_message": "map"}
        console.log(JSON.stringify(dataNew))
        setDataAdd(dataNew)
    }
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleAdd(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={8} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "165px"}}
                            id="employee"
                            name="employee"
                            label="Nhân viên cấp dưới"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.employee}
                            onChange={handleInputChange}
                            // error={formik.touched.employee_id && Boolean(formik.errors.employee_id)}
                            // helperText={formik.touched.employee_id && formik.errors.employee_id}
                            // multiline
                        >
                            {subordinateEmployee.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.last_name} {x.first_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="purpose"
                            name="purpose"
                            label="Lý do công tác"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.purpose}
                            onChange={formik.handleChange}
                            error={formik.touched.purpose && Boolean(formik.errors.purpose)}
                            helperText={formik.touched.purpose && formik.errors.purpose}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="travel_type"
                            name="travel_type"
                            label="Phương tiện"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.travel_type}
                            onChange={formik.handleChange}
                            error={formik.touched.travel_type && Boolean(formik.errors.travel_type)}
                            helperText={formik.touched.travel_type && formik.errors.travel_type}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="travel_from"
                            name="travel_from"
                            label="Nơi đi"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.travel_from}
                            onChange={formik.handleChange}
                            error={formik.touched.travel_from && Boolean(formik.errors.travel_from)}
                            helperText={formik.touched.travel_from && formik.errors.travel_from}

                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="travel_to"
                            name="travel_to"
                            label="Nơi đến"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.travel_to}
                            onChange={formik.handleChange}
                            error={formik.touched.travel_to && Boolean(formik.errors.travel_to)}
                            helperText={formik.touched.travel_to && formik.errors.travel_to}

                        />
                    </Grid>

                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{minWidth: "250px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                className={classes.textField}
                                label="Thời gian bắt đầu"
                                name="travel_date"
                                id="travel_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                ampm={false}
                                ampmInClock={false}
                                format="dd-MM-yyyy HH:mm:ss"
                                placeholder="ngày-tháng-năm giờ:phút:giây"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.travel_date}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={sdate => formik.setFieldValue('travel_date', moment(sdate).format("YYYY-MM-DD HH:mm:ss"))}
                                error={formik.touched.travel_date && Boolean(formik.errors.travel_date)}
                                helperText={formik.touched.travel_date && formik.errors.travel_date}
                            />

                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                style={{minWidth: "250px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                className={classes.textField}
                                label="Thời gian kết thúc"
                                name="return_date"
                                id="return_date"
                                margin="normal"
                                ampm={false}
                                ampmInClock={false}
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy HH:mm:ss"
                                placeholder="ngày-tháng-năm giờ:phút:giây"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                value={formik.values.return_date}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={edate => formik.setFieldValue('return_date', moment(edate).format("YYYY-MM-DD HH:mm:ss"))}
                                error={formik.touched.return_date && Boolean(formik.errors.return_date)}
                                helperText={formik.touched.return_date && formik.errors.return_date}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Thêm mới
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default SubordinateTravelRequestAdd;
