import {
    BackendError,
    Lockscreen,
    NotFound,
    PasswordReset,
    Signin,
    Signup
} from "./pages";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import AppProvider from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import DashboardMenu from "./containers/DashboardMenu";
import React from "react";
import registerServiceWorker from "./registerServiceWorker";
import {render} from "react-dom";
import axios from "axios";
import dayjs from "dayjs";

const duration = require('dayjs/plugin/duration');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
import durationPlugin from 'dayjs/plugin/duration';
import {store} from "./redux/storeConfig/store";
import {Provider} from "react-redux";
import {RefreshToken} from "./components/Signin/RefreshToken";

dayjs.extend(durationPlugin);
// onGetForcedToken();

// axios.interceptors.request.use(async (config) => {

//   let now = (dayjs().tz("Asia/Ho_Chi_Minh").valueOf());
//   console.log(now);
//   const expireAt = localStorage.getItem('expiresAt');
//   let token = localStorage.getItem('authToken');

//   // if (now>=expireAt) {
//   //   const data = onGetForcedToken();
//   //   token = typeof data === 'string' ? data : await data();
//   // }
//   // setting updated token
//   localStorage.setItem('authToken', token);
//   return config;
// }, (err) => {
//   console.log("error in getting ", err)
// });

// axios.interceptors.request.use(req => {
//     console.log(`${req.method} ${req.url}`);
//     // Important: request interceptors **must** return the request.
//     return req;
// });
// chưa refresh Token được nên đánh chặn về trang Login
axios.interceptors.response.use(
    res => res,
    err => {
        if (err.response.status === 401) {
            console.log(`Lỗi 401: ${err}`)
            window.location.href = "/";
            // const temp = RefreshToken;
            // if(!temp){
            //
            // }
        }
        throw err;
    }
);

// axios.interceptors.response.use(response => response, error => {
//     const status = error.response ? error.response.status : null
//
//     if (status === 401 || status === 400) {
//        RefreshToken()
//         window.location.reload();
//     }
//
//     return Promise.reject(error);
// });

render(
    <Provider store={store}>
        <AppProvider>
            <BrowserRouter basename={""}>
                <Switch>
                    <Route exact path="/404" component={NotFound}/>
                    <Route exact path="/500" component={BackendError}/>
                    <Route exact path="/Lockscreen" component={Lockscreen}/>
                    <Route exact path="/forgot" component={PasswordReset}/>
                    <Route exact path="/" component={Signin}/>
                    <Route exact path="/signup" component={Signup}/>
                    <Route exact path="/dashboardMenu" component={DashboardMenu}/>
                    <Route path="/" component={Dashboard}/>
                </Switch>
            </BrowserRouter>
        </AppProvider>
    </Provider>,
    document.getElementById("root")
);

registerServiceWorker();
