import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import BankCard from "../BankCard/BankCard";
import Divider from "@material-ui/core/Divider";
import {MenuItem} from "@material-ui/core";
import {ColorButton, ColorButtonAdd, ColorHeader} from "../MainStyleCSS/ColorHeader";
import {fontWeight} from "tailwindcss/lib/plugins";
import moment from "moment";

const styles = theme => ({
    // root: {
    //     width: '100%',
    // },
    familyInfo: {
        padding: 0,
        marginBottom: '-10px'
    },
    bankcard: {
        // background: '#1488cc',
        /* fallback for old browsers */
        background: 'linear-gradient(to left, #283593, #1976d2)',
        height: '75vh',
        fontFamily: 'Josefin Sans, sans-serif',
    },
    iconArrow:{
        // color: 'white'
    },
    headerexpan: {
        // backgroundColor: "#ccd7dd"
        backgroundColor: "#deefff",
    },
    heading: {
        color:'black',
        fontWeight:450,
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color:'black',
    },
    headerInput: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,

    },
    InputText: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: 'bold',
        marginTop: '-10px'
        // color: theme.palette.text.secondary,
    },
    lineFamily: {
        marginTop: '-10px',
        marginBottom: '5px'
    },
    familyHeader: {
        marginBottom: '-10px'
    }
});

class ControlledExpansionPanels extends React.Component {
    state = {
        expanded1: 'panel1',
        expanded2: null,
        expanded3: null,
        expanded4: 'panel4',
    };

    handleChangePanel1 = panel => (event, expanded) => {
        this.setState({
            expanded1: expanded ? panel : "",
        });
    };

    handleChangePanel2 = panel => (event, expanded) => {
        this.setState({
            expanded2: expanded ? panel : "",
        });
    };
    handleChangePanel3 = panel => (event, expanded) => {
        this.setState({
            expanded3: expanded ? panel : "",
        });
    };
    handleChangePanel4 = panel => (event, expanded) => {
        this.setState({
            expanded4: expanded ? panel : "",
        });
    };

    render() {
        const {classes,userInfo, jobDetails, genderItems} = this.props;
        const arrayFamily = [{"id": 1, "relationship" : "Cha","gender":"Nam", "mobiephone":"123456789","full_name":"A B C", "ssn_num":"123456789", "status":true,"address":"123/abc tổ S, Quận X, TP Y"}, {"id": 2, "relationship" : "Mẹ","gender":"Nữ", "mobiephone":"123456789","full_name":"A B C", "ssn_num":"123456789", "status":true,"address":"123/abc tổ S, Quận X, TP Y"}, {"id": 3, "relationship" : "Anh","gender":"Nam", "mobiephone":"123456789","full_name":"A B C", "ssn_num":"123456789", "status":false,"address":"123/abc tổ S, Quận X, TP Y"}, {"id": 4, "relationship" : "Em","gender":"Nam", "mobiephone":"123456789","full_name":"A B C", "ssn_num":"123456789", "status":false,"address":"123/abc tổ S, Quận X, TP Y"}]
        const {expanded1, expanded2, expanded3, expanded4} = this.state;
        const job_description = jobDetails.filter(x => x.id === userInfo.job_title.id).map(x => x.description)
        return (

            <div className={classes.root}>
                <ExpansionPanel expanded={expanded1 === 'panel1'} onChange={this.handleChangePanel1('panel1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.iconArrow}/>} className={classes.headerexpan}>
                        <Typography className={classes.heading}>1. Tổng quan</Typography>
                        {/*<Typography className={classes.secondaryHeading}>I am an expansion panel</Typography>*/}
                    </ExpansionPanelSummary>
                    <Grid container>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails className={classes.familyHeader}>
                                <Typography className={classes.headerInput}>
                                    Họ:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.last_name}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Tên đệm:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.middle_name ? userInfo.middle_name : `(Trống)`}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Tên:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.first_name}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Giới tính:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {genderItems.filter(x => x.id === userInfo.gender).map(x => x.title)}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Ngày sinh:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {moment(userInfo.birth_day).format('DD-MM-YYYY')}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Nơi sinh:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.birth_place? userInfo.birth_place : `(Trống)`}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Quốc tịch:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.nationality ? userInfo.nationality.name === "Vietnamese" ? "Việt Nam" : userInfo.nationality.name : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Hộ khẩu thường trú:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.address1}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Nguyên quán:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.nactive_place ? userInfo.nactive_place : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Dân tộc:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.ethnicity ? userInfo.ethnicity : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Tôn giáo:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.religion ? userInfo.religion : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Số CMND/CCCD:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.ssn_num ? `${userInfo.ssn_num}` : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Ngày cấp:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.ssn_date ? `${userInfo.ssn_date}` : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Nơi cấp:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.ssn_place ? `${userInfo.ssn_place}` : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Học vấn:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.education ? `${userInfo.education}` : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Trình độ học vấn:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.education_level ? `${userInfo.education_level}` : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Mã số thuế:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.tic_num ? userInfo.tic_num : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Số BHXH:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.nic_num ? userInfo.nic_num : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                    </Grid>

                </ExpansionPanel>
                <ExpansionPanel expanded={expanded2 === 'panel2'} onChange={this.handleChangePanel2('panel2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.iconArrow}/>} className={classes.headerexpan}>
                        <Typography className={classes.heading}>2. Mô tả công việc</Typography>
                        {/*<Typography className={classes.secondaryHeading}>*/}
                        {/*    You are currently not an owner*/}
                        {/*</Typography>*/}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        {userInfo.job_title ? userInfo.job_title.name ? userInfo.job_title.name : "Trống" : "(Trống)"}:
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {job_description.length  > 2 ? job_description : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded3 === 'panel3'} onChange={this.handleChangePanel3('panel3')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.iconArrow}/>} className={classes.headerexpan}>
                        <Typography className={classes.heading}>3. Thông tin ngân hàng</Typography>
                        <Typography className={classes.secondaryHeading}>
                            {userInfo.bank_type ? userInfo.bank_type : "(Trống)"} - STK: {userInfo.bank_number ? userInfo.bank_number : "(Trống)"} - Chủ TK: {userInfo.bank_name ? userInfo.bank_name:"(Trống)"}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container className={classes.bankcard}>
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                {/*<ExpansionPanelDetails>*/}
                                {/*    <Typography className={classes.headerInput}>*/}
                                {/*        Họ & chữ lót:*/}
                                {/*    </Typography>*/}
                                {/*</ExpansionPanelDetails>*/}
                                <ExpansionPanelDetails>
                                    <BankCard userInfo = {userInfo}/>
                                </ExpansionPanelDetails>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded4 === 'panel4'} onChange={this.handleChangePanel4('panel4')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.iconArrow}/>} className={classes.headerexpan}>
                        <Typography className={classes.heading}>4. Thông tin gia đình</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.familyInfo}>
                        <Grid container>
                            <Grid item xs={1}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Mối quan hệ:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={2}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Họ tên:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={1}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Giới tính:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={2}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Số CMND:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Địa chỉ thường trú:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={2}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Số điện thoại:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={1}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Người phụ thuộc:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                    {arrayFamily.map((x) => (
                        <div>
                            <Divider className={classes.lineFamily}/>
                            <ExpansionPanelDetails className={classes.familyInfo}>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                {x.relationship}
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                {x.full_name}
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                {x.gender}
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                {x.ssn_num}
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                {x.address}
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                {x.mobiephone}
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                {x.status ? "Có" : "Không"}
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </div>
                    ))}
                </ExpansionPanel>
            </div>
        );
    }
}
ControlledExpansionPanels.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlledExpansionPanels);
