import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import * as Yup from 'yup';
import dayjs from "dayjs";
import durationPlugin from 'dayjs/plugin/duration';
// import {RefreshToken} from "../../components/Signin/RefreshToken";
// import {
//     BackendError,
//     Lockscreen,
//     NotFound,
//     PasswordReset,
//     Signup,
//     Dashboard,
//     DashboardMenu
// } from "../../pages";

const useStyles = makeStyles(theme => ({

  "@keyframes spin": {
    "0%": {
      opacity: 0,
      transform: "rotate(0deg)"
    },
    "100%": {
      opacity: 1,
      transform: "rotate(360deg)"
    }
  },
  "@keyframes drop-in": {
    "from": {
      opacity: 0,
      transform: "translateY(-100px)"
    },
    "to": {
      opacity: 1,
      transform: "translate(0px)"
    }
  },

  buttonLogin: {
    marginTop:'20px'
  },
  card: {
    overflow: "visible",

  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  background: {
    // backgroundColor: theme.palette.primary.main,
    background: "url(" + `${process.env.PUBLIC_URL}/static/images/backgroundHR.jpeg` + ") no-repeat center center fixed",
    // backgroundPosition: "center",
    // backgroundSize:"100%",
    // background: "cover",
    // backgroundRepeat : "no-repeat"
    backgroundSize: "cover"
  },
  backgroundForm: {
    // backgroundColor: theme.palette.primary.main,
    //backgroundImage: "url(" + `${process.env.PUBLIC_URL}/static/images/backgroundHR.jpg` + ")",
    backgroundColor: "rgba(255,255,255,1.0)",
    backgroundPosition: "center",
    backgroundSize: "100%",
    borderRadius: "30px"
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center"
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto"
  },
  fullWidth: {
    width: "100%",
    marginTop: `10px`,
    marginBottom: `10px`
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap"
  },
//     spinningImg: {animationName: 'spin',animationIterationCount: 'infinite',animationTimingFunction: 'linear'},
//     animationTimingFunction: 'linear',
//     "@keyframes spin": {
//     "0%": {
//
//         transform: "rotate(0deg)"
//     },
//     "100%": {
//         transform: "rotate(360deg)"
//     }
// },
  logo: {
    margin: "10%",
    animation: "$spin 5s",
    // display: "flex",
    // flexDirection: "column",
    width: "30%"
    // justifyContent: "center",
    // justifyItems:"center"
  },
  logoText: {
    marginTop: "-8%",
    marginBottom: "10%",
    // backgroundColor: "#f3ec78",
    // fontWeight: "bold",
    backgroundColor: "#f3ec78",
    backgroundImage: "linear-gradient(45deg, #2121F9,#4169e1, #26a3b4);",
    backgroundSize: "100%;-webkit-background-clip: text;-moz-background-clip: text;-webkit-text-fill-color: transparent;-moz-text-fill-color: transparent;",
    animation: "$drop-in 1s ease 200ms backwards"
  },
  input: {
    // marginLeft:"50px"
  },
  error: {
    fontSize: "0.875rem",
    float: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color: "red",
    display: "none"
  }
}));

const duration = require('dayjs/plugin/duration');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

dayjs.extend(durationPlugin);
const SignupSchema = Yup.object().shape({
  // firstName: Yup.string()
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!')
  //     .required('Required'),
  // lastName: Yup.string()
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!')
  //     .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const PasswordReset = () => {
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState('');
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  //const { setAuthTokens } = useAuth();
  // const referer = props.location.state ? props.location.state.referer : '/';
  // const AuthContext = createContext();
  const classes = useStyles();
  classes.textField = undefined;
  const history = useHistory();
  // const useAuth = () => {
  //     return useContext(AuthContext);
  // }
  const validateForm = () => {
    return username.length > 0;
  }

  const handleSubmit = (event, props) => {
    event.preventDefault();
    setLoggedIn(true);
  }

  const handleRoute = () => {
    // history.push("/dashboardMenu");
  }

  useEffect(async () => {
    if (isLoggedIn) {
      setLoading(true)
      try {
        // const result = await axios.post("https://api-dev.hrms.com.vn/api/v1/login", {
        //   username,
        //   password
        // }, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, withCredentixals: false, dataType: "json"}).then(result => {
        //   if (result.status === 200) {
        //     //setAuthTokens(result.data);
        //     setLoggedIn(true);
        //     localStorage.removeItem("authToken");
        //     localStorage.removeItem("refreshToken");
        //     // localStorage.removeItem("ttl");
        //     localStorage.setItem("authToken", result.data.message.access_token);
        //     localStorage.setItem("refreshToken", result.data.message.refresh_token);
        //     if (result.data.message != null) {
        //       setToken(result.data.message.access_token);
        //     }
        //     // RefreshToken();
        //     handleRoute();
        //
        //   } else {
        //     setIsError(true);
        //     setLoggedIn(false);
        //   }
        // })

      } catch (e) {
        setIsError(true);
        setLoggedIn(false);
        console.log(e);
      }

    }
  }, [isLoggedIn])

  // useEffect(() => {
  //     if (isLoggedIn === true) {
  //         setLoading(true)
  //         axios.post("https://api-dev.hrms.com.vn/api/v1/login",
  //             {username, password},
  //             {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
  //             .then(res => {
  //                 if (res.status === 200) {
  //                     //setAuthTokens(result.data);
  //                     console.log(res.data.message);
  //                     setLoggedIn(true);
  //                     console.log(res.data.message);
  //                     // console.log(result.data.message.ttl);
  //                     let expiresAt = ((dayjs().tz("Asia/Ho_Chi_Minh")).valueOf()) + ((res.data.message.ttl) * 1000);
  //                     // console.log(expiresAt);
  //                     // localStorage.removeItem("authToken");
  //                     // localStorage.removeItem("expiresAt");
  //                     // localStorage.removeItem("refreshToken");
  //                     localStorage.removeItem("authToken");
  //                     localStorage.removeItem("refreshToken");
  //                     localStorage.removeItem("expiresAt");
  //                     // localStorage.removeItem("ttl");
  //                     localStorage.setItem("authToken", res.data.message.access_token);
  //                     localStorage.setItem("refreshToken", res.data.message.refresh_token);
  //                     localStorage.setItem("expiresAt", "" + expiresAt);
  //                     if (res.data.message != null) {
  //                         setToken(res.data.message.access_token);
  //                     }
  //                     // RefreshToken();
  //                     setLoading(false);
  //                     handleRoute(res.data.message);
  //                 }
  //
  //             })
  //     }
  //
  // }, [isLoggedIn])


  // async function getToken() {
  //     const headers = {
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //     };
  //     const response = await axios.post("https://api-dev.hrms.com.vn/api/v1/login", {
  //         username,
  //         password
  //     }, {headers: headers, withCredentixals: false, dataType: "json"}).then(async result => {
  //         if (result.status === 200) {
  //             //setAuthTokens(result.data);
  //             setLoggedIn(true);
  //             console.log(result.data.message);
  //
  //             // console.log(result.data.message.ttl);
  //             let expiresAt = ((dayjs().tz("Asia/Ho_Chi_Minh")).valueOf()) + ((result.data.message.ttl) * 1000);
  //             // console.log(expiresAt);
  //             // localStorage.removeItem("authToken");
  //             // localStorage.removeItem("expiresAt");
  //             // localStorage.removeItem("refreshToken");
  //             localStorage.removeItem("authToken");
  //             localStorage.removeItem("refreshToken");
  //             localStorage.removeItem("expiresAt");
  //             // localStorage.removeItem("ttl");
  //             localStorage.setItem("authToken", result.data.message.access_token);
  //             localStorage.setItem("refreshToken", result.data.message.refresh_token);
  //             localStorage.setItem("expiresAt", "" + expiresAt);
  //             if (result.data.message != null) {
  //                 setToken(result.data.message.access_token);
  //             }
  //             // RefreshToken();
  //             handleRoute(result.data.message);
  //
  //         } else {
  //             setIsError(true);
  //         }
  //     }).catch(e => {
  //         setIsError(true);
  //         console.log(e);
  //     });
  // }

  return (

      // <Formik
      //     initialValues={{ username: '', password: '' }}
      //     validate={values => {
      //         const errors = {};
      //         if (!values.email) {
      //             errors.email = 'Vui lòng nhập vào tài khoản';
      //         } else if (
      //             !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      //         ) {
      //             errors.email = 'Địa chỉ email không hợp lệ';
      //         }
      //         return errors;
      //     }}
      //     onSubmit={(values, { setSubmitting }) => {
      //         setTimeout(() => {
      //             alert(JSON.stringify(values, null, 2));
      //             setSubmitting(false);
      //         }, 400);
      //     }}
      // >
      //     {({ isSubmitting }) => (
      //         <Form>
      //             {/*<Field type="email" name="email" />*/}
      //             {/*<ErrorMessage name="email" component="div" />*/}
      //             {/*<Field type="password" name="password" />*/}
      //             {/*<ErrorMessage name="password" component="div" />*/}
      //             {/*<button type="submit" disabled={isSubmitting}>*/}
      //             {/*    Submit*/}
      //             {/*</button>*/}
      //         </Form>
      //     )}
      // </Formik>

      <div className={classNames(classes.session, classes.background)}>
        <div className={classes.content}>
          <div className={classes.wrapper}>
            <Card className={classes.backgroundForm}>
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <div
                      className={classNames(classes.logoContainer, `text-xs-center pb-xs`)}
                  >
                    <img
                        src={`${process.env.PUBLIC_URL}/static/images/LogoPX1-Notext.png`}
                        alt="logo"
                        className={classes.logo}
                    />
                    <Typography className={classes.logoText}>
                      people experience first
                    </Typography>
                    <Typography className={classes.logoText}>
                      people experience first
                    </Typography>
                    <Typography component={'span'}  variant="caption">
                     Nhập vào email đã đăng ký.
                    </Typography>
                  </div>
                  <TextField
                      id="username"
                      label="Email đã đăng ký"
                      className={classes.textField}
                      fullWidth
                      margin="normal"
                      autoFocus
                      type="email"
                      value={username}
                      onChange={(e) => setEmail(e.target.value)}
                  />
                  {/*<TextField*/}
                  {/*    id="password"*/}
                  {/*    label="Mật khẩu"*/}
                  {/*    className={classes.textField}*/}
                  {/*    type="password"*/}
                  {/*    fullWidth*/}
                  {/*    margin="normal"*/}
                  {/*    value={password}*/}
                  {/*    onChange={(e) => setPassword(e.target.value)}*/}
                  {/*/>*/}

                  {/*<FormControlLabel*/}
                  {/*    control={<Checkbox value="checkedA"/>}*/}
                  {/*    label="Lưu thông tin đăng nhập"*/}
                  {/*    className={classes.fullWidth}*/}
                  {/*/>*/}
                  <p className={classes.error}> Đăng nhập thất bại </p>

                  <Button
                      className ={classes.buttonLogin}
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      disabled={!validateForm()}
                  >
                    Reset mật khẩu
                  </Button>
                  <div className="pt-1 text-md-center">
                    <Link to="/">
                      <Button>Trở về trang đăng nhập?</Button>
                    </Link>
                  </div>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
  );
}

export default PasswordReset;


// import Button from "@material-ui/core/Button";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import { Link } from "react-router-dom";
// import React from "react";
// import TextField from "@material-ui/core/TextField";
// import Typography from "@material-ui/core/Typography";
// import classNames from "classnames";
// import { makeStyles } from "@material-ui/core/styles";
//
// const useStyles = makeStyles(theme => ({
//   card: {
//     overflow: "visible"
//   },
//   session: {
//     position: "relative",
//     zIndex: 4000,
//     minHeight: "100vh",
//     display: "flex",
//     flexDirection: "column"
//   },
//   background: {
//     backgroundColor: theme.palette.primary.main
//   },
//   content: {
//     padding: `40px ${theme.spacing(1)}px`,
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     flex: "1 0 auto",
//     flexDirection: "column",
//     minHeight: "100%",
//     textAlign: "center"
//   },
//   wrapper: {
//     flex: "none",
//     maxWidth: "400px",
//     width: "100%",
//     margin: "0 auto"
//   },
//   fullWidth: {
//     width: "100%"
//   },
//   logo: {
//     display: "flex",
//     flexDirection: "column"
//   }
// }));
//
// const PasswordReset = () => {
//   const classes = useStyles();
//   return (
//     <div className={classNames(classes.session, classes.background)}>
//       <div className={classes.content}>
//         <div className={classes.wrapper}>
//           <Card>
//             <CardContent>
//               <form>
//                 <div
//                   className={classNames(classes.logo, `text-xs-center pb-xs`)}
//                 >
//                   <img
//                     src={`${process.env.PUBLIC_URL}/static/images/logo-dark.svg`}
//                     alt=""
//                   />
//                   <Typography variant="caption">
//                     Enter your email and we'll send you instructions on how to
//                     reset your password.
//                   </Typography>
//                 </div>
//                 <TextField
//                   id="email"
//                   label="Email Address"
//                   className={classes.textField}
//                   fullWidth
//                   margin="normal"
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   fullWidth
//                   className="mt-1"
//                   type="submit"
//                 >
//                   Send password reset
//                 </Button>
//                 <div className="pt-1 text-xs-center">
//                   <Link to="/signin">
//                     <Button>Sign</Button>
//                   </Link>
//                   &nbsp;&nbsp;&nbsp;&nbsp;
//                   <Link to="/signup">
//                     <Button>Create new account.</Button>
//                   </Link>
//                 </div>
//               </form>
//             </CardContent>
//           </Card>
//         </div>
//       </div>
//     </div>
//   );
// };
//
// export default PasswordReset;
