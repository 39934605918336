import loginTypes from '../action/type/login.types';
const initialState = {
    isLoading: false,
    error: false,
    data: [],
    success: false

};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case loginTypes.START_LOGIN:
            return { ...state, isLoading: true, success: false, error: false, data: [] };

        case loginTypes.FETCH_SUCCESS_LOGIN:
            return {
                ...state,
                isLoading: false,
                error: false,
                data: action.payload,
                success: action.success,
                message: action.message,
            };

        case loginTypes.FETCH_ERROR:
            return { ...state, isLoading: false, error: true, data: [] };
        default:
            return state;
    }
};

export default loginReducer;

