import React, {useEffect, useState} from 'react';
import {DataGrid, GridApi, GridCellValue} from '@mui/x-data-grid';
import CustomToolbar from "../../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import FormTravelRequestView from "../../../components/Form/Attandance/TravelRequest/FormTravelRequestView";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const rootEl = document.getElementById('root');
const initialFValues = {
    startTime: new Date(),
    endTime: new Date(),
}
// const StyledDataGrid = withStyles({
//     root: {
//
//     },
// })(DataGrid);
const useStyles = makeStyles(theme => ({
    buttonsAdd: {
        marginTop: "15px",
        marginLeft: "20px",
    },
    lightText: {
        color: 'white'
    },
    dataGrid: {
        // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        // background:
        //     `radial-gradient(black 15%, transparent 16%) 0 0,
        //     radial-gradient(black 15%, transparent 16%) 8px 8px,
        //     radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
        //     radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
        //     background-color:#282828;
        //     background-size:16px 16px;`,
        borderRadius: 3,
        border: 0,
        color: "inherit!important",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        width: "100%"
    },
//     '.MuiDataGrid-renderingZone, .MuiDataGrid-root .MuiDataGrid-row' : {
//     width: '100% !important',
//         alignItems: 'center'
// },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    root: {
        padding: "20px",
        '& .super-app-theme--header': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: theme.palette.info.main,
            '&:hover': {
                backgroundColor: theme.palette.info.main,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.main,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        '& .MuiToolbar-root ': {
            color: theme.palette.text.primary
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        }

        // '& .MuiDataGrid-renderingZone': {
        //     maxHeight: 'none !important',
        // },
        // '& .MuiDataGrid-cell': {
        //     lineHeight: 'unset !important',
        //     maxHeight: 'none !important',
        //     whiteSpace: 'normal',
        // },
        // '& .MuiDataGrid-row': {
        //     maxHeight: 'none !important',
        // },
    },

}));

// const validate = (fieldValues = values) => {
//     let temp = {...errors}
//     if ('startTime' in fieldValues)
//         temp.startTime = fieldValues.startTime.valueOf() < fieldValues.endTime.valueOf() ? "" : "Thời gian vào không thể lớn hơn thời gian ra"
//     setErrors({
//         ...temp
//     })
//     const {
//         values,
//         setValues,
//         errors,
//         setErrors,
//         handleInputChange,
//         resetForm
//     } = useForm(initialFValues, true, validate);
// }


const dateFormater = (cell) => {
    return (
        <Moment format="DD-MM-YYYY HH:mm:ss">{cell}</Moment>
    );
};


function addOrEdit(thisRow) {

}

const arrStatus = [{"id":"Pending","name":"Chờ duyệt"},{"id":"Approved","name":"Đã duyệt"},{"id":"Rejected","name":"Từ chối"},{"id":"Cancelled","name":"Đã hủy"}]
export default function TravelRequest() {

    const classes = useStyles();
    // const {addOrEdit, recordForEdit} = props
    const [dataTravels, setDataTravels] = useState([])
    // const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [dataRows, setDataRows] = useState({});
    const handleClose = () => {
        setOpen(false);
        // setDataRows({});
    };
    useEffect(async () => {
        try {

            const api = `https://api-dev.hrms.com.vn/api/v1/travelrequest`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message === null) {
                        res.data.message = {};
                    }
                    // console.log(res.data.message)
                    setDataTravels(res.data.message)
                }
            })

        } catch (e) {

        }
    }, [])
    const data = {
        "columns":
            [{
                "field": "id",
                "headerName": "ID",
                "hide": true,
                headerClassName: 'super-app-theme--header',
                headerAlign: 'center',
            },
                {
                    "field": `employee`,
                    "headerName": "Họ Tên NV",
                    "width": 200,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: (params) => {
                        return `${params.value.last_name} ${params.value.first_name}`
                    },
                },
                {
                    "field": "travel_type",
                    "headerName": "Phương tiện",
                    "width": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "purpose",
                    "headerName": "Lý do công tác",
                    "width": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "travel_from",
                    "headerName": "Nơi đi",
                    "width": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "travel_to",
                    "headerName": "Nơi đến",
                    "width": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "travel_date",
                    "headerName": "Ngày đi",
                    "type": "string",
                    "width": 250,
                    "editable": false,
                    renderCell: (params) => {
                        return dateFormater(params.value)
                    },
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',

                },
                {
                    "field": "return_date",
                    "headerName": "Ngày về",
                    "type": "string",
                    "width": 250,
                    "editable": false,
                    "renderCell": (params) => {
                        return dateFormater(params.value)
                    },
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "status",
                    "headerName": "Trạng thái",
                    "type": "string",
                    "width": 100,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${arrStatus.filter(x => x.id === params.value).map(x => x.name)}`,
                    "renderCell" : params => {
                        return  arrStatus.filter(x => x.id === params.value).map(x => x.name);
                    }
                },
                {
                    "field": "actions",
                    "headerName": "Thao tác",
                    align: "center",
                    "sortTable": false,
                    "type": "string",
                    "width": 160,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                console.log(JSON.stringify(thisRow, null, 4))
                                setOpen(true);
                                // viewRows(thisRow)
                                // return alert(JSON.stringify(thisRow, null, 4));
                            }

                            }>
                                <VisibilityIcon sx={{color: '#58AD69!important'}}/>
                            </IconButton>
                        </div>
                    )
                }],
        "rows": dataTravels,
        rowLength: 100,
        maxColumns: 6,
    }

    return (
        <>
            <Grid item xs={2} className={classes.buttonsAdd}>
                <div>
                    <Button color="primary" variant="contained"
                            onClick={() => {
                                setOpenAdd(true)
                            }}
                    >
                        <AddIcon/>
                        Thêm mới
                    </Button>
                </div>
            </Grid>
            {/*<div style={{ height: '75%', width: '100%' }}>*/}
            <div style={{height: '800px', width: '97%'}} className={classes.root}>
                <DataGrid
                    {...data}
                    alignItems="center"
                    justifyContent="center"
                    className={classes.dataGrid}
                    disableExtendRowFullWidth={true}
                    getRowClassName={(params) =>
                        `super-app-theme--${params.getValue(params.id, 'status')}`}
                    hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                    localeText={
                        {
                            noRowsLabel: 'Không có dữ liệu',
                            noResultsOverlayLabel: 'Không tìm thấy kết quả.',
                            errorOverlayDefaultLabel: 'An error occurred.',
                            // Density selector toolbar button text
                            toolbarDensity: 'Độ cao dòng',
                            toolbarDensityLabel: 'Độ cao dòng',
                            toolbarDensityCompact: 'Thu gọn',
                            toolbarDensityStandard: 'Bình thường',
                            toolbarDensityComfortable: 'Mở rộng',

                            // Columns selector toolbar button text
                            toolbarColumns: 'Cột',
                            toolbarColumnsLabel: 'Chọn cột',

                            // Filters toolbar button text
                            toolbarFilters: 'Lọc',
                            toolbarFiltersLabel: 'Hiển thị Lọc',
                            toolbarFiltersTooltipHide: 'Ẩn Lọc',
                            toolbarFiltersTooltipShow: 'Hiển thị Lọc',
                            toolbarFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,

                            // Export selector toolbar button text
                            toolbarExport: 'Xuất dữ liệu',
                            toolbarExportLabel: 'Xuất dữ liệu',
                            toolbarExportCSV: 'Tải xuống định dạng CSV',
                            toolbarExportPrint: 'In',

                            // Columns panel text
                            columnsPanelTextFieldLabel: 'Tìm kiếm cột',
                            columnsPanelTextFieldPlaceholder: 'Tên cột',
                            columnsPanelDragIconLabel: 'Reorder column',
                            columnsPanelShowAllButton: 'Hiển thị tất cả',
                            columnsPanelHideAllButton: 'Ẩn tất cả',

                            // Filter panel text
                            filterPanelAddFilter: 'Thêm lọc',
                            filterPanelDeleteIconLabel: 'Xóa',
                            filterPanelOperators: 'Lọc theo:',
                            filterPanelOperatorAnd: 'Và',
                            filterPanelOperatorOr: 'Hoặc',
                            filterPanelColumns: 'Cột',
                            filterPanelInputLabel: 'Giá trị',
                            filterPanelInputPlaceholder: 'Giá trị lọc',

                            // Filter operators text
                            filterOperatorContains: 'chứa',
                            filterOperatorEquals: 'bằng',
                            filterOperatorStartsWith: 'bắt đầu với',
                            filterOperatorEndsWith: 'kết thúc với',
                            filterOperatorIs: 'is',
                            filterOperatorNot: 'is not',
                            filterOperatorAfter: 'is after',
                            filterOperatorOnOrAfter: 'is on or after',
                            filterOperatorBefore: 'is before',
                            filterOperatorOnOrBefore: 'is on or before',
                            filterOperatorIsEmpty: 'giá trị rỗng',
                            filterOperatorIsNotEmpty: 'giá trị không rỗng',

                            // Filter values text
                            filterValueAny: 'any',
                            filterValueTrue: 'true',
                            filterValueFalse: 'false',

                            // Column menu text
                            columnMenuLabel: 'Danh mục',
                            columnMenuShowColumns: 'Hiển thị cột',
                            columnMenuFilter: 'Lọc',
                            columnMenuHideColumn: 'Ẩn',
                            columnMenuUnsort: 'Chưa sắp xếp',
                            columnMenuSortAsc: 'Sắp xếp tăng dần',
                            columnMenuSortDesc: 'Sắp xếp giảm dần',

                            // Column header text
                            columnHeaderFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,
                            columnHeaderFiltersLabel: 'Hiển thị lọc',
                            columnHeaderSortIconLabel: 'Sắp xếp',

                            // Rows selected footer text
                            footerRowSelected: (count) =>
                                count !== 1
                                    ? `${count.toLocaleString()} dòng đang chọn`
                                    : `${count.toLocaleString()} dòng đang chọn`,

                            // Total rows footer text
                            footerTotalRows: 'Tổng dòng:',

                            // Total visible rows footer text
                            footerTotalVisibleRows: (visibleCount, totalCount) =>
                                `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
                            // Checkbox selection text
                            checkboxSelectionHeaderName: 'Checkbox selection',

                            // Boolean cell text
                            booleanCellTrueLabel: 'true',
                            booleanCellFalseLabel: 'false',

                            // Actions cell more text
                            actionsCellMore: 'more',

                            // Tree Data
                            treeDataGroupingHeaderName: 'Nhóm',
                            treeDataExpand: 'Hiện danh sách con',
                            treeDataCollapse: 'Ẩn danh sách con',
                            TablePagination: 'ok'
                        }
                    }
                    components={{
                        Toolbar: CustomToolbar,
                        title: "Danh mục chức danh"
                    }}
                />

            </div>
            <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
                <DialogTitle>CHI TIẾT CÔNG TÁC</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"xs"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <FormTravelRequestView data={dataRows} arrStatus={arrStatus}/>
                        {/*<MuiButton onClick={handleClose} style={{width: "25%"}}>*/}
                        {/*    Đóng*/}
                        {/*</MuiButton>*/}
                        <Button color="primary" variant="contained" onClick={handleClose}>
                            Đóng
                        </Button>
                    </div>

                </DialogActions>
            </Dialog>
        </>
    );
}

