import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import * as Yup from 'yup';
import dayjs from "dayjs";
import durationPlugin from 'dayjs/plugin/duration';
import {keyframes} from "styled-components";
import clsx from "clsx";
import AlertMessage from "../../components/Alert/AlertMessage";
import {SnackbarProvider} from "notistack";
import AlertError from "../../components/Alert/AlertError";
// import {RefreshToken} from "../../components/Signin/RefreshToken";
// import {
//     BackendError,
//     Lockscreen,
//     NotFound,
//     PasswordReset,
//     Signup,
//     Dashboard,
//     DashboardMenu
// } from "../../pages";


const useStyles = makeStyles(theme => ({

    "@keyframes spin": {
        "0%": {
            opacity: 0,
            transform: "rotate(0deg)"
        },
        "100%": {
            opacity: 1,
            transform: "rotate(360deg)"
        }
    },
    "@keyframes drop-in": {
        "from": {
            opacity: 0,
            transform: "translateY(-100px)"
        },
        "to": {
            opacity: 1,
            transform: "translate(0px)"
        }
    },

    buttonLogin: {
        marginTop: '20px'
    },
    card: {
        overflow: "visible",

    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        // backgroundColor: theme.palette.primary.main,
        background: "url(" + `${process.env.PUBLIC_URL}/static/images/backgroundHR.jpeg` + ") no-repeat center center fixed",
        // backgroundPosition: "center",
        // backgroundSize:"100%",
        // background: "cover",
        // backgroundRepeat : "no-repeat"
        backgroundSize: "cover"
    },
    backgroundForm: {
        // backgroundColor: theme.palette.primary.main,
        //backgroundImage: "url(" + `${process.env.PUBLIC_URL}/static/images/backgroundHR.jpg` + ")",
        backgroundColor: "rgba(255,255,255,1.0)",
        backgroundPosition: "center",
        backgroundSize: "100%",
        borderRadius: "30px"
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%",
        marginTop: `10px`,
        marginBottom: `10px`
    },
    logoContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        flexDirection: "column",
        flexWrap: "wrap"
    },
//     spinningImg: {animationName: 'spin',animationIterationCount: 'infinite',animationTimingFunction: 'linear'},
//     animationTimingFunction: 'linear',
//     "@keyframes spin": {
//     "0%": {
//
//         transform: "rotate(0deg)"
//     },
//     "100%": {
//         transform: "rotate(360deg)"
//     }
// },
    logo: {
        margin: "10%",
        animation: "$spin 5s",
        // display: "flex",
        // flexDirection: "column",
        width: "30%"
        // justifyContent: "center",
        // justifyItems:"center"
    },
    logoText: {
        marginTop: "-8%",
        marginBottom: "10%",
        // backgroundColor: "#f3ec78",
        // fontWeight: "bold",
        backgroundColor: "#f3ec78",
        backgroundImage: "linear-gradient(45deg, #2121F9,#4169e1, #26a3b4);",
        backgroundSize: "100%;-webkit-background-clip: text;-moz-background-clip: text;-webkit-text-fill-color: transparent;-moz-text-fill-color: transparent;",
        animation: "$drop-in 1s ease 200ms backwards"
    },
    logoText2: {
        marginTop: "-8%",
        marginBottom: "10%",
        // backgroundColor: "#f3ec78",
        // fontWeight: "bold",
        backgroundColor: "#f3ec78",
        backgroundImage: "linear-gradient(45deg, #2121F9,#4169e1, #26a3b4);",
        backgroundSize: "100%;-webkit-background-clip: text;-moz-background-clip: text;-webkit-text-fill-color: transparent;-moz-text-fill-color: transparent;",
        animation: "$drop-in 1s ease 200ms backwards",
        fontWeight:"bold",
        fontSize:"20px"
    },
    input: {
        // marginLeft:"50px"
    },
    error: {
        fontSize: "0.875rem",
        float: "left",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        color: "red",
        display: "none"
    }
}));

const duration = require('dayjs/plugin/duration');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

dayjs.extend(durationPlugin);
const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});

const Signin = () => {
    const [exit, setExit] = useState(false);
    const [username, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [token, setToken] = useState('');
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statusAlert, setStatusAlert] = useState("");
    const submitAlert = async () => {
        await setStatusAlert({msg: 'Sai tên đăng nhập hoặc mật khẩu', key: Math.random()});
    };
    const submitAlertError = async () => {
        await setStatusAlert({msg: "Sai tên đăng nhập hoặc mật khẩu", key: Math.random()});
    };

    //const { setAuthTokens } = useAuth();
    // const referer = props.location.state ? props.location.state.referer : '/';
    // const AuthContext = createContext();
    const classes = useStyles();
    classes.textField = undefined;
    const history = useHistory();
    // const useAuth = () => {
    //     return useContext(AuthContext);
    // }
    const validateForm = () => {
        return username.length > 0 && password.length > 0;
    }

    const handleSubmit = (event, props) => {
        event.preventDefault();
        setLoggedIn(true);
    }

    const handleRoute = () => {
        history.push("/dashboardMenu");
    }

    useEffect(async () => {
        if (isLoggedIn) {
            setLoading(true)
            try {
                const result = await axios.post("https://api-dev.hrms.com.vn/api/v1/login", {
                    username,
                    password
                }, {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    withCredentixals: false,
                    dataType: "json"
                }).then(result => {
                    if (result.status === 200) {
                        setLoggedIn(true);
                        localStorage.removeItem("authToken");
                        localStorage.removeItem("refreshToken");
                        localStorage.setItem("authToken", result.data.message.access_token);
                        localStorage.setItem("refreshToken", result.data.message.refresh_token);
                        if (result.data.message != null) {
                            setToken(result.data.message.access_token);
                        }

                        // RefreshToken();
                        handleRoute();

                    } else {

                        setIsError(true);
                        setLoggedIn(false);
                    }
                })

            } catch (e) {
                await submitAlertError()
                await setIsError(true);
                await  setLoggedIn(false);
                console.log(e);
                console.log(isError)

            }

        }
    }, [isLoggedIn])
    return (

        <div className={classNames(classes.session, classes.background)}>
            <SnackbarProvider maxSnack={4}>
                <div className={classes.content}>
                    <div className={classes.wrapper}>
                        <Card className={classes.backgroundForm}>
                            <CardContent>
                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={classNames(classes.logoContainer, `text-xs-center pb-xs`)}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/static/images/LogoPX1-Notext.png`}
                                            alt="logo"
                                            className={classes.logo}
                                        />
                                        <Typography className={classes.logoText}>
                                            people experience first
                                        </Typography >
                                        <Typography className={classes.logoText2}>
                                            (CORE)
                                        </Typography>
                                        <Typography component={'span'} variant="caption">
                                            Vui lòng đăng nhập để truy cập.
                                        </Typography>
                                    </div>
                                    <TextField
                                        id="username"
                                        label="Tài khoản"
                                        className={classes.textField}
                                        fullWidth
                                        margin="normal"
                                        autoFocus
                                        type="email"
                                        value={username}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <TextField
                                        id="password"
                                        label="Mật khẩu"
                                        className={classes.textField}
                                        type="password"
                                        fullWidth
                                        margin="normal"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />

                                    {/*<FormControlLabel*/}
                                    {/*    control={<Checkbox value="checkedA"/>}*/}
                                    {/*    label="Lưu thông tin đăng nhập"*/}
                                    {/*    className={classes.fullWidth}*/}
                                    {/*/>*/}
                                    <p className={classes.error}> Đăng nhập thất bại </p>

                                    <Button
                                        className={classes.buttonLogin}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        disabled={!validateForm()}
                                    >
                                        Đăng nhập
                                    </Button>
                                    <div className="pt-1 text-md-center">
                                        <Link to="/forgot">
                                            <Button>Quên mật khẩu?</Button>
                                        </Link>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                    </div>
                </div>
                {statusAlert ? <AlertError  key={statusAlert.key} message={statusAlert.msg}/> : null}
            </SnackbarProvider >
        </div>
    );
}

export default Signin;
