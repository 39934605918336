import React from 'react';

import { Wrapper, Example } from '../../components';
import ItemExpansionPanels from "./ItemExpansionPanel";
import ItemWorkProfile from "./ItemWorkProfile";

const arrExpansion = [
    {
        // js: require('../../utils/demos/expansion-panels/ControlledExpansionPanels').default,
        js: require('../../components/ExpansionPanel/ControlledWorkProfile.js').default,
        title: 'Controlled Expansion Panel',
        // docs: 'https://material-ui.com/api/expansion-panel/'
        docs:''
    }
]

const WorkProfileExpansionPanels = (props) => (
    <div style={{width: '100%' }}>
        {arrExpansion.map((demo, index) => (
            <ItemWorkProfile style={{width: '100%'}}
                             userInfo={props.userInfo }
                             genderItems = {props.genderItems}
                                 key={index}
                                 index={index}
                                 title={demo.title}
                                 js={demo.js}
                                 docs={demo.docs}
            />
        ))}
    </div>
);

export default WorkProfileExpansionPanels;
