import React, {Component, useState} from "react";
import Chart from "react-apexcharts";

const LineChartEmployee = () => {
    const [options, setObjects] = useState({

        chart: {
            height: 250,
            type: "area"
        },
        markers: {
            size: [4, 7]
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            fontSize: "16px",

            labels: {
                colors: "#b2b2b2",
                useSeriesColors: false
            }
        },
        colors: ["#7db975", "#de286f", "white"],

        stroke: {
            curve: "smooth"
        },
        yaxis: {
            labels: {
                style: {
                    colors: ["#b2b2b2"]
                }
            }
        },
        xaxis: {
            labels: {
                style: {
                    colors: ["#b2b2b2"]
                }
            },
            type: "datetime",
            categories: [
                "2021-09-19T00:00:00.000Z",
                "2021-10-19T01:30:00.000Z",
                "2021-11-19T02:30:00.000Z",
                "2021-12-19T03:30:00.000Z",
                "2022-01-19T04:30:00.000Z",
                "2022-02-19T05:30:00.000Z",
                "2022-03-19T06:30:00.000Z"
            ]
        },
        tooltip: {
            x: {
                format: "dd/MM/yy HH:mm"
            }
        }
    })
    const [series, setSeries] = useState([
        {
            name: "Khách hàng đăng ký mới",
            data: [31, 40, 28, 51, 42, 109, 100]
        },
        {
            name: "Khách hàng hủy gói",
            data: [11, 32, 45, 32, 34, 52, 41]
        }
    ])


    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={options}
                        series={series}
                        type="area"
                        width="100%"
                        height="345px"
                    />
                </div>
            </div>
        </div>
    );
}

export default LineChartEmployee;
