import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {getDroppedOrSelectedFiles} from 'html5-file-selector'
import Input from "./Input"
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {useEffect, useState} from "react";

const useStyles = makeStyles(theme => ({
    dropzone: {
        width: '25%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        backgroundColor: 'red'
    },

}))
const UploadFile = (props) => {
    // Dropzone.prototype.defaultOptions.dictDefaultMessage = "Drop files here to upload";
    // Dropzone.prototype.defaultOptions.dictFallbackMessage = "Your browser does not support drag'n'drop file uploads.";
    // Dropzone.prototype.defaultOptions.dictFallbackText = "Please use the fallback form below to upload your files like in the olden days.";
    // Dropzone.prototype.defaultOptions.dictFileTooBig = "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.";
    // Dropzone.prototype.defaultOptions.dictInvalidFileType = "You can't upload files of this type.";
    // Dropzone.prototype.defaultOptions.dictResponseError = "Server responded with {{statusCode}} code.";
    // Dropzone.prototype.defaultOptions.dictCancelUpload = "Cancel upload";
    // Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Are you sure you want to cancel this upload?";
    // Dropzone.prototype.defaultOptions.dictRemoveFile = "Remove file";
    // Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "You can not upload any more files.";
    const classes = useStyles();
    const [listFiles,setListFiles] = useState([])
    const handleSubmit = (files, allFiles) => {
        // console.log(props.model_name)
        // console.log(files.map(f => f.meta))
        // // allFiles.forEach(f => f.remove())
        //
        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                'Content-Type': 'multipart/form-data'
            }
        };
        let fd = new FormData();
        allFiles.map(f  => {
            fd.append( "file",f.file);
            console.log(JSON.stringify(f))
        });
        setListFiles[fd]
        // fd.append('file', allFiles[0])
    }
    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }
    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const result = await axios.post(`https://api-dev.hrms.com.vn/api/v1/upload/${props.model_name}`, fd, config)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data.message);
                        props.values.file = response.data.message;
                    }
                    // callback(response);
                })

            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [listFiles])
    return (


        <Dropzone className={classes.dropzone}
            // accept="image/*,audio/*,video/*,.pdf"
            //       getUploadParams={() => ({url: 'https://httpbin.org/post'})}
                  onSubmit={handleSubmit}
                  InputComponent={Input}
                  isDragActive={true}
                  getFilesFromEvent={getFilesFromEvent}
                  submitButtonContent='Tải lên'
                  styles={{dropzone: {minHeight: 100, maxHeight: 50}}}
        >
            {/*{({getRootProps, getInputProps, isDragActive, isDragReject}) => (*/}
            {/*    <div {...getRootProps()}>*/}
            {/*        <input {...getInputProps()} />*/}
            {/*        {!isDragActive && 'Click here or drop a file to upload!'}*/}
            {/*        {isDragActive && !isDragReject && "Drop it like it's hot!"}*/}
            {/*        {isDragReject && "File type not accepted, sorry!"}*/}
            {/*    </div>*/}
            {/*)}*/}

        </Dropzone>
    )
}

export default UploadFile;
