import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import {handleCloseAddEdit} from "../../pages/Employees/JobDetailsSetup";
import axios from "axios";
import {MenuItem} from "@material-ui/core";
import {RefreshToken} from "../Signin/RefreshToken";

const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const FormDepartmentsDelete = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const initDataDelete = props.data
    const countries = props.countries
    const [dataDelete,setDataDelete] = useState(initDataDelete)
    const classes = useStyles();
    const validationSchema = yup.object({
    });
    useEffect(async () => {
        try {

            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/companystructure`
            const result = await axios.post(api, dataDelete,{headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                console.log(res);
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }
                else if (res.data.code === 401){
                    RefreshToken()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch(e) {

        }
    }, [dataDelete])

    const handleDelete = (values) => {
        // console.log(JSON.stringify(values));
        const dataNew = {"message":{...{"id":values.id}},"action":"delete","type_message":"map"}
        // alert(JSON.stringify({dataNew}, localStorage.getItem("authToken")))
        setDataDelete(dataNew);
        // console.log(dataNew)
    }
    const formik = useFormik({
        initialValues: {
            id: props.data.id,
            name: props.data.name,
            detail: props.data.description,
            type: props.data.type.name,
            timezone: props.data.timezone,
            parent: props.data.parent.name,
            address: props.data.address,
            country: props.data.country
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleDelete(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="name"
                            name="name"
                            label="Tên Phòng Ban"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="detail"
                            name="detail"
                            label="Mô Tả"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.detail}
                            onChange={formik.handleChange}
                            error={formik.touched.detail && Boolean(formik.errors.detail)}
                            helperText={formik.touched.detail && formik.errors.detail}
                            multiline
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="type"
                            name="type"
                            label="Phân Loại"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            error={formik.touched.type && Boolean(formik.errors.type)}
                            helperText={formik.touched.type && formik.errors.type}
                            multiline
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="parent"
                            label="Thuộc Phòng Ban"
                            name="parent"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.parent}
                            onChange={formik.handleChange}
                            error={formik.touched.parent && Boolean(formik.errors.parent)}
                            helperText={formik.touched.parent && formik.errors.parent}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="address"
                            label="Địa chỉ"
                            name="address"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="country"
                            name="country"
                            label="Quốc gia"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.country}
                            // error={values.country.id === ""}
                            // helperText={values.country.id === "" ? "Dữ liệu bắt buộc" : ""}
                            disabled
                            multiline
                        >
                            {countries.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name !== `Viet Nam` ? `${x.name}` : `Việt Nam`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="timezone"
                            label="Múi Giờ"
                            name="timezone"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.timezone}
                            onChange={formik.handleChange}
                            error={formik.touched.timezone && Boolean(formik.errors.timezone)}
                            helperText={formik.touched.timezone && formik.errors.timezone}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Xóa
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormDepartmentsDelete;
