import {Formik, Form, Field, ErrorMessage, useField} from 'formik';
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {alignItems, flex} from "tailwindcss/lib/plugins";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {MenuItem} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
export default function FormDepartmentsView(props) {
    const classes = useStyles();
    const [data, setData] = useState(props.data)
    const countries = props.countries
    // alert(JSON.stringify(data))
    return (
        <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
            <Grid item xs={12} sm={12} md={6} lg={5}>
                <Grid item>
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        id="id"
                        label="ID"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={data.id}
                        disabled

                    />
                </Grid>
                <Grid item>
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        id="name"
                        label="Tên Phòng Ban"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={data.name}
                        disabled
                    />
                </Grid>
                <Grid item>
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        id="detail"
                        label="Mô tả"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={data.detail}
                        disabled

                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="country"
                        name="country"
                        label="Quốc gia"
                        select
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={data.country}
                        // error={values.country.id === ""}
                        // helperText={values.country.id === "" ? "Dữ liệu bắt buộc" : ""}
                        disabled
                        multiline
                    >
                        {countries.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                                {x.name !== `Viet Nam` ? `${x.name}` : `Việt Nam`}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        id="type"
                        label="Phân Loại"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={data.type.name}
                        disabled

                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5}>
                <Grid item>
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        id="parent"
                        label="Thuộc Phòng Ban"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={data.parent.name}
                        disabled

                    />
                </Grid>
                <Grid item>
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        id="address"
                        label="Địa Chỉ"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={data.address}
                        disabled

                    />
                </Grid>
                <Grid item>
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        id="created_at"
                        label="Ngày Tạo"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={moment(data.created_at).format('YYYY-MM-DD')}
                        disabled
                    />
                </Grid>
                <Grid item>
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        id="updated_at"
                        label="Ngày sửa đổi"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={moment(data.updated_at).format('YYYY-MM-DD')}
                        disabled
                    />
                </Grid>

            </Grid>
        </Grid>
    )

}
