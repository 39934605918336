import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from "dayjs";
import viLocale from "date-fns/locale/vi";
import TimePicker from "../../components/controls/TimePicker";
import format from "date-fns/format";
import DatePicker from "../controls/DatePicker";
import moment from 'moment';
import 'moment-timezone';
import {RefreshToken} from "../Signin/RefreshToken";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (
            this.parent
                .filter(v => v !== value)
                .some(v => v[propertyName] === value[propertyName])
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});

// yup.addMethod(yup.string, 'unique', function(message, mapper = a => a) {
//     return this.test('unique', message, function(list) {
//         return list.length  === new Set(list.map(mapper)).size;
//     });
// });
const FormShiftTimeAdd = (props) => {
    const [dataAdd, setDataAdd] = useState()
    const classes = useStyles();
    const isSameOrBefore = (start, end) => {
        return moment(start, 'HH:mm:ss').isSameOrBefore(moment(end, 'HH:mm:ss'));
    }
    const validationSchema = yup.object().shape({
        description: yup
            .string('Nhập vào mô tả'),
        name: yup
            .string('Nhập vào loại phòng ban')
            .required('Dữ liệu bắt buộc nhập'),
        start_time: yup
            .date()
            .typeError('Sai định dạng thời gian')
            .test(
                'not empty',
                'Vui lòng nhập thời gian bắt đầu',
                function(value) {
                    return !!value;
                }
            )
            .test(
                "start_time_test",
                "Thời gian bắt đầu phải trước thời gian kết thúc",
                function(value) {
                    const { end_time } = this.parent;
                    return isSameOrBefore(value, end_time);
                }
            ),
        end_time: yup.date()
            .typeError('Sai định dạng thời gian')

    });

    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/shifttime`
            const result = await axios.post(api, dataAdd, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 201) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataAdd])
    const handleAdd = (values) => {
        values.start_time = moment.tz(values.start_time, "Asia/Ho_Chi_Minh").format('HH:mm:ss');
        values.end_time = moment.tz(values.end_time, "Asia/Ho_Chi_Minh").format('HH:mm:ss');
        // console.log(JSON.stringify(values))
        const dataNew = {"message": {...values}, "action": "create", "type_message": "map"}
        // console.log(JSON.stringify(dataNew))

        setDataAdd(dataNew)
    }
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            start_time: moment.tz('Asia/Ho_Chi_Minh').startOf('day').utc(),
            end_time: moment.tz('Asia/Ho_Chi_Minh').endOf('day').utc()
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleAdd(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="name"
                            name="name"
                            label="Tên ca làm việc"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="description"
                            name="description"
                            label="Mô Tả"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            multiline
                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <MuiPickersUtilsProvider locale={viLocale} utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                className={classes.textField}
                                // inputFormat="HH:mm:ss"
                                // value={values.start_time}
                                // onChange={handleInputChange}
                                name="start_time"
                                id="start_time"
                                ampm={false}
                                margin="normal"
                                inputVariant="outlined"
                                openTo="hours"
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss"
                                mask="__:__:__"
                                label="TG bắt đầu"
                                keyboardIcon={<AccessTimeIcon/>}
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.start_time}
                                value={formik.values.start_time}
                                // value={startTime}

                                onChange={startTime => formik.setFieldValue('start_time', moment.tz(startTime, "Asia/Ho_Chi_Minh").format())}
                                error={formik.touched.start_time && Boolean(formik.errors.start_time)}
                                helperText={formik.touched.start_time && formik.errors.start_time}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardTimePicker
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                className={classes.textField}
                                name="end_time"
                                id="end_time"
                                ampm={false}
                                margin="normal"
                                inputVariant="outlined"
                                openTo="hours"
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss"
                                mask="__:__:__"
                                label="TG kết thúc"
                                keyboardIcon={<AccessTimeIcon/>}
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.end_time}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                error={formik.touched.end_time && Boolean(formik.errors.end_time)}
                                helperText={formik.touched.end_time && formik.errors.end_time}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Thêm mới
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormShiftTimeAdd;
