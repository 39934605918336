import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css'

import {Calendar, momentLocalizer} from 'react-big-calendar';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import React, {Children, useEffect, useState} from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TodayIcon from '@material-ui/icons/Today';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {mockEvents} from '../../utils/mock';
import moment from 'moment';
import 'moment/locale/vi'

const localizer = momentLocalizer(moment);
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CustomEvent from "./CustomEvent";
import {RefreshToken} from "../Signin/RefreshToken";
import axios from "axios";
import AttandanceData from "./AttandanceData";

const CustomToolbar = (toolbar) => {
    const [count, setCount] = useState(0)
    // console.log(`test: ${JSON.stringify(toolbar.date)}`);
    // const [monthAttandance, setMonthAttendance] = useState(toolbar.date)
    const handleChanger = (event, value) => {
        switch (value) {
            case 'day':
                goToDayView();
                break;
            case 'week':
                goToWeekView();
                break;
            case 'agenda':
                goToAgendaView();
                break;
            default:
                goToMonthView();
                break;
        }
    };

    const goToDayView = async () => await toolbar.onView('day');

    const goToWeekView = async () => await toolbar.onView('week');

    const goToMonthView = async () => await toolbar.onView('month');

    const goToAgendaView = async () => await toolbar.onView('agenda');
    const goToBack = async () => {
        await toolbar.date.setMonth(toolbar.date.getMonth() - 1);
        await toolbar.onNavigate('prev');
    };

    const goToNext = async () => {
        await toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        await toolbar.onNavigate('next');
    };

    const goToCurrent = async () => {
        const now = new Date();
        await toolbar.date.setMonth(now.getMonth());
        await toolbar.date.setYear(now.getFullYear());
        await toolbar.onNavigate('current');
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span>
        <b>{date.format('MMMM')}</b>
        <span> {date.format('YYYY')}</span>
      </span>
        );
    };
    useEffect(async () => {
        await setCount(2)
    }, [])
    useEffect(async () => {
        if (count === 2) {
            const startDate = new Date(toolbar.date.getFullYear(), toolbar.date.getMonth(), 1);
            const endDate = new Date(toolbar.date.getFullYear(), toolbar.date.getMonth() + 1, 0);
            const startTime = moment(startDate).format('YYYY-MM-DD')
            const endTime = moment(endDate).format('YYYY-MM-DD')
            console.log(`${startTime} ----- ${endTime}`);
            console.log(localStorage.getItem("authToken"))
            const api = `https://api-dev.hrms.com.vn/api/v1/attendance/me?start_date=${startTime}&end_date=${endTime}`
            const api2 = `https://api-dev.hrms.com.vn/api/v1/leaveavailable`
            const api3 = `https://api-dev.hrms.com.vn/api/v1/leaverequest/me?start_date=${startTime}&end_date=${endTime}`
            const api4 = `https://api-dev.hrms.com.vn/api/v1/travelrequest/me?start_date=${startTime}&end_date=${endTime}`
            const api5 = `https://api-dev.hrms.com.vn/api/v1/maternityleave/me?start_date=${startTime}&end_date=${endTime}`
            const api6 = `https://api-dev.hrms.com.vn/api/v1/compassionateleave/me?start_date=${startTime}&end_date=${endTime}`


            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message === null) {
                        res.data.message = [];
                    }
                    toolbar.setDataAttandance(res.data.message)
                    // console.log(res.data.message)
                    // setCount(0)
                } else {

                }
            })

            const result2 = await axios.get(api2, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res2 => {
                if (res2.status === 200) {
                    if (res2.data.message === null) {
                        res2.data.message = [];
                    }
                    toolbar.setTypeLeaves(res2.data.message)
                    // console.log(res2.data.message)
                    // setCount(0)
                } else {

                }
            })
            const result3 = await axios.get(api3, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res3 => {
                if (res3.status === 200) {
                    if (res3.data.message === null) {
                        res3.data.message = [];
                    }
                    toolbar.setDataLeaves(res3.data.message)
                    // console.log(res3.data.message)
                    // setCount(0)
                } else {

                }
            })
            const result4 = await axios.get(api4, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res4 => {
                if (res4.status === 200) {
                    if (res4.data.message === null) {
                        res4.data.message = [];
                    }
                    toolbar.setDataTravels(res4.data.message)
                    console.log(res4.data.message)
                    // setCount(0)
                } else {

                }
            })
            const result5 = await axios.get(api5, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res5 => {
                if (res5.status === 200) {
                    if (res5.data.message === null) {
                        res5.data.message = [];
                    }
                    toolbar.setDataMaternityLeaves(res5.data.message)
                    console.log(res5.data.message)
                    // setCount(0)
                } else {

                }
            })
            const result6 = await axios.get(api6, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res6 => {
                if (res6.status === 200) {
                    if (res6.data.message === null) {
                        res6.data.message = [];
                    }
                    toolbar.setDataCompassionateLeaves(res6.data.message)
                    console.log(res6.data.message)
                    // setCount(0)
                } else {

                }
            })

        }


    }, [toolbar.date.getMonth()])
    return (
        <AppBar position="static" style={{zIndex: 1000}}>
            <Toolbar className="calendar-toolbar" style={{backgroundColor: '#1A94FF'}}>
                <Hidden mdUp>
                    <Typography component={'span'} variant="h6" color="inherit" className="flexSpacer px-1">
                        {label()}
                    </Typography>
                    <IconButton aria-label="Previous" color="inherit" onClick={goToBack}>
                        <ChevronLeftIcon/>
                    </IconButton>
                    <IconButton aria-label="Today" color="inherit" onClick={goToCurrent}>
                        <TodayIcon/>
                    </IconButton>
                    <IconButton aria-label="Next" color="inherit" onClick={goToNext}>
                        <ChevronRightIcon/>{' '}
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Hidden smDown>
                <Toolbar className="calendar-toolbar" style={{backgroundColor: '#1A94FF'}}>
                    <Typography component={'span'} variant="h6" color="inherit" className="flexSpacer px-1"
                                style={{marginTop: '-50px'}}>
                        {label()}
                    </Typography>
                    {/*<Button color="inherit" onClick={goToDayView}>*/}
                    {/*    Trong ngày*/}
                    {/*</Button>*/}
                    {/*<Button color="inherit" onClick={goToWeekView}>*/}
                    {/*    Trong tuần*/}
                    {/*</Button>*/}
                    <Button color="inherit" onClick={goToMonthView}>
                        Trong tháng
                    </Button>
                    {/*<Button color="inherit" onClick={goToAgendaView}>*/}
                    {/*    Danh sách*/}
                    {/*</Button>*/}
                    <Hidden mdUp>
                        <span className="flexSpacer"/>
                    </Hidden>
                    <IconButton aria-label="Previous" color="inherit" onClick={goToBack}>
                        <ChevronLeftIcon/>
                    </IconButton>
                    <IconButton aria-label="Today" color="inherit" onClick={goToCurrent}>
                        <TodayIcon/>
                    </IconButton>
                    <IconButton aria-label="Next" color="inherit" onClick={goToNext}>
                        <ChevronRightIcon/>{' '}
                    </IconButton>
                </Toolbar>
            </Hidden>
            <Hidden mdUp>
                <Tabs value={toolbar.view} onChange={handleChanger} style={{backgroundColor: '#1A94FF'}}>
                    {/*<Tab label="Trong ngày" value="day"/>*/}
                    {/*<Tab label="Trong tuần" value="week"/>*/}
                    <Tab label="Trong tháng" value="month"/>
                    {/*<Tab label="Danh sách" value="agenda"/>*/}
                </Tabs>
            </Hidden>
        </AppBar>
    );
};

const AttandanceCelander = () => {
    // const [toolbarReturn, setToolbarReturn] = useState([])
    // console.log(`Return date${toolbar.date}`)
    // const [statusReturn, setStatusReturn] = useState(0)
    const [dataAttandance, setDataAttandance] = useState([])
    const [typeLeaves, setTypeLeaves] = useState([])
    const [dataLeaves, setDataLeaves] = useState([])
    const [dataTravels, setDataTravels] = useState([])
    const [dataMaternityLeaves, setDataMaternityLeaves] = useState([])
    const [dataCompassionateLeaves, setDataCompassionateLeaves] = useState([])
    useEffect(async () => {
        const dateNow = new Date()
        const startDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
        const endDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
        const startTime = moment(startDate).format('YYYY-MM-DD')
        const endTime = moment(endDate).format('YYYY-MM-DD')
        // console.log(`${startTime} ----- ${endTime}`);
        // console.log(localStorage.getItem("authToken"))
        const api = `https://api-dev.hrms.com.vn/api/v1/attendance/me?start_date=${startTime}&end_date=${endTime}`
        const api2 = `https://api-dev.hrms.com.vn/api/v1/leaveavailable`
        const api3 = `https://api-dev.hrms.com.vn/api/v1/leaverequest/me?start_date=${startTime}&end_date=${endTime}`
        const api4 = `https://api-dev.hrms.com.vn/api/v1/travelrequest/me?start_date=${startTime}&end_date=${endTime}`
        const api5 = `https://api-dev.hrms.com.vn/api/v1/maternityleave/me?start_date=${startTime}&end_date=${endTime}`
        const api6 = `https://api-dev.hrms.com.vn/api/v1/compassionateleave/me?start_date=${startTime}&end_date=${endTime}`

        const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
            if (res.status === 200) {
                if (res.data.message === null) {
                    res.data.message = [];
                }
                setDataAttandance(res.data.message)
                // console.log(res.data.message)
                // setCount(0)
            } else {

            }
        })
        const result2 = await axios.get(api2, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res2 => {
            if (res2.status === 200) {
                if (res2.data.message === null) {
                    res2.data.message = [];
                }
                setTypeLeaves(res2.data.message)
                // console.log(res2.data.message)
                // setCount(0)
            } else {

            }
        })
        const result3 = await axios.get(api3, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res3 => {
            if (res3.status === 200) {
                if (res3.data.message === null) {
                    res3.data.message = [];
                }
                setDataLeaves(res3.data.message)
                // console.log(res3.data.message)
                // setCount(0)
            } else {

            }
        })
        const result4 = await axios.get(api4, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res4 => {
            if (res4.status === 200) {
                if (res4.data.message === null) {
                    res4.data.message = [];
                }
                setDataTravels(res4.data.message)
                console.log(res4.data.message)
                // setCount(0)
            } else {

            }
        })
        const result5 = await axios.get(api5, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res5 => {
            if (res5.status === 200) {
                if (res5.data.message === null) {
                    res5.data.message = [];
                }
                setDataMaternityLeaves(res5.data.message)
                console.log(res5.data.message)
                // setCount(0)
            } else {

            }
        })
        const result6 = await axios.get(api6, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res6 => {
            if (res6.status === 200) {
                if (res6.data.message === null) {
                    res6.data.message = [];
                }
                setDataCompassionateLeaves(res6.data.message)
                console.log(res6.data.message)
                // setCount(0)
            } else {

            }
        })

    }, [])
    // console.log(dataAttandance)
    const renameKeyAttandace = (({in_time: start, out_time: end, ...rest}) => ({title: "chấm công", start, end, ...rest}))
    const arrayDataAttandance = [];
    dataAttandance.map(x => {
        x.in_time = new Date(x.in_time)
        x.out_time = "" ? new Date(x.out_time) : new Date(x.in_time)
        arrayDataAttandance.push((renameKeyAttandace(x)))
    })
    const renameKeyTravels = (({date: start, ...rest}) => ({title: "công tác", start, ...rest}))
    // console.log(dataLeaves)
    dataTravels.map(x => {
        if (x.status === "Approved")  {
            x.dates?.map(y =>{
                y.date = new Date(y.date)
                y.end = y.date
                arrayDataAttandance.push((renameKeyTravels(y)))
            })
        }
    })
    const renameKeyLeaves = (({date: start, ...rest}) => ({title: "nghỉ phép", start, ...rest}))
    // console.log(dataLeaves)
    dataLeaves.map(x => {
        if (x.status === "Approved")  {
            x.dates?.map(y =>{
                y.date = new Date(y.date)
                y.end = y.date
                arrayDataAttandance.push((renameKeyLeaves(y)))
            })
        }

    })
    const renameKeyMaternityLeaves = (({date: start, ...rest}) => ({title: "nghỉ thai sản", start, ...rest}))
    // console.log(dataLeaves)
    dataMaternityLeaves.map(x => {
        if (x.status === "Approved") {
            x.dates?.map(y =>{
                y.date = new Date(y.date)
                y.end = y.date
                arrayDataAttandance.push((renameKeyMaternityLeaves(y)))
            })
        }

    })
    const renameKeyCompassionateLeaves = (({date: start, ...rest}) => ({title: "nghỉ việc riêng", start, ...rest}))
    // console.log(dataLeaves)
    dataCompassionateLeaves.map(x => {
        if (x.status === "Approved") {
            x.dates?.map(y =>{
                y.date = new Date(y.date)
                y.end = y.date
                arrayDataAttandance.push((renameKeyCompassionateLeaves(y)))
            })
        }

    })
    // console.log(arrayDataAttandance)
    return (
        <Typography component="span">
            <Calendar
                style={{height: '800px', width: '800px', border: '1px solid grey'}}
                culture='vi-VN'
                overflow='true'
                onNavigate={(date, view) => {
                    // console.log('#### onNavigate');
                    // console.log('#### date=', date);
                    // console.log('#### view=', view);
                    //this.setState({currentDate: date});
                }}
                onView={(view) => {
                    // console.log('#### onView');
                    // console.log('#### view=', view);
                    //this.setState({currentView: view});
                }}
                localizer={localizer}
                events={arrayDataAttandance}
                defaultDate={new Date()}
                components={{
                    // toolbar: CustomToolbar,
                    toolbar: props => (<CustomToolbar {...props} setTypeLeaves={setTypeLeaves} setDataAttandance={setDataAttandance} setDataTravels={setDataTravels} setDataLeaves={setDataLeaves} setDataMaternityLeaves={setDataMaternityLeaves} setDataCompassionateLeaves={setDataCompassionateLeaves}/>),
                    event: CustomEvent,
                    // dateCellWrapper: '112321'
                }}
            />
        </Typography>
    );
};

export default AttandanceCelander;

//test data
// id: 9,
//     title: 'Bao bận',
//     start: new Date(2022, 3, 12, 17, 0, 0, 0),
//     end: new Date(2022, 3, 12, 17, 30, 0, 0),
//     desc: 'Most important meal of the day'
