import React, {useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import CustomToolbar from "../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Moment from "react-moment";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import FormChangePassword from "../../components/System/FormChangePassword";
import LockIcon from '@mui/icons-material/Lock';
import {CustomLocaleText} from "../../components/CustomTableToolbar/CustomLocaleText";
import {
    ColorActionDelete,
    ColorActionEdit, ColorActionUpdatePW,
    ColorActionView,
    ColorButton,
    ColorHeader, ColorTextButton
} from "../../components/MainStyleCSS/ColorHeader";
import FormUsersAdd from "../../components/System/User/FormUsersAdd";
import FormUsersView from "../../components/System/User/FormUserView";
import FormUserDelete from "../../components/System/User/FormUserDelete";
import FormUserUpdate from "../../components/System/User/FormUserUpdate";
import FormUserChangePassword from "../../components/System/User/FormUserChangePassword";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const rootEl = document.getElementById('root');
const initialFValues = {
    startTime: new Date(),
    endTime: new Date(),
}

const useStyles = makeStyles(theme => ({

    TitleDialog:{
        backgroundColor:ColorButton,
        color: ColorTextButton,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold; line-height: 20px',
        // background: 'linear-gradient(#9BC90D 0%, #79A70A 100%)',
        background: 'linear-gradient(#1A94FF 0%, #1977d4 100%)',
        boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
    },
    ContainerDialog: {
        maxWidth: "lg",
        maxHeight: "calc(100vh - 64px)",

    },
    paper: {borderRadius: 15},
    ContentDialog: {
        margin: "30px",
    },
    buttonsAdd: {
        color: '#1A94FF',
        padding: "10px",
        position: 'absolute',
        right: 0,
        marginTop: '10px',
        marginRight: '10px',
        zIndex: 1
    },
    lightText: {
        color: 'white'
    },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    root: {

        clear: "both",
        padding: "10px",
        '& .super-app-theme--header': {
            backgroundColor: ColorHeader,
            // color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        '& .MuiToolbar-root ': {
            color: theme.palette.text.primary
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        }
    },

}));

const dateFormater = (cell) => {
    return (
        <Moment format="DD-MM-YYYY HH:mm:ss">{cell}</Moment>
    );
};

function addOrEdit(thisRow) {

}

export default function Users() {

    const classes = useStyles();
    // const {addOrEdit, recordForEdit} = props
    const [dataUser, setDataUser] = useState([])
    // const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openChangePW, setOpenChangePW] = useState(false);
    const [dataRows, setDataRows] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [dataEmployees, setDataEmployees] = useState([]);
    // console.log(dataUser)
    const handleEdit = () => {
        setOpenEdit(false);
    };
    const handleDelete = () => {
        setOpenDelete(false);
    };
    const handleAdd = () => {
        setOpenAdd(false);
    };
    const handleClose = () => {
        setOpen(false);
        // setDataRows({});
    };
    useEffect(async () => {
        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/employee`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message === null) {
                        res.data.message = {};
                    }
                    //
                    console.log(res.data.message);
                    // console.log(localStorage.getItem("authToken"))
                    setDataEmployees(res.data.message)
                }

            })
        } catch (e) {

        }
    }, [openAdd, openDelete, openEdit])
    useEffect(async () => {

        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/user`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    let newArray = []
                    if (res.data.message === null) {
                        res.data.message = {};
                    } else {
                        console.log(res.data.message)
                        newArray = res.data.message.map((element) => {
                                return {
                                    id: element.id,
                                    e_id: element.employee.id,
                                    e_first_name: element.employee.first_name,
                                    e_last_name: element.employee.last_name,
                                    r_id: element.role.id,
                                    r_name: element.role.name,
                                    user_name: element.user_name,
                                }
                            }
                        );
                    }

                    // console.log(newArray)
                    setDataUser(newArray)
                }
            })

        } catch (e) {

        }
    }, [openAdd, openDelete, openEdit, isExpired])

    const data = {
        "columns":
            [
                {
                    "field": "id",
                    "headerName": "ID",
                    "hide": true,
                    "minWidth": 300,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "e_id",
                    "headerName": "ID nhân viên",
                    "hide": true,
                    "editable": false,
                    "minWidth": 250,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "user_name",
                    "headerName": "Tài khoản",
                    "minWidth": 200,
                    flex: 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "e_first_name",
                    "headerName": "Tên",
                    "minWidth": 200,
                    flex: 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "e_last_name",
                    "headerName": "Họ và tên đệm",
                    "type": "string",
                    flex: 1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "r_id",
                    "headerName": "ID phân quyền",
                    "type": "string",
                    "minWidth": 250,
                    flex: 1,
                    "hide": true,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "r_name",
                    "headerName": "Tên phân quyền",
                    "type": "string",
                    flex: 1,
                    "minWidth": 250,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "actions",
                    "headerName": "Thao tác",
                    "sortTable": false,
                    "type": "string",
                    "width": 210,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpen(true);
                                // viewRows(thisRow)
                                // return alert(JSON.stringify(thisRow, null, 4));
                            }

                            }>
                                <VisibilityIcon style={{color: ColorActionView}}/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenEdit(true);
                            }
                            }>
                                <EditIcon style={{color: ColorActionEdit}}/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenDelete(true);
                            }}>
                                <DeleteForeverIcon style={{color: ColorActionDelete}}/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenChangePW(true);
                            }}>
                                <LockIcon style={{color: ColorActionUpdatePW}}/>
                            </IconButton>
                        </div>
                    )
                },
            ],
        "rows": dataUser,
        rowLength: 100,
        maxColumns: 6,
    }

    return (
        <>
            <Grid item xs={2} className={classes.buttonsAdd}>
                <div>
                    <Button variant="contained" style={{backgroundColor: ColorButton, color: 'white'}}
                            onClick={() => {
                                setOpenAdd(true)
                            }}
                    >
                        <AddIcon/>
                        Thêm mới
                    </Button>
                </div>
            </Grid>

            <div style={{
                height: '100vh',
                width: 'calc(100vh -150px)',
                boxShadow: '0.25rem 0.25rem 0.6rem rgb(0 0 0 / 5%), 0 0.5rem 1.125rem rgb(75 0 0 / 5%)'
            }} className={classes.root}>
                <DataGrid
                    {...data}
                    className={classes.dataGrid}
                    disableExtendRowFullWidth={true}
                    getRowClassName={(params) =>
                        `super-app-theme--${params.getValue(params.id, 'status')}`}
                    hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                    localeText={
                        CustomLocaleText
                    }
                    components={{
                        Toolbar: CustomToolbar,
                        title: "KHÁCH HÀNG"
                    }}
                />
            </div>
            <Dialog open={open} className={`${classes.ContainerDialog}`}>
                <DialogTitle className={classes.TitleDialog}>CHI TIẾT TÀI KHOẢN</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                        <FormUsersView data={dataRows}
                                       employees={dataEmployees}
                                           onClick={() => {
                                               setOpen(false)
                                           }}
                        />
                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} className={`${classes.ContainerDialog}`}>
                <DialogTitle className={classes.TitleDialog}>CẬP NHẬT TÀI KHOẢN</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormUserUpdate
                        data={dataRows}
                        employees = {dataEmployees}
                        onClick={() => {
                            setOpenEdit(false)
                        }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openChangePW} className={`${classes.ContainerDialog}`}>
                <DialogTitle className={classes.TitleDialog}>ĐỔI MẬT KHẨU</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormUserChangePassword
                        data={dataRows}
                        employees = {dataEmployees}
                        onClick={() => {
                            setOpenChangePW(false)
                        }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} className={`${classes.ContainerDialog}`}>
                <DialogTitle className={classes.TitleDialog}>XÓA TÀI KHOẢN</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                        <FormUserDelete
                            data={dataRows}
                            employees = {dataEmployees}
                            onClick={() => {
                                setOpenDelete(false)
                            }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openAdd} className={`${classes.ContainerDialog}`}>
                <DialogTitle className={classes.TitleDialog}>THÊM TÀI KHOẢN</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormUsersAdd
                        employees={dataEmployees}
                        onClick={() => {
                            setOpenAdd(false)
                        }}/>
                    {/*</div>*/}
                </DialogActions>
            </Dialog>
            {/*<Dialog open={openPW} fullWidth={true} maxWidth={"xs"}*/}
            {/*>*/}
            {/*    <DialogTitle>ĐỔI MẬT KHẨU : {dataRows.user_name}</DialogTitle>*/}
            {/*    <DialogActions fullWidth={true} maxWidth={"xs"}>*/}
            {/*        <div style={{*/}
            {/*            display: 'flex',*/}
            {/*            flexDirection: 'column',*/}
            {/*            width: '100%',*/}
            {/*            alignItems: 'center',*/}
            {/*            justifyContent: 'center'*/}
            {/*        }}>*/}
            {/*            /!*<FormChangePassword*!/*/}
            {/*            /!*    data={dataRows}*!/*/}
            {/*            /!*    onClick={() => {*!/*/}
            {/*            /!*        setOpenPW(false)*!/*/}
            {/*            /!*    }}/>*!/*/}
            {/*        </div>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
        </>
    );
}

