import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import moment from "moment";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import viLocale from "date-fns/locale/vi";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {setHours} from "date-fns";
import {RefreshToken} from "../Signin/RefreshToken";

const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const FormShiftTimeUpdate = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const [dataUpdate,setDataUpdate] = useState({})
    // console.log(props.data)
    const classes = useStyles();

    const isSameOrBefore = (start, end) => {
        return moment(start, 'HH:mm:ss').isSameOrBefore(moment(end, 'HH:mm:ss'));
    }
    const validationSchema = yup.object().shape({
        description: yup
            .string('Nhập vào mô tả'),
        name: yup
            .string('Nhập vào loại phòng ban')
            .required('Dữ liệu bắt buộc nhập'),
        start_time: yup
            .date()
            .typeError('Sai định dạng thời gian')
            .test(
                'not empty',
                'Vui lòng nhập thời gian bắt đầu',
                function(value) {
                    return !!value;
                }
            )
            .test(
                "start_time_test",
                "Thời gian bắt đầu phải trước thời gian kết thúc",
                function(value) {
                    const { end_time } = this.parent;
                    return isSameOrBefore(value, end_time);
                }
            ),
        end_time: yup.date()
            .typeError('Sai định dạng thời gian')

    });
    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/shifttime`
            const result = await axios.post(api, dataUpdate, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataUpdate])
    const handleUpdate = (values) => {
        // console.log(JSON.stringify(values));
        const data = values
        // data.created_at = undefined;
        // data.updated_at = undefined;
        values.start_time = moment.tz(values.start_time, "Asia/Ho_Chi_Minh").format('HH:mm:ss');
        values.end_time = moment.tz(values.end_time, "Asia/Ho_Chi_Minh").format('HH:mm:ss');
        const dataNew = {"message":{...data},"action":"update","type_message":"map"}
        // console.log(JSON.stringify(dataNew))
        setDataUpdate(dataNew)
    }
    const formik = useFormik({
        initialValues: {
            id: props.data.id,
            name: props.data.name,
            // created_at: props.data.created_at,
            // updated_at: props.data.updated_at,
            description: props.data.description,
            start_time: moment(new Date(`2021-12-12T${props.data.start_time}`)).utc().format(),
            end_time: moment(new Date(`2021-12-12T${props.data.end_time}`)).utc().format()
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleUpdate(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="name"
                            name="name"
                            label="Tên ca làm việc"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="description"
                            name="description"
                            label="Mô Tả"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            multiline
                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <MuiPickersUtilsProvider locale={viLocale} utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                className={classes.textField}
                                // inputFormat="HH:mm:ss"
                                // value={values.start_time}
                                // onChange={handleInputChange}
                                name="start_time"
                                id="start_time"
                                ampm={false}
                                margin="normal"
                                inputVariant="outlined"
                                openTo="hours"
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss"
                                mask="__:__:__"
                                label="TG bắt đầu"
                                keyboardIcon={<AccessTimeIcon/>}
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                value={formik.values.start_time}
                                onChange={startTime => formik.setFieldValue('start_time', startTime)}
                                error={formik.touched.start_time && Boolean(formik.errors.start_time)}
                                helperText={formik.touched.start_time && formik.errors.start_time}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardTimePicker
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                className={classes.textField}
                                name="end_time"
                                id="end_time"
                                ampm={false}
                                margin="normal"
                                inputVariant="outlined"
                                openTo="hours"
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss"
                                mask="__:__:__"
                                label="TG kết thúc"
                                keyboardIcon={<AccessTimeIcon/>}
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.end_time}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={endTime => formik.setFieldValue('end_time', endTime)}
                                error={formik.touched.end_time && Boolean(formik.errors.end_time)}
                                helperText={formik.touched.end_time && formik.errors.end_time}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Cập nhật
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormShiftTimeUpdate;
