import React, {useEffect, useState} from 'react';
import {DataGrid, GridApi, GridCellValue} from '@mui/x-data-grid';
import CustomToolbar from "../../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import MyWorkFromHomeAdd from "../../../components/Form/Attandance/WorkFromHome/MyWorkFromHomeAdd";
import MyWorkFromHomeView from "../../../components/Form/Attandance/WorkFromHome/MyWorkFromHomeView";
import MyWorkFromHomeUpdate from "../../../components/Form/Attandance/WorkFromHome/MyWorkFromHomeUpdate";
import MyWorkFromHomeDelete from "../../../components/Form/Attandance/WorkFromHome/MyWorkFromHomeDelete";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

// const StyledDataGrid = withStyles({
//     root: {
//
//     },
// })(DataGrid);
const useStyles = makeStyles(theme => ({
    buttonsAdd: {
        marginTop: "15px",
        marginLeft: "20px",
    },
    lightText: {
        color: 'white'
    },
    dataGrid: {
        borderRadius: 3,
        border: 0,
        color: "inherit!important",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        width: "100%"
    },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    root: {
        padding: "20px",
        '& .super-app-theme--header': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: theme.palette.info.main,
            '&:hover': {
                backgroundColor: theme.palette.info.main,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.main,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        '& .MuiToolbar-root ': {
            color: theme.palette.text.primary
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        }
    },

}));
const arrStatus = [{"id": "Pending", "name": "Chờ duyệt"}, {"id": "Approved", "name": "Đã duyệt"}, {
    "id": "Rejected",
    "name": "Từ chối"
}, {"id": "Cancelled", "name": "Đã hủy"}]
const categories = [{"id": "Be Late", "name": "Xin đi trễ"}, {"id": "Be Early", "name": "Xin về sớm"}]
const typeDate = [{"id": "Full Day", "name": "Cả ngày"}, {"id": "Half Day - Morning","name":"Nửa ngày - Buổi sáng"},{ "id" : "Half Day - Afternoon", "name":"Nửa ngày - Buổi chiều"}]
const MyWorkFromHome = () => {

    const classes = useStyles();
    // const {addOrEdit, recordForEdit} = props
    const [myLaEData, setMyLaEData] = useState([])
    // const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [dataRows, setDataRows] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    // const [categories, setCategories] = useState([]);
    const convertString = (arr) =>{
        const stringData = arr.reduce((result, item) => {
            return `${result}${item.date},${item.type}|`
        }, "")
        return stringData;
    }
    const handleEdit = () => {
        setOpenEdit(false);
    };
    const handleDelete = () => {
        setOpenDelete(false);
    };
    const handleAdd = () => {
        setOpenAdd(false);
    };
    const handleChangeStatus = () => {
        setOpenStatus(false);
        // setDataRows({});
    };
    const handleClose = () => {
        setOpen(false);
        // setDataRows({});
    };
    useEffect(async () => {
        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/workfromhome/me`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message === null) {
                        res.data.message = {};
                    }
                    console.log(res.data.message);
                    setMyLaEData(res.data.message)
                }
            })


        } catch (e) {

        }
    }, [openAdd, openDelete, openEdit, isExpired])
//Cleanup and not warning Memory Leak

    //
    const data = {
        "columns":
            [{
                "field": "id",
                "headerName": "ID",
                "hide": true,
                "flex": 1,
                align: 'center',
                headerClassName: 'super-app-theme--header',
                headerAlign: 'center',
            },
                {
                    "field": "start_date",
                    "headerName": "Ngày bắt đầu",
                    "type": "string",
                    align: 'center',
                    "flex": 1,
                    "minWidth": 200,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "end_date",
                    align: 'center',
                    "headerName": "Ngày kết thúc",
                    "type": "string",
                    "flex": 1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "notes",
                    align: 'center',
                    "minWidth": 200,
                    "headerName": "Mô tả chi tiết",
                    "type": "string",
                    "flex": 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',

                },
                {
                    "field": "dates",
                    align: 'center',
                    "minWidth": 200,
                    "headerName": "Chi tiết ngày",
                    "type": "string",
                    "flex": 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    // "valueFormatter": (params) => `${params.map()}`,
                    "renderCell": (params) => {
                        return (() => convertString(params))
                    },

                },
                {
                    "field": "status",
                    align: 'center',
                    "headerName": "Trạng thái",
                    "type": "string",
                    "flex": 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${arrStatus.filter(x => x.id === params.value).map(x => x.name)}`,
                    "renderCell": params => {
                        return arrStatus.filter(x => x.id === params.value).map(x => x.name);
                    },
                },
                {
                    "field": "log_change_status",
                    align: 'center',
                    "headerName": "Lịch sử phê duyệt",
                    "type": "string",
                    "flex": 1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    // "valueFormatter": (params) => `${arrStatus.filter(x => x.id === params.data.status).map(x => x.name)}`,
                    "valueFormatter": "",
                    "renderCell": params => {
                        params.value
                    },
                },
                {
                    "field": "actions",
                    align: 'center',
                    "headerName": "Thao tác",
                    "sortTable": false,
                    "type": "string",
                    "width": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: params => (
                        (params.row.status === 'Pending') ?
                            (moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(moment(`${params.row.date_request} ${params.row.time_request}`, "YYYY-MM-DD HH:mm:ss")) >= 0) ?
                                <div>
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation(); // don't select this row after clicking
                                        const api: GridApi = params.api;
                                        const thisRow: Record<string, GridCellValue> = {};
                                        api
                                            .getAllColumns()
                                            .filter((c) => c.field !== "__check__" && !!c)
                                            .forEach(
                                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                            );
                                        setDataRows(thisRow);
                                        setOpen(true);
                                        // viewRows(thisRow)
                                        // return alert(JSON.stringify(thisRow, null, 4));
                                    }

                                    }>
                                        <VisibilityIcon sx={{color: '#58AD69!important'}}/>
                                    </IconButton>
                                </div>
                                : <div>
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation(); // don't select this row after clicking
                                        const api: GridApi = params.api;
                                        const thisRow: Record<string, GridCellValue> = {};
                                        api
                                            .getAllColumns()
                                            .filter((c) => c.field !== "__check__" && !!c)
                                            .forEach(
                                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                            );
                                        setDataRows(thisRow);
                                        setOpen(true);
                                        // viewRows(thisRow)
                                        // return alert(JSON.stringify(thisRow, null, 4));
                                    }

                                    }>
                                        <VisibilityIcon sx={{color: '#58AD69!important'}}/>
                                    </IconButton>
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation(); // don't select this row after clicking
                                        const api: GridApi = params.api;
                                        const thisRow: Record<string, GridCellValue> = {};
                                        api
                                            .getAllColumns()
                                            .filter((c) => c.field !== "__check__" && !!c)
                                            .forEach(
                                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                            );
                                        setDataRows(thisRow);
                                        setOpenEdit(true);
                                    }
                                    }>
                                        <EditIcon sx={{color: '#22C6F0!important'}}/>
                                    </IconButton>
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation(); // don't select this row after clicking
                                        const api: GridApi = params.api;
                                        const thisRow: Record<string, GridCellValue> = {};
                                        api
                                            .getAllColumns()
                                            .filter((c) => c.field !== "__check__" && !!c)
                                            .forEach(
                                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                            );
                                        setDataRows(thisRow);
                                        setOpenDelete(true);
                                    }}>
                                        <DeleteForeverIcon sx={{color: '#FF0000!important'}}/>
                                    </IconButton>
                                </div>

                            :
                            // Not Pending
                            <div>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation(); // don't select this row after clicking
                                    const api: GridApi = params.api;
                                    const thisRow: Record<string, GridCellValue> = {};
                                    api
                                        .getAllColumns()
                                        .filter((c) => c.field !== "__check__" && !!c)
                                        .forEach(
                                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                        );
                                    setDataRows(thisRow);
                                    setOpen(true);
                                    // viewRows(thisRow)
                                    // return alert(JSON.stringify(thisRow, null, 4));
                                }

                                }>
                                    <VisibilityIcon sx={{color: '#58AD69!important'}}/>
                                </IconButton>
                            </div>


                    )

                }],
        "rows": myLaEData,
        rowLength: 100,
        maxColumns: 6,
    }

    return (
        <>

            <Grid item xs={2} className={classes.buttonsAdd}>
                <div>
                    <Button color="primary" variant="contained"
                            onClick={() => {
                                setOpenAdd(true)
                            }}
                    >
                        <AddIcon/>
                        Thêm mới
                    </Button>
                </div>
            </Grid>

            <div style={{height: '800px', width: '97%'}} className={classes.root}>
                <DataGrid
                    {...data}
                    className={classes.dataGrid}
                    disableExtendRowFullWidth={true}
                    getRowClassName={(params) =>
                        `super-app-theme--${params.getValue(params.id, 'status')}`}
                    hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                    localeText={
                        {
                            noRowsLabel: 'Không có dữ liệu',
                            noResultsOverlayLabel: 'Không tìm thấy kết quả.',
                            errorOverlayDefaultLabel: 'An error occurred.',
                            // Density selector toolbar button text
                            toolbarDensity: 'Độ cao dòng',
                            toolbarDensityLabel: 'Độ cao dòng',
                            toolbarDensityCompact: 'Thu gọn',
                            toolbarDensityStandard: 'Bình thường',
                            toolbarDensityComfortable: 'Mở rộng',

                            // Columns selector toolbar button text
                            toolbarColumns: 'Cột',
                            toolbarColumnsLabel: 'Chọn cột',

                            // Filters toolbar button text
                            toolbarFilters: 'Lọc',
                            toolbarFiltersLabel: 'Hiển thị Lọc',
                            toolbarFiltersTooltipHide: 'Ẩn Lọc',
                            toolbarFiltersTooltipShow: 'Hiển thị Lọc',
                            toolbarFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,

                            // Export selector toolbar button text
                            toolbarExport: 'Xuất dữ liệu',
                            toolbarExportLabel: 'Xuất dữ liệu',
                            toolbarExportCSV: 'Tải xuống định dạng CSV',
                            toolbarExportPrint: 'In',

                            // Columns panel text
                            columnsPanelTextFieldLabel: 'Tìm kiếm cột',
                            columnsPanelTextFieldPlaceholder: 'Tên cột',
                            columnsPanelDragIconLabel: 'Reorder column',
                            columnsPanelShowAllButton: 'Hiển thị tất cả',
                            columnsPanelHideAllButton: 'Ẩn tất cả',

                            // Filter panel text
                            filterPanelAddFilter: 'Thêm lọc',
                            filterPanelDeleteIconLabel: 'Xóa',
                            filterPanelOperators: 'Lọc theo:',
                            filterPanelOperatorAnd: 'Và',
                            filterPanelOperatorOr: 'Hoặc',
                            filterPanelColumns: 'Cột',
                            filterPanelInputLabel: 'Giá trị',
                            filterPanelInputPlaceholder: 'Giá trị lọc',

                            // Filter operators text
                            filterOperatorContains: 'chứa',
                            filterOperatorEquals: 'bằng',
                            filterOperatorStartsWith: 'bắt đầu với',
                            filterOperatorEndsWith: 'kết thúc với',
                            filterOperatorIs: 'is',
                            filterOperatorNot: 'is not',
                            filterOperatorAfter: 'is after',
                            filterOperatorOnOrAfter: 'is on or after',
                            filterOperatorBefore: 'is before',
                            filterOperatorOnOrBefore: 'is on or before',
                            filterOperatorIsEmpty: 'giá trị rỗng',
                            filterOperatorIsNotEmpty: 'giá trị không rỗng',

                            // Filter values text
                            filterValueAny: 'any',
                            filterValueTrue: 'true',
                            filterValueFalse: 'false',

                            // Column menu text
                            columnMenuLabel: 'Danh mục',
                            columnMenuShowColumns: 'Hiển thị cột',
                            columnMenuFilter: 'Lọc',
                            columnMenuHideColumn: 'Ẩn',
                            columnMenuUnsort: 'Chưa sắp xếp',
                            columnMenuSortAsc: 'Sắp xếp tăng dần',
                            columnMenuSortDesc: 'Sắp xếp giảm dần',

                            // Column header text
                            columnHeaderFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,
                            columnHeaderFiltersLabel: 'Hiển thị lọc',
                            columnHeaderSortIconLabel: 'Sắp xếp',

                            // Rows selected footer text
                            footerRowSelected: (count) =>
                                count !== 1
                                    ? `${count.toLocaleString()} dòng đang chọn`
                                    : `${count.toLocaleString()} dòng đang chọn`,

                            // Total rows footer text
                            footerTotalRows: 'Tổng dòng:',

                            // Total visible rows footer text
                            footerTotalVisibleRows: (visibleCount, totalCount) =>
                                `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
                            // Checkbox selection text
                            checkboxSelectionHeaderName: 'Checkbox selection',

                            // Boolean cell text
                            booleanCellTrueLabel: 'true',
                            booleanCellFalseLabel: 'false',

                            // Actions cell more text
                            actionsCellMore: 'more',

                            // Tree Data
                            treeDataGroupingHeaderName: 'Nhóm',
                            treeDataExpand: 'Hiện danh sách con',
                            treeDataCollapse: 'Ẩn danh sách con',
                            TablePagination: 'ok'
                        }
                    }
                    components={{
                        Toolbar: CustomToolbar,
                        title: "Danh mục ca làm việc"
                    }}
                />

            </div>
            <Dialog open={open} fullWidth={true} maxWidth={"md"} align='center'>
                <DialogTitle>CHI TIẾT YÊU CẦU WFH CỦA TÔI</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <MyWorkFromHomeView data={dataRows} arrStatus={arrStatus}/>
                        {/*<MuiButton onClick={handleClose} style={{width: "25%"}}>*/}
                        {/*    Đóng*/}
                        {/*</MuiButton>*/}
                        <Button color="primary" variant="contained" onClick={handleClose}>
                            Đóng
                        </Button>
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} fullWidth={true} maxWidth={"md"}
            >
                <DialogTitle>CẬP NHẬT YÊU CẦU WFH CỦA TÔI</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <MyWorkFromHomeUpdate
                            data={dataRows}
                            listStatus={arrStatus}
                            onClick={() => {
                                setOpenEdit(false)
                            }}/>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} fullWidth={true} align='center'
            >
                <DialogTitle>XÓA YÊU CẦU WFH CỦA TÔI</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <MyWorkFromHomeDelete
                            data={dataRows}
                            categories={categories} listStatus={arrStatus}
                            onClick={() => {
                                setOpenDelete(false)
                            }}/>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog open={openAdd} fullWidth={true} maxWidth={"md"}
            >
                <DialogTitle align="center">THÊM YÊU CẦU WFH CỦA TÔI</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {/*<MyWorkFromHomeAdd  listStatus={arrStatus}*/}
                        {/*                   typeDate = {typeDate}*/}
                        {/*                   onClick={() => {*/}
                        {/*                       setOpenAdd(false)*/}
                        {/*                   }}/>*/}
                        <MyWorkFromHomeAdd  listStatus={arrStatus}
                                            typeDate = {typeDate}
                                            onClick={() => {
                                                setOpenAdd(false)
                                            }}/>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default MyWorkFromHome;
