import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import moment from "moment";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import viLocale from "date-fns/locale/vi";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {MenuItem} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
const format1 = "YYYY-MM-DD"
const format2 = "YYYY-MM-DD HH:mm:ss"

const initialValues = {
    type_request: 'Be Late',
    details: '',
}
const MyLateAndEarlyUpdate = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const [dataUpdate, setDataUpdate] = useState({})
    // console.log(props.data)
    const classes = useStyles();
    const [selectedTime, setTime] = useState(moment(`${moment(new Date()).format('YYYY-MM-DD')} ${props.data.time_request}`).format(format2));
    const [selectedDate, handleDateChange] = useState(moment(props.data.date_request).format(format1));
    const categories = props.categories
    const [count, setCount] = useState(0)
    const handleTimeChange = (val) => {
        const hours = new Date(val).getHours();
        const minutes = new Date(val).getMinutes();
        const seconds = new Date(val).getSeconds();
        // console.log(`${hours}:${minutes}:${seconds}`);
        setTime(val);
    };
    const validationSchema = yup.object().shape({
        details: yup
            .string('Nhập vào mô tả')
            .required('Vui lòng nhập vào mô tả'),
    });
    useEffect(async () => {
        if (count) {
            try {
                // console.log(JSON.stringify(dataUpdate))
                const api = `https://api-dev.hrms.com.vn/api/v1/worktimerequest`
                const result = await axios.post(api, dataUpdate, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                    if (res.status === 200) {
                        // window.location.reload();
                        props.onClick()
                    }
                })
                // const imageAvatr = await getImageAvatar(result.image);
            } catch (e) {
            }
        }
    }, [count])
    const handleUpdate = (dataSubmit) => {
        // console.log(JSON.stringify(values))
        const temp = {}
        temp.id = props.data.id
        temp.date_request = moment(selectedDate).format('YYYY-MM-DD')
        temp.time_request = moment(selectedTime).format('HH:mm:ss')
        temp.type_request = dataSubmit.type_request
        temp.details = dataSubmit.details
        const dataNew = {"message": {...temp}, "action": "update", "type_message": "map"}
        // console.log(JSON.stringify(dataNew))
        setDataUpdate(dataNew)
        setCount(1);
    }
    const formik = useFormik({
        initialValues: {
            id: props.data.id,
            details: props.data.details,
            date_request: props.data.date_request,
            time_request: props.data.time_request,
            type_request: props.data.type_request,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleUpdate(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            style={{minWidth: "200px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="type_request"
                            name="type_request"
                            label="Loại yêu cầu"
                            className={classes.textField}
                            select
                            margin="normal"
                            variant="outlined"
                            value={formik.values.type_request}
                            onChange={formik.handleChange}
                            error={formik.touched.type_request && Boolean(formik.errors.type_request)}
                            helperText={formik.touched.type_request && formik.errors.type_request}

                        >
                            {categories.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardDatePicker
                                style={{minWidth: "200px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                InputLabelProps={{
                                    shrink: formik.values.date_request ? true : undefined,
                                }}
                                className={classes.textField}
                                label="Ngày yêu cầu"
                                name="date_request"
                                id="date_request"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy"
                                placeholder="ngày-tháng-năm"
                                mask="__/__/____"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                onChange={(date) => handleDateChange(date)}
                                minDate={new Date()}
                                value={selectedDate}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            style={{minWidth: "200px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="details"
                            name="details"
                            label="Mô tả chi tiết"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.details}
                            onChange={formik.handleChange}
                            error={formik.touched.details && Boolean(formik.errors.details)}
                            helperText={formik.touched.details && formik.errors.details}

                        />

                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider locale={viLocale} utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                style={{minWidth: "200px"}}
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                className={classes.textField}
                                // inputFormat="HH:mm:ss"
                                // value={values.start_time}
                                // onChange={handleInputChange}
                                name="time_request"
                                id="time_request"
                                ampm={false}
                                margin="normal"
                                inputVariant="outlined"
                                openTo="hours"
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss"
                                mask="__:__:__"
                                placeholder="__:__:__"
                                label="Thời gian yêu cầu"
                                keyboardIcon={<AccessTimeIcon/>}
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                value={selectedTime}
                                onChange={handleTimeChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Cập nhật
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default MyLateAndEarlyUpdate;
