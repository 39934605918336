import * as React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {
    DataGrid,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import {useEffect, useState} from "react";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                padding: theme.spacing(0.5, 0.5, 0),
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'flex-end',
                flexWrap: 'wrap',
            },
            textField: {
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                },
                margin: theme.spacing(1, 0.5, 1.5),
                '& .MuiSvgIcon-root': {
                    marginRight: theme.spacing(0.5),
                },
                '& .MuiInput-underline:before': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                },
            },
        }),
    { defaultTheme },
);

const QuickSearchToolbar = (props) => {
    const classes = useStyles();

    return (
            <div className={classes.root}>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Tìm kiếm..."
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
                </div>
    );
}
export default QuickSearchToolbar;
// QuickSearchToolbar.propTypes = {
//     clearSearch: PropTypes.func.isRequired,
//     onChange: PropTypes.func.isRequired,
//     value: PropTypes.string.isRequired,
// };
//
// export default function QuickFilteringGrid() {
//     const { data } = useDemoData({
//         dataSet: 'Commodity',
//         rowLength: 100,
//         maxColumns: 6,
//     });
//
//     const [searchText, setSearchText] = useState('');
//     const [rows, setRows] = useState(data.rows);
//
//     const requestSearch = (searchValue) => {
//         setSearchText(searchValue);
//         const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
//         const filteredRows = data.rows.filter((row) => {
//             return Object.keys(row).some((field) => {
//                 return searchRegex.test(row[field].toString());
//             });
//         });
//         setRows(filteredRows);
//     };
//
//     useEffect(() => {
//         setRows(data.rows);
//     }, [data.rows]);
//
//     return (
//         <div style={{ height: 400, width: '100%' }}>
//             <DataGrid
//                 components={{ Toolbar: QuickSearchToolbar }}
//                 rows={rows}
//                 columns={data.columns}
//                 componentsProps={{
//                     toolbar: {
//                         value: searchText,
//                         onChange: (event) => requestSearch(event.target.value),
//                         clearSearch: () => requestSearch(''),
//                     },
//                 }}
//             />
//         </div>
//     );
// }
