import {Header, NotificationCenter, Sidebar, Workspace} from "../components";
import React, {useState} from "react";
import {Route, Switch} from "react-router-dom";
import {MobileBreakpoint} from "../styleVariables";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import routes from "../routes";
import {useAppState} from "../components/AppProvider/AppProvider";
import useMountEffect from "../mountEffect";
import SidebarMini from "../components/Sidebar/SidebarMini";

const useStyles = makeStyles(theme => ({
    panel: {
        backgroundColor: 'white',
        position: "relative",
        overflow: "hidden",
        width: "100%",
        maxWidth: "100%",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            minHeight: "calc(100vh - 64px)",
            paddingTop: "64px"
        },
        [theme.breakpoints.down("xs")]: {
            height: "auto",
            minHeight: "calc(100vh - 56px)",
            paddingTop: "56px"
        },
        [theme.breakpoints.up("sm")]: {
            height: "calc(100vh - 64px)"
        },
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
    },
    speedDial: {
        position: "absolute",
        bottom: theme.spacing(1) * 2,
        right: theme.spacing(1) * 3
    }
}));
const Dashboard = ({history}) => {

    const classes = useStyles();
    const [state, dispatch] = useAppState();
    const [opened, setOpened] = useState(true);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [openSpeedDial, setOpenSpeedDial] = useState(false);

    const mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);

    const resizeDispatch = () => {
        if (typeof Event === "function") {
            window.dispatchEvent(new Event("resize"));
        } else {
            const evt = window.document.createEvent("UIEvents");
            evt.initUIEvent("resize", true, false, window, 0);
            window.dispatchEvent(evt);
        }
    };

    const handleDrawerToggle = () => {
        setOpened(!opened);
        resizeDispatch();
    };

    const handleNotificationToggle = () =>
        setNotificationsOpen(!notificationsOpen);

    const handleFullscreenToggle = () => {
        const element = document.querySelector("#root");
        const isFullscreen =
            document.webkitIsFullScreen || document.mozFullScreen || false;
        element.requestFullScreen =
            element.requestFullScreen ||
            element.webkitRequestFullScreen ||
            element.mozRequestFullScreen ||
            function () {
                return false;
            };
        document.cancelFullScreen =
            document.cancelFullScreen ||
            document.webkitCancelFullScreen ||
            document.mozCancelFullScreen ||
            function () {
                return false;
            };
        isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
    };

    const handleSpeedDialOpen = () => setOpenSpeedDial(true);

    const handleSpeedDialClose = () => setOpenSpeedDial(false);
    const titleHeader = "Qi Technologies";

    const getRoutes = (
        <Switch>
            {routes.items.map((item, index) =>
                item.type === "external" ? (
                    <Route
                        exact
                        path={item.path}
                        component={item.component}
                        name={item.name}
                        key={index}
                    />
                ) : item.type === "submenu" ? (
                    item.children.map(subItem => (
                        <Route
                            exact
                            path={`${item.path}${subItem.path}`}
                            component={subItem.component}
                            name={subItem.name}
                        />
                    ))
                ) : (
                    <Route
                        exact
                        path={item.path}
                        component={item.component}
                        name={item.name}
                        key={index}
                    />
                )
            )}
            {/*<Redirect to="/404"/>*/}
        </Switch>
    );

    useMountEffect(async () => {
        if (mediaMatcher.matches || {}) setOpened(true);
        mediaMatcher.addListener(match => {
            setTimeout(() => {
                if (match.matches) setOpened(true);
                else setOpened(false);
            }, 300);
        });

        const unlisten = history.listen(() => {
            if (mediaMatcher.matches) setOpened(true);
            {
                if (document.querySelector("#root > div > main") != null) {
                    document.querySelector("#root > div > main").scrollTop = 0;
                }
                // (document.querySelector("#root > div > main") || {}).scrollTop = 0;
            }
            // document.querySelector("#root > div > main").scrollTop =0;
        });

        return () => {
            unlisten();
            mediaMatcher.removeListener(match => {
                setTimeout(() => {
                    if (match.matches) setOpened(true);
                    else setOpened(false);
                }, 300);
            });
        };
    });
    // RefreshToken();
    return (
        <>
            <Header
                // logoAltText="NHÂN VIÊN"
                logo={`${process.env.PUBLIC_URL}/static/images/LogoPX2.png`}
                title={titleHeader}
                toggleDrawer={handleDrawerToggle}
                toogleNotifications={handleNotificationToggle}
                toggleFullscreen={handleFullscreenToggle}
            />
            <div className={classNames(classes.panel, "theme-light")}>
                {opened ?
                    <SidebarMini
                        routes={routes.items}
                        opened={opened}
                        toggleDrawer={handleDrawerToggle}
                    />
                    :
                    <Sidebar
                        routes={routes.items}
                        opened={!opened}
                        toggleDrawer={handleDrawerToggle}
                    />
                }

                <Workspace opened={opened}>{getRoutes}</Workspace>
                <NotificationCenter
                    notificationsOpen={notificationsOpen}
                    toogleNotifications={handleNotificationToggle}
                />
            </div>
        </>
    );
};

export default Dashboard;
