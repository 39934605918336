import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import {MuiThemeProvider} from "@material-ui/core/styles";
import FormDialog from "../../utils/demos/dialogs/FormDialog";

export class Success extends Component {
  continue = e => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (

        <>
          <FormDialog
            open
            fullWidth
            maxWidth='sm'
          >
            <AppBar title="Success" />
            {/*<h1>Thank You For Your Submission</h1>*/}
            {/*<p>You will get an email with further instructions.</p>*/}
          </FormDialog>
        </>

    );
  }
}

export default Success;
