import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from "dayjs";
import viLocale from "date-fns/locale/vi";
import TimePicker from "../../components/controls/TimePicker";
import format from "date-fns/format";
import DatePicker from "../controls/DatePicker";
import moment from 'moment';
import 'moment-timezone';
import {RefreshToken} from "../Signin/RefreshToken";
import Typography from "@material-ui/core/Typography";
import {ColorButtonAdd, ColorButtonCancel, ColorButtonHoverAdd, ColorTextButton} from "../MainStyleCSS/ColorHeader";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    ButtonAdd: {
        background: ColorButtonAdd,
        color: ColorTextButton,
        '&:hover': {
            background: ColorButtonHoverAdd
        },
    },
    ButtonCancel: {
        backgroundColor: ColorButtonCancel,
        '&:hover': {
            background: ColorButtonHoverAdd,
            color: ColorTextButton
        },
    },

    ButtonAction: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (
            this.parent
                .filter(v => v !== value)
                .some(v => v[propertyName] === value[propertyName])
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});

// yup.addMethod(yup.string, 'unique', function(message, mapper = a => a) {
//     return this.test('unique', message, function(list) {
//         return list.length  === new Set(list.map(mapper)).size;
//     });
// });
const FormChangePassword = (props) => {
    const [dataUpdate, setDataUpdate] = useState()
    const classes = useStyles();
    const [showPassword,setShow] = useState(false)
    const isSameOrBefore = (start, end) => {
        return moment(start, 'HH:mm:ss').isSameOrBefore(moment(end, 'HH:mm:ss'));
    }
    const validationSchema = yup.object().shape({
        old_password: yup
            .string('Nhập vào mật khẩu')
            .required('Dữ liệu bắt buộc nhập'),
        new_password: yup
            .string('Nhập vào mật khẩu')
            .required('Dữ liệu bắt buộc nhập'),
        replace_password: yup
            .string('Nhập vào mật khẩu')
            .required('Dữ liệu bắt buộc nhập'),
    });

    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/change_password`
            const result = await axios.post(api, dataUpdate, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataUpdate])
    const handleUpdate = (dataSubmit) => {
        setDataUpdate(dataSubmit)
    }
    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            replace_password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleUpdate(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4} style={{display: 'flex', flexDirection: 'column'}}>

                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography style={{minWidth: '150px'}}> Mật khẩu cũ:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            id="old_password"
                            name="old_password"
                            style={{minWidth: "250px"}}
                            className={classes.textField}
                            margin="normal"
                            type="password"
                            variant="outlined"
                            value={formik.values.old_password}
                            onChange={formik.handleChange}
                            error={formik.touched.old_password && Boolean(formik.errors.old_password)}
                            helperText={formik.touched.old_password && formik.errors.old_password}
                        >
                        </TextField>
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography style={{minWidth: '150px'}}> Nhập lại mật khẩu:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            id="new_password"
                            name="new_password"
                            style={{minWidth: "250px"}}
                            className={classes.textField}
                            margin="normal"
                            type="password"
                            variant="outlined"
                            value={formik.values.new_password}
                            onChange={formik.handleChange}
                            error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                            helperText={formik.touched.new_password && formik.errors.new_password}
                        >
                        </TextField>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography style={{minWidth: '150px'}}> Nhập lại mật khẩu:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            id="replace_password"
                            name="replace_password"
                            style={{minWidth: "250px"}}
                            className={classes.textField}
                            margin="normal"
                            type="password"
                            variant="outlined"
                            value={formik.values.replace_password}
                            onChange={formik.handleChange}
                            error={formik.touched.replace_password && Boolean(formik.errors.replace_password)}
                            helperText={formik.touched.replace_password && formik.errors.replace_password}
                        >
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button className={classes.ButtonAdd} variant="contained" type="submit">
                        Cập nhật
                    </Button>
                </Grid>
                <Grid item>
                    <Button className={classes.ButtonCancel} variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormChangePassword;
