import React from 'react';
import {Wrapper, Example} from '../../components';
import ItemExpansionPanels from "./ItemExpansionPanel";
import Accordion from '@material-ui/core/Accordion'

const arrExpansion = [
    {
        // js: require('../../utils/demos/expansion-panels/ControlledExpansionPanels').default,
        js: require('../../components/ExpansionPanel/ControlledExpansionPanels').default,
        title: 'Controlled Expansion Panel',
        // docs: 'https://material-ui.com/api/expansion-panel/'
        docs: ''
    }
]

const ExpansionPanels = (props) => (
    <div style={{width: '100%'}}>
        {arrExpansion.map((demo, index) => (
            <ItemExpansionPanels style={{width: '100%'}}
                                 userInfo={props.userInfo}
                                 jobDetails={props.jobDetails}
                                 genderItems={props.genderItems}
                                 key={index}
                                 index={index}
                                 title={demo.title}
                                 js={demo.js}
                                 docs={demo.docs}
            />
        ))}
    </div>
);

export default ExpansionPanels;
