import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import moment from "moment";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import viLocale from "date-fns/locale/vi";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {setHours} from "date-fns";
import {RefreshToken} from "../Signin/RefreshToken";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {MenuItem} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const LeaveTypeSetupDelete = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const initDataDelete = props.data
    const [dataDelete, setDataDelete] = useState(initDataDelete)
    const forwardLeaveType = props.forwardLeaveType
    const [isExpired, setIsExpired] = useState(false)
    const classes = useStyles();
    const [count,setCount] = useState(0)
    const validationSchema = yup.object({});
    useEffect(async () => {
        if(count) {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/leavetype`
            const result = await axios.post(api, dataDelete, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
        }
    }, [count])
    const handleDelete = (dataSubmit) => {
        // console.log(JSON.stringify(values));
        const dataNew = {"message": {...{"id": props.data.id}}, "action": "delete", "type_message": "map"}
        // alert(JSON.stringify({dataNew}, localStorage.getItem("authToken")))
        setDataDelete(dataNew);
        setCount(1);
        // console.log(dataNew)
    }
    const formik = useFormik({
        initialValues:{
            id:props.data.id
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleDelete(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3} align={'center'}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="name"
                            name="name"
                            label="Tên phân loại"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={initDataDelete.name}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="leaves_per_leave_type"
                            name="leaves_per_leave_type"
                            label="leaves_per_leave_type"
                            type="number"
                            inputProps={{
                                maxLength: 13,
                                step: "1"
                            }}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={initDataDelete.leaves_per_leave_type}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>suppervisor_can_assign</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="suppervisor_can_assign"
                                name="suppervisor_can_assign"
                                aria-label="suppervisor_can_assign"
                                value={initDataDelete.suppervisor_can_assign}
                                disabled
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>employee_can_apply</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="employee_can_apply"
                                name="employee_can_apply"
                                aria-label="employee_can_apply"
                                value={initDataDelete.employee_can_apply}
                                disabled
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>apply_beyond_current</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="apply_beyond_current"
                                name="apply_beyond_current"
                                aria-label="apply_beyond_current"
                                value={initDataDelete.apply_beyond_current}
                                disabled
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>leave_accrue</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="leave_accrue"
                                name="leave_accrue"
                                aria-label="leave_accrue"
                                value={initDataDelete.leave_accrue}
                                disabled
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>leave_accrue_beyond_current</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="leave_accrue_beyond_current"
                                name="leave_accrue_beyond_current"
                                aria-label="employee_can_apply"
                                value={initDataDelete.leave_accrue_beyond_current}
                                disabled
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>carried_forward</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="carried_forward"
                                name="carried_forward"
                                aria-label="carried_forward"
                                value={initDataDelete.carried_forward}
                                disabled
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} align={'center'}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="carried_forward_percentage"
                            name="carried_forward_percentage"
                            label="carried_forward_percentage"
                            type="number"
                            inputProps={{
                                maxLength: 13,
                                step: "1"
                            }}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={initDataDelete.carried_forward_percentage}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="max_carried_forward_amount"
                            name="max_carried_forward_amount"
                            label="max_carried_forward_amount"
                            type="number"
                            inputProps={{
                                maxLength: 13,
                                step: "1"
                            }}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={initDataDelete.max_carried_forward_amount}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="carried_forward_leave_availability"
                            name="carried_forward_leave_availability"
                            label="carried_forward_leave_availability"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={initDataDelete.carried_forward_leave_availability}
                            disabled
                        >
                            {forwardLeaveType.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <TextField*/}
                    {/*        InputProps={{*/}
                    {/*            className: classes.multilineColor*/}
                    {/*        }}*/}
                    {/*        style={{minWidth: "180px"}}*/}
                    {/*        id="carried_forward_leave_availability"*/}
                    {/*        name="carried_forward_leave_availability"*/}
                    {/*        label="carried_forward_leave_availability"*/}
                    {/*        className={classes.textField}*/}
                    {/*        margin="normal"*/}
                    {/*        variant="outlined"*/}
                    {/*        value={formik.values.carried_forward_leave_availability}*/}
                    {/*        onChange={formik.handleChange}*/}
                    {/*        error={formik.touched.carried_forward_leave_availability && Boolean(formik.errors.carried_forward_leave_availability)}*/}
                    {/*        helperText={formik.touched.carried_forward_leave_availability && formik.errors.carried_forward_leave_availability}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>propotionate_on_joined_date</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="propotionate_on_joined_date"
                                name="propotionate_on_joined_date"
                                aria-label="propotionate_on_joined_date"
                                value={initDataDelete.propotionate_on_joined_date}
                                disabled
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>use_employee_leave_period</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="use_employee_leave_period"
                                name="use_employee_leave_period"
                                aria-label="use_employee_leave_period"
                                value={initDataDelete.use_employee_leave_period}
                                disabled
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="leave_group"
                            name="leave_group"
                            label="leave_group"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="leave_color"
                            name="leave_color"
                            label="leave_color"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={initDataDelete.leave_color}
                            disabled
                        />
                        {/*<ColorPicker*/}
                        {/*    id="leave_color"*/}
                        {/*    value={color}*/}
                        {/*    margin="normal"*/}
                        {/*    variant="outlined"*/}
                        {/*    onChange={handleChangeColor}*/}
                        {/*/>*/}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Xóa
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default LeaveTypeSetupDelete;
