import {ActivityStream, Wrapper} from "../../components";
import React, {useEffect, useState} from "react";

import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import {mockActivity} from "../../utils/mock";
import axios from "axios";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneIcon from '@mui/icons-material/Phone';
// import signin from "../../components/Signin";
import Moment from 'react-moment';
// import EditEmployees from "./EditEmployees";
import {CircularProgress, GridListTileBar} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ExpansionPanels from "../../components/ExpansionPanel/ExpansionPanel";
import WorkProfileExpansionPanels from "../../components/ExpansionPanel/WorkProfileExpansionPanels";
import {
    ColorButtonAdd,
    ColorButtonCancel, ColorButtonDelete,
    ColorButtonEdit, ColorButtonHoverAdd,
    ColorHeader
} from "../../components/MainStyleCSS/ColorHeader";
import GridListTile from "@material-ui/core/GridListTile";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import GridList from "../Material/grid-list";
import AttandanceCelander from "../../components/AttandanceCelandar/AttandanceCelander";
import SaveFileEmployees from "../../components/Form/SaveFileEmployees/SaveFileEmployees";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    header: {
        textAlign: 'center',
        // fontWeight: 'bold',
        padding: '10px',
        fontSize: '15px'
    },
    BoxCard: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    avatar: {
        display: "inline-flex",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            width: 80,
            height: 80
        },
        [theme.breakpoints.down("xs")]: {
            width: 64,
            height: 64
        },
        // marginBottom: theme.spacing(1),
    },
    tabRoot: {
        textTransform: "initial",
        color: theme.palette.text.primary
    },
    postInput: {
        border: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3)
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2)
        },
        fontSize: "13px",
        outline: 0,
        backgroundColor: theme.palette.background.paper
    },
    profiles: {
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
    }
}));
// const refresh_token = RefreshToken();
const editUser = () => {
    let permission = "editEmployees"; // temp : set static
    permission = "editEmployees" ? 1 : alert("test");
};
// const checkExpiredToken = CheckTokenExpired();
// const token = localStorage.getItem("authToken");
const TabContainer = props => (
    <Typography component={'span'} className="pa-0">
        {props.children}
    </Typography>
);
const genderItems = [
    {id: 'male', title: 'Nam'},
    {id: 'female', title: 'Nữ'},
    {id: 'other', title: 'Khác'},
]
const Profiles = () => {
    const [userInfo, setUserInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [supervisor, setSupervisor] = useState('');
    const [token, setToken] = useState(localStorage.getItem("authToken"));
    const [image, setImage] = useState('')
    const [isExpired, setIsExpired] = useState(false)
    const [count, setCount] = useState(0)
    const [dataAttandance, setDataAttandance] = useState([])
    const [jobDetails, setJobDetails] = useState([])
    const [leaveAvailable, setLeaveAvailable] = useState([])
    // console.log (token);
    useEffect(async () => {
        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/myinfo`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${token}`}}).then(res1 => {
                if (res1.status === 200) {
                    if (res1.data.message === null) {
                        res1.data.message = {};
                    }
                    setUserInfo(res1.data.message);
                    console.log(res1.data.message)
                }
            })
            const api2 = `https://api-dev.hrms.com.vn/api/v1/jobtitle`
            const result2 = await axios.get(api2, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res2 => {
                if (res2.status === 200) {
                    if (res2.data.message === null) {
                        res2.data.message = {};
                    }
                    console.log(res2.data.message);
                    setJobDetails(res2.data.message)
                }
            })
            const dateNow = new Date()
            const startDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
            const endDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
            const startTime = moment(startDate).format('YYYY-MM-DD')
            const endTime = moment(endDate).format('YYYY-MM-DD')
            // console.log(`${startTime} ----- ${endTime}`);
            // console.log(localStorage.getItem("authToken"))
            const api3 = `https://api-dev.hrms.com.vn/api/v1/attendance/me?start_date=${startTime}&end_date=${endTime}`
            const result3 = await axios.get(api3, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res3 => {
                if (res3.status === 200) {
                    if (res3.data.message === null) {
                        res3.data.message = [];
                    }
                    setDataAttandance(res3.data.message)
                    console.log(res3.data.message)
                    // setCount(0)
                } else {

                }
            })
            const api4 = `https://api-dev.hrms.com.vn/api/v1/leaveavailable`
            const result4 = await axios.get(api4, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res4 => {
                if (res4.status === 200) {
                    if (res4.data.message === null) {
                        res4.data.message = [];
                    }
                     setLeaveAvailable(res4.data.message)
                    console.log(res4.data.message)
                    // setCount(0)
                } else {

                }
            })
        } catch (e) {
        }
    }, [])
    useEffect(async () => {
        if (userInfo) {
            try {
                if (userInfo.image_profile) {
                    setAvatar(userInfo.image_profile)
                }
            } catch (e) {
            }
        }
    }, [userInfo])


    // console.log(typeof(userInfo))
    if (userInfo instanceof Error) {
        return (
            <div>
                <h1>Error.</h1>
            </div>
        );
    }
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const handleTabToggle = (event, tab) => setTab(tab);
    // const supervisor = Object.keys(userInfo.supervisor_name).map(key =>
    //     <p value={key}>{userInfo.supervisor_name[key]}</p>
    // )
    return (
        <Wrapper>
            {loading &&
                // <p>It's loading...</p>
                <center>
                    <CircularProgress/>
                </center>
            }
            {
                !loading &&
                <div>
                    <Typography className={classes.header}>THÔNG TIN CÁ NHÂN</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={3} className={classes.profiles}>
                                    <Card className={classes.BoxCard}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6} lg={4} className={classes.profiles}>
                                                <Avatar
                                                    alt={`${userInfo.firstName} ${userInfo.lastName}`}
                                                    // src={`${process.env.PUBLIC_URL}/static/images/avatar.jpg`}
                                                    src={avatar}
                                                    className={classNames(classes.avatar)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={8} className={classes.profiles}>
                                                <Typography variant="h6" gutterBottom>
                                                    {userInfo.last_name} {userInfo.first_name}
                                                </Typography>
                                                <Typography>
                                                    {userInfo.job_title ? (userInfo.job_title.name ?
                                                        `(${userInfo.job_title.name})` : `(Trống)`) : `(Trống)`}
                                                    {/*{userInfo.job_title.name}*/}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3} className={classes.profiles}>
                                    <Card className={classes.BoxCard}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start'
                                        }}>
                                            {/*<Typography component={'span'} variant="caption" gutterBottom >*/}
                                            {/*    THÔNG TIN LIÊN LẠC:*/}
                                            {/*</Typography>*/}
                                            <Typography component={'span'} variant="body1" gutterBottom style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>
                                                <PhoneIcon/>
                                                {userInfo.work_phone ? `(${userInfo.work_phone})` : `(Trống)`}

                                            </Typography>
                                            <Typography component={'span'} variant="body1" gutterBottom style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>
                                                <PhoneIphoneIcon/>
                                                {userInfo.mobile_phone ? `(${userInfo.mobile_phone})` : `(Trống)`}
                                            </Typography>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Card>
                                        <CardContent className="text-xs-center">
                                            <Grid container spacing={3}>
                                                <Grid item xs={4}>

                                                    {/*{leaveAvailable.map(x => {*/}
                                                    {/*        {console.log (JSON.stringify(x.detail.avalilable))}*/}
                                                    {/*})}*/}
                                                    <Typography variant="h6" gutterBottom
                                                                style={{color: ColorButtonAdd}}>
                                                        {leaveAvailable[0]?leaveAvailable[0].detail.avalilable:"(trống)"}
                                                        </Typography>

                                                    <Typography variant="caption">
                                                        Ngày phép khả dụng
                                                    </Typography>
                                                    <br/>
                                                    <Typography variant="caption">
                                                      {leaveAvailable[0]?`(${leaveAvailable[0].name})`:""}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6" gutterBottom
                                                                style={{color: ColorButtonDelete}}>
                                                        {leaveAvailable[0]?leaveAvailable[0].detail.approved:"(trống)"}
                                                    </Typography>
                                                    <Typography variant="caption">Ngày phép đã sử dụng</Typography>
                                                    <br/>
                                                    <Typography variant="caption">
                                                        {leaveAvailable[0]?`(${leaveAvailable[0].name})`:""}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6" gutterBottom
                                                                style={{color: ColorButtonHoverAdd}}>
                                                        {dataAttandance.length}
                                                    </Typography>
                                                    <Typography variant="caption">Ngày công hiện tại</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>


                            {/*<Divider/>*/}


                            {/*<CardContent>*/}
                            {/*    <Typography component={'span'} variant="subtitle1" gutterBottom>*/}
                            {/*        <b>Mô tả công việc: </b>*/}
                            {/*    </Typography>*/}
                            {/*    <Typography>*/}
                            {/*        1. Cài đặt tất cả phần cứng mới, hệ thống, và phần mềm cho các mạng. <br/>*/}
                            {/*        2. Cài đặt, cấu hình, duy trì mạng lưới dịch vụ, thiết bị và các thiết bị. <br/>*/}
                            {/*        3. Hỗ trợ quản lý các máy chủ và các cụm máy chủ. <br/>*/}
                            {/*        4. Quản lý tất cả các hệ thống sao lưu và khôi phục giao thức. <br/>*/}
                            {/*        5. Lập kế hoạch và hỗ trợ mạng và cơ sở hạ tầng thông tin. <br/>*/}
                            {/*        6. Thực hiện phân tích xử lý sự cố máy chủ, máy trạm và hệ thống liên*/}
                            {/*        quan. <br/>*/}
                            {/*        7. Xây dựng tài liệu mạng và giải pháp các vấn đề để tham khảo trong tương*/}
                            {/*        lai. <br/>*/}
                            {/*        8. Giám sát hiệu suất hệ thống và thực hiện điều chỉnh phù hợp. <br/>*/}
                            {/*        9. Quản lý tài khoản người dùng, điều khoản, email, chống virus, chống*/}
                            {/*        spam. <br/>*/}
                            {/*        10. Đòi hỏi một kiến thức toàn diện của mạng cần thiết. <br/>*/}
                            {/*        11. Giám sát phần mềm và bảo mật mạng. <br/>*/}
                            {/*        12. Khả năng phân tích chuyên sâu và kinh nghiệm làm việc văn phòng chuyên*/}
                            {/*        nghiệp. <br/>*/}
                            {/*    </Typography>*/}
                            {/*</CardContent>*/}

                        </Grid>

                        {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
                        {/*    <Card>*/}
                        {/*        1111111*/}
                        {/*        <Divider/>*/}
                        {/*    </Card>*/}
                        {/*</Grid>*/}

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <Tabs value={tab} onChange={handleTabToggle}>
                                    <Tab label="Sơ yếu lý lịch" classes={{root: classes.tabRoot}}/>
                                    <Tab label="Hồ sơ nhân viên" classes={{root: classes.tabRoot}}/>
                                    <Tab label="Thông tin chấm công" classes={{root: classes.tabRoot}}/>
                                    <Tab label="Quá trình làm việc" classes={{root: classes.tabRoot}}/>
                                    <Tab label="Lưu trữ" classes={{root: classes.tabRoot}}/>
                                </Tabs>
                                <Divider/>

                                {tab === 0 && (
                                    <TabContainer>
                                        <CardContent>
                                            <ExpansionPanels userInfo={userInfo} jobDetails={jobDetails}
                                                             genderItems={genderItems}/>
                                        </CardContent>
                                        <Divider/>
                                    </TabContainer>
                                )}
                                {tab === 1 && (
                                    <TabContainer>
                                        <CardContent>
                                            <WorkProfileExpansionPanels userInfo={userInfo} genderItems={genderItems}/>
                                        </CardContent>
                                        <Divider/>
                                    </TabContainer>

                                )}

                                {tab === 2 && (
                                    <TabContainer>
                                        <div style={{
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <AttandanceCelander/>
                                        </div>

                                        {/*<GridList cols={3} spacing={1} cellHeight={180}>*/}
                                        {/*    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(tile => (*/}
                                        {/*        <GridListTile key={tile}>*/}
                                        {/*            <img*/}
                                        {/*                src={`${process.env.PUBLIC_URL}/static/images/unsplash/${tile}.jpg`}*/}
                                        {/*                alt={tile}*/}
                                        {/*            />*/}
                                        {/*            <GridListTileBar*/}
                                        {/*                title={tile}*/}
                                        {/*                subtitle={<span>Gallery image: {tile}</span>}*/}
                                        {/*                actionIcon={*/}
                                        {/*                    <IconButton>*/}
                                        {/*                        <FavoriteIcon/>*/}
                                        {/*                    </IconButton>*/}
                                        {/*                }*/}
                                        {/*            />*/}
                                        {/*        </GridListTile>*/}
                                        {/*    ))}*/}
                                        {/*</GridList>*/}
                                    </TabContainer>
                                )}
                                {tab === 3 && (
                                    <TabContainer>
                                        <CardContent className="pb-0">
                                            <ActivityStream
                                                title="NĂM 2020"
                                                stream={mockActivity}
                                            />
                                            <ActivityStream
                                                title="NĂM 2019"
                                                stream={mockActivity}
                                            />
                                        </CardContent>
                                    </TabContainer>
                                )}
                                {tab === 4 && (
                                    <TabContainer>
                                        <SaveFileEmployees/>
                                    </TabContainer>
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            }
        </Wrapper>
    );
};

export default Profiles;
