import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ColorPicker from "material-ui-color-picker";
import moment from "moment";
import {MenuItem} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
// yup.addMethod(yup.string, 'unique', function(message, mapper = a => a) {
//     return this.test('unique', message, function(list) {
//         return list.length  === new Set(list.map(mapper)).size;
//     });
// });


const MyWorkFromHomeAddDates = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const classes = useStyles();
    const dates = props.dates
    const typeDate = props.props.typeDate
    const initDates = dates.reduce((obj, item) => (obj[`${item.date}`] = `${item.type}`, obj) ,{});
    const [values, setValues] = useState(initDates)
    const validationSchema = yup.object({
        // name: yup
        //     .string()
        //     .required('Dữ liệu bắt buộc nhập'),
    });

    const handleInputChange = e => {
        // console.log(e)
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    useEffect(async () => {
        props.setDataDatesReturn(values)
    }, [values]);
    return (
      <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} align={'center'}>
                    {
                        // (dates && dates.length) ?
                        dates.map(item => (
                            <Grid item>
                                <TextField
                                    style={{minWidth: "200px"}}
                                    InputProps={{
                                        className: classes.multilineColor
                                    }}
                                    id={item.date}
                                    name={item.date}
                                    label={item.date}
                                    select
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    // value={values.dates}
                                    // onChange={handleInputChange}
                                    value={values[`${item.date}`]}
                                    // value={values[`2022-01-13`]}
                                    // defaultValue={item.name}
                                    onChange={handleInputChange}
                                    // onChange={sdate => console.log(sdate.target.value)}
                                    // error={formik.touched[`${item.date}`] && Boolean(formik.errors[`${item.date}`])}
                                    // helperText={formik.touched[`${item.date}`] && formik.errors[`${item.date}`]}
                                    disabled={item.type === 'Non-working Day'}
                                >
                                    {item.type === 'Non-working Day' ?
                                        <MenuItem key={'Non-working Day'} value={'Non-working Day'}>
                                            Ngày Nghĩ
                                        </MenuItem>
                                        :
                                        typeDate.map((x) => (
                                            <MenuItem key={x.id} value={x.id}>
                                                {x.name}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                        ))

                        // :
                        //
                        // ""
                    }
                </Grid>
            </Grid>
            {/*<Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>*/}
            {/*    <Grid item>*/}
            {/*        <Button color="primary" variant="contained" type="submit">*/}
            {/*            Thêm mới*/}
            {/*        </Button>*/}
            {/*    </Grid>*/}
            {/*    <Grid item>*/}
            {/*        <Button color="primary" variant="contained" onClick={props.onClick}>*/}
            {/*            Hủy*/}
            {/*        </Button>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </>

    )

}
export default MyWorkFromHomeAddDates ;
