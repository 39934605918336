import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import BankCard from "../BankCard/BankCard";
import Divider from "@material-ui/core/Divider";
import {MenuItem} from "@material-ui/core";
import moment from "moment";

const styles = theme => ({
    // root: {
    //     width: '100%',
    // },
    familyInfo: {
        padding: 0,
        marginBottom: '-10px'
    },
    bankcard: {
        // background: '#1488cc',
        /* fallback for old browsers */

        background: 'linear-gradient(to left, #283593, #1976d2)',
        height: '75vh',
        fontFamily: 'Josefin Sans, sans-serif',
    },
    iconArrow:{
        // color: 'white'
    },
    headerexpan: {
        // backgroundColor: "#ccd7dd"
        backgroundColor: "#deefff",

    },
    heading: {
        color:'black',
        fontWeight:450,
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color:'black',
    },
    headerInput: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,

    },
    InputText: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: 'bold',
        marginTop: '-10px'
        // color: theme.palette.text.secondary,
    },
    lineFamily: {
        marginTop: '-10px',
        marginBottom: '5px'
    },
    familyHeader: {
        marginBottom: '-10px'
    }
});


class ControlledWorkProfile extends React.Component {
    state = {
        expanded1: 'panel1',
        expanded2: 'panel2',
        expanded3: 'panel3',
        expanded4: 'panel4',
    };

    handleChangePanel1 = panel => (event, expanded) => {
        this.setState({
            expanded1: expanded ? panel : "",
        });
    };

    handleChangePanel2 = panel => (event, expanded) => {
        this.setState({
            expanded2: expanded ? panel : "",
        });
    };
    handleChangePanel3 = panel => (event, expanded) => {
        this.setState({
            expanded3: expanded ? panel : "",
        });
    };
    handleChangePanel4 = panel => (event, expanded) => {
        this.setState({
            expanded4: expanded ? panel : "",
        });
    };

    render() {
        const {classes,userInfo,genderItems} = this.props;
        const arrayFamily = [{"id": 1}, {"id": 2}, {"id": 3}, {"id": 4}]
        const {expanded1, expanded2, expanded3, expanded4} = this.state;
        return (
            <div className={classes.root}>
                <ExpansionPanel expanded={expanded1 === 'panel1'} onChange={this.handleChangePanel1('panel1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.iconArrow}/>} className={classes.headerexpan}>
                        <Typography className={classes.heading}>1. Thông tin công việc</Typography>
                        {/*<Typography className={classes.secondaryHeading}>I am an expansion panel</Typography>*/}
                    </ExpansionPanelSummary>
                    <Grid container>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <ExpansionPanelDetails className={classes.familyHeader}>
                                <Typography className={classes.headerInput}>
                                    Phòng ban:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.department ? userInfo.department.name ? userInfo.department.name : "(Trống)":("(Trống)")}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Chức danh:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.job_title ? userInfo.job_title.name ? userInfo.job_title.name : "(Trống)":("(Trống)")}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Chức vụ:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.position ? userInfo.position.name ? userInfo.position.name : "(Trống)":("(Trống)")}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Mã nhân viên:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.employee_id ?  userInfo.employee_id :("(Trống)")}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Người quản lý trực tiếp:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.supervisor ? userInfo.supervisor.first_name ? `${userInfo.supervisor.last_name} ${userInfo.supervisor.first_name}` : "(Trống)" : "(Trống)" }
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Ngày bắt đầu làm việc:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.joined_date ?  moment(userInfo.joined_date).format('DD-MM-YYYY') : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <ExpansionPanelDetails>
                                <Typography className={classes.headerInput}>
                                    Ngày kết thúc công việc:
                                </Typography>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Typography className={classes.InputText}>
                                    {userInfo.termination_date ?  moment(userInfo.termination_date).format('DD-MM-YYYY') : "(Trống)"}
                                </Typography>
                            </ExpansionPanelDetails>
                        </Grid>

                    </Grid>

                </ExpansionPanel>
                <ExpansionPanel expanded={expanded2 === 'panel2'} onChange={this.handleChangePanel2('panel2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.iconArrow}/>} className={classes.headerexpan}>
                        <Typography className={classes.heading}>2. Thông tin liên hệ</Typography>
                        {/*<Typography className={classes.secondaryHeading}>*/}
                        {/*    You are currently not an owner*/}
                        {/*</Typography>*/}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Email:
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {userInfo.work_mail ? userInfo.work_mail : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Số điện thoại (dđ):
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {userInfo.mobie_phone ? userInfo.mobie_phone : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Số điện thoại (nhà):
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {userInfo.home_phone ? userInfo.home_phone : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Địa chỉ thường trú:
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {userInfo.address1 ? userInfo.address1 : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Địa chỉ tạm trú:
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {userInfo.address2 ? userInfo.address2 : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded3 === 'panel3'} onChange={this.handleChangePanel3('panel3')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.iconArrow}/>} className={classes.headerexpan}>
                        <Typography className={classes.heading}>3. Hợp đồng lao động</Typography>
                        {/*<Typography className={classes.secondaryHeading}>*/}
                        {/*    Sacombank - STK: 060237464748 - Chủ TK: Bùi Văn Giang*/}
                        {/*</Typography>*/}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                       Phòng ban:
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {userInfo.labor_contract  ? userInfo.labor_contract.department ? userInfo.labor_contract.department : "(Trống)" : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Số HĐLĐ:
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {userInfo.labor_contract  ? userInfo.labor_contract.number ? userInfo.labor_contract.number : "(Trống)" : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Người ký:
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {userInfo.labor_contract  ? userInfo.labor_contract.contractor ? userInfo.labor_contract.contractor : "(Trống)" : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Ngày ký:
                                    </Typography>
                                </ExpansionPanelDetails>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.InputText}>
                                        {userInfo.labor_contract  ? userInfo.labor_contract.date ? userInfo.labor_contract.date : "(Trống)" : "(Trống)"}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded4 === 'panel4'} onChange={this.handleChangePanel4('panel4')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.iconArrow}/>} className={classes.headerexpan}>
                        <Typography className={classes.heading}>4. Thông tin lương</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.familyInfo}>
                        <Grid container>
                            <Grid item xs={2}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Thành phần lương:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={2}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Số tiền:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={2}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Đơn vị tính
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={2}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Số HĐLĐ:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={2}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Loại:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                            <Grid item xs={2}>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.headerInput}>
                                        Ghi chú:
                                    </Typography>
                                </ExpansionPanelDetails>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                    {arrayFamily.map((x) => (
                        <div>
                            <Divider className={classes.lineFamily}/>
                            <ExpansionPanelDetails className={classes.familyInfo}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                (trống)
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                (trống)
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                VNĐ
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                (trống)
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                Lương
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ExpansionPanelDetails>
                                            <Typography className={classes.InputText}>
                                                Phụ lục: (trống)
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </div>
                    ))}
                </ExpansionPanel>
            </div>
        );
    }
}
ControlledWorkProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlledWorkProfile);
