import React, {useEffect, useState} from 'react';
import {DataGrid, GridApi, GridCellValue} from '@mui/x-data-grid';
import CustomToolbar from "../../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import LateAndEarlyView from "../../../components/Form/Attandance/LateAndEarly/LateAndEarlyView";
import TimeKeepingView from "../../../components/Form/Attandance/TimeKeeping/TimeKeepingView";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const rootEl = document.getElementById('root');
const initialFValues = {
    startTime: new Date(),
    endTime: new Date(),
}
// const StyledDataGrid = withStyles({
//     root: {
//
//     },
// })(DataGrid);
const useStyles = makeStyles(theme => ({
    buttonsAdd: {
        marginTop: "15px",
        marginLeft: "20px",
    },
    lightText: {
        color: 'white'
    },
    dataGrid: {
        borderRadius: 3,
        border: 0,
        color: "inherit!important",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        width: "100%"
    },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    parent: {
        // use flexbox layout for children
        display: "flex",
        flexDirection: "column"
    },
    childThatGrows: {
        // take as much space as available
        flexGrow: 1,

        // don't take more space as available (elements are as high as their content by default)
        minHeight: 0,
        overflow: "scroll"
    },
    root: {
        padding: "20px",
        '& .super-app-theme--header': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: theme.palette.info.main,
            '&:hover': {
                backgroundColor: theme.palette.info.main,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.main,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        '& .MuiToolbar-root ': {
            color: theme.palette.text.primary
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        }
        // '& .MuiDataGrid-renderingZone': {
        //     maxHeight: 'none !important',
        // },
        // '& .MuiDataGrid-cell': {
        //     lineHeight: 'unset !important',
        //     maxHeight: 'none !important',
        //     whiteSpace: 'normal',
        // },
        // '& .MuiDataGrid-row': {
        //     maxHeight: 'none !important',
        // },
    },

}));

// const validate = (fieldValues = values) => {
//     let temp = {...errors}
//     if ('startTime' in fieldValues)
//         temp.startTime = fieldValues.startTime.valueOf() < fieldValues.endTime.valueOf() ? "" : "Thời gian vào không thể lớn hơn thời gian ra"
//     setErrors({
//         ...temp
//     })
//     const {
//         values,
//         setValues,
//         errors,
//         setErrors,
//         handleInputChange,
//         resetForm
//     } = useForm(initialFValues, true, validate);
// }


const dateFormater = (cell) => {
    return (
        <Moment format="DD-MM-YYYY HH:mm:ss">{cell}</Moment>
    );
};


function addOrEdit(thisRow) {

}

const arrStatus = [{"id": "Pending", "name": "Chờ duyệt"}, {"id": "Approved", "name": "Đã duyệt"}, {
    "id": "Rejected",
    "name": "Từ chối"
}, {"id": "Cancelled", "name": "Đã hủy"}]
const categories = [{"id": "Punch In","name":"Chấm công vào"}, {"id": "Punch Out","name":"Chấm công ra"}]
const TimeKeepingRequest = () => {

    const classes = useStyles();
    // const {addOrEdit, recordForEdit} = props
    const [lateAndEarly, setLateAndEarly] = useState([])
    // const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState(false);
    const [dataRows, setDataRows] = useState({});
    const [category, setCategory] = useState({})
    const handleClose = () => {
        setOpen(false);
        // setDataRows({});
    };
    useEffect(async () => {
        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/timekeeping`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message === null) {
                        res.data.message = {};
                    }
                    setLateAndEarly(res.data.message)
                }
            })
        } catch (e) {

        }
    }, [open])

    const data = {
        "columns":
            [{
                "field": "id",
                "headerName": "ID",
                "hide": true,
                align: 'center',
                "flex": 1,
                headerClassName: 'super-app-theme--header',
                headerAlign: 'center',
            },
                {
                    "field": "employee",
                    "headerName": "Nhân viên yêu cầu",
                    "type": "string",
                    flex: 1,
                    "minWidth": 200,
                    align: 'center',
                    "headerClassName": 'super-app-theme--header',
                    "headerAlign": 'center',
                    "valueFormatter": (params) => `${params.value.first_name} ${params.value.last_name}`,
                    "renderCell": (params) => {
                        return (`${params.value.first_name} ${params.value.last_name}`)
                    },
                },
                {
                    "field": "type_request",
                    "headerName": "Phân loại",
                    "flex": 1,
                    "minWidth": 200,
                    align: 'center',
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${categories.filter(x => x.id === params.value).map(x => x.name)}`,
                    "renderCell": params => {
                        return categories.filter(x => x.id === params.value).map(x => x.name);
                    },
                },
                {
                    "field": "date_request",
                    "headerName": "Ngày chấm công bù",
                    "type": "string",
                    align: 'center',
                    "flex": 1,
                    "minWidth": 200,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "time_request",
                    align: 'center',
                    "headerName": "TG chấm công bù",
                    "type": "string",
                    "flex": 1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "details",
                    align: 'center',
                    "minWidth": 200,
                    "headerName": "Mô tả chi tiết",
                    "type": "string",
                    "flex": 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',

                },
                {
                    "field": "log_change_status",
                    align: 'center',
                    "headerName": "Lịch sử phê duyệt",
                    "type": "string",
                    "flex": 1,
                    "minWidth": 200,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    // "valueFormatter": (params) => `${arrStatus.filter(x => x.id === params.data.status).map(x => x.name)}`,
                    "valueFormatter": "",
                    "renderCell": params => {
                        params.value
                    },
                },
                {
                    "field": "status",
                    align: 'center',
                    "headerName": "Trạng thái",
                    "type": "string",
                    "flex": 1,
                    "minWidth": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${arrStatus.filter(x => x.id === params.value).map(x => x.name)}`,
                    "renderCell": params => {
                        return arrStatus.filter(x => x.id === params.value).map(x => x.name);
                    },
                },
                {
                    "field": "actions",
                    align: 'center',
                    "headerName": "Thao tác",
                    "sortTable": false,
                    "type": "string",
                    "width": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpen(true);
                                // viewRows(thisRow)
                                // return alert(JSON.stringify(thisRow, null, 4));
                            }

                            }>
                                <VisibilityIcon sx={{color: '#58AD69!important'}}/>
                            </IconButton>
                        </div>
                    )
                }],
        "rows": lateAndEarly,
        rowLength: 100,
        maxColumns: 6,
    }

    return (
        <>
            {/*<PageHeader*/}
            {/*    title="DANH MỤC CHỨC DANH CÔNG VIỆC"*/}
            {/*    // subTitle=""*/}
            {/*    // icon={<PeopleOutlineTwoToneIcon fontSize="large"/>}*/}
            {/*/>*/}

            <div style={{height: '800px', width: '97%'}} className={classes.root}>
                <DataGrid
                    {...data}
                    className={classes.dataGrid}
                    disableExtendRowFullWidth={true}
                    getRowClassName={(params) =>
                        `super-app-theme--${params.getValue(params.id, 'status')}`}
                    hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                    localeText={
                        {
                            noRowsLabel: 'Không có dữ liệu',
                            noResultsOverlayLabel: 'Không tìm thấy kết quả.',
                            errorOverlayDefaultLabel: 'An error occurred.',
                            // Density selector toolbar button text
                            toolbarDensity: 'Độ cao dòng',
                            toolbarDensityLabel: 'Độ cao dòng',
                            toolbarDensityCompact: 'Thu gọn',
                            toolbarDensityStandard: 'Bình thường',
                            toolbarDensityComfortable: 'Mở rộng',

                            // Columns selector toolbar button text
                            toolbarColumns: 'Cột',
                            toolbarColumnsLabel: 'Chọn cột',

                            // Filters toolbar button text
                            toolbarFilters: 'Lọc',
                            toolbarFiltersLabel: 'Hiển thị Lọc',
                            toolbarFiltersTooltipHide: 'Ẩn Lọc',
                            toolbarFiltersTooltipShow: 'Hiển thị Lọc',
                            toolbarFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,

                            // Export selector toolbar button text
                            toolbarExport: 'Xuất dữ liệu',
                            toolbarExportLabel: 'Xuất dữ liệu',
                            toolbarExportCSV: 'Tải xuống định dạng CSV',
                            toolbarExportPrint: 'In',

                            // Columns panel text
                            columnsPanelTextFieldLabel: 'Tìm kiếm cột',
                            columnsPanelTextFieldPlaceholder: 'Tên cột',
                            columnsPanelDragIconLabel: 'Reorder column',
                            columnsPanelShowAllButton: 'Hiển thị tất cả',
                            columnsPanelHideAllButton: 'Ẩn tất cả',

                            // Filter panel text
                            filterPanelAddFilter: 'Thêm lọc',
                            filterPanelDeleteIconLabel: 'Xóa',
                            filterPanelOperators: 'Lọc theo:',
                            filterPanelOperatorAnd: 'Và',
                            filterPanelOperatorOr: 'Hoặc',
                            filterPanelColumns: 'Cột',
                            filterPanelInputLabel: 'Giá trị',
                            filterPanelInputPlaceholder: 'Giá trị lọc',

                            // Filter operators text
                            filterOperatorContains: 'chứa',
                            filterOperatorEquals: 'bằng',
                            filterOperatorStartsWith: 'bắt đầu với',
                            filterOperatorEndsWith: 'kết thúc với',
                            filterOperatorIs: 'is',
                            filterOperatorNot: 'is not',
                            filterOperatorAfter: 'is after',
                            filterOperatorOnOrAfter: 'is on or after',
                            filterOperatorBefore: 'is before',
                            filterOperatorOnOrBefore: 'is on or before',
                            filterOperatorIsEmpty: 'giá trị rỗng',
                            filterOperatorIsNotEmpty: 'giá trị không rỗng',

                            // Filter values text
                            filterValueAny: 'any',
                            filterValueTrue: 'true',
                            filterValueFalse: 'false',

                            // Column menu text
                            columnMenuLabel: 'Danh mục',
                            columnMenuShowColumns: 'Hiển thị cột',
                            columnMenuFilter: 'Lọc',
                            columnMenuHideColumn: 'Ẩn',
                            columnMenuUnsort: 'Chưa sắp xếp',
                            columnMenuSortAsc: 'Sắp xếp tăng dần',
                            columnMenuSortDesc: 'Sắp xếp giảm dần',

                            // Column header text
                            columnHeaderFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,
                            columnHeaderFiltersLabel: 'Hiển thị lọc',
                            columnHeaderSortIconLabel: 'Sắp xếp',

                            // Rows selected footer text
                            footerRowSelected: (count) =>
                                count !== 1
                                    ? `${count.toLocaleString()} dòng đang chọn`
                                    : `${count.toLocaleString()} dòng đang chọn`,

                            // Total rows footer text
                            footerTotalRows: 'Tổng dòng:',

                            // Total visible rows footer text
                            footerTotalVisibleRows: (visibleCount, totalCount) =>
                                `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
                            // Checkbox selection text
                            checkboxSelectionHeaderName: 'Checkbox selection',

                            // Boolean cell text
                            booleanCellTrueLabel: 'true',
                            booleanCellFalseLabel: 'false',

                            // Actions cell more text
                            actionsCellMore: 'more',

                            // Tree Data
                            treeDataGroupingHeaderName: 'Nhóm',
                            treeDataExpand: 'Hiện danh sách con',
                            treeDataCollapse: 'Ẩn danh sách con',
                            TablePagination: 'ok'
                        }
                    }
                    components={{
                        Toolbar: CustomToolbar,
                        title: "Danh mục ca làm việc"
                    }}
                />

            </div>
            <Dialog open={open} fullWidth={true} maxWidth={"md"} align={'center'}>
                <DialogTitle>CHI TIẾT YÊU CẦU CHẤM CÔNG BÙ</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <TimeKeepingView data={dataRows} categories={categories} arrStatus={arrStatus}/>
                        <Button color="primary" variant="contained" onClick={handleClose}>
                            Đóng
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default TimeKeepingRequest;
