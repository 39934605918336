import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import {handleCloseAddEdit} from "../../pages/Employees/JobDetailsSetup";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const FormJobDetailsDelete = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const initDataDelete = props.data
    const [dataDelete,setDataDelete] = useState(initDataDelete)
    const classes = useStyles();
    const validationSchema = yup.object({
    });
    useEffect(async () => {
        try {

            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/jobtitle`
            const result = await axios.post(api, dataDelete,{headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                console.log(res);
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }})
            // const imageAvatr = await getImageAvatar(result.image);

        } catch(e) {

        }
    }, [dataDelete])

    const handleDelete = (values) => {
        // console.log(JSON.stringify(values));
        const dataNew = {"message":{...{"id":values.id}},"action":"delete","type_message":"map"}
        // alert(JSON.stringify({dataNew}, localStorage.getItem("authToken")))
        setDataDelete(dataNew);
        // console.log(dataNew)
    }
    const formik = useFormik({
        initialValues: {
            id: props.data.id,
            code: props.data.code,
            name: props.data.name,
            created_at: props.data.created_at,
            updated_at: props.data.updated_at,
            description: props.data.description
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleDelete(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="id"
                            name="id"
                            label="ID"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            error={formik.touched.id && Boolean(formik.errors.id)}
                            helperText={formik.touched.id && formik.errors.id}
                            disabled

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="code"
                            name="code"
                            label="Mã Chức Danh"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                            helperText={formik.touched.code && formik.errors.code}
                            disabled

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="name"
                            name="name"
                            label="Tên Chức Danh"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="created_at"
                            name="created_at"
                            label="Ngày cập nhật"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.created_at}
                            onChange={formik.handleChange}
                            error={formik.touched.created_at && Boolean(formik.errors.created_at)}
                            helperText={formik.touched.created_at && formik.errors.created_at}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="updated_at"
                            name="updated_at"
                            label="Ngày sửa đổi"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.updated_at}
                            onChange={formik.handleChange}
                            error={formik.touched.updated_at && Boolean(formik.errors.updated_at)}
                            helperText={formik.touched.updated_at && formik.errors.updated_at}
                            disabled
                        />

                    </Grid>
                    <Grid item>
                        <TextField
                            multiline
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="description"
                            label="Ghi chú"
                            name="description"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Xóa
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormJobDetailsDelete;
