
// Spacing
const CustomLocaleText= {
    noRowsLabel: 'Không có dữ liệu',
    noResultsOverlayLabel: 'Không tìm thấy kết quả.',
    errorOverlayDefaultLabel: 'An error occurred.',
    // Density selector toolbar button text
    toolbarDensity: 'Độ cao dòng',
    toolbarDensityLabel: 'Độ cao dòng',
    toolbarDensityCompact: 'Thu gọn',
    toolbarDensityStandard: 'Bình thường',
    toolbarDensityComfortable: 'Mở rộng',

    // Columns selector toolbar button text
    toolbarColumns: 'Cột',
    toolbarColumnsLabel: 'Chọn cột',

    // Filters toolbar button text
    toolbarFilters: 'Lọc',
    toolbarFiltersLabel: 'Hiển thị Lọc',
    toolbarFiltersTooltipHide: 'Ẩn Lọc',
    toolbarFiltersTooltipShow: 'Hiển thị Lọc',
    toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} active filters` : `${count} active filter`,

    // Export selector toolbar button text
    toolbarExport: 'Xuất dữ liệu',
    toolbarExportLabel: 'Xuất dữ liệu',
    toolbarExportCSV: 'Tải xuống định dạng CSV',
    toolbarExportPrint: 'In',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Tìm kiếm cột',
    columnsPanelTextFieldPlaceholder: 'Tên cột',
    columnsPanelDragIconLabel: 'Reorder column',
    columnsPanelShowAllButton: 'Hiển thị tất cả',
    columnsPanelHideAllButton: 'Ẩn tất cả',

    // Filter panel text
    filterPanelAddFilter: 'Thêm lọc',
    filterPanelDeleteIconLabel: 'Xóa',
    filterPanelOperators: 'Lọc theo:',
    filterPanelOperatorAnd: 'Và',
    filterPanelOperatorOr: 'Hoặc',
    filterPanelColumns: 'Cột',
    filterPanelInputLabel: 'Giá trị',
    filterPanelInputPlaceholder: 'Giá trị lọc',

    // Filter operators text
    filterOperatorContains: 'chứa',
    filterOperatorEquals: 'bằng',
    filterOperatorStartsWith: 'bắt đầu với',
    filterOperatorEndsWith: 'kết thúc với',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is not',
    filterOperatorAfter: 'is after',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'is before',
    filterOperatorOnOrBefore: 'is on or before',
    filterOperatorIsEmpty: 'giá trị rỗng',
    filterOperatorIsNotEmpty: 'giá trị không rỗng',

    // Filter values text
    filterValueAny: 'any',
    filterValueTrue: 'true',
    filterValueFalse: 'false',

    // Column menu text
    columnMenuLabel: 'Danh mục',
    columnMenuShowColumns: 'Hiển thị cột',
    columnMenuFilter: 'Lọc',
    columnMenuHideColumn: 'Ẩn',
    columnMenuUnsort: 'Chưa sắp xếp',
    columnMenuSortAsc: 'Sắp xếp tăng dần',
    columnMenuSortDesc: 'Sắp xếp giảm dần',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} active filters` : `${count} active filter`,
    columnHeaderFiltersLabel: 'Hiển thị lọc',
    columnHeaderSortIconLabel: 'Sắp xếp',

    // Rows selected footer text
    footerRowSelected: (count) =>
    count !== 1
        ? `${count.toLocaleString()} dòng đang chọn`
        : `${count.toLocaleString()} dòng đang chọn`,

    // Total rows footer text
    footerTotalRows: 'Tổng dòng:',

    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',

    // Boolean cell text
    booleanCellTrueLabel: 'true',
    booleanCellFalseLabel: 'false',

    // Actions cell more text
    actionsCellMore: 'more',

    // Tree Data
    treeDataGroupingHeaderName: 'Nhóm',
    treeDataExpand: 'Hiện danh sách con',
    treeDataCollapse: 'Ẩn danh sách con',
    TablePagination: 'ok'
}


export {
    CustomLocaleText
};
