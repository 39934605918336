import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {getDroppedOrSelectedFiles} from 'html5-file-selector'
import Input from "../../UploadFile/Input"
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {ColorButtonAdd, ColorButtonCancel, ColorButtonHoverAdd, ColorTextButton} from "../../MainStyleCSS/ColorHeader";

const useStyles = makeStyles(theme => ({
    dropzoneStyle: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        backgroundColor: 'red'
    },
    ButtonAdd:{
        background: ColorButtonAdd,
        color: ColorTextButton,
        '&:hover': {
            background: ColorButtonHoverAdd
        },
    },
    ButtonCancel:{
        backgroundColor: ColorButtonCancel,
        '&:hover': {
            background: ColorButtonHoverAdd,
            color: ColorTextButton
        },
    },

    ButtonAction:{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px'
    },
}))
const FormAddFileEmployees = (props) => {
    // Dropzone.prototype.defaultOptions.dictDefaultMessage = "Drop files here to upload";
    // Dropzone.prototype.defaultOptions.dictFallbackMessage = "Your browser does not support drag'n'drop file uploads.";
    // Dropzone.prototype.defaultOptions.dictFallbackText = "Please use the fallback form below to upload your files like in the olden days.";
    // Dropzone.prototype.defaultOptions.dictFileTooBig = "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.";
    // Dropzone.prototype.defaultOptions.dictInvalidFileType = "You can't upload files of this type.";
    // Dropzone.prototype.defaultOptions.dictResponseError = "Server responded with {{statusCode}} code.";
    // Dropzone.prototype.defaultOptions.dictCancelUpload = "Cancel upload";
    // Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Are you sure you want to cancel this upload?";
    // Dropzone.prototype.defaultOptions.dictRemoveFile = "Remove file";
    // Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "You can not upload any more files.";
    const classes = useStyles();
    const [listFiles,setListFiles] = useState([])
    const handleSubmit = (files, allFiles) => {
        // console.log(props.model_name)
        // console.log(files.map(f => f.meta))
        // // allFiles.forEach(f => f.remove())
        //
        const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                'Content-Type': 'multipart/form-data'
            }
        };
        let fd = new FormData();
        allFiles.map(f  => {
            fd.append( "file",f.file);
            console.log(JSON.stringify(f))
        });
        setListFiles[fd]
        // fd.append('file', allFiles[0])
    }
    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }
    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const result = await axios.post(`https://api-dev.hrms.com.vn/api/v1/upload/${props.model_name}`, fd, config)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data.message);
                        props.values.file = response.data.message;
                    }
                    // callback(response);
                })

            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [listFiles])
    return (
        <form onSubmit={handleSubmit}>


        <Dropzone
            // accept="image/*,audio/*,video/*,.pdf"
                  getUploadParams={() => ({url: 'https://httpbin.org/post'})}
                  onSubmit={handleSubmit}
                  InputComponent={Input}
                  isDragActive={true}
                  getFilesFromEvent={getFilesFromEvent}
                  submitButtonContent='Tải lên'
                  styles={{dropzone: {minHeight: '300px', maxHeight: '600px', minWidth: '400px', maxWidth: '800px', padding:'20px'}}}
        >
            {/*{({getRootProps, getInputProps, isDragActive, isDragReject}) => (*/}
            {/*    <div {...getRootProps()}>*/}
            {/*        <input {...getInputProps()} />*/}
            {/*        {!isDragActive && 'Click here or drop a file to upload!'}*/}
            {/*        {isDragActive && !isDragReject && "Drop it like it's hot!"}*/}
            {/*        {isDragReject && "File type not accepted, sorry!"}*/}
            {/*    </div>*/}
            {/*)}*/}

        </Dropzone>
            <Grid container spacing={4} className={classes.ButtonAction} >
                <Grid item>
                    <Button className={classes.ButtonAdd} variant="contained" type="submit">
                        Hoàn tất
                    </Button>
                </Grid>
                <Grid item>
                    <Button className={classes.ButtonCancel} variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default FormAddFileEmployees ;
