import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector, GridToolbarExport,
    GridToolbarFilterButton
} from "@mui/x-data-grid";
import QuickSearchToolbar from "../ToolBar/QuickSearchToolbar";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import React from "react";


const CustomToolbar = (props) => {
    return (
        <>
            {/*<QuickSearchToolbar/>*/}
            <Grid container style={{padding:'10px'}}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <GridToolbarContainer>
                        <div>
                            <GridToolbarColumnsButton
                            />
                        </div>
                        <GridToolbarFilterButton
                        />
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>

                    </GridToolbarContainer>
                </Grid>
                {/*<Grid item xs={6} sm={6} md={6} lg={6}>*/}
                {/*    <Grid container justify="flex-end">*/}
                {/*        <Button variant="contained" style={{backgroundColor: '#1A94FF', color: 'white' }}*/}
                {/*                                onClick={props}*/}
                {/*        > Thêm mới </Button>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Grid>

        </>


    );
}
export default CustomToolbar;
