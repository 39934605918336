import React, {useEffect, useRef, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import CustomToolbar from "../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Moment from "react-moment";
import {Dialog, DialogActions, DialogTitle, MenuItem} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GetAppIcon from '@mui/icons-material/GetApp';
import {CustomLocaleText} from "../../components/CustomTableToolbar/CustomLocaleText";
import {
    ColorActionDelete,
    ColorActionEdit,
    ColorActionView,
    ColorButton,
    ColorExcel,
    ColorHeader,
    ColorTextButton
} from "../../components/MainStyleCSS/ColorHeader";
import FormAttandanceDevicesView from "../../components/System/AttandanceDevices/FormAttandanceDevicesView";
import FormAttandanceDevicesUpdate from "../../components/System/AttandanceDevices/FormAttandanceDevicesUpdate";
import FormAttandanceDevicesDelete from "../../components/System/AttandanceDevices/FormAttandanceDevicesDelete";
import FormAttandanceDevicesAdd from "../../components/System/AttandanceDevices/FormAttandanceDevicesAdd";
import ExportExcel from "../../components/ExportExcel/ExportExcel";
import 'moment-timezone';
import moment from "moment";
import FormServicePackageView from "../../components/System/ServicePackage/FormServicePackageView";
import FormServicePackageUpdate from "../../components/System/ServicePackage/FormServicePackageUpdate";
import FormServicePackageDelete from "../../components/System/ServicePackage/FormServicePackageDelete";
import FormServicePackageAdd from "../../components/System/ServicePackage/FormServicePackageAdd";
import FormDetailsPackageView from "../../components/System/ServicePackage/FormDetailsPackageView";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const rootEl = document.getElementById('root');
const initialFValues = {
    startTime: new Date(),
    endTime: new Date(),
}

const useStyles = makeStyles(theme => ({
    PackageBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "15px",
        height: "15px",
        margin:"2px"
    },
    TitleDialog: {
        backgroundColor: ColorButton,
        color: ColorTextButton,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold; line-height: 20px',
        // background: 'linear-gradient(#9BC90D 0%, #79A70A 100%)',
        background: 'linear-gradient(#1A94FF 0%, #1977d4 100%)',
        boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
    },
    ContainerDialog: {
        maxWidth: "lg",
        maxHeight: "calc(100vh - 64px)",

    },
    paper: {borderRadius: 15},
    ContentDialog: {
        margin: "30px",
    },
    buttonsAdd: {
        color: '#1A94FF',
        padding: "10px",
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        right: 0,
        marginTop: '10px',
        marginRight: '10px',
        zIndex: 1,
    },
    buttonsExcel: {
        color: '#1A94FF',
        padding: "10px",
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        right: 145,
        marginTop: '10px',
        marginRight: '10px',
        zIndex: 1,
    },

    lightText: {
        color: 'white'
    },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    root: {

        clear: "both",
        padding: "10px",
        '& .super-app-theme--header': {
            backgroundColor: ColorHeader,
            // color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        '& .MuiToolbar-root ': {
            color: theme.palette.text.primary
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        }
    },

}));

let fileName = `DANH SÁCH THIẾT BỊ CHẤM CÔNG`
const dateFormater = (cell) => {
    return (
        <Moment format="DD-MM-YYYY HH:mm:ss">{cell}</Moment>
    );
};

function addOrEdit(thisRow) {

}

// const statusDevices = [{"id":true, "name":"Trực tuyến"},{"id":false,"name":"Ngoại tuyến"}]
export default function ServicePackage() {

    const classes = useStyles();
    // const {addOrEdit, recordForEdit} = props
    const [data, setData] = useState([])
    // const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [dataRows, setDataRows] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [detailsServicePack, setDetailsServicePack] = useState(false)
    //     // console.log(dataUser)
    const handleEdit = () => {
        setOpenEdit(false);
    };
    const handleDelete = () => {
        setOpenDelete(false);
    };
    const handleAdd = () => {
        setOpenAdd(false);
    };
    const handleClose = () => {
        setOpen(false);
        // setDataRows({});
    };
    // const handleExportExcel = () => {
    //     const temp = {}
    //     temp.dataGrid = dataGrid.rows
    //     temp.name = fileName
    //     temp.workbook = ''
    //     // console.log(temp)
    //     ExportExcel(temp)
    //     // <ExportExcelTest/>
    // }
    useEffect(async () => {

        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/servicepack`
            const api2 = `https://api-dev.hrms.com.vn/api/v1/servicetype`
            const api3 = `https://api-dev.hrms.com.vn/api/v1/model`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    let newArray = []
                    if (res.data.message === null) {
                        res.data.message = {};
                        setData({})
                    } else {
                        setData(res.data.message)
                        console.log(res.data.message)
                    }
                }
            })
            const result2 = await axios.get(api2, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res2 => {
                if (res2.status === 200) {
                    let newArray = []
                    if (res2.data.message === null) {
                        res2.data.message = {};
                        setData2({})
                    } else {
                        setData2(res2.data.message)
                        console.log(res2.data.message)
                    }
                }
            })

            const result3 = await axios.get(api3, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res3 => {
                if (res3.status === 200) {
                    if (res3.data.message === null) {
                        res3.data.message = {};
                        setData3({})
                    } else {
                        setData3(res3.data.message)
                        console.log(res3.data.message)
                    }
                }
            })

        } catch (e) {

        }
    }, [openAdd, openDelete, openEdit, isExpired])

    const dataGrid = {
        "columns":
            [
                {
                    "field": "id",
                    "headerName": "ID",
                    "hide": true,
                    "minWidth": 300,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "name",
                    "headerName": "Tên gói dịch vụ",
                    "editable": false,
                    "minWidth": 250,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "service_type",
                    "headerName": "Loại dịch vụ",
                    "minWidth": 200,
                    flex: 1,
                    "valueFormatter": (params) => `${data2.filter(x => x.id === params.value).map(x => x.name)}`,
                    "renderCell": params => {
                        return data2.filter(x => x.id === params.value).map(x => x.name);
                    },
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "pack_info",
                    "headerName": "Thông tin gói",
                    "minWidth": 200,
                    flex: 1,
                    renderCell: (params) => (
                        <div>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setDetailsServicePack(true);
                                // viewRows(thisRow)
                                // return alert(JSON.stringify(thisRow, null, 4));
                            }

                            }>
                                {/*<VisibilityIcon style={{color: ColorActionView}}/>*/}
                                {params.value ?
                                    params.value.map((x) => (
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "5px"
                                        }}>
                                            <img
                                                src={`${process.env.PUBLIC_URL}/static/images/PackageBox.png`}
                                                alt="package"
                                                className={classes.PackageBox}
                                            />
                                        </div>
                                    ))
                                    //     <span style={{fontSize:'12px', color:'blue'}}>{params.value.join()}</span>
                                    :
                                    <span style={{fontSize: '12px', color: 'blue'}}>(Trống)</span>}
                                {/*{params.value.map((x) => (*/}
                                {/*    <span style={{fontSize:'12px', color:'blue'}}>{x.model_name}</span>*/}
                                {/*))}*/}
                            </IconButton>
                        </div>
                    ),
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                },

                {
                    "field": "actions",
                    "headerName": "Thao tác",
                    "sortTable": false,
                    "type": "string",
                    "width": 160,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpen(true);
                                // viewRows(thisRow)
                                // return alert(JSON.stringify(thisRow, null, 4));
                            }

                            }>
                                <VisibilityIcon style={{color: ColorActionView}}/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenEdit(true);
                            }
                            }>
                                <EditIcon style={{color: ColorActionEdit}}/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenDelete(true);
                            }}>
                                <DeleteForeverIcon style={{color: ColorActionDelete}}/>
                            </IconButton>

                        </div>
                    )
                },
            ],
        "rows": data,
        rowLength: 100,
        maxColumns: 6,
    }

    return (
        <>
            <Grid container>
                <Grid item xs={2} className={classes.buttonsAdd}>
                    <div>
                        <Button variant="contained" style={{
                            backgroundColor: ColorButton,
                            color: 'white',
                            maxHeight: '37px',
                            minWidth: '140px'
                        }}
                                onClick={() => {
                                    setOpenAdd(true)
                                }}
                        >
                            <AddIcon/>
                            Thêm mới
                        </Button>
                    </div>
                </Grid>
                {/*<Grid item xs={2} className={classes.buttonsExcel}>*/}
                {/*    <div>*/}
                {/*        <Button variant="contained" style={{*/}
                {/*            backgroundColor: ColorExcel,*/}
                {/*            color: 'white',*/}
                {/*            maxHeight: '37px',*/}
                {/*            minWidth: '140px'*/}
                {/*        }}*/}
                {/*                onClick={handleExportExcel}*/}
                {/*        >*/}
                {/*            <GetAppIcon/>*/}
                {/*            Xuất Excel*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</Grid>*/}
            </Grid>


            <div style={{
                height: '100vh',
                width: 'calc(100vh -150px)',
                boxShadow: '0.25rem 0.25rem 0.6rem rgb(0 0 0 / 5%), 0 0.5rem 1.125rem rgb(75 0 0 / 5%)'
            }} className={classes.root}>
                <DataGrid
                    {...dataGrid}
                    className={classes.dataGrid}
                    disableExtendRowFullWidth={true}
                    getRowClassName={(params) =>
                        `super-app-theme--${params.getValue(params.id, 'status')}`}
                    hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                    localeText={
                        CustomLocaleText
                    }
                    components={{
                        Toolbar: CustomToolbar,
                        title: "KHÁCH HÀNG"
                    }}
                />
            </div>
            <Dialog open={detailsServicePack} fullWidth={true} maxWidth={"lg"}>
                <DialogTitle className={classes.TitleDialog}>CHI TIẾT THÔNG TIN GÓI</DialogTitle>
                <DialogActions style={{backgroundColor: "rgba(0, 0, 0, 0.2)", overflow: "hidden"}}>
                    <FormDetailsPackageView data={dataRows}
                                            data2={data2}
                                            data3={data3}
                                            onClick={() => {
                                                setDetailsServicePack(false)
                                            }}
                    />
                </DialogActions>
            </Dialog>
            <Dialog open={open} fullWidth={true} maxWidth={"md"} align='center'>
                <DialogTitle className={classes.TitleDialog}>CHI TIẾT GÓI DỊCH VỤ</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormServicePackageView data={dataRows}
                                            data2={data2}
                                            data3={data3}
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                    />
                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} fullWidth={true} maxWidth={"lg"}>
                <DialogTitle className={classes.TitleDialog}>CẬP NHẬT GÓI DỊCH VỤ</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormServicePackageUpdate
                        data={dataRows}
                        data2={data2}
                        data3={data3}
                        onClick={() => {
                            setOpenEdit(false)
                        }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} fullWidth={true} maxWidth={"md"} align='center'>
                <DialogTitle className={classes.TitleDialog}>XÓA GÓI DỊCH VỤ</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormServicePackageDelete
                        data={dataRows}
                        data2={data2}
                        data3={data3}
                        onClick={() => {
                            setOpenDelete(false)
                        }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openAdd} fullWidth={true} maxWidth={"md"} align='center'>
                <DialogTitle className={classes.TitleDialog}>THÊM GÓI DỊCH VỤ</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormServicePackageAdd
                        data2={data2}
                        data3={data3}
                        onClick={() => {
                            setOpenAdd(false)
                        }}/>
                    {/*</div>*/}
                </DialogActions>
            </Dialog>

        </>
    );
}

