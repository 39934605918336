import React, {useEffect, useState} from 'react';
import {DataGrid, GridApi, GridCellValue, GridCellParams} from '@mui/x-data-grid';
import CustomToolbar from "../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import FormEmployeesAdd from "../../components/Form/FormEmployeesAdd";
import AlertMessage from "../../components/Alert/AlertMessage";
import {SnackbarProvider} from "notistack";
import FormEmployeesUpdate from "../../components/Form/FormEmployeesUpdate";
import FormEmployeesView from "../../components/Form/FormEmployeesView";
import FormEmployeesDelete from "../../components/Form/FormEmployeesDelete";
import {CustomRow} from "../../components/CustomRowDataGridMUI/CustomRow"
import clsx from "clsx";
import Box from "@mui/material/Box";
import {useAppState} from "../../components/AppProvider/AppProvider";
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const rootEl = document.getElementById('root');
// const initialFValues = {
//     startTime: new Date(),
//     endTime: new Date(),
// }
// const StyledDataGrid = withStyles({
//     root: {
//
//     },
// })(DataGrid);
const useStyles = makeStyles(theme => ({
    buttonsAdd: {
        marginTop: "15px",
        marginLeft: "20px",
    },
    dataGrid: {
        // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        // background:
        //     `radial-gradient(black 15%, transparent 16%) 0 0,
        //     radial-gradient(black 15%, transparent 16%) 8px 8px,
        //     radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
        //     radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
        //     background-color:#282828;
        //     background-size:16px 16px;`,
        borderRadius: 3,
        border: 0,
        color: "inherit",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",

    },
    // rows:{
    //     color:"inherit"
    // },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },

    root: {

        padding: "20px",
        '& .super-app-theme--header': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: theme.palette.info.main,
            '&:hover': {
                backgroundColor: theme.palette.info.main,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.main,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        '& .MuiToolbar-root ':{
            color: theme.palette.text.primary
        },
        '& .MuiSvgIcon-root':{
            color: theme.palette.text.primary
        }
    },

}));


const dateFormater = (cell) => {
    return (
        <Moment format="DD-MM-YYYY HH:mm:ss">{cell}</Moment>
    );
};
const dateFormater2 = (cell) => {
    return (
        <Moment format="DD-MM-YYYY">{cell}</Moment>
    );
};

function addOrEdit(thisRow) {

}


export default function Employees() {
    const [state, dispatch] = useAppState();
    const submitAlert = async () => {
        setStatusAlert({ msg: 'Thêm nhân viên thành công', key: Math.random() });
    };
    const submitAlertError = async () => {
        setStatusAlert({ msg: message, key: Math.random() });
    };
    const [statusAlert,setStatusAlert] = useState("");
    const classes = useStyles();
    // const {addOrEdit, recordForEdit} = props
    const [dataEmployees, setDataEmployees] = useState([])
    // const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [dataRows, setDataRows] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    // const [country, setCountry] = useState('')
    const [countries, setCountries] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [jobDetails, setJobDetails] = useState([]);
    const [shiftTimes, setShiftTimes] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [laborContracts, setLaborContracts] = useState([]);
    const [shiftTypes,setShiftTypes] = useState([]);
    const [skills,setSkills] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [educations, setEducations] = useState([]);

    // console.log(localStorage.getItem("authToken"))
    const maritalStatus = [
        {"id": "married", "name": "Đã kết hôn"},
        {"id": "single", "name": "Độc thân"},
        {"id": "divorced", "name": "Ly hôn"},
        {"id": "widowed", "name": "Góa"},
        {"id": "other", "name": "Khác"}
    ]
    const genders = [
        {"id": "male", "name": "Nam"},
        {"id": "female", "name": "Nữ"},
        {"id": "other", "name": "Khác"}
    ]
    const immigrationStatus = [{"id": "permanent", "name": "Thường trú"},{"id": "temporary", "name": "Tạm trú"}]


    useEffect(async () => {
        try {
            const api1 = `https://api-dev.hrms.com.vn/api/v1/jobtitle`
            const result = await axios.get(api1, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res1 => {
                if (res1.status === 200) {
                    if (res1.data.message === null) {
                        res1.data.message = {};

                    }
                    console.log(res1.data.message);
                    setJobDetails(res1.data.message)
                }
            })

            const api2 = `https://api-dev.hrms.com.vn/api/v1/companystructure`
            const result2 = await axios.get(api2, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res2 => {
                if (res2.status === 200) {
                    if (res2.data.message === null) {
                        res2.data.message = {};
                    }
                    setDepartments(res2.data.message);

                }
            })
            const api3 = `https://api-dev.hrms.com.vn/api/v1/shifttime`
            const result3 = await axios.get(api3, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res3 => {
                if (res3.status === 200) {
                    if (res3.data.message === null) {
                        res3.data.message = {};
                    }
                    setShiftTimes(res3.data.message)
                }
            })
            const api4 = `https://api-dev.hrms.com.vn/api/v1/nationalities`
            const result4 = await axios.get(api4, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res4 => {
                if (res4.status === 200) {
                    if (res4.data.message === null) {
                        res4.data.message = {};
                    }
                    // console.log(JSON.stringify(res4.data.message))
                    setNationalities(res4.data.message)
                }

            })
            const api5 = `https://api-dev.hrms.com.vn/api/v1/employmentstatus`
            const result5 = await axios.get(api5, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res5 => {
                if (res5.status === 200) {
                    if (res5.data.message === null) {
                        res5.data.message = {};
                    }
                    // console.log(res4.data)
                    // console.log(JSON.stringify(res5.data.message,null,4))
                    setLaborContracts(res5.data.message)
                }

            })
            const api6 = `https://api-dev.hrms.com.vn/api/v1/countries`
            const result6 = await axios.get(api6, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res6 => {
                if (res6.status === 200) {
                    if (res6.data.message === null) {
                        res6.data.message = {};
                    }
                    setCountries(res6.data.message)
                }

            })
            const api7 = `https://api-dev.hrms.com.vn/api/v1/workshifttype`
            const result7 = await axios.get(api7, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res7 => {
                if (res7.status === 200) {
                    if (res7.data.message === null) {
                        res7.data.message = {};
                    }
                    setShiftTypes(res7.data.message)
                }

            })
            const api8 = `https://api-dev.hrms.com.vn/api/v1/skill`
            const result8 = await axios.get(api8, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res8 => {
                if (res8.status === 200) {
                    if (res8.data.message === null) {
                        res8.data.message = {};
                    }
                    setSkills(res8.data.message)
                }
            })
            const api9 = `https://api-dev.hrms.com.vn/api/v1/certification`
            const result9 = await axios.get(api9, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res9 => {
                if (res9.status === 200) {
                    if (res9.data.message === null) {
                        res9.data.message = {};
                    }
                    setCertificates(res9.data.message)
                }
            })
            const api10 = `https://api-dev.hrms.com.vn/api/v1/educations`
            const result10 = await axios.get(api10, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res10 => {
                if (res10.status === 200) {
                    if (res10.data.message === null) {
                        res10.data.message = {};
                    }
                    setEducations(res10.data.message)
                }
            })

        } catch (e) {
        }
    }, [])




    const handleEdit = () => {
        setOpenEdit(false);
    };
    const handleDelete = () => {
        setOpenDelete(false);
    };
    const handleAdd = () => {
        setOpenAdd(false);
    };
    const handleClose = () => {
        setOpen(false);
        // setDataRows({});
    };

    useEffect(async () => {
        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/employee`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message === null) {
                        res.data.message = {};
                    }
                    //
                    console.log(res.data.message);
                    // console.log(localStorage.getItem("authToken"))
                    setDataEmployees(res.data.message)
                }

            })
        } catch (e) {

        }
    }, [openAdd, openDelete, openEdit])


    const data = {
        "columns":
            [{
                "field": "id",
                "headerName": "ID",
                "hide": true,
                "width": 300,
                headerClassName: 'super-app-theme--header',
                headerAlign: 'center',
            },
                {
                    "field": "employee_id",
                    "headerName": "Mã NV",
                    "width": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },

                {
                    "field": "first_name",
                    "headerName": "Tên",
                    "width": 200,
                    "editable": false,
                    "hide":true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "last_name",
                    "headerName": "Họ",
                    "width": 200,
                    "editable": false,
                    "hide":true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "full_name",
                    "headerName": "Tên đầy đủ",
                    "width": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": params => { return `${params.api.getCellParams(params.id,"last_name").formattedValue} ${params.api.getCellParams(params.id,"first_name").formattedValue}`},
                    "renderCell": params => {
                        return `${params.row.last_name} ${params.row.first_name}`
                    },


                },
                {
                    "field": "department",
                    "headerName": "Phòng ban",
                    "type": "string",
                    minWidth: 200,
                    flex:1,
                    align:'center',
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.name}`,
                    "renderCell": (params) => {
                        return (params.value.name)
                    },
                },
                {
                    "field": "supervisor",
                    "headerName": "Người quản lý",
                    "type": "string",
                    minWidth:200,
                    align:'center',
                    flex:1,
                    "hide": true,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.first_name} ${params.value.last_name}`,
                    "renderCell": (params) => {
                        return (`${params.value.first_name} ${params.value.last_name}`)
                    },
                },
                {
                    "field": "country",
                    "headerName": "Quốc Gia",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => {
                        return `${params.value.name}`===`Viet Nam` ? `Việt Nam` : `${params.value.name}`},
                    "renderCell": (params) => {
                        return `${params.value.name}`===`Viet Nam` ? `Việt Nam` : `${params.value.name}`
                    },
                },
                {
                    "field": "parent",
                    "headerName": "Thuộc Phòng Ban",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "hide": true,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${departments.filter(x => x.id === params.id).map(x => x.name)}`,
                    "renderCell": (params) => {
                        return params.value
                    },
                },
                {
                    "field": "address1",
                    "headerName": "Địa Chỉ 1",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "address2",
                    "headerName": "Địa chỉ 2",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "approver1",
                    "headerName": "Người giám sát 1",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.first_name} ${params.value.last_name}`,
                    "renderCell": (params) => {
                        return (`${params.value.first_name} ${params.value.last_name}` )
                    },
                },
                {
                    "field": "approver2",
                    "headerName": "Người giám sát 2",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.first_name} ${params.value.last_name}`,
                    "renderCell": (params) => {
                        return (`${params.value.first_name} ${params.value.last_name}` )
                    },
                },
                {
                    "field": "approver3",
                    "headerName": "Người giám sát 3",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.first_name} ${params.value.last_name}`,
                    "renderCell": (params) => {
                        return (`${params.value.first_name} ${params.value.last_name}` )
                    },
                },
                {
                    "field": "birth_day",
                    "headerName": "Ngày sinh",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "renderCell": (params) => {
                      return params.value === '' ? '' : dateFormater2(params.value)

                        // if (params.value === '') {
                        //     return ''
                        // }
                        // else {
                        //     return dateFormater2(params.value)
                        // }
                    },
                },
                {
                    "field": "city",
                    "headerName": "Thành phố",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "confirmation_date",
                    "headerName": "Ngày ký hợp đồng",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "renderCell": (params) => {
                        return params.value === '' ? '' : dateFormater2(params.value)
                    },
                },
                {
                    "field": "driving_license",
                    "headerName": "Bằng lái xe",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "driving_license_exp_date",
                    "headerName": "Ngày hết hạn bằng lái xe",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "renderCell": (params) => {
                        return params.value === '' ? '' : dateFormater2(params.value)
                    },
                },
                {
                    "field": "employment_status",
                    "headerName": "Loại hợp đồng lao động",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.name}`,
                    "renderCell": (params) => {
                        return params.value.name
                    },
                },
                {
                    "field": "ethnicity",
                    "headerName": "Dân tộc",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "gender",
                    "headerName": "Giới tính",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${genders.filter(x => x.id === params.value).map(x => x.name)}`,
                    renderCell : params => {
                        return  genders.filter(x => x.id === params.value).map(x => x.name);
                    }
                },
                {
                    "field": "hic_num",
                    "headerName": "Số BHYT",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "home_phone",
                    "headerName": "SĐT bàn",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "image_profile",
                    "headerName": "Ảnh đại diện",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "imagegration_status",
                    "headerName": "Trạng thái ảnh tích hợp",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "job_title",
                    "headerName": "Chức danh công việc",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.name}`,
                    "renderCell": (params) => {
                        return params.value.name
                    },
                },
                {
                    "field": "joined_date",
                    "headerName": "Ngày nhận việc",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "renderCell": (params) => {
                        return params.value === '' ? '' : dateFormater2(params.value)
                    },
                },
                {
                    "field": "marital_status",
                    "headerName": "Tình trạng hôn nhân",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${maritalStatus.filter(x => x.id === params.value).map(x => x.name)}`,
                    renderCell : params => {
                      return  maritalStatus.filter(x => x.id === params.value).map(x => x.name);
                    }
                },
                {
                    "field": "immigration_status",
                    "headerName": "Tình trạng cư trú",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${immigrationStatus.filter(x => x.id === params.value).map(x => x.name)}`,
                    renderCell : params => {
                        return  immigrationStatus.filter(x => x.id === params.value).map(x => x.name);
                    }
                },
                {
                    "field": "mobile_phone",
                    "headerName": "SĐT di động",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "nationality",
                    "headerName": "Quốc tịch",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.name}`===`Vietnamese` ? `Việt Nam` : `${params.value.name}}`,
                    "renderCell": (params) => {
                        return `${params.value.name}`===`Vietnamese` ? `Việt Nam` : `${params.value.name}`
                    },
                },
                {
                    "field": "nic_num",
                    "headerName": "nic_num",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "notes",
                    "headerName": "Ghi chú",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "other_id",
                    "headerName": "Giấy tờ tùy thân khác",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "pay_grade",
                    "headerName": "Pay Grade",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.name}`,
                    "renderCell": (params) => {
                        return params.value.name
                    },
                },
                {
                    "field": "postal_code",
                    "headerName": "Mã bưu điện",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "private_email",
                    "headerName": "Email cá nhân",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "province",
                    "headerName": "Tỉnh thành",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "shift_time",
                    "headerName": "Ca làm việc",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.name}`,
                    "renderCell": (params) => {
                        return params.value.name
                    },
                },
                {
                    "field": "shift_type",
                    "headerName": "Loại tuyển dụng",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.name}`,
                    "renderCell": (params) => {
                        return params.value.name
                    },
                },
                {
                    "field": "ssn_num",
                    "headerName": "CMND/CCCD",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "termination_date",
                    "headerName": "Ngày thôi việc",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "renderCell": (params) => {
                        return params.value === '' ? '' : dateFormater2(params.value)
                    },
                },
                {
                    "field": "tic_num",
                    "headerName": "Mã số thuế cá nhân",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "work_email",
                    "headerName": "Email công ty",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "work_phone",
                    "headerName": "SĐT làm việc",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "work_station_id",
                    "headerName": "Work Station ID",
                    "type": "string",
                    minWidth:200,
                    flex:1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "actions",
                    "headerName": "Thao tác",
                    "sortTable": false,
                    "type": "string",
                    "description": "Thao tác Xem Sửa Xóa tương ứng với Icon gợi ý",
                    minWidth:160,
                    align:'center',
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpen(true);
                                // viewRows(thisRow)
                                // return alert(JSON.stringify(thisRow, null, 4));
                            }

                            }>
                                <VisibilityIcon/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenEdit(true);
                            }
                            }>
                                <EditIcon/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenDelete(true);
                            }}>
                                <DeleteForeverIcon/>
                            </IconButton>
                        </div>
                    )
                }],
        "rows": dataEmployees,
        rowLength: 100,
        maxColumns: 6,
    }

    return (
        <>
            <SnackbarProvider maxSnack={4}>
            {/*<PageHeader*/}
            {/*    title="DANH SÁCH NHÂN VIÊN"*/}
            {/*    // subTitle=""*/}
            {/*    // icon={<PeopleOutlineTwoToneIcon fontSize="large"/>}*/}
            {/*/>*/}
            {/*<Grid container className="Search"*/}
            {/*      style={{columnGap: "10px", rowGap: "15px", marginBottom: "10px", marginLeft: "10px"}}>*/}
            {/*    <Grid item xs={2}>*/}
            {/*        <div>*/}
            {/*            <Button color="primary" variant="contained"*/}
            {/*                    onClick={() => {*/}
            {/*                        setOpenAdd(true)*/}
            {/*                    }}*/}
            {/*            >*/}
            {/*                <AddIcon/>*/}
            {/*                Thêm mới*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*    </Grid>*/}
            {/*    /!*<Grid xs={12} style={{display:"flex", justifyContent: "center", alignItems: "center", marginBottom:"10px"}}>*!/*/}
            {/*    /!*    *!/*/}
            {/*    /!*</Grid>*!/*/}
            {/*</Grid>*/}

            <Grid item xs={2} className={classes.buttonsAdd} >
                <div>
                    <Button color="primary" variant="contained"
                            onClick={() => {
                                setOpenAdd(true)
                            }}
                    >
                        <AddIcon/>
                        Thêm mới
                    </Button>
                </div>
            </Grid>
            {/*<div style={{ height: '75%', width: '100%' }}>*/}
            <div style={{height: '100%', width: '97%'}} className={classes.root}  >
                {(state.type === 'dark') ?
                    <Box sx={{height: "100%", width: "100%", "& .odd": {bgcolor: "#212121"}, "& .even": {bgcolor: "#424242"}}}>
                        <DataGrid

                            className={classes.dataGrid }
                            disableExtendRowFullWidth={true}
                            {...data}
                            getRowClassName={(params) =>
                                `super-app-theme--${params.getValue(params.id, 'status')}`}
                            hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                            localeText={
                                {
                                    noRowsLabel: 'Không có dữ liệu',
                                    noResultsOverlayLabel: 'Không tìm thấy kết quả.',
                                    errorOverlayDefaultLabel: 'An error occurred.',
                                    // Density selector toolbar button text
                                    toolbarDensity: 'Độ cao dòng',
                                    toolbarDensityLabel: 'Độ cao dòng',
                                    toolbarDensityCompact: 'Thu gọn',
                                    toolbarDensityStandard: 'Bình thường',
                                    toolbarDensityComfortable: 'Mở rộng',

                                    // Columns selector toolbar button text
                                    toolbarColumns: 'Cột',
                                    toolbarColumnsLabel: 'Chọn cột',

                                    // Filters toolbar button text
                                    toolbarFilters: 'Lọc',
                                    toolbarFiltersLabel: 'Hiển thị Lọc',
                                    toolbarFiltersTooltipHide: 'Ẩn Lọc',
                                    toolbarFiltersTooltipShow: 'Hiển thị Lọc',
                                    toolbarFiltersTooltipActive: (count) =>
                                        count !== 1 ? `${count} active filters` : `${count} active filter`,

                                    // Export selector toolbar button text
                                    toolbarExport: 'Xuất dữ liệu',
                                    toolbarExportLabel: 'Xuất dữ liệu',
                                    toolbarExportCSV: 'Tải xuống định dạng CSV',
                                    toolbarExportPrint: 'In',

                                    // Columns panel text
                                    columnsPanelTextFieldLabel: 'Tìm kiếm cột',
                                    columnsPanelTextFieldPlaceholder: 'Tên cột',
                                    columnsPanelDragIconLabel: 'Reorder column',
                                    columnsPanelShowAllButton: 'Hiển thị tất cả',
                                    columnsPanelHideAllButton: 'Ẩn tất cả',

                                    // Filter panel text
                                    filterPanelAddFilter: 'Thêm lọc',
                                    filterPanelDeleteIconLabel: 'Xóa',
                                    filterPanelOperators: 'Lọc theo:',
                                    filterPanelOperatorAnd: 'Và',
                                    filterPanelOperatorOr: 'Hoặc',
                                    filterPanelColumns: 'Cột',
                                    filterPanelInputLabel: 'Giá trị',
                                    filterPanelInputPlaceholder: 'Giá trị lọc',

                                    // Filter operators text
                                    filterOperatorContains: 'chứa',
                                    filterOperatorEquals: 'bằng',
                                    filterOperatorStartsWith: 'bắt đầu với',
                                    filterOperatorEndsWith: 'kết thúc với',
                                    filterOperatorIs: 'is',
                                    filterOperatorNot: 'is not',
                                    filterOperatorAfter: 'is after',
                                    filterOperatorOnOrAfter: 'is on or after',
                                    filterOperatorBefore: 'is before',
                                    filterOperatorOnOrBefore: 'is on or before',
                                    filterOperatorIsEmpty: 'giá trị rỗng',
                                    filterOperatorIsNotEmpty: 'giá trị không rỗng',

                                    // Filter values text
                                    filterValueAny: 'any',
                                    filterValueTrue: 'true',
                                    filterValueFalse: 'false',

                                    // Column menu text
                                    columnMenuLabel: 'Danh mục',
                                    columnMenuShowColumns: 'Hiển thị cột',
                                    columnMenuFilter: 'Lọc',
                                    columnMenuHideColumn: 'Ẩn',
                                    columnMenuUnsort: 'Chưa sắp xếp',
                                    columnMenuSortAsc: 'Sắp xếp tăng dần',
                                    columnMenuSortDesc: 'Sắp xếp giảm dần',

                                    // Column header text
                                    columnHeaderFiltersTooltipActive: (count) =>
                                        count !== 1 ? `${count} active filters` : `${count} active filter`,
                                    columnHeaderFiltersLabel: 'Hiển thị lọc',
                                    columnHeaderSortIconLabel: 'Sắp xếp',

                                    // Rows selected footer text
                                    footerRowSelected: (count) =>
                                        count !== 1
                                            ? `${count.toLocaleString()} dòng đang chọn`
                                            : `${count.toLocaleString()} dòng đang chọn`,

                                    // Total rows footer text
                                    footerTotalRows: 'Tổng dòng:',

                                    // Total visible rows footer text
                                    footerTotalVisibleRows: (visibleCount, totalCount) =>
                                        `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
                                    // Checkbox selection text
                                    checkboxSelectionHeaderName: 'Checkbox selection',

                                    // Boolean cell text
                                    booleanCellTrueLabel: 'true',
                                    booleanCellFalseLabel: 'false',

                                    // Actions cell more text
                                    actionsCellMore: 'more',

                                    // Tree Data
                                    treeDataGroupingHeaderName: 'Nhóm',
                                    treeDataExpand: 'Hiện danh sách con',
                                    treeDataCollapse: 'Ẩn danh sách con',
                                    TablePagination: 'ok'
                                }
                            }
                            components={{
                                Row: CustomRow,
                                Toolbar: CustomToolbar,
                                title: "Danh mục chức danh"
                            }}
                        />
                    </Box>
                    :
                    <Box sx={{height: "100%", width: "100%", "& .odd": {bgcolor: "#ccc"}, "& .even": {bgcolor: "#eee"}}}>
                        <DataGrid

                            className={classes.dataGrid }
                            disableExtendRowFullWidth={true}
                            {...data}
                            getRowClassName={(params) =>
                                `super-app-theme--${params.getValue(params.id, 'status')}`}
                            hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                            localeText={
                                {
                                    noRowsLabel: 'Không có dữ liệu',
                                    noResultsOverlayLabel: 'Không tìm thấy kết quả.',
                                    errorOverlayDefaultLabel: 'An error occurred.',
                                    // Density selector toolbar button text
                                    toolbarDensity: 'Độ cao dòng',
                                    toolbarDensityLabel: 'Độ cao dòng',
                                    toolbarDensityCompact: 'Thu gọn',
                                    toolbarDensityStandard: 'Bình thường',
                                    toolbarDensityComfortable: 'Mở rộng',

                                    // Columns selector toolbar button text
                                    toolbarColumns: 'Cột',
                                    toolbarColumnsLabel: 'Chọn cột',

                                    // Filters toolbar button text
                                    toolbarFilters: 'Lọc',
                                    toolbarFiltersLabel: 'Hiển thị Lọc',
                                    toolbarFiltersTooltipHide: 'Ẩn Lọc',
                                    toolbarFiltersTooltipShow: 'Hiển thị Lọc',
                                    toolbarFiltersTooltipActive: (count) =>
                                        count !== 1 ? `${count} active filters` : `${count} active filter`,

                                    // Export selector toolbar button text
                                    toolbarExport: 'Xuất dữ liệu',
                                    toolbarExportLabel: 'Xuất dữ liệu',
                                    toolbarExportCSV: 'Tải xuống định dạng CSV',
                                    toolbarExportPrint: 'In',

                                    // Columns panel text
                                    columnsPanelTextFieldLabel: 'Tìm kiếm cột',
                                    columnsPanelTextFieldPlaceholder: 'Tên cột',
                                    columnsPanelDragIconLabel: 'Reorder column',
                                    columnsPanelShowAllButton: 'Hiển thị tất cả',
                                    columnsPanelHideAllButton: 'Ẩn tất cả',

                                    // Filter panel text
                                    filterPanelAddFilter: 'Thêm lọc',
                                    filterPanelDeleteIconLabel: 'Xóa',
                                    filterPanelOperators: 'Lọc theo:',
                                    filterPanelOperatorAnd: 'Và',
                                    filterPanelOperatorOr: 'Hoặc',
                                    filterPanelColumns: 'Cột',
                                    filterPanelInputLabel: 'Giá trị',
                                    filterPanelInputPlaceholder: 'Giá trị lọc',

                                    // Filter operators text
                                    filterOperatorContains: 'chứa',
                                    filterOperatorEquals: 'bằng',
                                    filterOperatorStartsWith: 'bắt đầu với',
                                    filterOperatorEndsWith: 'kết thúc với',
                                    filterOperatorIs: 'is',
                                    filterOperatorNot: 'is not',
                                    filterOperatorAfter: 'is after',
                                    filterOperatorOnOrAfter: 'is on or after',
                                    filterOperatorBefore: 'is before',
                                    filterOperatorOnOrBefore: 'is on or before',
                                    filterOperatorIsEmpty: 'giá trị rỗng',
                                    filterOperatorIsNotEmpty: 'giá trị không rỗng',

                                    // Filter values text
                                    filterValueAny: 'any',
                                    filterValueTrue: 'true',
                                    filterValueFalse: 'false',

                                    // Column menu text
                                    columnMenuLabel: 'Danh mục',
                                    columnMenuShowColumns: 'Hiển thị cột',
                                    columnMenuFilter: 'Lọc',
                                    columnMenuHideColumn: 'Ẩn',
                                    columnMenuUnsort: 'Chưa sắp xếp',
                                    columnMenuSortAsc: 'Sắp xếp tăng dần',
                                    columnMenuSortDesc: 'Sắp xếp giảm dần',

                                    // Column header text
                                    columnHeaderFiltersTooltipActive: (count) =>
                                        count !== 1 ? `${count} active filters` : `${count} active filter`,
                                    columnHeaderFiltersLabel: 'Hiển thị lọc',
                                    columnHeaderSortIconLabel: 'Sắp xếp',

                                    // Rows selected footer text
                                    footerRowSelected: (count) =>
                                        count !== 1
                                            ? `${count.toLocaleString()} dòng đang chọn`
                                            : `${count.toLocaleString()} dòng đang chọn`,

                                    // Total rows footer text
                                    footerTotalRows: 'Tổng dòng:',

                                    // Total visible rows footer text
                                    footerTotalVisibleRows: (visibleCount, totalCount) =>
                                        `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
                                    // Checkbox selection text
                                    checkboxSelectionHeaderName: 'Checkbox selection',

                                    // Boolean cell text
                                    booleanCellTrueLabel: 'true',
                                    booleanCellFalseLabel: 'false',

                                    // Actions cell more text
                                    actionsCellMore: 'more',

                                    // Tree Data
                                    treeDataGroupingHeaderName: 'Nhóm',
                                    treeDataExpand: 'Hiện danh sách con',
                                    treeDataCollapse: 'Ẩn danh sách con',
                                    TablePagination: 'ok'
                                }
                            }
                            components={{
                                Row: CustomRow,
                                Toolbar: CustomToolbar,
                                title: "Danh mục chức danh"
                            }}
                        />
                    </Box>
                }


            </div>
            <Dialog open={open} fullWidth={true} maxWidth={"xl"}>
                <DialogTitle>CHI TIẾT NHÂN VIÊN</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"xl"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <FormEmployeesView data={dataRows}
                                             countries={countries} departments={departments} shiftTimes={shiftTimes}
                                             jobDetails={jobDetails} nationalities={nationalities}
                                             dataEmployees={dataEmployees} laborContracts={laborContracts} maritaltatus={maritalStatus} shiftTypes={shiftTypes}
                                             submitAlert = {()=>submitAlert()}
                                             submitAlertError = {()=>submitAlertError()}
                        />
                        {/*<MuiButton onClick={handleClose} style={{width: "25%"}}>*/}
                        {/*    Đóng*/}
                        {/*</MuiButton>*/}
                        <Button color="primary" variant="contained" onClick={handleClose}>
                            Đóng
                        </Button>
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} fullWidth={true} maxWidth={"xl"}
            >
                <DialogTitle>CẬP NHẬT NHÂN VIÊN</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"xl"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <FormEmployeesUpdate
                            countries={countries} departments={departments} shiftTimes={shiftTimes}
                            jobDetails={jobDetails} nationalities={nationalities}
                            dataEmployees={dataEmployees} laborContracts={laborContracts} maritaltatus={maritalStatus} shiftTypes={shiftTypes}
                            submitAlert = {()=>submitAlert()}
                            submitAlertError = {()=>submitAlertError()}
                            data={dataRows}
                            onClick={() => {
                                setOpenEdit(false)
                            }}/>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} fullWidth={true} maxWidth={"xl"}
            >
                <DialogTitle>XÓA NHÂN VIÊN</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"xl"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <FormEmployeesDelete
                            data={dataRows}
                            countries={countries} departments={departments} shiftTimes={shiftTimes}
                            jobDetails={jobDetails} nationalities={nationalities}
                            dataEmployees={dataEmployees} laborContracts={laborContracts} maritaltatus={maritalStatus} shiftTypes={shiftTypes}
                            submitAlert = {()=>submitAlert()}
                            submitAlertError = {()=>submitAlertError()}
                            onClick={() => {
                                setOpenDelete(false)
                            }}/>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={openAdd} fullWidth={true} maxWidth={"xl"}
            >
                <DialogTitle align={'center'}>THÊM NHÂN VIÊN</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"xl"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <FormEmployeesAdd countries={countries} departments={departments} shiftTimes={shiftTimes}
                                          jobDetails={jobDetails} nationalities={nationalities}
                                          dataEmployees={dataEmployees} laborContracts={laborContracts} maritaltatus={maritalStatus} shiftTypes={shiftTypes}
                                          skills={skills} certificates={certificates} educations={educations}
                                          onClick={() => {
                                              setOpenAdd(false)
                                          }}
                                          submitAlert = {()=>submitAlert()}
                                          submitAlertError = {()=>submitAlertError()}

                        />

                    </div>
                </DialogActions>
            </Dialog>
            {statusAlert ? <AlertMessage key={statusAlert.key} message={statusAlert.msg} /> : null}
            </SnackbarProvider>
        </>
    );
}


