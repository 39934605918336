import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        // paddingBottom: theme.spacing(1),
        width: '100%'
    },
    // card: {
    //     position: 'relative',
    //     clear: 'both'
    // },
    appBar: {
        boxShadow: theme.shadows[0]
    },
    content: {
        width: '100%',
    }
}));

const ItemWorkProfile = ({ index, title, js: WorkProfilesComponent, docs, userInfo, genderItems }) => {
    const classes = useStyles();
    console.log(genderItems)
    return (
        <div className={classes.root}>
            {/*<Card className={classes.card}>*/}
            {/*    <AppBar position="static" color="default" className={classes.appBar}>*/}
            {/*    </AppBar>*/}
            {/*    <CardContent className={classes.content}>*/}
            <WorkProfilesComponent userInfo={userInfo} genderItems = {genderItems}/>
            {/*</CardContent>*/}
            {/*</Card>*/}
        </div>
    );
};

ItemWorkProfile.prototypes = {
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    js: PropTypes.node.isRequired,
    docs: PropTypes.string.isRequired
};

export default ItemWorkProfile;
