import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ColorButtonAdd, ColorButtonCancel, ColorButtonHoverAdd, ColorTextButton} from "../../MainStyleCSS/ColorHeader";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    ButtonAdd:{
        background: ColorButtonAdd,
        color: ColorTextButton,
        '&:hover': {
            background: ColorButtonHoverAdd
        },
    },
    ButtonCancel:{
        backgroundColor: ColorButtonCancel,
        '&:hover': {
            background: ColorButtonHoverAdd,
            color: ColorTextButton
        },
    },

    ButtonAction:{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (
            this.parent
                .filter(v => v !== value)
                .some(v => v[propertyName] === value[propertyName])
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});

const arrPermissions = [{"id":"61b9a478f799e2427113651c", "name":"System Admin"}]
const FormAttandanceDevicesUpdate = (props) => {

    const initvalueSelect = {
        sn: props.data.sn,
        domain: props.data.domain,
        device_type:props.data.device_type
    }
    const [count, setCount] = useState(0)
    const [dataUpdate, setDataUpdate] = useState()
    const classes = useStyles();
    const [values, setValues] = useState(initvalueSelect)
    const types = props.data2
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const validationSchema = yup.object().shape({
       sn: Yup.string().required('Dữ liệu bắt buộc'),
    });

    useEffect(async () => {
        if(count) {
            try {
                // console.log(JSON.stringify(dataUpdate))
                const api = `https://api-dev.hrms.com.vn/api/v1/device`
                const result = await axios.post(api, dataUpdate, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                    if (res.status === 200) {
                        // window.location.reload();
                        props.onClick()
                    }
                })
                // const imageAvatr = await getImageAvatar(result.image);

            } catch (e) {

            }
        }
    }, [count])
    const handleUpdate = (dataSubmit) => {
        const temp = {...dataSubmit}
        temp.device_type = values.device_type
        const dataNew = {"message": temp, "action": "update", "type_message": "map"}
        console.log(JSON.stringify(dataNew))
        setDataUpdate(dataNew)
        setCount(count+1)
    }
    const formik = useFormik({
        initialValues: {
            id:props.data.id,
            sn: props.data.sn,
            domain: props.data.domain
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleUpdate(values);
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4} style={{display: 'flex', flexDirection: 'column'}}>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}> <span style={{color:'red'}}>*</span>Mã thiết bị:</Typography>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            id="sn"
                            name="sn"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.sn}
                            onChange={formik.handleChange}
                            error={formik.touched.sn && Boolean(formik.errors.sn)}
                            helperText={formik.touched.sn && formik.errors.sn}

                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}>Tên miền:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            id="domain"
                            name="domain"
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.domain}
                            onChange={formik.handleChange}
                            error={formik.touched.domain && Boolean(formik.errors.domain)}
                            helperText={formik.touched.domain && formik.errors.domain}
                            multiline
                        >
                        </TextField>
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}>Loại thiết bị:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            id="device_type"
                            name="device_type"
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.device_type}
                            onChange={handleInputChange}
                            error={formik.touched.device_type && Boolean(formik.errors.device_type)}
                            helpertext={formik.touched.device_type && formik.errors.device_type}
                            multiline
                        >
                            {types.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.ButtonAction} >
                <Grid item>
                    <Button className={classes.ButtonAdd} variant="contained" type="submit">
                        Cập nhật
                    </Button>
                </Grid>
                <Grid item>
                    <Button className={classes.ButtonCancel} variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormAttandanceDevicesUpdate;
