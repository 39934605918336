// Pages
import {AttandanceDevices, AttandanceDevicesType, Home, ServicePackage, ServiceType} from './pages';
// Icons
import ExploreIcon from '@material-ui/icons/Explore';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Customer from "./pages/System/Customer";
import Users from "./pages/System/Users";
import PermissionsSetup from "./pages/System/PermissionsSetup";
import RoleSetup from "./pages/System/RoleSetup";

export default {
    items: [
        {
            path: '/dashboard',
            name: 'Tổng quan',
            type: 'link',
            icon: ExploreIcon,
            component: Home
        },
        {
            path: '/systems',
            name: 'Quản trị',
            type: 'submenu',
            icon: PowerSettingsNewIcon,
            children: [
                {
                    path: '/custormers',
                    name: 'Quản lý khách hàng',
                    component: Customer
                },
                {
                    path: '/users',
                    name: 'Quản lý người dùng',
                    component: Users
                },
                // {
                //     path: '/users',
                //     name: 'Quản lý nhóm người dùng',
                //     component: Users
                // },
                {
                    path: '/roles',
                    name: 'Quản lý nhóm phân quyền',
                    component: RoleSetup
                },
                {
                    path: '/permissions',
                    name: 'Quản lý vai trò người dùng',
                    component: PermissionsSetup
                },
                {
                    path: '/devices/devicestype',
                    name: 'Quản lý loại thiết bị',
                    component: AttandanceDevicesType
                },
                {
                    path: '/devices/devices',
                    name: 'Quản lý thiết bị',
                    component: AttandanceDevices
                },

                {
                    path: '/servicetype',
                    name: 'Quản lý loại dịch vụ',
                    component: ServiceType
                },
                {
                    path: '/servicepage',
                    name: 'Quản lý gói dịch vụ',
                    component: ServicePackage
                },

                // {
                //     path: '/myprofile',
                //     name: 'Thông tin cá nhân',
                //     component: Profiles
                // },






                // {
                //     path: '/users',
                //     name: 'Tài khoản',
                //     component: Users
                // },
                // {
                //     path: '/permissions',
                //     name: 'Phân quyền',
                //     component: PermissionsSetup
                // },
            ]

        },
        // {
        //     path: '/settings',
        //     name: 'Thiết Lập danh mục',
        //     type: 'submenu',
        //     icon: SettingsIcon,
        //     children: [
        //         {
        //             path: '/DepartmentTypesSetup',
        //             name:'Loại phòng ban',
        //             component: DepartmentTypesSetup
        //         },
        //         {
        //             path: '/DepartmentDetails',
        //             name: 'Phòng ban',
        //             component: DepartmentDetailsSetup
        //         },
        //         {
        //             path: '/jobDetails',
        //             name: 'Chức danh',
        //             component: JobDetailsSetup
        //         },
        //         {
        //             path: '/shiftTime',
        //             name: 'Ca làm việc',
        //             component: ShiftTimeSetup
        //         },
        //         {
        //             path: '/overtimecategory',
        //             name: 'Làm thêm giờ',
        //             component: RequestOvertimeCategorySetup
        //         },
        //         {
        //             path: '/leavetype',
        //             name: 'Loại nghĩ phép',
        //             component: LeaveTypeSetup
        //         },
        //
        //         {
        //             path: '/larborContracts',
        //             name: 'Loại hợp đồng lao động',
        //             component: LaborContractsSetup
        //         },
        //         {
        //             path: '/educations',
        //             name: 'Trình độ học vấn',
        //             component: EmployeeEducation
        //         },
        //         {
        //             path: '/languages',
        //             name: 'Trình độ ngoại ngữ',
        //             component: EmployeeLanguageSetup
        //         },
        //         {
        //             path: '/certificates',
        //             name: 'Chứng chỉ',
        //             component: EmployeeCertificateSetup
        //         },
        //         {
        //             path: '/skills',
        //             name: 'Kỹ năng',
        //             component: EmployeeSkillSetup
        //         }
        //
        //     ]
        // },
        // {
        //     path: '/employees',
        //     name: 'Hồ sơ nhân sự',
        //     type: 'submenu',
        //     icon: PeopleAltIcon,
        //     // badge: {
        //     //     type: 'primary',
        //     //     value: '5'
        //     // },
        //     children: [
        //         {
        //             path: '/profiles',
        //             name: 'Thông tin cá nhân',
        //             component: Profiles
        //         },
        //         {
        //             path: '/listEmployees',
        //             name: 'Danh sách nhân viên',
        //             component: ListEmployees
        //         },
        //         {
        //             path: '/birthDay',
        //             name: 'Danh sách sinh nhật',
        //             component: Calendar
        //         },
        //     ]
        // },
        // {
        //     path: '/antendance',
        //     name: 'Quản lý ngày công',
        //     type: 'submenu',
        //     icon: AlarmOnIcon,
        //     color: "red",
        //     // badge: {
        //     //     type: 'primary',
        //     //     value: '5'
        //     // },
        //     children: [
        //         {
        //             path: '/attendance',
        //             name: 'Chấm công',
        //             component: TabAttandance,
        //         },
        //         {
        //             path: '/timekeeping',
        //             name: 'Chấm công bù',
        //             component: TabTimeKeeping,
        //         },
        //         {
        //             path: '/lateandearly',
        //             name: 'Đi trễ về sớm',
        //             component: TabLateAndEarly,
        //         },
        //         {
        //             path: '/overtime',
        //             name: 'Làm thêm giờ',
        //             component: TabOverTime,
        //         },
        //         {
        //             path: '/workfromhome',
        //             name: 'Làm việc tại nhà',
        //             component: TabWorkFromHome,
        //         },
        //         {
        //             path: '/travel',
        //             name: 'Công tác',
        //             component: TabTravel,
        //         },
        //         {
        //             path: '/leaverequest',
        //             name: 'Nghĩ phép',
        //             component: LeaveRequest
        //         },
        //     ]
        // },
        // {
        //     path: '/travel',
        //     name: 'Công tác',
        //     type: 'submenu',
        //     icon: FlightIcon,
        //     children: [
        //         {
        //             path: '/travelrequest',
        //             name: 'Danh sách công tác',
        //             component: TravelRequest
        //         },
        //         {
        //             path: '/mytravel',
        //             name: 'Công tác của tôi',
        //             component: MyTravelRequest
        //         },
        //         {
        //             path: '/subotravel',
        //             name: 'Công tác cấp dưới',
        //             component: SubordinateTravelRequest
        //         },
        //     ]
        // },

        // {
        //     path: '/apps',
        //     name: 'Apps',
        //     type: 'submenu',
        //     icon: AppsIcon,
        //     badge: {
        //         type: 'primary',
        //         value: '5'
        //     },
        //     children: [
        //         {
        //             path: '/calendar',
        //             name: 'Calendar',
        //             component: Calendar
        //         },
        //         {
        //             path: '/media',
        //             name: 'Media',
        //             component: Media
        //         },
        //         {
        //             path: '/messages',
        //             name: 'Messages',
        //             component: Messages
        //         },
        //         {
        //             path: '/social',
        //             name: 'Social',
        //             component: Social
        //         },
        //         {
        //             path: '/chat',
        //             name: 'Chat',
        //             component: Chat
        //         }
        //     ]
        // },

        // {
        //     path: '/widgets',
        //     name: 'Widgets',
        //     type: 'link',
        //     icon: PhotoIcon,
        //     component: Widgets
        // },
        // {
        //     path: '/material',
        //     name: 'Material',
        //     type: 'submenu',
        //     icon: EqualizerIcon,
        //     badge: {
        //         type: 'error',
        //         value: '10'
        //     },
        //     children: [
        //         {
        //             path: '/appbar',
        //             name: 'App Bar',
        //             component: AppBar
        //         },
        //         {
        //             path: '/autocomplete',
        //             name: 'Auto Complete',
        //             component: Autocomplete
        //         },
        //         {
        //             path: '/avatars',
        //             name: 'Avatars',
        //             component: Avatars
        //         },
        //         {
        //             path: '/badges',
        //             name: 'Badges',
        //             component: Badges
        //         },
        //         {
        //             path: '/button-navigation',
        //             name: 'Button Navigation',
        //             component: ButtonNavigation
        //         },
        //         {
        //             path: '/buttons',
        //             name: 'Buttons',
        //             component: Buttons
        //         },
        //         {
        //             path: '/cards',
        //             name: 'Cards',
        //             component: Cards
        //         },
        //         {
        //             path: '/chips',
        //             name: 'Chips',
        //             component: Chips
        //         },
        //         {
        //             path: '/dialogs',
        //             name: 'Dialogs',
        //             component: Dialogs
        //         },
        //         {
        //             path: '/dividers',
        //             name: 'Dividers',
        //             component: Dividers
        //         },
        //         {
        //             path: '/drawers',
        //             name: 'Drawers',
        //             component: Drawers
        //         },
        //         {
        //             path: '/expansion-panels',
        //             name: 'Expansion Panels',
        //             component: ExpansionPanels
        //         },
        //         {
        //             path: '/grid-list',
        //             name: 'Grid List',
        //             component: GridList
        //         },
        //         {
        //             path: '/lists',
        //             name: 'Lists',
        //             component: Lists
        //         },
        //         {
        //             path: '/menus',
        //             name: 'Menus',
        //             component: Menus
        //         },
        //         {
        //             path: '/paper',
        //             name: 'Paper',
        //             component: Paper
        //         },
        //         {
        //             path: '/pickers',
        //             name: 'Pickers',
        //             component: Pickers
        //         },
        //         {
        //             path: '/progress',
        //             name: 'Progress',
        //             component: Progress
        //         },
        //         {
        //             path: '/selection-controls',
        //             name: 'Selection Controls',
        //             component: SelectionControls
        //         },
        //         {
        //             path: '/selects',
        //             name: 'Selects',
        //             component: Selects
        //         },
        //         {
        //             path: '/snackbars',
        //             name: 'Snackbars',
        //             component: Snackbars
        //         },
        //         {
        //             path: '/steppers',
        //             name: 'Steppers',
        //             component: Steppers
        //         },
        //         {
        //             path: '/tables',
        //             name: 'Tables',
        //             component: Tables
        //         },
        //         {
        //             path: '/tabs',
        //             name: 'Tabs',
        //             component: Tabs
        //         },
        //         {
        //             path: '/text-fields',
        //             name: 'Text Fields',
        //             component: TextFields
        //         },
        //         {
        //             path: '/tooltips',
        //             name: 'Tooltips',
        //             component: Tooltips
        //         }
        //     ]
        // },
        // {
        //     path: '/editor',
        //     name: 'Editor',
        //     type: 'link',
        //     icon: Looks3Icon,
        //     component: Editor
        // },
        // {
        //     path: '/ecommerce',
        //     name: 'Ecommerce',
        //     type: 'submenu',
        //     icon: Looks4Icon,
        //     badge: {
        //         type: 'secondary',
        //         value: 'N'
        //     },
        //     children: [
        //         {
        //             path: '/products',
        //             name: 'Products',
        //             component: Products
        //         },
        //         {
        //             path: '/detail',
        //             name: 'Detail',
        //             component: Detail
        //         }
        //     ]
        // },
        // {
        //     path: '/taskboard',
        //     name: 'Taskboard',
        //     type: 'link',
        //     icon: ViewColumnIcon,
        //     component: Taskboard
        // },
        // {
        //     path: '/charts',
        //     name: 'Charts',
        //     type: 'link',
        //     icon: ShowChartIcon,
        //     component: Charts
        // },
        // {
        //     path: '/maps',
        //     name: 'Maps',
        //     type: 'submenu',
        //     icon: NavigationIcon,
        //     children: [
        //         {
        //             path: '/google',
        //             name: 'Google',
        //             component: Google
        //         },
        //         {
        //             path: '/leaflet',
        //             name: 'Leaflet',
        //             component: Leaflet
        //         }
        //     ]
        // },
        // {
        //     path: '/pages',
        //     name: 'Pages',
        //     type: 'submenu',
        //     icon: PagesIcon,
        //     children: [
        //         {
        //             path: '/invoice',
        //             name: 'Invoice',
        //             component: Invoice
        //         },
        //         {
        //             path: '/timeline',
        //             name: 'Timeline',
        //             component: TimelinePage
        //         },
        //         {
        //             path: '/blank',
        //             name: 'Blank',
        //             component: Blank
        //         },
        //         {
        //             path: '/pricing',
        //             name: 'Pricing',
        //             component: PricingPage
        //         }
        //     ]
        // },
        // {
        //     // name: 'Authentication',
        //     // type: 'submenu',
        //     // icon: PersonIcon,
        //     // children: [
        //     //     {
        //     //         path: '/signin',
        //     //         name: 'Signin',
        //     //         component: Signin
        //     //     },
        //         // {
        //         //     path: '/signup',
        //         //     name: 'Signup',
        //         //     component: Signup
        //         // },
        //         // {
        //         //     path: '/forgot',
        //         //     name: 'Forgot',
        //         //     component: PasswordReset
        //         // },
        //         // {
        //         //     path: '/lockscreen',
        //         //     name: 'Lockscreen',
        //         //     component: Lockscreen
        //         // }
        //     ]
        // },
        // {
        //     name: 'Error',
        //     type: 'submenu',
        //     icon: FaceIcon,
        //     children: [
        //         {
        //             path: '/404',
        //             name: '404',
        //             component: NotFound
        //         },
        //         {
        //             path: '/500',
        //             name: 'Error',
        //             component: BackendError
        //         }
        //     ]
        // },
        // {
        //     path: 'https://iamnyasha.github.io/react-primer-docs/',
        //     name: 'Documentation',
        //     type: 'external',
        //     icon: LocalLibraryIcon
        // },
        // {
        //     path: 'https://primer.fusepx.com',
        //     name: 'Get Angular Version',
        //     type: 'external',
        //     icon: BookmarkIcon
        // }
    ]
};
