import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import ListAttendance from "./ListAttendance";

const useStyles = makeStyles(theme => ({
        root: {
            marginLeft: "44px",
            marginTop: '5px',
            color: theme.palette.text.primary,
            '& .MuiSvgIcon-root': {
                color: theme.palette.text.primary
            }

        },


    }))
;
const TabAttandance = () => {
    const [tab, setTab] = useState(0)
    const handleTabToggle = (event, tab) => setTab(tab);
    const classes = useStyles();
    const TabContainer = props => (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
    return (
        <div >
            <Tabs value={tab} onChange={handleTabToggle} className={classes.root}>
                <Tab label="DANH SÁCH CHẤM CÔNG"/>
                {/*<Tab label="YÊU CẦU CỦA TÔI"/>*/}
                {/*<Tab label="YÊU CẦU CẤP DƯỚI"/>*/}
            </Tabs>
            {/*<Divider/>*/}

            {
                tab === 0 && (
                    <TabContainer>
                        {/*<Divider/>*/}
                        <ListAttendance/>
                    </TabContainer>
                )
            }
            {/*{*/}
            {/*    tab === 1 && (*/}
            {/*        <TabContainer>*/}
            {/*            /!*<Divider/>*!/*/}
            {/*            <MyOvertimeRequest/>*/}
            {/*        </TabContainer>*/}
            {/*    )*/}
            {/*}*/}
            {/*{*/}
            {/*    tab === 2 && (*/}
            {/*        <TabContainer>*/}
            {/*            /!*<Divider/>*!/*/}
            {/*            <SubordinateOvertimeRequest/>*/}
            {/*        </TabContainer>*/}
            {/*    )*/}
            {/*}*/}
        </div>
    );

}
export default TabAttandance;
