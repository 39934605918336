import AssignmentIcon from "@material-ui/icons/Assignment";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import green from "@material-ui/core/colors/green";
import pink from "@material-ui/core/colors/pink";

const styles = {
  pinkAvatar: {
    color: "#fff",
    backgroundColor: pink[500]
  },
  greenAvatar: {
    color: "#fff",
    backgroundColor: green[500]
  }
};

export default [
  {
    title: "DEV-SOLUTION",
    date: "15/11/2021",
    subtitle: "THAY ĐỔI CHỨC DANH",
    avatar: <Avatar style={{ ...styles.pinkAvatar }}>H</Avatar>,
    body:
      "Thay đổi chức danh theo quyết định số..."
  },
  {
    title: "NETWORK - ENGINEER",
    date: "15/09/2021",
    subtitle: "LUÂN CHUYỂN VỊ TRÍ",
    avatar: (
      <Avatar style={{ ...styles.greenAvatar }}>
        <AssignmentIcon />
      </Avatar>
    ),
    body:
      "Luân chuyển vị trí theo quyết định số...."
  },
  // {
  //   title: "DEV-SOLUTION",
  //   date: "15/02/2021",
  //   subtitle: "THAY ĐỔI CHỨC DANH",
  //   avatar: (
  //     <Avatar
  //       alt=""
  //       src={`${process.env.PUBLIC_URL}/static/images/face1.jpg`}
  //     />
  //   ),
  //   body:
  //     "Thay đổi chức danh theo quyết định số...."
  // },
  // {
  //   title: "DEV",
  //   date: "15/05/2020",
  //   subtitle: "LUÂN CHUYỂN VỊ TRÍ",
  //   avatar: (
  //     <Avatar
  //       alt=""
  //       src={`${process.env.PUBLIC_URL}/static/images/face2.jpg`}
  //     />
  //   ),
  //   body:
  //     "Luân chuyển vị trí theo quyết định số...."
  // },
  {
    title: "IT OPERATIONS",
    date: "15/05/2019",
    subtitle: "NHẬN VIỆC",
    avatar: (
      <Avatar
        alt=""
        src={`${process.env.PUBLIC_URL}/static/images/face3.jpg`}
      />
    ),
    body:
      "GIA NHẬP CÔNG TY SBD SERVICES"
  }
];
