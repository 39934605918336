import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {makeStyles} from "@material-ui/core/styles";
import {EventBusy, Flight, PregnantWoman} from "@material-ui/icons";
import {PersonOff} from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    contentDate: {}
}));
const CustomEvent = (event) => {
    const classes = useStyles();
    // console.log(event)
    return (
        (event.title === "chấm công") ?
            <div style={{height: '35px'}} className={classes.contentDate}>
        <span style={{display: 'flex', justifyContent: 'center', height: '20px'}}>
            <AccessTimeIcon/>
        </span>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {event.title}
                </div>
            </div>
            :
            (event.title === "nghỉ phép") ?
                <div style={{height: '35px', backgroundColor: "orange"}}>
                    <span style={{display: 'flex', justifyContent: 'center', height: '20px'}}>
                        <EventBusy/>
                    </span>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {event.title}
                    </div>
                </div>
                :
                (event.title === "nghỉ thai sản") ?
                    <div style={{height: '35px', backgroundColor: "red"}}>
                        <span style={{display: 'flex', justifyContent: 'center', height: '20px'}}>
                            <PregnantWoman/>
                        </span>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            {event.title}
                        </div>
                    </div>
                    :
                    (event.title === "công tác") ?
                        <div style={{height: '35px', backgroundColor: "#008744"}}>
                        <span style={{display: 'flex', justifyContent: 'center', height: '20px'}}>
                            <Flight/>
                        </span>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                {event.title}
                            </div>
                        </div>
                        :
                        (event.title === "nghỉ việc riêng") ?
                            <div style={{height: '35px', backgroundColor: "#46291b"}}>
                        <span style={{display: 'flex', justifyContent: 'center', height: '20px'}}>
                            <PersonOff/>
                        </span>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {event.title}
                                </div>
                            </div>
                            :
                            <div style={{height: '35px'}} className={classes.contentDate}>
                        <span style={{display: 'flex', justifyContent: 'center', height: '20px'}}>
                            Icon tạm
                        </span>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {event.title}
                                </div>
                            </div>


    )
}
export default CustomEvent;
