import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import TravelRequest from "./TravelRequest";
import MyTravelRequest from "./MyTravelRequest";
import SubordinateTravelRequest from "./SubordinateTravelRequest";

const useStyles = makeStyles(theme => ({
        root: {
            marginLeft: "44px",
            marginTop: '5px',
            color: theme.palette.text.primary,
            '& .MuiSvgIcon-root': {
                color: theme.palette.text.primary
            }

        },


    }))
;
const TabTravel = () => {
    const [tab, setTab] = useState(0)
    const handleTabToggle = (event, tab) => setTab(tab);
    const classes = useStyles();
    const TabContainer = props => (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
    return (
        <div >
            <Tabs value={tab} onChange={handleTabToggle} className={classes.root}>
                <Tab label="DANH SÁCH YÊU CẦU"/>
                <Tab label="YÊU CẦU CỦA TÔI"/>
                <Tab label="YÊU CẦU CẤP DƯỚI"/>
            </Tabs>
            {/*<Divider/>*/}

            {
                tab === 0 && (
                    <TabContainer>
                        {/*<Divider/>*/}
                        <TravelRequest/>
                    </TabContainer>
                )
            }
            {
                tab === 1 && (
                    <TabContainer>
                        {/*<Divider/>*/}
                        <MyTravelRequest/>
                    </TabContainer>
                )
            }
            {
                tab === 2 && (
                    <TabContainer>
                        {/*<Divider/>*/}
                        <SubordinateTravelRequest/>
                    </TabContainer>
                )
            }
        </div>
    );

}
export default TabTravel;
