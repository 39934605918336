import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import viLocale from "date-fns/locale/vi";
import moment from 'moment';
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
const format1 = "YYYY-MM-DD HH:mm:ss"
const initialValues = {
    start_time: moment(new Date()).format(format1),
    end_time: moment(new Date()).format(format1),
    category: '',
    notes: '',
}
const MyOvertimeDelete = (props) => {
    const [dataDelete, setDataDelete] = useState()

    const categories = props.categories;
    const [values, setValues] = useState(initialValues)
    const classes = useStyles();
    // console.log (initialFValues)
    // const isSameOrBefore = (start, end) => {
    //     return moment(start, 'HH:mm:ss').isSameOrBefore(moment(end, 'HH:mm:ss'));
    // }
    const validationSchema = yup.object().shape({
        // description: yup
        //     .string('Nhập vào mô tả'),
        // name: yup
        //     .string('Nhập vào loại phòng ban')
        //     .required('Dữ liệu bắt buộc nhập'),

    });

    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/overtimerequest`
            const result = await axios.post(api, dataDelete, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataDelete])
    const handleDelete = (dataSubmit) => {
        // console.log(JSON.stringify(values))
        const dataNew = {"message": {"id":props.data.id}, "action": "delete", "type_message": "map"}
        console.log(JSON.stringify(dataNew))
        setDataDelete(dataNew)
    }
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleDelete(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={6} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="category"
                            name="category"
                            label="Loại làm thêm giờ"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.category.name}
                            disabled
                            multiline
                        >
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            style={{minWidth: "250px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="notes"
                            name="notes"
                            label="Mô Tả"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.notes}
                            multiline
                            disabled
                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid item>
                        <TextField
                            style={{minWidth: "250px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="start_time"
                            name="start_time"
                            label="Thời gian bắt đầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={moment(props.data.start_time).format('DD-MM-yyyy HH:mm:ss')}
                            multiline
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <Grid item>
                            <TextField
                                style={{minWidth: "250px"}}
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                id="end_time"
                                name="end_time"
                                label="Thời gian kết thúc"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                value={moment(props.data.end_time).format('DD-MM-yyyy HH:mm:ss')}
                                multiline
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Xóa
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default MyOvertimeDelete;
