import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ColorButtonAdd, ColorButtonCancel, ColorButtonHoverAdd, ColorTextButton} from "../../MainStyleCSS/ColorHeader";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    labelInput:{
        maxWidth: '150px', display:"flex", justifyContent: 'flex-end', marginRight:'10px'
    },
    ButtonAdd:{
        background: ColorButtonAdd,
        color: ColorTextButton,
        '&:hover': {
            background: ColorButtonHoverAdd
        },
    },
    ButtonCancel:{
        backgroundColor: ColorButtonCancel,
        '&:hover': {
            background: ColorButtonHoverAdd,
            color: ColorTextButton
        },
    },

    ButtonAction:{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const FormServiceTypeView = (props) => {
    const classes = useStyles();
    const data = props.data;
    return (
        <form>
            <Grid container spacing={4} style={{display: 'flex', flexDirection: 'column'}}>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  className={classes.labelInput}>Tên dịch vụ:</Typography>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={4} lg={4}  style={{minWidth: "300px", display: "flex", justifyContent: "center"}}>
                        <TextField
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            id="sn"
                            name="sn"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value= {data.name}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  className={classes.labelInput}>Mô tả:</Typography>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={4} lg={4}  style={{minWidth: "300px", display: "flex", justifyContent: "center"}}>
                        <TextField
                            id="domain"
                            name="domain"
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={data.description}
                            disabled
                            multiline
                        >
                        </TextField>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.ButtonAction} >
                <Grid item>
                    <Button className={classes.ButtonCancel} variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormServiceTypeView;
