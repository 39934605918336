import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ColorButtonAdd, ColorButtonCancel, ColorButtonHoverAdd, ColorTextButton, ColorButtonDelete} from "../../MainStyleCSS/ColorHeader";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    ButtonDelete:{
        background: ColorButtonDelete,
        color: ColorTextButton,
        '&:hover': {
            background: ColorButtonHoverAdd
        },
    },
    ButtonCancel:{
        backgroundColor: ColorButtonCancel,
        '&:hover': {
            background: ColorButtonHoverAdd,
            color: ColorTextButton
        },
    },

    ButtonAction:{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (
            this.parent
                .filter(v => v !== value)
                .some(v => v[propertyName] === value[propertyName])
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});

const FormUsersDelete = (props) => {
    const [dataDelete, setDataDelete] = useState()
    const classes = useStyles();
    const employees = props.employees
    const data = props.data
    // const [values, setValues] = useState(initialValues)
    // const handleInputChange = e => {
    //     const {name, value} = e.target
    //     setValues({
    //         ...values,
    //         [name]: value
    //     })
    // }


    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/user`
            const result = await axios.post(api, dataDelete, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataDelete])
    const handleDelete = (dataSubmit) => {
        const dataNew = {"message": {"id": data.id}, "action": "delete", "type_message": "map"}
        console.log(JSON.stringify(dataNew))
        setDataDelete(dataNew)
    }
    const validationSchema = yup.object().shape({
        // description: yup
        //     .string('Nhập vào mô tả'),


    });
    const formik = useFormik({
        initialValues: {
            id : props.id
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleDelete(values);
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4} style={{display: 'flex', flexDirection: 'column'}}>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}>Tên tài khoản:</Typography>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            id="user_name"
                            name="user_name"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value= {data.user_name}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}> Nhân viên:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            id="employee"
                            name="employee"
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={`${employees.filter(x => x.id === data.e_id).map(x => `${x.last_name} ${x.first_name}`)}`}
                            disabled
                            multiline
                        >
                        </TextField>
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}> Phân quyền:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            id="role"
                            name="role"
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={data.r_name}
                            disabled
                            multiline
                        >
                        </TextField>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.ButtonAction} >
                <Grid item>
                    <Button className={classes.ButtonDelete} variant="contained" type="submit">
                        Xóa
                    </Button>
                </Grid>
                <Grid item>
                    <Button className={classes.ButtonCancel} variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormUsersDelete;
