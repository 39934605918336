import {useFormik} from 'formik';
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import * as Yup from 'yup';
import {Formik, Field, Form} from 'formik';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {useForm, Controller} from "react-hook-form";
import 'moment-timezone';
import {ColorButtonAdd, ColorButtonCancel, ColorButtonHoverAdd, ColorTextButton} from "../MainStyleCSS/ColorHeader";
import moment from "moment";
import {FormControl, FormHelperText} from "@material-ui/core";
// const moment = require('moment-timezone');
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {yupResolver} from "@hookform/resolvers/yup";
import Divider from "@material-ui/core/Divider";


const items = [
    {
        id: 0,
        name: "Module X"
    },
    {
        id: 1,
        name: "Module Y"
    },
    {
        id: 2,
        name: "Module Z"
    },
    {
        id: 3,
        name: "Module G"
    },
    {
        id: 4,
        name: "Module H"
    }
];
const useStyles = makeStyles(theme => ({
    ButtonAdd: {
        background: ColorButtonAdd,
        color: ColorTextButton,
        '&:hover': {
            background: ColorButtonHoverAdd
        },
    },
    ButtonCancel: {
        backgroundColor: ColorButtonCancel,
        '&:hover': {
            background: ColorButtonHoverAdd,
            color: ColorTextButton
        },
    },

    ButtonAction: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
export const Schema = Yup.object().shape({
    item_ids: Yup.array()
        .transform(ids => {
            return ids.filter(id => {
                return id === 0 || id;
            });
        })
        .min(1, "Select at least one")
});


const AddRole = (props) => {
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    const [dataAdd, setDataAdd] = useState()
    const [count, setCount] = useState(0)
    const [dataModel,setDataModel] = useState([])
    const classes = useStyles();
    const validationSchema = yup.object().shape({});
    const defaultIds = ["620a1e382bf855244aa77075"];

    const {control, handleSubmit, getValues, errors} = useForm({
        resolver: yupResolver(Schema),
        defaultValues: {item_ids: defaultIds}
    });

    const handleCheck = checkedId => {
        const {item_ids: ids} = getValues();
        const newIds = ids?.includes(checkedId)
            ? ids?.filter(id => id !== checkedId)
            : [...(ids ?? []), checkedId];
        return newIds;
    };


    useEffect(async () => {

            try {
                const api = `https://api-dev.hrms.com.vn/api/v1/model`
                const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                    if (res.status === 200) {
                        let newArray = []
                        if (res.data.message === null) {
                            res.data.message = {};
                            setDataModel([])
                        } else {
                            setDataModel(res.data.message)
                            console.log(res.data.message)
                        }
                    }
                })

            } catch (e) {

            }

    }, [])

    const handleAdd = (dataSubmit) => {
        // dataSubmit.fouding_date = moment(dataSubmit.fouding_date).format('YYYY-MM-DD')
        // const dataNew = {"message": {...dataSubmit}, "action": "create", "type_message": "map"}
        setDataAdd(dataSubmit)
        setCount(count + 1)

    }


    // const formik = useFormik({
    //     initialValues: {
    //         toggle: false,
    //         checked: [],
    //     },
    //     validationSchema: validationSchema,
    //     onSubmit: async (values) => {
    //         await sleep(500);
    //         alert(JSON.stringify(values, null, 2));
    //         handleAdd(values);
    //     },
    // });
    return (
        <form onSubmit={handleSubmit(data => handleAdd(data.item_ids))} style={{width: "100%"}}>
            <FormControl error={!!errors ? errors.item_ids?.message : false}>
                <FormHelperText>{errors ? errors.item_ids?.message : ""}</FormHelperText>
                <Controller
                    name="item_ids"
                    render={({
                                 field: {onChange, onBlur, value, name, ref},
                                 fieldState: {invalid, isTouched, isDirty, error},
                                 formState,
                             }) =>
                        <Grid container spacing={4} style={{display: 'flex', flexDirection: 'column'}}>
                            {dataModel.map((item, index) => (
                                <Grid container spacing={2} style={{display: 'flex', flexDirection: 'row', width: "100%"}} >
                                    <Grid item md={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={() => onChange(handleCheck(item.id))}
                                                    defaultChecked={defaultIds.includes(item.id)}
                                                />
                                            }
                                            key={item.id}
                                            label={item.name}
                                            style={{ width: "100%"}}

                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    }
                    control={control}
                />
            </FormControl>
            <Grid container spacing={4} className={classes.ButtonAction}>
                <Grid item>
                    <Button className={classes.ButtonAdd} variant="contained" type="submit">
                        Thêm mới
                    </Button>
                </Grid>
                <Grid item>
                    <Button className={classes.ButtonCancel} variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
            <Divider style={{marginTop: "20px"}}/>
            <pre>ID Phân quyền đã chọn: {JSON.stringify(dataAdd, null, 2)}</pre>
            {/*<button>Thêm</button>*/}
        </form>

    )

}
export default AddRole;
