import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {MenuItem} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import UploadFile from "../UploadFile/UploadFile"
import ListItemText from "@material-ui/core/ListItemText";
import AvatarEmployee from "../../pages/Employees/AvatarEditor";
import Avatar from "@material-ui/core/Avatar";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    text: {

        color: 'inherit', fontSize: "0.95rem",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        // fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: "0.03333em",
        fontWeight: 'bold'
    },
    avatar: {
        display: "inline-flex",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            width: 128,
            height: 128
        },
        [theme.breakpoints.down("xs")]: {
            width: 64,
            height: 64
        },
        marginBottom: theme.spacing(1),
        alignItems: "center"
    },
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    },
    avatarDrop:{
        boxShadow: "3px 3px 2px 1px #ccc",
        borderTopLeftRadius: "1px solid black"
    }
}));
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (
            this.parent
                .filter(v => v !== value)
                .some(v => v[propertyName] === value[propertyName])
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});
const maritalStatus = [
    {"id": "married", "name": "Đã kết hôn"},
    {"id": "single", "name": "Độc thân"},
    {"id": "divorced", "name": "Ly hôn"},
    {"id": "widowed", "name": "Góa"},
    {"id": "other", "name": "Khác"}
]
const immigrationStatus = [{"id": "permanent", "name": "Thường trú"},{"id": "temporary", "name": "Tạm trú"}]

const formatYmd = date => date.toISOString().slice(0, 10);
const initialValues = {
    country: "61bc63d61a7044f8754e00c7",
    address1: "",
    address2: "",
    // approver1: "",
    // approver2: "",
    // approver3: "",
    birth_day: (new Date()).toISOString().split('T')[0],
    city: "",
    confirmation_date: (new Date()).toISOString().split('T')[0],
    department: "",
    driving_license: "",
    driving_license_exp_date: "",
    employee_id: "",
    employment_status: "",
    ethnicity: "",
    first_name: "",
    gender: "male",
    hic_num: "",
    home_phone: "",
    image_profile: "",
    immigration_status: "",
    job_title: "",
    joined_date: (new Date()).toISOString().split('T')[0],
    last_name: "",
    marital_status: "single",
    mobile_phone: "",
    nationality: "61bc5314dc7d820a6d43cdc0",
    nic_num: "",
    notes: "",
    other_id: "",
    pay_grade: "",
    postal_code: "",
    private_email: "",
    province: "",
    shift_time: "",
    shift_type: "",
    ssn_num: "",
    // supervisor: "",
    termination_date: "",
    tic_num: "",
    work_email: "",
    work_phone: ""
}
function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}
const FormEmployeesAdd = (props) => {
    // console.log(props.data);
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const [dataAdd, setDataAdd] = useState()
    const classes = useStyles();
    const countries = props.countries
    const departments = props.departments
    const jobDetails = props.jobDetails
    const shiftTimes = props.shiftTimes
    const nationalities = props.nationalities
    const supervisors = props.dataEmployees
    const laborContracts = props.laborContracts
    const educations = props.educations
    const skills = props.skills
    const certificates = props.certificates
    const shiftTypes = props.shiftTypes
    const [values, setValues] = useState(initialValues)
    const [avatar, setAvatar] = useState()
    const [urlAvatar, setUrlAvatar] = useState('')
    const [addEmployee,setAddEmployee] = useState(0)
    const [file1, setFile1] = useState([])
    const addFile = (f) => {

    }
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    // const handleCountryChange = (e) => {
    //     setCountry(e.target.value);
    // };
    // const handleNationalityChange = (e) => {
    //     setNationality(e.target.value);
    // };
const validationSchema = yup.object({
        // employee_id: yup
        //     .string('ID không hợp lệ')
        //     .required('Dữ liệu bắt buộc nhập'),
        // first_name: yup
        //     .string('Nhập vào loại phòng ban')
        //     .required('Dữ liệu bắt buộc nhập'),
        // last_name: yup
        //     .string('Nhập vào loại phòng ban')
        //     .required('Dữ liệu bắt buộc nhập'),
        // nationality: yup
        //     .string('Chọn Quốc tịch')
        //     .required('Dữ liệu bắt buộc nhập'),
        // department: yup
        //     .string()
        //     .required('Dữ liệu bắt buộc nhập'),
        // job_title: yup
        //     .string()
        //     .required('Dữ liệu bắt buộc nhập'),
        // birth_day: yup
        //     .date()
        //     .typeError('Sai định dạng ngày tháng năm')
        //     .test(
        //         "DOB",
        //         "Tuổi nhân viên phải lớn hơn 18 tuổi và nhỏ hơn 120 tuổi, vui lòng kiểm tra lại ngày sinh",
        //         birth_day => {
        //             return (moment().diff(moment(birth_day), 'years') >= 18 && moment().diff(moment(birth_day), 'years') <= 120);
        //         })
        //     .required('Dữ liệu bắt buộc nhập'),
        // gender: yup
        //     .string('Chọn Giới tính')
        //     .required('Dữ liệu bắt buộc nhập'),
        // marital_status: yup
        //     .string('Tình trạng hôn nhân')
        //     .required('Dữ liệu bắt buộc nhập'),
        // shift_type: yup
        //     .string('Chọn loại HĐLĐ')
        //     .required('Dữ liệu bắt buộc nhập'),
        // private_email: yup
        //     .string().email('Email không đúng định dạng'),
        // work_email: yup
        //     .string().email('Email không đúng định dạng'),
        // joined_date: yup
        //     .date()
        //     .typeError('Sai định dạng ngày tháng năm')
        //     .required('Dữ liệu bắt buộc nhập'),
        // country: yup
        //     .string('Chọn Quốc gia')
        //     .required('Dữ liệu bắt buộc chọn'),
        // confirmation_date: yup
        //     .date()
        //     .typeError('Sai định dạng ngày tháng năm')
        //     .required('Dữ liệu bắt buộc nhập'),

    });
    useEffect(async() => {
            try {
                // console.log(JSON.stringify(dataUpdate))
                        console.log(localStorage.getItem("authToken"))
                        const fd = await new FormData(document.forms[0]);
                        if(avatar){
                            // const dataURL = avatarPreview.img.toDataURL('image/jpeg', 0.5);
                            const blob =  await  dataURItoBlob(avatar.img);
                            await  fd.append("file", blob, 'image_profile.png');
                        }
                        const api = `https://api-dev.hrms.com.vn/api/v1/upload/image_upload`
                        const result = await axios.post(api, fd, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`,'Content-Type': 'multipart/form-data'}}).then(res => {
                            if (res.status === 201) {
                                if (res.data.message) {
                                    console.log(res.data.message)
                                    setUrlAvatar(res.data.message)
                                    setAddEmployee(addEmployee+1)
                                }
                            }
                            setAddEmployee(addEmployee+1)
                        })
            } catch (e) {

            }
    },[dataAdd])
    useEffect(async () => {
        if (addEmployee){
            // console.log(dataAdd)
        try {
                if(urlAvatar){
                    dataAdd.message.image_profile = urlAvatar
                }
            const api = `https://api-dev.hrms.com.vn/api/v1/employee`
            const result = await axios.post(api, dataAdd, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}` }}).then(res => {
                if (res.status === 201) {
                    // window.location.reload();
                    // const temp = handleClickVariant('success');
                    props.onClick()
                    props.submitAlert()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
        }
    }, [addEmployee])
    const handleAdd = (dataSubmit) => {
        const temp = {...dataSubmit}
        temp.country = values.country;
        temp.nationality = values.nationality;
        temp.department = values.department;
        temp.job_title = values.job_title;
        temp.supervisor = values.supervisor;
        temp.approver1 = values.approver1;
        temp.approver2 = values.approver2;
        temp.approver3 = values.approver3;
        temp.shift_type = values.shift_type;
        temp.shift_time = values.shift_time;
        temp.image_profile = urlAvatar;
        temp.employement_status = values.employment_status;
        temp.education = values.education;
        temp.status = "Active";
        const dataNew = {"message": temp, "action": "create", "type_message": "map"}
        console.log(dataNew)
        setDataAdd(dataNew)
    }
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleAdd(values);
        },
    });


    return (

        <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>

            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item align="center">
                        <AvatarEmployee  dataAdd={dataAdd} props={props} setAvatar={setAvatar}/>
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item align="center">
                    {!!avatar && <div style={{display:'flex', flexDirection:'rows', justifyContent: 'center', alignItems: 'flex-end', gap:'20px', marginTop:'20px'}}>
                        <br/>
                        <div style={{display:'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}} >
                        <img alt="Preview" src={avatar.img} className={classes.avatarDrop}/>
                        <br/>
                        <div className={classes.text}>Ảnh đầy đủ</div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}} >
                        <Avatar alt ={`preview Avatar`} src={`${avatar.img}`} className={classNames(classes.avatar)}/>
                        <br/>
                        <div className={classes.text}>Ảnh đại diện</div>
                         </div>
                        </div>
                        }
                </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>

                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="employee_id"
                            name="employee_id"
                            label="Mã nhân viên"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.employee_id}
                            onChange={formik.handleChange}
                            error={formik.touched.employee_id && Boolean(formik.errors.employee_id)}
                            helperText={formik.touched.employee_id && formik.errors.employee_id}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="first_name"
                            name="first_name"
                            label="Tên"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                            helperText={formik.touched.first_name && formik.errors.first_name}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="last_name"
                            name="last_name"
                            label="Họ và tên đệm"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                            helperText={formik.touched.last_name && formik.errors.last_name}
                            multiline
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>Giới tính</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="gender"
                                name="gender"
                                aria-label="Giới tính"
                                value={formik.values.gender}
                                onChange={formik.handleChange}
                                error={formik.touched.gender && Boolean(formik.errors.gender)}
                                helpertext={formik.touched.gender && formik.errors.gender}
                            >
                                <FormControlLabel value="male" control={<Radio/>} label="Nam"/>
                                <FormControlLabel value="female" control={<Radio/>} label="Nữ"/>
                                <FormControlLabel value="other" control={<Radio/>} label="Khác"/>
                            </RadioGroup>
                        </FormControl>
                        {/*<TextField*/}
                        {/*    InputProps={{*/}
                        {/*        className: classes.multilineColor*/}
                        {/*    }}*/}
                        {/*    id="gender"*/}
                        {/*    name="gender"*/}
                        {/*    label="Giới tính"*/}
                        {/*    className={classes.textField}*/}
                        {/*    margin="normal"*/}
                        {/*    variant="outlined"*/}
                        {/*    value={formik.values.gender}*/}
                        {/*    onChange={formik.handleChange}*/}
                        {/*    error={formik.touched.gender && Boolean(formik.errors.gender)}*/}
                        {/*    helperText={formik.touched.gender && formik.errors.gender}*/}
                        {/*    multiline*/}
                        {/*/>*/}
                    </Grid>
                    <Grid item>
                        <TextField
                            id="nationality"
                            name="nationality"
                            label="Quốc tịch"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.nationality}
                            onChange={handleInputChange}
                            error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                            helpertext={formik.touched.nationality && formik.errors.nationality}
                            multiline
                        >
                            {nationalities.map((x) => (
                                <MenuItem key = {x.id} value={x.id}>
                                    {x.name !== `Vietnamese` ? `${x.name}` : `Việt Nam`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item style={{marginTop: "16px", marginBottom: "8px"}}>
                        {/*<DatePicker*/}

                        {/*    className={classes.textField}*/}
                        {/*    name="birth_day"*/}
                        {/*    id="birth_day"*/}
                        {/*    label="Ngày sinh"*/}
                        {/*    inputFormat="dd/MM/yyyy"*/}
                        {/*    value={values.birth_day}*/}
                        {/*    onChange={handleInputChange}*/}
                        {/*    error={formik.touched.birth_day && Boolean(formik.errors.birth_day)}*/}
                        {/*    helperText={formik.touched.birth_day && formik.errors.birth_day}*/}
                        {/*    // renderInput={(params) => <TextField {...params} />}*/}
                        {/*/>*/}
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardDatePicker style={{maxWidth: "190px"}}
                                                InputProps={{
                                                    className: classes.multilineColor

                                                }}
                                                // InputLabelProps={{
                                                //     shrink: formik.values.birth_day ? true : undefined,
                                                // }}
                                                className={classes.textField}
                                                label="Ngày sinh"
                                                name="birth_day"
                                                id="birth_day"
                                                margin="normal"
                                                inputVariant="outlined"
                                                openTo="date"
                                                format="dd-MM-yyyy"
                                                placeholder="ngày-tháng-năm"
                                                mask="__/__/____"
                                                okLabel="Chọn"
                                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                                value={formik.values.birth_day}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                                onChange={date => formik.setFieldValue('birth_day',moment(date).format("YYYY-MM-DD"))}
                                                error={formik.touched.birth_day && Boolean(formik.errors.birth_day)}
                                                helperText={formik.touched.birth_day && formik.errors.birth_day}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "190px"}}
                            id="marital_status"
                            name="marital_status"
                            label="Tình trạng hôn nhân"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.marital_status}
                            onChange={handleInputChange}
                            error={formik.touched.marital_status && Boolean(formik.errors.marital_status)}
                            helperText={formik.touched.marital_status && formik.errors.marital_status}
                            multiline
                        >
                            {/*<MenuItem key="" value="">*/}
                            {/*    Vui lòng chọn phòng ban*/}
                            {/*</MenuItem>*/}
                            {maritalStatus.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "190px"}}
                            id="shift_type"
                            name="shift_type"
                            label="Loại ca làm việc"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.shift_type}
                            onChange={handleInputChange}
                            // error={values.shift_type==="" && Boolean(formik.errors.shift_type)}
                            helperText={values.shift_type ==="" ? "Dữ liệu bắt buộc" : ""}
                            multiline
                        >
                            {/*<MenuItem key="" value="">*/}
                            {/*    Vui lòng chọn phòng ban*/}
                            {/*</MenuItem>*/}
                            {shiftTypes.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "190px"}}
                            id="employment_status"
                            name="employment_status"
                            label="Loại hợp đồng lao động"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.employment_status}
                            onChange={handleInputChange}
                            error={values.employment_status==="" && Boolean(formik.errors.employment_status)}
                            helperText={values.employment_status ==="" ? "Dữ liệu bắt buộc" : ""}
                            multiline
                        >
                            {/*<MenuItem key="" value="">*/}
                            {/*    Vui lòng chọn phòng ban*/}
                            {/*</MenuItem>*/}
                            {laborContracts.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        {/*<TextField*/}
                        {/*    InputProps={{*/}
                        {/*        className: classes.multilineColor*/}
                        {/*    }}*/}
                        {/*    id="department"*/}
                        {/*    name="department"*/}
                        {/*    label="Phòng ban"*/}
                        {/*    className={classes.textField}*/}
                        {/*    margin="normal"*/}
                        {/*    variant="outlined"*/}
                        {/*    value={formik.values.department}*/}
                        {/*    onChange={formik.handleChange}*/}
                        {/*    error={formik.touched.department && Boolean(formik.errors.department)}*/}
                        {/*    helperText={formik.touched.department && formik.errors.department}*/}
                        {/*    multiline*/}
                        {/*/>*/}
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "190px"}}
                            id="department"
                            name="department"
                            label="Phòng ban"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.department}
                            onChange={handleInputChange}
                            error={values.department==="" }
                            helperText={values.department ==="" ? "Dữ liệu bắt buộc" : ""}
                            multiline
                        >
                            {/*<MenuItem key="" value="">*/}
                            {/*    Vui lòng chọn phòng ban*/}
                            {/*</MenuItem>*/}
                            {departments.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "190px"}}
                            id="job_title"
                            name="job_title"
                            label="Chức danh công việc"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.job_title}
                            onChange={handleInputChange}
                            error={values.job_title === "" && Boolean(formik.errors.job_title)}
                            helperText={(values.job_title ==="") ? "Dữ liệu bắt buộc" : ""}
                            multiline
                        >
                            {/*<MenuItem key="" value="">*/}
                            {/*    Vui lòng chọn phòng ban*/}
                            {/*</MenuItem>*/}
                            {jobDetails.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardDatePicker
                                style={{maxWidth: "190px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                InputLabelProps={{
                                    shrink: formik.values.joined_date ? true : undefined,
                                }}
                                className={classes.textField}
                                label="Ngày nhận việc"
                                name="joined_date"
                                id="joined_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy"
                                placeholder="ngày-tháng-năm"
                                mask="__/__/____"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.joined_date}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={date => formik.setFieldValue('joined_date', moment(date).format("YYYY-MM-DD"))}
                                error={formik.touched.joined_date && Boolean(formik.errors.joined_date)}
                                helperText={formik.touched.joined_date && formik.errors.joined_date}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="country"
                            name="country"
                            label="Quốc gia"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.country}
                            onChange={handleInputChange}
                            error={formik.touched.country && Boolean(formik.errors.country)}
                            helperText={formik.touched.country && formik.errors.country}
                            multiline
                        >
                            {countries.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name !== `Viet Nam` ? `${x.name}` : `Việt Nam`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                            <TextField style={{minWidth: "190px"}}
                                       id="supervisor"
                                       name="supervisor"
                                       label="Cấp quản lý"
                                       select
                                       className={classes.textField}
                                       margin="normal"
                                       variant="outlined"
                                       value={values.supervisor}
                                       onChange={handleInputChange}
                                       error={formik.touched.supervisor && Boolean(formik.errors.supervisor)}
                                       helperText={formik.touched.supervisor && formik.errors.supervisor}
                                       multiline
                            >
                                {supervisors.map((x) => (
                                    <MenuItem key={x.id} value={x.id}>
                                        {`${x.last_name} ${x.first_name} - ${x.employee_id}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                    </Grid>
                    <Grid item>
                        <TextField style={{minWidth: "190px"}}
                                   id="approver1"
                                   name="approver1"
                                   label="Cấp phê duyệt 1"
                                   select
                                   className={classes.textField}
                                   margin="normal"
                                   variant="outlined"
                                   value={values.approver1}
                                   onChange={handleInputChange}
                                   error={formik.touched.approver1 && Boolean(formik.errors.approver1)}
                                   helperText={formik.touched.approver1 && formik.errors.approver1}
                                   multiline
                        >
                            {supervisors.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {`${x.last_name} ${x.first_name} - ${x.employee_id}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField style={{minWidth: "190px"}}
                                   id="approver2"
                                   name="approver2"
                                   label="Cấp phê duyệt 2"
                                   select
                                   className={classes.textField}
                                   margin="normal"
                                   variant="outlined"
                                   value={values.approver2}
                                   onChange={handleInputChange}
                                   error={formik.touched.approver2 && Boolean(formik.errors.approver2)}
                                   helperText={formik.touched.approver2 && formik.errors.approver2}
                                   multiline
                        >
                            {supervisors.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {`${x.last_name} ${x.first_name} - ${x.employee_id}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField style={{minWidth: "190px"}}
                                   id="approver3"
                                   name="approver3"
                                   label="Cấp phê duyệt 3"
                                   select
                                   className={classes.textField}
                                   margin="normal"
                                   variant="outlined"
                                   value={values.approver3}
                                   onChange={handleInputChange}
                                   error={formik.touched.approver3 && Boolean(formik.errors.approver3)}
                                   helperText={formik.touched.approver3 && formik.errors.approver3}
                                   multiline
                        >
                            {supervisors.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {`${x.last_name} ${x.first_name} - ${x.employee_id}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="city"
                            name="city"
                            label="Thành phố"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="address1"
                            name="address1"
                            label="Địa chỉ 1"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            error={formik.touched.address1 && Boolean(formik.errors.address1)}
                            helperText={formik.touched.address1 && formik.errors.address1}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="address2"
                            name="address2"
                            label="Địa chỉ 2"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            error={formik.touched.address2 && Boolean(formik.errors.address2)}
                            helperText={formik.touched.address2 && formik.errors.address2}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardDatePicker
                                style={{maxWidth: "190px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                InputLabelProps={{
                                    shrink: formik.values.confirmation_date ? true : undefined,
                                }}
                                className={classes.textField}
                                label="Ngày ký hợp đồng"
                                name="confirmation_date"
                                id="confirmation_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy"
                                placeholder="ngày-tháng-năm"
                                mask="__/__/____"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.confirmation_date}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={date => formik.setFieldValue('confirmation_date', moment(date).format("YYYY-MM-DD"))}
                                error={formik.touched.confirmation_date && Boolean(formik.errors.confirmation_date)}
                                helperText={formik.touched.confirmation_date && formik.errors.confirmation_date}
                            />
                        </MuiPickersUtilsProvider>
                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>*/}
                        {/*    <KeyboardDatePicker style={{maxWidth: "190px"}}*/}
                        {/*                        InputProps={{*/}
                        {/*                            className: classes.multilineColor*/}

                        {/*                        }}*/}
                        {/*                        InputLabelProps={{*/}
                        {/*                            shrink: formik.values.confirmation_date ? true : undefined,*/}
                        {/*                        }}*/}
                        {/*                        className={classes.textField}*/}
                        {/*                        label="Ngày ký hợp đồng"*/}
                        {/*                        name="confirmation_date"*/}
                        {/*                        id="confirmation_date"*/}
                        {/*                        margin="normal"*/}
                        {/*                        inputVariant="outlined"*/}
                        {/*                        openTo="date"*/}
                        {/*                        format="dd/MM/yyyy"*/}
                        {/*                        placeholder="ngày/tháng/năm"*/}
                        {/*                        mask="__/__/____"*/}
                        {/*                        okLabel="Chọn"*/}
                        {/*                        cancelLabel="Hủy"*/}
                        {/*        // selected={formik.values.end_time}*/}
                        {/*                        value={formik.values.confirmation_date}*/}
                        {/*        // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}*/}
                        {/*                        onChange={date => formik.setFieldValue('confirmation_date', moment.tz(date, "Asia/Ho_Chi_Minh").format())}*/}
                        {/*                        error={formik.touched.confirmation_date && Boolean(formik.errors.confirmation_date)}*/}
                        {/*                        helperText={formik.touched.confirmation_date && formik.errors.confirmation_date}*/}
                        {/*    />*/}
                        {/*</MuiPickersUtilsProvider>*/}
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <TextField*/}
                    {/*        InputProps={{*/}
                    {/*            className: classes.multilineColor*/}
                    {/*        }}*/}
                    {/*        id="created_at"*/}
                    {/*        name="created_at"*/}
                    {/*        label="Ngày tạo"*/}
                    {/*        className={classes.textField}*/}
                    {/*        margin="normal"*/}
                    {/*        variant="outlined"*/}
                    {/*        value={formik.values.created_at}*/}
                    {/*        onChange={formik.handleChange}*/}
                    {/*        error={formik.touched.created_at && Boolean(formik.errors.created_at)}*/}
                    {/*        helperText={formik.touched.created_at && formik.errors.created_at}*/}
                    {/*        multiline*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="driving_license"
                            name="driving_license"
                            label="Bằng lái xe"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.driving_license}
                            onChange={formik.handleChange}
                            error={formik.touched.driving_license && Boolean(formik.errors.driving_license)}
                            helperText={formik.touched.driving_license && formik.errors.driving_license}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardDatePicker
                                style={{maxWidth: "190px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                InputLabelProps={{
                                    shrink: formik.values.driving_license_exp_date ? true : undefined,
                                }}
                                className={classes.textField}
                                label="Ngày hết hạn bằng lái xe"
                                name="driving_license_exp_date"
                                id="driving_license_exp_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy"
                                placeholder="ngày/tháng/năm"
                                mask="__/__/____"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.driving_license_exp_date}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={date => formik.setFieldValue('driving_license_exp_date', moment(date).format("YYYY-MM-DD"))}
                                error={formik.touched.driving_license_exp_date && Boolean(formik.errors.driving_license_exp_date)}
                                helperText={formik.touched.driving_license_exp_date && formik.errors.driving_license_exp_date}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="mobile_phone"
                            name="mobile_phone"
                            label="SĐT di động"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.mobile_phone}
                            onChange={formik.handleChange}
                            error={formik.touched.mobile_phone && Boolean(formik.errors.mobile_phone)}
                            helperText={formik.touched.mobile_phone && formik.errors.mobile_phone}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="nic_num"
                            name="nic_num"
                            label="Số BHXH"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.nic_num}
                            onChange={formik.handleChange}
                            error={formik.touched.nic_num && Boolean(formik.errors.nic_num)}
                            helperText={formik.touched.nic_num && formik.errors.nic_num}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="notes"
                            name="notes"
                            label="Ghi chú"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            error={formik.touched.notes && Boolean(formik.errors.notes)}
                            helperText={formik.touched.notes && formik.errors.notes}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="other_id"
                            name="other_id"
                            label="Giấy tờ khác"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.other_id}
                            onChange={formik.handleChange}
                            error={formik.touched.other_id && Boolean(formik.errors.other_id)}
                            helperText={formik.touched.other_id && formik.errors.other_id}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="pay_grade"
                            name="pay_grade"
                            label="Pay grade"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.pay_grade}
                            onChange={formik.handleChange}
                            error={formik.touched.pay_grade && Boolean(formik.errors.pay_grade)}
                            helperText={formik.touched.pay_grade && formik.errors.pay_grade}
                            multiline
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="postal_code"
                            name="postal_code"
                            label="Mã bưu chính"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.postal_code}
                            onChange={formik.handleChange}
                            error={formik.touched.postal_code && Boolean(formik.errors.postal_code)}
                            helperText={formik.touched.postal_code && formik.errors.postal_code}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="private_email"
                            name="private_email"
                            label="Email cá nhân"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.private_email}
                            onChange={formik.handleChange}
                            error={formik.touched.private_email && Boolean(formik.errors.private_email)}
                            helperText={formik.touched.private_email && formik.errors.private_email}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="province"
                            name="province"
                            label="Tỉnh thành"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.province}
                            onChange={formik.handleChange}
                            error={formik.touched.province && Boolean(formik.errors.province)}
                            helperText={formik.touched.province && formik.errors.province}
                            multiline
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "190px"}}
                            id="shift_time"
                            name="shift_time"
                            label="Ca làm việc"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.shift_time}
                            onChange={handleInputChange}
                            error={formik.touched.shift_time && Boolean(formik.errors.shift_time)}
                            helperText={formik.touched.shift_time && formik.errors.shift_time}
                            multiline
                        >
                            {/*<MenuItem key="" value="">*/}
                            {/*    Vui lòng chọn phòng ban*/}
                            {/*</MenuItem>*/}
                            {shiftTimes.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/*<TextField*/}
                        {/*    InputProps={{*/}
                        {/*        className: classes.multilineColor*/}
                        {/*    }}*/}
                        {/*    id="shift_time"*/}
                        {/*    name="shift_time"*/}
                        {/*    label="Ca làm việc"*/}
                        {/*    className={classes.textField}*/}
                        {/*    margin="normal"*/}
                        {/*    variant="outlined"*/}
                        {/*    value={formik.values.shift_time}*/}
                        {/*    onChange={formik.handleChange}*/}
                        {/*    error={formik.touched.shift_time && Boolean(formik.errors.shift_time)}*/}
                        {/*    helperText={formik.touched.shift_time && formik.errors.errors}*/}
                        {/*    multiline*/}
                        {/*/>*/}
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="ssn_num"
                            name="ssn_num"
                            label="CMND/CCCD"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.ssn_num}
                            onChange={formik.handleChange}
                            error={formik.touched.ssn_num && Boolean(formik.errors.ssn_num)}
                            helperText={formik.touched.ssn_num && formik.errors.ssn_num}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardDatePicker
                                style={{maxWidth: "190px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                InputLabelProps={{
                                    shrink: formik.values.joined_date ? true : undefined,
                                }}
                                className={classes.textField}
                                label="Ngày thôi việc"
                                name="termination_date"
                                id="termination_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy"
                                placeholder="ngày-tháng-năm"
                                mask="__/__/____"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.termination_date}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={date => formik.setFieldValue('termination_date', moment(date).format("YYYY-MM-DD"))}
                                error={formik.touched.termination_date && Boolean(formik.errors.termination_date)}
                                helperText={formik.touched.termination_date && formik.errors.termination_date}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="tic_num"
                            name="tic_num"
                            label="Mã số thuế cá nhân"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.tic_num}
                            onChange={formik.handleChange}
                            error={formik.touched.tic_num && Boolean(formik.errors.tic_num)}
                            helperText={formik.touched.tic_num && formik.errors.tic_num}
                            multiline
                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                {/*    <Grid item>*/}
                {/*        <TextField*/}
                {/*            InputProps={{*/}
                {/*                className: classes.multilineColor*/}
                {/*            }}*/}
                {/*            id="updated_at"*/}
                {/*            name="updated_at"*/}
                {/*            label="Ngày cập nhật"*/}
                {/*            className={classes.textField}*/}
                {/*            margin="normal"*/}
                {/*            variant="outlined"*/}
                {/*            value={formik.values.updated_at}*/}
                {/*            onChange={formik.handleChange}*/}
                {/*            error={formik.touched.updated_at && Boolean(formik.errors.updated_at)}*/}
                {/*            helperText={formik.touched.updated_at && formik.errors.updated_at}*/}
                {/*            multiline*/}
                {/*        />*/}
                {/*    </Grid>*/}
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="work_email"
                            name="work_email"
                            label="Email làm việc"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.work_email}
                            onChange={formik.handleChange}
                            error={formik.touched.work_email && Boolean(formik.errors.work_email)}
                            helperText={formik.touched.work_email && formik.errors.work_email}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="work_phone"
                            name="work_phone"
                            label="SĐT làm việc"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.work_phone}
                            onChange={formik.handleChange}
                            error={formik.touched.work_phone && Boolean(formik.errors.work_phone)}
                            helperText={formik.touched.work_phone && formik.errors.work_phone}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="ethnicity"
                            name="ethnicity"
                            label="Dân tộc"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.ethnicity}
                            onChange={formik.handleChange}
                            error={formik.touched.ethnicity && Boolean(formik.errors.ethnicity)}
                            helperText={formik.touched.ethnicity && formik.errors.ethnicity}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="hic_num"
                            name="hic_num"
                            label="Số BHYT"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.hic_num}
                            onChange={formik.handleChange}
                            error={formik.touched.hic_num && Boolean(formik.errors.hic_num)}
                            helperText={formik.touched.hic_num && formik.errors.hic_num}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="home_phone"
                            name="home_phone"
                            label="Điện thoại nhà"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.home_phone}
                            onChange={formik.handleChange}
                            error={formik.touched.home_phone && Boolean(formik.errors.home_phone)}
                            helperText={formik.touched.home_phone && formik.errors.home_phone}
                            multiline
                        />
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <TextField*/}
                    {/*        InputProps={{*/}
                    {/*            className: classes.multilineColor*/}
                    {/*        }}*/}
                    {/*        id="image_profile"*/}
                    {/*        name="image_profile"*/}
                    {/*        label="image_profile"*/}
                    {/*        className={classes.textField}*/}
                    {/*        margin="normal"*/}
                    {/*        variant="outlined"*/}
                    {/*        value={formik.values.image_profile}*/}
                    {/*        onChange={formik.handleChange}*/}
                    {/*        error={formik.touched.image_profile && Boolean(formik.errors.image_profile)}*/}
                    {/*        helperText={formik.touched.image_profile && formik.errors.image_profile}*/}
                    {/*        multiline*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "190px"}}
                            id="immigration_status"
                            name="immigration_status"
                            label="Tình trạng cư trú"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.immigration_status}
                            onChange={handleInputChange}
                            error={formik.touched.immigration_status && Boolean(formik.errors.immigration_status)}
                            helperText={formik.touched.immigration_status && formik.errors.immigration_status}
                            multiline
                        >
                            {/*<MenuItem key="" value="">*/}
                            {/*    Vui lòng chọn phòng ban*/}
                            {/*</MenuItem>*/}
                            {immigrationStatus.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ListItemText primary="Trình độ học vấn:" />
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        style={{minWidth: "190px"}}
                        id="education"
                        name="education"
                        label=""
                        select
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={values.education}
                        onChange={handleInputChange}
                        error={formik.touched.education && Boolean(formik.errors.education)}
                        helperText={formik.touched.education && formik.errors.education}
                        multiline
                    >
                        {/*<MenuItem key="" value="">*/}
                        {/*    Vui lòng chọn phòng ban*/}
                        {/*</MenuItem>*/}
                        {educations.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <UploadFile model_name="educations" value={values} setValues = {setValues} />
                    {values.files &&
                        values.files.map((file, i) => (
                            <li key={i}>
                                {`File:${file.name} Type:${file.type} Size:${
                                    file.size
                                } bytes`}{" "}
                            </li>
                        ))}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ListItemText primary="Chứng chỉ:" />
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        style={{minWidth: "190px"}}
                        id="certification"
                        name="certification"
                        label=""
                        select
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={values.certification}
                        onChange={handleInputChange}
                        error={formik.touched.certification && Boolean(formik.errors.certification)}
                        helperText={formik.touched.certification && formik.errors.certification}
                        multiline
                    >
                        {/*<MenuItem key="" value="">*/}
                        {/*    Vui lòng chọn phòng ban*/}
                        {/*</MenuItem>*/}
                        {certificates.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <UploadFile model_name="certificates"/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ListItemText primary="Kỹ năng:" />
                    <TextField
                        InputProps={{
                            className: classes.multilineColor
                        }}
                        style={{minWidth: "190px"}}
                        id="skill"
                        name="skill"
                        label=""
                        select
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        value={values.skill}
                        onChange={handleInputChange}
                        error={formik.touched.skill && Boolean(formik.errors.skill)}
                        helperText={formik.touched.skill && formik.errors.skill}
                        multiline
                    >
                        {/*<MenuItem key="" value="">*/}
                        {/*    Vui lòng chọn phòng ban*/}
                        {/*</MenuItem>*/}
                        {skills.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <UploadFile model_name="skills"/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ListItemText primary="Hợp đồng lao động:" />
                    <UploadFile model_name="labor_contracts"/>
                </Grid>

            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit" >
                        Thêm mới
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>

    )

}
export default FormEmployeesAdd;
