import React, {useEffect, useState} from 'react';
import {DataGrid, GridApi, GridCellValue} from '@mui/x-data-grid';
import CustomToolbar from "../../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import LoopIcon from '@mui/icons-material/Loop';
import SurbodinateOvertimeView from "../../../components/Form/Attandance/OvertimeRequest/SurbodinateOvertimeView";
import SurbodinateOvertimeUpdate from "../../../components/Form/Attandance/OvertimeRequest/SurbodinateOvertimeUpdate";
import SurbodinateOvertimeAdd from "../../../components/Form/Attandance/OvertimeRequest/SurbodinateOvertimeAdd";
import SurbodinateOvertimeApprove from "../../../components/Form/Attandance/OvertimeRequest/SurbodinateOvertimeApprove";
import moment from "moment";
// const StyledDataGrid = withStyles({
//     root: {
//
//     },
// })(DataGrid);
const useStyles = makeStyles(theme => ({
    buttonsAdd: {
        marginTop: "15px",
        marginLeft: "20px",
    },
    lightText: {
        color: 'white'
    },
    dataGrid: {
        borderRadius: 3,
        border: 0,
        color: "inherit!important",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        width: "100%"
    },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    root: {
        padding: "20px",
        '& .super-app-theme--header': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: theme.palette.info.main,
            '&:hover': {
                backgroundColor: theme.palette.info.main,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.main,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        '& .MuiToolbar-root ': {
            color: theme.palette.text.primary
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        }
    },

}));
const arrStatus = [{"id": "Pending", "name": "Chờ duyệt"}, {"id": "Approved", "name": "Đã duyệt"}, {
    "id": "Rejected",
    "name": "Từ chối"
}, {"id": "Cancelled", "name": "Đã hủy"}]
const SubordinateOvertimeRequest = () => {
    const classes = useStyles();
    let timeExpired = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    // const {addOrEdit, recordForEdit} = props
    const [subordinateOverTime, setSubordinateOverTime] = useState([])
    // const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [dataRows, setDataRows] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [category, setCategory] = useState([]);
    const [subordinateEmployee, setSubordinateEmployee] = useState([])
    // const permissions = "request_expired";
    const permissions = "";
    // console.log(subordinateEmployee)
    const handleEdit = () => {
        setOpenEdit(false);
    };
    const handleDelete = () => {
        setOpenDelete(false);
    };
    const handleAdd = () => {
        setOpenAdd(false);
    };
    const handleChangeStatus = () => {
        setOpenStatus(false);
        // setDataRows({});
    };
    const handleClose = () => {
        setOpen(false);
        // setDataRows({});
    };
    // console.log(localStorage.getItem("authToken"));
    useEffect(async () => {
        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/overtimerequest/subordinate`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message === null) {
                        res.data.message = {};
                    }
                    console.log(res.data.message)
                    setSubordinateOverTime(res.data.message)
                }
            })

            const api2 = `https://api-dev.hrms.com.vn/api/v1/overtimecategory`
            const result2 = await axios.get(api2, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res2 => {
                if (res2.status === 200) {
                    if (res2.data.message === null) {
                        res2.data.message = {};
                    }
                    setCategory(res2.data.message);

                }
            })
            const api3 = `https://api-dev.hrms.com.vn/api/v1/subordinate`
            const result3 = await axios.get(api3, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res3 => {
                if (res3.status === 200) {
                    if (res3.data.message === null) {
                        res3.data.message = {};
                    }
                    setSubordinateEmployee(res3.data.message);
                }
            })

        } catch (e) {

        }
    }, [openAdd, openDelete, openEdit, openStatus, isExpired])

    const data = {
        "columns":
            [{
                "field": "id",
                "headerName": "ID",
                "hide": true,
                "width": 300,
                align: 'center',
                headerClassName: 'super-app-theme--header',
                headerAlign: 'center',
            },
                {
                    "field": "employee",
                    "headerName": "Nhân viên yêu cầu",
                    "flex": 1,
                    align: 'center',
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.first_name} ${params.value.last_name}`,
                    "renderCell": (params) => {
                        return (`${params.value.first_name} ${params.value.last_name}`)
                    },
                },
                {
                    "field": "category",
                    "headerName": "Phân loại",
                    "flex": 1,
                    align: 'center',
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${params.value.name}`,
                    "renderCell": params => {
                        return params.value.name
                    },
                },
                {
                    "field": "start_time",
                    "headerName": "Thời gian bắt đầu",
                    "type": "string",
                    align: 'center',
                    "flex": 1,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "end_time",
                    align: 'center',
                    "headerName": "Thời gian kết thúc",
                    "type": "string",
                    "flex": 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                },
                {
                    "field": "notes",
                    align: 'center',
                    "headerName": "Ghi chú",
                    "type": "string",
                    "flex": 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',

                },
                {
                    "field": "status",
                    align: 'center',
                    "headerName": "Trạng thái",
                    "type": "string",
                    "flex": 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "valueFormatter": (params) => `${arrStatus.filter(x => x.id === params.value).map(x => x.name)}`,
                    "renderCell": params => {
                        return arrStatus.filter(x => x.id === params.value).map(x => x.name);
                    },
                },
                {
                    "field": "log_change_status",
                    align: 'center',
                    "headerName": "Lịch sử phê duyệt",
                    "type": "string",
                    "flex": 1,
                    "editable": false,
                    "hide": true,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    // "valueFormatter": (params) => `${arrStatus.filter(x => x.id === params.data.status).map(x => x.name)}`,
                    "valueFormatter": "",
                    "renderCell": params => {
                        params.value
                    },
                },
                {
                    "field": "actions",
                    align: 'center',
                    "headerName": "Thao tác",
                    "sortTable": false,
                    "type": "string",
                    "width": 200,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: (params) => (
                        // (params.row. === 'Pending') ?
                        (moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(moment(params.row.start_time, "YYYY-MM-DD HH:mm:ss")) >= 0)
                            ?
                            (permissions === 'request_expired') ?
                                <div>
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation(); // don't select this row after clicking
                                        const api: GridApi = params.api;
                                        const thisRow: Record<string, GridCellValue> = {};
                                        api
                                            .getAllColumns()
                                            .filter((c) => c.field !== "__check__" && !!c)
                                            .forEach(
                                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                            );
                                        setDataRows(thisRow);
                                        setOpenStatus(true);
                                    }

                                    }>
                                        <LoopIcon sx={{color: '#FFBC49!important'}}/>
                                    </IconButton>
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation(); // don't select this row after clicking
                                        const api: GridApi = params.api;
                                        const thisRow: Record<string, GridCellValue> = {};
                                        api
                                            .getAllColumns()
                                            .filter((c) => c.field !== "__check__" && !!c)
                                            .forEach(
                                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                            );
                                        setDataRows(thisRow);
                                        setOpen(true);
                                    }

                                    }>
                                        <VisibilityIcon sx={{color: '#58AD69!important'}}/>
                                    </IconButton>
                                </div>
                                :
                                <div>
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation(); // don't select this row after clicking
                                        const api: GridApi = params.api;
                                        const thisRow: Record<string, GridCellValue> = {};
                                        api
                                            .getAllColumns()
                                            .filter((c) => c.field !== "__check__" && !!c)
                                            .forEach(
                                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                            );
                                        setDataRows(thisRow);
                                        setOpen(true);
                                    }

                                    }>
                                        <VisibilityIcon sx={{color: '#58AD69!important'}}/>
                                    </IconButton>
                                </div>


                            :
                            <div>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation(); // don't select this row after clicking
                                    const api: GridApi = params.api;
                                    const thisRow: Record<string, GridCellValue> = {};
                                    api
                                        .getAllColumns()
                                        .filter((c) => c.field !== "__check__" && !!c)
                                        .forEach(
                                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                        );
                                    setDataRows(thisRow);
                                    setOpenStatus(true);
                                }

                                }>
                                    <LoopIcon sx={{color: '#FFBC49!important'}}/>
                                </IconButton>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation(); // don't select this row after clicking
                                    const api: GridApi = params.api;
                                    const thisRow: Record<string, GridCellValue> = {};
                                    api
                                        .getAllColumns()
                                        .filter((c) => c.field !== "__check__" && !!c)
                                        .forEach(
                                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                        );
                                    setDataRows(thisRow);
                                    setOpen(true);
                                }

                                }>
                                    <VisibilityIcon sx={{color: '#58AD69!important'}}/>
                                </IconButton>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation(); // don't select this row after clicking
                                    const api: GridApi = params.api;
                                    const thisRow: Record<string, GridCellValue> = {};
                                    api
                                        .getAllColumns()
                                        .filter((c) => c.field !== "__check__" && !!c)
                                        .forEach(
                                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                        );
                                    setDataRows(thisRow);
                                    setOpenEdit(true);
                                }
                                }>
                                    <EditIcon sx={{color: '#22C6F0!important'}}/>
                                </IconButton>
                            </div>

                    )
                }],
        "rows": subordinateOverTime,
        rowLength: 100,
        maxColumns: 6,
    }

    return (
        <>

            <Grid item xs={2} className={classes.buttonsAdd}>
                <div>
                    <Button color="primary" variant="contained"
                            onClick={() => {
                                setOpenAdd(true)
                            }}
                    >
                        <AddIcon/>
                        Thêm mới
                    </Button>
                </div>
            </Grid>

            <div style={{height: '800px', width: '97%'}} className={classes.root}>
                <DataGrid
                    {...data}
                    className={classes.dataGrid}
                    disableExtendRowFullWidth={true}
                    getRowClassName={(params) =>
                        `super-app-theme--${params.getValue(params.id, 'status')}`}
                    hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                    localeText={
                        {
                            noRowsLabel: 'Không có dữ liệu',
                            noResultsOverlayLabel: 'Không tìm thấy kết quả.',
                            errorOverlayDefaultLabel: 'An error occurred.',
                            // Density selector toolbar button text
                            toolbarDensity: 'Độ cao dòng',
                            toolbarDensityLabel: 'Độ cao dòng',
                            toolbarDensityCompact: 'Thu gọn',
                            toolbarDensityStandard: 'Bình thường',
                            toolbarDensityComfortable: 'Mở rộng',

                            // Columns selector toolbar button text
                            toolbarColumns: 'Cột',
                            toolbarColumnsLabel: 'Chọn cột',

                            // Filters toolbar button text
                            toolbarFilters: 'Lọc',
                            toolbarFiltersLabel: 'Hiển thị Lọc',
                            toolbarFiltersTooltipHide: 'Ẩn Lọc',
                            toolbarFiltersTooltipShow: 'Hiển thị Lọc',
                            toolbarFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,

                            // Export selector toolbar button text
                            toolbarExport: 'Xuất dữ liệu',
                            toolbarExportLabel: 'Xuất dữ liệu',
                            toolbarExportCSV: 'Tải xuống định dạng CSV',
                            toolbarExportPrint: 'In',

                            // Columns panel text
                            columnsPanelTextFieldLabel: 'Tìm kiếm cột',
                            columnsPanelTextFieldPlaceholder: 'Tên cột',
                            columnsPanelDragIconLabel: 'Reorder column',
                            columnsPanelShowAllButton: 'Hiển thị tất cả',
                            columnsPanelHideAllButton: 'Ẩn tất cả',

                            // Filter panel text
                            filterPanelAddFilter: 'Thêm lọc',
                            filterPanelDeleteIconLabel: 'Xóa',
                            filterPanelOperators: 'Lọc theo:',
                            filterPanelOperatorAnd: 'Và',
                            filterPanelOperatorOr: 'Hoặc',
                            filterPanelColumns: 'Cột',
                            filterPanelInputLabel: 'Giá trị',
                            filterPanelInputPlaceholder: 'Giá trị lọc',

                            // Filter operators text
                            filterOperatorContains: 'chứa',
                            filterOperatorEquals: 'bằng',
                            filterOperatorStartsWith: 'bắt đầu với',
                            filterOperatorEndsWith: 'kết thúc với',
                            filterOperatorIs: 'is',
                            filterOperatorNot: 'is not',
                            filterOperatorAfter: 'is after',
                            filterOperatorOnOrAfter: 'is on or after',
                            filterOperatorBefore: 'is before',
                            filterOperatorOnOrBefore: 'is on or before',
                            filterOperatorIsEmpty: 'giá trị rỗng',
                            filterOperatorIsNotEmpty: 'giá trị không rỗng',

                            // Filter values text
                            filterValueAny: 'any',
                            filterValueTrue: 'true',
                            filterValueFalse: 'false',

                            // Column menu text
                            columnMenuLabel: 'Danh mục',
                            columnMenuShowColumns: 'Hiển thị cột',
                            columnMenuFilter: 'Lọc',
                            columnMenuHideColumn: 'Ẩn',
                            columnMenuUnsort: 'Chưa sắp xếp',
                            columnMenuSortAsc: 'Sắp xếp tăng dần',
                            columnMenuSortDesc: 'Sắp xếp giảm dần',

                            // Column header text
                            columnHeaderFiltersTooltipActive: (count) =>
                                count !== 1 ? `${count} active filters` : `${count} active filter`,
                            columnHeaderFiltersLabel: 'Hiển thị lọc',
                            columnHeaderSortIconLabel: 'Sắp xếp',

                            // Rows selected footer text
                            footerRowSelected: (count) =>
                                count !== 1
                                    ? `${count.toLocaleString()} dòng đang chọn`
                                    : `${count.toLocaleString()} dòng đang chọn`,

                            // Total rows footer text
                            footerTotalRows: 'Tổng dòng:',

                            // Total visible rows footer text
                            footerTotalVisibleRows: (visibleCount, totalCount) =>
                                `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
                            // Checkbox selection text
                            checkboxSelectionHeaderName: 'Checkbox selection',

                            // Boolean cell text
                            booleanCellTrueLabel: 'true',
                            booleanCellFalseLabel: 'false',

                            // Actions cell more text
                            actionsCellMore: 'more',

                            // Tree Data
                            treeDataGroupingHeaderName: 'Nhóm',
                            treeDataExpand: 'Hiện danh sách con',
                            treeDataCollapse: 'Ẩn danh sách con',
                            TablePagination: 'ok'
                        }
                    }
                    components={{
                        Toolbar: CustomToolbar,
                        title: "Danh mục ca làm việc"
                    }}
                />

            </div>
            <Dialog open={openStatus} fullWidth={true} maxWidth={"md"}>
                <DialogTitle>PHÊ DUYỆT LÀM NGOÀI GIỜ</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <SurbodinateOvertimeApprove data={dataRows} category={category} listStatus={arrStatus}
                                                    onClick={() => {
                                                        setOpenStatus(false)
                                                    }}/>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={open} fullWidth={true} maxWidth={"md"}>
                <DialogTitle>CHI TIẾT LÀM NGOÀI GIỜ</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <SurbodinateOvertimeView data={dataRows} category={category} arrStatus={arrStatus}/>
                        {/*<MuiButton onClick={handleClose} style={{width: "25%"}}>*/}
                        {/*    Đóng*/}
                        {/*</MuiButton>*/}
                        <Button color="primary" variant="contained" onClick={handleClose} style={{marginTop: "30px"}}>
                            Đóng
                        </Button>
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} fullWidth={true} maxWidth={"md"}
            >
                <DialogTitle>CẬP NHẬT LÀM NGOÀI GIỜ</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <SurbodinateOvertimeUpdate
                            subordinateEmployee={subordinateEmployee}
                            category={category}
                            arrStatus={arrStatus}
                            data={dataRows}
                            onClick={() => {
                                setOpenEdit(false)
                            }}/>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog open={openAdd} fullWidth={true} maxWidth={"md"}
            >
                <DialogTitle>THÊM CÔNG TÁC CẤP DƯỚI</DialogTitle>
                <DialogActions fullWidth={true} maxWidth={"md"}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <SurbodinateOvertimeAdd
                            subordinateEmployee={subordinateEmployee}
                            category={category}
                            arrStatus={arrStatus}
                            onClick={() => {
                                setOpenAdd(false)
                            }}/>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default SubordinateOvertimeRequest;
