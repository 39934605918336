import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import 'moment-timezone';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import viLocale from "date-fns/locale/vi";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));


const FormTravelRequestView = (props) => {
    const classes = useStyles();
    const [data, setData] = useState(props.data)
    const arrStatus = props.arrStatus
    return (
        <>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="type"
                            name="type"
                            label="Phương tiện"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.travel_type}
                            disabled

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="purpose"
                            name="purpose"
                            label="Lý do công tác"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.purpose}
                            multiple
                            disabled

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="travel_from"
                            name="travel_from"
                            label="Nơi đi"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.travel_from}
                            disabled

                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="travel_to"
                            name="travel_to"
                            label="Nơi đến"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.travel_to}
                            disabled
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="travel_date"
                            name="travel_date"
                            label="Thời gian đi"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.travel_date}
                            disabled
                        />
                    </Grid>

                    <Grid item>
                        <Grid item>
                            <TextField
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                id="return_date"
                                name="return_date"
                                label="Thời gian trở về"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                value={props.data.return_date}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item>
                            <TextField
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                id="status"
                                name="status"
                                label="Trạng thái hiện tại"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                value={`${arrStatus.filter(x => x.id === data.status).map(x => x.name)}`}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}
export default FormTravelRequestView;
