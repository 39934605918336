import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {MenuItem} from "@material-ui/core";
import {RefreshToken} from "../Signin/RefreshToken";

const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (
            this.parent
                .filter(v => v !== value)
                .some(v => v[propertyName] === value[propertyName])
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});
// yup.addMethod(yup.string, 'unique', function(message, mapper = a => a) {
//     return this.test('unique', message, function(list) {
//         return list.length  === new Set(list.map(mapper)).size;
//     });
// });
const initialValues = {
    name: '',
    detail: '',
    type: '',
    address: '',
    country: "61bc63d61a7044f8754e00c7",
    parent: '',
    timezone: '000000000000000000000000',
}
const FormDepartmentsAdd = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const [dataAdd, setDataAdd] = useState()
    const classes = useStyles();
    const types = props.types;
    const departments = props.departments
    const countries = props.countries
    const [values, setValues] = useState(initialValues)
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const validationSchema = yup.object({
        name: yup
            .string('Nhập vào mô tả')
            .required('Dữ liệu bắt buộc nhập'),
        detail: yup
            .string('Nhập vào mô tả'),
        type: yup
            .string('Nhập vào loại phòng ban'),
        //     .required('Dữ liệu bắt buộc nhập'),
        // parent: yup
        //     .string('Nhập vào thuộc phòng ban nào'),
        address: yup
            .string('Nhập vào địa chỉ'),
        // country: yup
        //     .string('Nhập vào quốc gia')
        //     .required('Dữ liệu bắt buộc nhập'),
    });

    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/companystructure`
            const result = await axios.post(api, dataAdd, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 201) {
                    // window.location.reload();
                    props.onClick()
                }
                else if (res.data.code === 401){
                    RefreshToken()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataAdd])
    const handleAdd = (data) => {
        const dataTemp = {...data}
        dataTemp.country = values.country
        dataTemp.parent = values.parent
        dataTemp.type = values.type
        const dataNew = {"message": {...dataTemp}, "action": "create", "type_message": "map"}
        console.log(JSON.stringify(dataNew))
        setDataAdd(dataNew)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleAdd(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="name"
                            name="name"
                            label="Tên Phòng Ban"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="detail"
                            name="detail"
                            label="Mô Tả"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.detail}
                            onChange={formik.handleChange}
                            error={formik.touched.detail && Boolean(formik.errors.detail)}
                            helperText={formik.touched.detail && formik.errors.detail}
                            multiline
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "150px"}}
                            id="type"
                            name="type"
                            label="Phân loại"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.type}
                            onChange={handleInputChange}
                            // error={values.type === "" && Boolean(formik.errors.type)}
                            // helperText={values.type === "" ? "Dữ liệu bắt buộc" : ""}
                            multiline
                        >
                            {/*<MenuItem key="" value="">*/}
                            {/*    Vui lòng chọn phòng ban*/}
                            {/*</MenuItem>*/}
                            {types.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "150px"}}
                            id="parent"
                            name="parent"
                            label="Thuộc phòng ban"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.parent}
                            onChange={handleInputChange}
                            // error={values.parent === ""}
                            // helperText={values.parent === "" ? "Dữ liệu bắt buộc" : ""}
                            multiline
                        >
                            {/*<MenuItem key="" value="">*/}
                            {/*    Vui lòng chọn phòng ban*/}
                            {/*</MenuItem>*/}
                            {departments.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {/*<TextField*/}
                        {/*    InputProps={{*/}
                        {/*        className: classes.multilineColor*/}
                        {/*    }}*/}
                        {/*    id="parent"*/}
                        {/*    label="Thuộc Phòng Ban"*/}
                        {/*    name="parent"*/}
                        {/*    className={classes.textField}*/}
                        {/*    margin="normal"*/}
                        {/*    variant="outlined"*/}
                        {/*    value={formik.values.parent}*/}
                        {/*    onChange={formik.handleChange}*/}
                        {/*    error={formik.touched.parent && Boolean(formik.errors.parent)}*/}
                        {/*    helperText={formik.touched.parent && formik.errors.parent}*/}
                        {/*    disabled*/}
                        {/*/>*/}
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="address"
                            label="Địa chỉ"
                            name="address"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="country"
                            name="country"
                            label="Quốc gia"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.country}
                            onChange={handleInputChange}
                            error={values.country === ""}
                            helperText={values.country=== "" ? "Dữ liệu bắt buộc" : ""}
                            multiline
                        >
                            {countries.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name !== `Viet Nam` ? `${x.name}` : `Việt Nam`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="timezone"
                            label="Múi Giờ"
                            name="timezone"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.timezone}
                            onChange={formik.handleChange}
                            error={formik.touched.timezone && Boolean(formik.errors.timezone)}
                            helperText={formik.touched.timezone && formik.errors.timezone}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Thêm mới
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormDepartmentsAdd;
