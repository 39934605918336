import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ColorButtonAdd, ColorButtonCancel, ColorButtonHoverAdd, ColorTextButton} from "../../MainStyleCSS/ColorHeader";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import moment from "moment";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    ButtonAdd:{
        background: ColorButtonAdd,
        color: ColorTextButton,
        '&:hover': {
            background: ColorButtonHoverAdd
        },
    },
    ButtonCancel:{
        backgroundColor: ColorButtonCancel,
        '&:hover': {
            background: ColorButtonHoverAdd,
            color: ColorTextButton
        },
    },

    ButtonAction:{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (
            this.parent
                .filter(v => v !== value)
                .some(v => v[propertyName] === value[propertyName])
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});

const arrPermissions = [{"id":"61b9a478f799e2427113651c", "name":"System Admin"}]
const FormUsersUpdate = (props) => {

    const initvalueSelect = {
        employee: props.data.e_id,
        role: props.data.r_id, // gán tạm Systemadmin
    }
    const [count, setCount] = useState(0)
    const [dataUpdate, setDataUpdate] = useState()
    const classes = useStyles();
    const [values, setValues] = useState(initvalueSelect)
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const validationSchema = yup.object().shape({
        company_name: Yup.string().required('Dữ liệu bắt buộc'),
        email_contact: Yup.string().email('Email không đúng định dạng').required('Dữ liệu bắt buộc'),
        reminiscent_name: Yup.string(),
        address: Yup.string()

    });

    useEffect(async () => {
        if(count) {
            try {
                // console.log(JSON.stringify(dataUpdate))
                const api = `https://api-dev.hrms.com.vn/api/v1/customer`
                const result = await axios.post(api, dataUpdate, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                    if (res.status === 200) {
                        // window.location.reload();
                        props.onClick()
                    }
                })
                // const imageAvatr = await getImageAvatar(result.image);

            } catch (e) {

            }
        }
    }, [count])
    const handleUpdate = (dataSubmit) => {
        dataSubmit.fouding_date = moment(dataSubmit.fouding_date).format('YYYY-MM-DD')
        const dataNew = {"message": {...dataSubmit}, "action": "update", "type_message": "map"}
        console.log(JSON.stringify(dataNew))
        setDataUpdate(dataNew)
        setCount(count+1)
    }
    const formik = useFormik({
        initialValues: {
            id: props.data.id,
            reminiscent_name: props.data.reminiscent_name,
            company_name: props.data.company_name,
            address: props.data.address,
            fouding_date:props.data.fouding_date
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleUpdate(values);
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4} style={{display: 'flex', flexDirection: 'column'}}>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}>Tên viết tắt:</Typography>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            id="reminiscent_name"
                            name="reminiscent_name"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.reminiscent_name}
                            onChange={formik.handleChange}
                            error={formik.touched.reminiscent_name && Boolean(formik.errors.reminiscent_name)}
                            helperText={formik.touched.reminiscent_name && formik.errors.reminiscent_name}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}> <span style={{color:'red'}}>*</span>Khách hàng:</Typography>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            id="company_name"
                            name="company_name"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.company_name}
                            onChange={formik.handleChange}
                            error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                            helperText={formik.touched.company_name && formik.errors.company_name}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}> Địa chỉ:</Typography>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            id="address"
                            name="address"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}> <span style={{color:'red'}}>*</span>Email liên hệ:</Typography>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            id="email_contact"
                            name="email_contact"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.email_contact}
                            onChange={formik.handleChange}
                            error={formik.touched.email_contact && Boolean(formik.errors.email_contact)}
                            helperText={formik.touched.email_contact && formik.errors.email_contact}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography  style={{minWidth: '150px'}}> <span style={{color:'red'}}>*</span>Ngày thành lập:</Typography>
                    </Grid>
                    <Grid  item xs={12} sm={12} md={4} lg={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardDatePicker
                                style={{ minWidth: "250px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                InputLabelProps={{
                                    shrink: formik.values.joined_date ? true : undefined,
                                }}
                                className={classes.textField}
                                label="Ngày thành lập"
                                name="fouding_date"
                                id="fouding_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd/MM/yyyy"
                                placeholder="ngày/tháng/năm"
                                mask="__/__/____"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={formik.values.fouding_date}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={date => formik.setFieldValue('fouding_date', date)}
                                error={formik.touched.fouding_date && Boolean(formik.errors.fouding_date)}
                                helperText={formik.touched.fouding_date && formik.errors.fouding_date}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.ButtonAction} >
                <Grid item>
                    <Button className={classes.ButtonAdd} variant="contained" type="submit">
                        Cập nhật
                    </Button>
                </Grid>
                <Grid item>
                    <Button className={classes.ButtonCancel} variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormUsersUpdate;
