import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from '@mui/material/Alert';
import {makeStyles} from "@material-ui/core/styles";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = makeStyles(theme => ({
    alert: {
        // backgroundImage: "linear-gradient(to top, #4481eb 0%, #04befe 100%)",
        border:"25%"
    }
}));

export default function AlertError({ message }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    function handleClose(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    }

    return (
        <div>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}
                      anchorOrigin={{
                          vertical: "top",
                          horizontal: "right"
                      }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }} className={classes.alert}>
                    {message}
                </Alert>
            </Snackbar>
            {/*<Snackbar*/}
            {/*    anchorOrigin={{*/}
            {/*        vertical: "top",*/}
            {/*        horizontal: "right"*/}
            {/*    }}*/}
            {/*    open={open}*/}
            {/*    autoHideDuration={6000}*/}
            {/*    onClose={handleClose}*/}
            {/*    variant="success"*/}
            {/*    ContentProps={{*/}
            {/*        "aria-describedby": "message-id"*/}
            {/*    }}*/}
            {/*    message={message}*/}
            {/*    action={[*/}
            {/*        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>*/}
            {/*            Thêm nhân viên thành công*/}
            {/*        </Alert>*/}
            {/*    ]}*/}
            {/*/>*/}
        </div>
    );
}
