
import * as React from "react";
import * as excelJS from "exceljs";
import { saveAs } from "file-saver";


// 添加表头
// sheet.getRow(1).values = ["种类", "销量", , , , "店铺"];
// sheet.getRow(2).values = [
//     "种类",
//     "2018-05",
//     "2018-06",
//     "2018-07",
//     "2018-08",
//     "店铺"
// ];

import 'moment-timezone';
import moment from "moment-timezone";
export default function ExportExcel(props) {
    // console.log(props)
    const workbook = new excelJS.Workbook();
    workbook.creator = "";
    workbook.lastModifiedBy = "";
    workbook.created = new Date();
    workbook.modified = new Date();

    let sheet = workbook.addWorksheet(props.workbook);
    // sheet.getRow(1).values = [
    //     ,
    //     ,
    //     "Danh sách máy chấm công",
    //     ,
    //     ,
    //
    // ];
    sheet.getRow(1).values = ["id", "sn","domain" ,"device_type" ,"status" ];

// 添加数据项定义，与之前不同的是，此时去除header字段
    sheet.columns = [
        { key: "id", width: 30 },
        { key: "sn", width: 30 },
        { key: "domain", width: 30 },
        { key: "device_type", width: 30 },
        { key: "status", width: 30 },

    ];
    const data = props.dataGrid;
    // console.log(props.dataGrid)
    sheet.addRows(data);

// 合并单元格
//     sheet.mergeCells(`B1:E1`);
//     sheet.mergeCells("A1:A2");
//     sheet.mergeCells("F1:F2");

// 设置每一列样式
    const row = sheet.getRow(1);
    row.eachCell((cell, rowNumber) => {
        sheet.getColumn(rowNumber).alignment = {
            vertical: "middle",
            horizontal: "center"
        };
        sheet.getColumn(rowNumber).font = { size: 14, family: 2 };
    });

    console.log(workbook.xlsx);


    workbook.xlsx.writeBuffer().then(function(buffer) {
        // done
        console.log(buffer);
        const blob = new Blob([buffer], { type: "applicationi/xlsx" });
        saveAs(blob, `${props.name} ${moment(new Date()).format('DD-MM-YYYY HH-mm-ss')}`);
    });
    return (
        <>
        </>

    );

}
