import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import axios from "axios";
import {RefreshToken} from "../Signin/RefreshToken";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
    textEllipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'pre'
    }
}));

const NewsCard = ({feed, subtitle}) => {
    const classes = useStyles();
    const [dataEmployees, setDataEmployees] = useState([])
    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/employee`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message === null) {
                        res.data.message = [];
                    }
                    setDataEmployees(res.data.message)
                    console.log(res.data.message)
                } else if (res.data.code === 401) {
                    RefreshToken()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [])
    const newArrayEmployees = dataEmployees.slice(0, 5)
    return (
        <Card>
            <CardHeader title="Tài khoản mới" subheader={subtitle}/>
            <Divider/>
            <List>
                {newArrayEmployees ? newArrayEmployees.map((item, index) => (
                    <ListItem key={index}>
                        {item.image_profile ? (
                            <Avatar alt={item.id} src={item.image_profile}></Avatar>

                        ) : (
                            <Avatar></Avatar>
                        )}
                        <ListItemText
                            primary={`${item.last_name} ${item.first_name} - ${item.employee_id}`}
                            secondary={item.job_title.name}
                            inset
                            classes={{
                                primary: classes.textEllipsis,
                                secondary: classes.textEllipsis
                            }}
                        />
                    </ListItem>
                )) : <></>}
            </List>
        </Card>
    );
};

NewsCard.propTypes = {
    feed: PropTypes.arrayOf(
        PropTypes.shape({
            from: PropTypes.string,
            avatar: PropTypes.element,
            subject: PropTypes.string,
            message: PropTypes.string
        })
    ).isRequired,
    subtitle: PropTypes.string
};

export default NewsCard;
