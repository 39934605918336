import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import moment from "moment";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import viLocale from "date-fns/locale/vi";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {setHours} from "date-fns";
import {RefreshToken} from "../Signin/RefreshToken";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {MenuItem} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const LeaveTypeSetupUpdate = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const initialValues = props.data
    const [dataUpdate,setDataUpdate] = useState(initialValues)
    // console.log(props.data)
    const classes = useStyles();
    const forwardLeaveType= props.forwardLeaveType
    const [values, setValues] = useState(initialValues)
    const [count, setCount] = useState(0)
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const validationSchema = yup.object().shape({
        name: yup
            .string('Nhập vào loại phòng ban')
            .required('Dữ liệu bắt buộc nhập'),

    });
    useEffect(async () => {
        if(count) {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/leavetype`
            const result = await axios.post(api, dataUpdate, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
        }
    }, [count])
    const handleUpdate = (dataSubmit) => {
        dataSubmit.carried_forward_leave_availability = values.carried_forward_leave_availability
        const dataNew = {"message":{...dataSubmit},"action":"update","type_message":"map"}
        // console.log(JSON.stringify(dataNew))
        setDataUpdate(dataNew)
        setCount(1)
    }
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleUpdate(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3} align={'center'}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="name"
                            name="name"
                            label="Tên phân loại"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="leaves_per_leave_type"
                            name="leaves_per_leave_type"
                            label="leaves_per_leave_type"
                            type="number"
                            inputProps={{
                                maxLength: 13,
                                step: "1"
                            }}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.leaves_per_leave_type}
                            onChange={formik.handleChange}
                            error={formik.touched.leaves_per_leave_type && Boolean(formik.errors.leaves_per_leave_type)}
                            helperText={formik.touched.leaves_per_leave_type && formik.errors.leaves_per_leave_type}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>suppervisor_can_assign</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="suppervisor_can_assign"
                                name="suppervisor_can_assign"
                                aria-label="suppervisor_can_assign"
                                value={formik.values.suppervisor_can_assign}
                                onChange={formik.handleChange}
                                error={formik.touched.suppervisor_can_assign && Boolean(formik.errors.suppervisor_can_assign)}
                                helpertext={formik.touched.suppervisor_can_assign && formik.errors.suppervisor_can_assign}
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>employee_can_apply</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="employee_can_apply"
                                name="employee_can_apply"
                                aria-label="employee_can_apply"
                                value={formik.values.employee_can_apply}
                                onChange={formik.handleChange}
                                error={formik.touched.employee_can_apply && Boolean(formik.errors.employee_can_apply)}
                                helpertext={formik.touched.employee_can_apply && formik.errors.employee_can_apply}
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>apply_beyond_current</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="apply_beyond_current"
                                name="apply_beyond_current"
                                aria-label="apply_beyond_current"
                                value={formik.values.apply_beyond_current}
                                onChange={formik.handleChange}
                                error={formik.touched.apply_beyond_current && Boolean(formik.errors.apply_beyond_current)}
                                helpertext={formik.touched.apply_beyond_current && formik.errors.apply_beyond_current}
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>leave_accrue</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="leave_accrue"
                                name="leave_accrue"
                                aria-label="leave_accrue"
                                value={formik.values.leave_accrue}
                                onChange={formik.handleChange}
                                error={formik.touched.leave_accrue && Boolean(formik.errors.leave_accrue)}
                                helpertext={formik.touched.leave_accrue && formik.errors.leave_accrue}
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>leave_accrue_beyond_current</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="leave_accrue_beyond_current"
                                name="leave_accrue_beyond_current"
                                aria-label="employee_can_apply"
                                value={formik.values.leave_accrue_beyond_current}
                                onChange={formik.handleChange}
                                error={formik.touched.leave_accrue_beyond_current && Boolean(formik.errors.leave_accrue_beyond_current)}
                                helpertext={formik.touched.leave_accrue_beyond_current && formik.errors.leave_accrue_beyond_current}
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>carried_forward</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="carried_forward"
                                name="carried_forward"
                                aria-label="carried_forward"
                                value={formik.values.carried_forward}
                                onChange={formik.handleChange}
                                error={formik.touched.carried_forward && Boolean(formik.errors.carried_forward)}
                                helpertext={formik.touched.carried_forward && formik.errors.carried_forward}
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} align={'center'}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="carried_forward_percentage"
                            name="carried_forward_percentage"
                            label="carried_forward_percentage"
                            type="number"
                            inputProps={{
                                maxLength: 13,
                                step: "1"
                            }}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.carried_forward_percentage}
                            onChange={formik.handleChange}
                            error={formik.touched.carried_forward_percentagee && Boolean(formik.errors.carried_forward_percentage)}
                            helperText={formik.touched.carried_forward_percentage && formik.errors.carried_forward_percentage}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="max_carried_forward_amount"
                            name="max_carried_forward_amount"
                            label="max_carried_forward_amount"
                            type="number"
                            inputProps={{
                                maxLength: 13,
                                step: "1"
                            }}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.max_carried_forward_amount}
                            onChange={formik.handleChange}
                            error={formik.touched.max_carried_forward_amount && Boolean(formik.errors.max_carried_forward_amount)}
                            helperText={formik.touched.max_carried_forward_amount && formik.errors.max_carried_forward_amount}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="carried_forward_leave_availability"
                            name="carried_forward_leave_availability"
                            label="carried_forward_leave_availability"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.carried_forward_leave_availability}
                            onChange={handleInputChange}
                            error={formik.touched.carried_forward_leave_availability && Boolean(formik.errors.carried_forward_leave_availability)}
                            helperText={formik.touched.carried_forward_leave_availability && formik.errors.carried_forward_leave_availability}
                            multiline
                        >
                            {forwardLeaveType.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <TextField*/}
                    {/*        InputProps={{*/}
                    {/*            className: classes.multilineColor*/}
                    {/*        }}*/}
                    {/*        style={{minWidth: "180px"}}*/}
                    {/*        id="carried_forward_leave_availability"*/}
                    {/*        name="carried_forward_leave_availability"*/}
                    {/*        label="carried_forward_leave_availability"*/}
                    {/*        className={classes.textField}*/}
                    {/*        margin="normal"*/}
                    {/*        variant="outlined"*/}
                    {/*        value={formik.values.carried_forward_leave_availability}*/}
                    {/*        onChange={formik.handleChange}*/}
                    {/*        error={formik.touched.carried_forward_leave_availability && Boolean(formik.errors.carried_forward_leave_availability)}*/}
                    {/*        helperText={formik.touched.carried_forward_leave_availability && formik.errors.carried_forward_leave_availability}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>propotionate_on_joined_date</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="propotionate_on_joined_date"
                                name="propotionate_on_joined_date"
                                aria-label="propotionate_on_joined_date"
                                value={formik.values.propotionate_on_joined_date}
                                onChange={formik.handleChange}
                                error={formik.touched.propotionate_on_joined_date && Boolean(formik.errors.propotionate_on_joined_date)}
                                helpertext={formik.touched.propotionate_on_joined_date && formik.errors.propotionate_on_joined_date}
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Grid item>
                        <FormControl component="fieldset" className={classes.textField}
                                     style={{marginTop: "16px", marginBottom: "8px"}}>
                            <FormLabel>use_employee_leave_period</FormLabel>
                            <RadioGroup
                                row
                                margin="normal"
                                id="use_employee_leave_period"
                                name="use_employee_leave_period"
                                aria-label="use_employee_leave_period"
                                value={formik.values.use_employee_leave_period}
                                onChange={formik.handleChange}
                                error={formik.touched.use_employee_leave_period && Boolean(formik.errors.use_employee_leave_period)}
                                helpertext={formik.touched.use_employee_leave_period && formik.errors.use_employee_leave_period}
                            >
                                <FormControlLabel value="Yes" control={<Radio/>} label="Có"/>
                                <FormControlLabel value="No" control={<Radio/>} label="Không"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="leave_group"
                            name="leave_group"
                            label="leave_group"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            // value={formik.values.leave_group}
                            // onChange={formik.handleChange}
                            // error={formik.touched.leave_group && Boolean(formik.errors.leave_group)}
                            // helperText={formik.touched.leave_group && formik.errors.leave_group}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "180px"}}
                            id="leave_color"
                            name="leave_color"
                            label="leave_color"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.leave_color}
                            onChange={formik.handleChange}
                            error={formik.touched.leave_color && Boolean(formik.errors.leave_color)}
                            helperText={formik.touched.leave_color && formik.errors.leave_color}
                        />
                        {/*<ColorPicker*/}
                        {/*    id="leave_color"*/}
                        {/*    value={color}*/}
                        {/*    margin="normal"*/}
                        {/*    variant="outlined"*/}
                        {/*    onChange={handleChangeColor}*/}
                        {/*/>*/}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Cập nhật
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default LeaveTypeSetupUpdate;
