import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
const format1 = "YYYY-MM-DD HH:mm:ss"
const SuborTimeKeepingApprove = (props) => {
    const [dataApprove, setDataApprove] = useState()
    // const category = props.category;
    const [values, setValues] = useState(props.data)
    const arrStatus = props.listStatus;
    const data = props.data
    const categories = props.categories
    const [count,setCount] = useState(0)
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const classes = useStyles();
    // console.log (initialFValues)
    // const isSameOrBefore = (start, end) => {
    //     return moment(start, 'HH:mm:ss').isSameOrBefore(moment(end, 'HH:mm:ss'));
    // }
    const validationSchema = yup.object().shape({
        // description: yup
        //     .string('Nhập vào mô tả'),
        notes: yup
            .string('Nhập vào loại phòng ban')
            .required('Dữ liệu bắt buộc nhập'),

    });

    useEffect(async () => {
        if(count)
        {
            try {
                // console.log(JSON.stringify(dataUpdate))
                const api = `https://api-dev.hrms.com.vn/api/v1/changestatus/timekeeping`
                const result = await axios.post(api, dataApprove, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                    if (res.status === 200) {
                        if (res.data.message === null) {
                            res.data.message = {};
                        }
                        props.onClick()
                    }
                })
                // const imageAvatr = await getImageAvatar(result.image);

            } catch (e) {
            }
        }
    }, [count])
    const handleApprove = (dataSubmit) => {
        // console.log(JSON.stringify(values))
        const temp = {};
        temp.request_id = props.data.id;
        temp.status = values.status;
        temp.notes = dataSubmit.notes;
        const dataNew = {...temp}
        console.log(JSON.stringify(dataNew))
        setDataApprove(dataNew)
        setCount(1)

    }
    const formik = useFormik({
        initialValues: {
            notes: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleApprove(values);
        },
    });

    return (
        <>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="employee"
                            name="employee"
                            label="Nhân viên cấp dưới"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={`${data.employee.last_name} ${data.employee.first_name}`}
                            disabled
                        />

                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="type_request"
                            name="type_request"
                            label="Loại yêu cầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={`${categories.filter(x => x.id === props.data.type_request).map(x => x.name)}`}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            style={{minWidth: "250px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="details"
                            name="details"
                            label="Mô tả chi tiết"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.details}
                            disabled
                            multiline
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>

                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="date_request"
                            name="date_request"
                            label="Ngày yêu cầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.date_request}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="time_request"
                            name="time_request"
                            label="Thời gian yêu cầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.time_request}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <Grid item>
                            <TextField
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                style={{minWidth: "250px"}}
                                id="status"
                                name="status"
                                label="Trạng thái hiện tại"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                value={`${arrStatus.filter(x => x.id === data.status).map(x => x.name)}`}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid item>
                            <TextField
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                style={{minWidth: "250px"}}
                                id="status"
                                name="status"
                                label="Đổi trạng thái"
                                select
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                value={values.status}
                                onChange={handleInputChange}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                helperText={formik.touched.status && formik.errors.status}
                                multiline
                            >
                                {arrStatus.map((x) => (
                                    <MenuItem key={x.id} value={x.id}>
                                        {x.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                style={{minWidth: "250px"}}
                                id="notes"
                                name="notes"
                                label="Ghi chú"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                value={formik.values.notes}
                                onChange={formik.handleChange}
                                error={formik.touched.notes && Boolean(formik.errors.notes)}
                                helperText={formik.touched.notes && formik.errors.notes}
                                multiline
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                    <Grid item>
                        <Button color="primary" variant="contained" type="submit">
                            Hoàn tất
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={props.onClick}>
                            Hủy
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}
export default SuborTimeKeepingApprove;
