import React, {useEffect, useState} from 'react';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Collapse from '@material-ui/core/Collapse';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import FormChangePassword from "../System/FormChangePassword";
import {RefreshToken} from "../Signin/RefreshToken";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {ColorButton, ColorTextButton} from "../MainStyleCSS/ColorHeader";

const useStyles = makeStyles(theme => ({
    TitleDialog:{
        backgroundColor:ColorButton,
        color: ColorTextButton,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold; line-height: 20px',
        // background: 'linear-gradient(#9BC90D 0%, #79A70A 100%)',
        background: 'linear-gradient(#1A94FF 0%, #1977d4 100%)',
        boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
    },
    ContainerDialog: {
        maxWidth: "lg",
        maxHeight: "calc(100vh - 64px)",

    },
    ContentDialog: {
        margin: "30px",
    },
    avatar: {

        [theme.breakpoints.up("sm")]: {
            width: 32,
            height: 32
        },
        [theme.breakpoints.down("xs")]: {
            width: 16,
            height: 16
        },

    },
    appBar: {
        background: '#1A94FF',
        boxShadow: '0 1px 8px rgba(0,0,0,.3)',
        position: 'relative',
        zIndex: theme.zIndex.drawer + 100,
        [theme.breakpoints.down('sm')]: {
            position: 'fixed'
        }
    },
    toolBar: {
        paddingLeft: theme.spacing(1) / 2,
        paddingRight: theme.spacing(1) / 2
    },
    branding: {
        // display: 'inline-block',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
        // margin: 'auto 0',
        // lineHeight: '50px',
        // padding: `0 120px 0 0`,
        // alignItems:'center',
        // justifyContent: 'space-evenly',
        // justifyItems:'center',

    },
    logo: {
        maxHeight: "40px",
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '60px'
        }
    },
    searchWrapper: {
        flex: '1 1 0%',
        boxSizing: ' border-box',

    },
    searchForm: {
        background: 'white',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(1) * 2,
        display: 'block',
        maxWidth: '800px'
    },
    searchInput: {
        fontSize: '1rem',
        padding: theme.spacing(1) * 1.9,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1) * 1.2
        },
        cursor: 'text',
        textIndent: '30px',
        border: 'none',
        background: 'transparent',
        width: '100%',
        outline: '0'
    },
    searchIcon: {
        position: 'absolute',
        top: '50%',
        left: '0',
        marginTop: '-24px',
        color: 'rgba(0,0,0,.87)'
    },
    title: {
        textAlign: "center",
        fontFamily: "ROBOTO",
        fontWeight: "500",
        fontSize: "25px",
        display: "flex",
        marginLeft: "20px",
        alignItems: "center",

        // height: 'cover'
    },
    userMenu: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'red',
        color: "white",
    },
    ribbon: {
        fontWeight: "bold"
    },
    spanTypo: {
        fontSize: '10px',
        color: '#fff',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold; line-height: 20px',
        // background: 'linear-gradient(#9BC90D 0%, #79A70A 100%)',
        background: 'linear-gradient(#1A94FF 0%, #1977d4 100%)',
        boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
    }
}));

const Header = ({
                    logo,
                    logoAltText,
                    title,
                    toggleFullscreen,
                    toggleDrawer,
                    toogleNotifications
                }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [openPW, setOpenPW] = useState(false);
    const handleSettingdToggle = event => setAnchorEl(event.currentTarget);
    const handleCloseMenu = () => setAnchorEl(null);
    const [myInfo, setMyInfo] = useState();
    const [name, setName] = useState('');
    const [signout, setSignout] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataUser, setDataUser] = useState();
    const [count, setCount] = useState(0);
    const handleCloseChangePW = () => {
        setOpenPW(false);
    };
    const handleSignOut = () => {
        setSignout(signout + 1)
    }
    const handleProfiles = () => {
        window.location.href = '/employees/profiles';
    }
    const handleChangePassword = () => {
        setOpenPW(true)
    }
    const handleSearchExpandToggle = () => setSearchExpanded(!searchExpanded);

    const handleDrawerToggle = () => {
        toggleDrawer();
        if (searchExpanded) handleSearchExpandToggle();
    };

    const handleNotificationToggle = () => {
        toogleNotifications();
        if (searchExpanded) handleSearchExpandToggle();
    };
    useEffect(async () => {
        if (signout) {
            try {
                await localStorage.clear();
            } catch (e) {
            }
        }
    }, )

    useEffect(async () => {
        try {
            await setLoading(true)
            // const temp = CheckTokenExpired();
            // console.log(JSON.stringify(dataUpdate))
            // RefreshToken()
            const api = `https://api-dev.hrms.com.vn/api/v1/myinfo`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res2 => {
                if (res2.status === 200) {
                    setMyInfo(res2.data.message.image_profile);
                    setName(`${res2.data.message.last_name} ${res2.data.message.first_name}`)
                    // console.log(res2.data.message)
                    setLoading(false)
                    // setCount(1);
                }
            })
        } catch (e) {
        }
    }, [signout])
    // useEffect(async () => {
    //   try {
    //     if (myInfo){
    //        setLoading(false)
    //     }
    //
    //   } catch (e) {
    //   }
    // }, [myInfo])
    //
    //
    // useEffect(async ()=>{
    //   if(count){
    //     try {
    //       const api = `https://api-dev.hrms.com.vn/api/v1/user`
    //       const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
    //         if (res.status === 200) {
    //           if (res.data.message === null) {
    //             setDataUser(null);
    //           } else {
    //             // console.log(res.data.message)
    //             // console.log(dataRows.id)
    //             const user_name = res.data.message.filter(x => x.employee.id === dataRows.id).map(x => x.user_name)
    //             setDataUser(user_name);
    //             // console.log(user_name)
    //
    //           }
    //           // setDataUser(res.data.message)
    //         }
    //       })
    //
    //     } catch (e) {
    //
    //     }
    //   }
    // },[dataRows])

    return (
        <>
            {loading &&
                // <p>It's loading...</p>
                <center>
                    <CircularProgress/>
                </center>
            }
            {!loading &&
                // <p>It's loading...</p>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar className={classes.toolBar}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <div className={classes.branding}>
                            <img src={logo} alt={logoAltText} className={classes.logo}/>
                        </div>
                        <div className={classes.title}>
                            {title}
                        </div>

                        <Hidden xsDown>
                            <div className={classes.searchWrapper}>
                                {/*<form className={classes.searchForm}>*/}
                                {/*  <IconButton aria-label="Search" className={classes.searchIcon}>*/}
                                {/*    <SearchIcon />*/}
                                {/*  </IconButton>*/}
                                {/*  <input*/}
                                {/*    className={classes.searchInput}*/}
                                {/*    type="text"*/}
                                {/*    placeholder="Search"*/}
                                {/*    autoFocus={true}*/}
                                {/*  />*/}
                                {/*</form>*/}
                            </div>
                        </Hidden>

                        <Hidden smUp>
                            <span className="flexSpacer"/>
                        </Hidden>

                        <Hidden smUp>
                            <IconButton
                                color="inherit"
                                onClick={handleSearchExpandToggle}
                                aria-expanded={searchExpanded}
                                aria-label="Show searchbar"
                            >
                                <SearchIcon/>
                            </IconButton>
                        </Hidden>

                        <Hidden xsDown>
                            <IconButton color="inherit" onClick={toggleFullscreen}>
                                <FullscreenIcon/>
                            </IconButton>
                        </Hidden>

                        <IconButton color="inherit" onClick={handleNotificationToggle}>
                            <Badge badgeContent={5} color="secondary">
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>

                        <IconButton
                            aria-label="User Settings"
                            aria-owns={anchorEl ? 'user-menu' : null}
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleSettingdToggle}
                        >
                            {/*<MoreVertIcon />*/}
                            <Avatar
                                alt={`1`}
                                src={myInfo}
                                className={classNames(classes.avatar)}
                            />
                        </IconButton>

                        <Menu
                            id="user-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            style={{top: "60px"}}
                        >
                            <MenuItem onClick={handleCloseMenu} className={classes.spanTypo}>
                                <Typography className={classes.ribbon}> {name}</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseMenu}>
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Settings"/>
                            </MenuItem>
                            <MenuItem onClick={handleCloseMenu}>
                                <ListItemIcon>
                                    <AccountBoxIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Thông tin cá nhân" onClick={handleProfiles}/>
                            </MenuItem>
                            <MenuItem onClick={handleCloseMenu}>
                                <ListItemIcon onClick={handleChangePassword}>
                                    <AdminPanelSettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Đổi mật khẩu" onClick={handleChangePassword}/>
                            </MenuItem>
                            <MenuItem onClick={handleCloseMenu}>
                                <ListItemIcon>
                                    <NotificationsOffIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Tắt thông báo"/>
                            </MenuItem>
                            <MenuItem onClick={handleCloseMenu}>
                                <ListItemIcon onClick={handleSignOut}>
                                    <ExitToAppIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Đăng xuất" onClick={handleSignOut}/>
                            </MenuItem>
                        </Menu>
                        <Dialog open={openPW} className={`${classes.ContainerDialog}`}
                        >
                            <DialogTitle className={classes.TitleDialog}>ĐỔI MẬT KHẨU</DialogTitle>
                            <DialogActions className={classes.ContentDialog}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <FormChangePassword
                                        // data={dataUser}
                                        onClick={() => {
                                            setOpenPW(false)
                                        }}/>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </Toolbar>
                    <Hidden smUp>
                        <Collapse in={searchExpanded} timeout="auto" unmountOnExit>
                            <Toolbar className={classes.toolBar}>
                                <div className={classes.searchWrapper}>
                                    <form className={classNames(classes.searchForm, 'mr-0')}>
                                        <IconButton aria-label="Tìm kiếm" className={classes.searchIcon}>
                                            <SearchIcon/>
                                        </IconButton>
                                        <input
                                            className={classes.searchInput}
                                            type="text"
                                            placeholder="Search"
                                            autoFocus="true"
                                        />
                                    </form>
                                </div>
                            </Toolbar>
                        </Collapse>
                    </Hidden>
                </AppBar>
            }
        </>
    );
};

Header.prototypes = {
    logo: PropTypes.string,
    logoAltText: PropTypes.string
};

export default Header;
