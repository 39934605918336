import React from 'react';
import { Wrapper } from '../../components';
import { UserForm } from '../../components/EditEmployees/UserForm';
import CustomizedSteppers from "../../components/EditEmployees/CustomizedSteppers";
import Employees from "../Employees/Employees";
const Blank = () => (
  <Wrapper>
      <Employees/>
    {/*<CustomizedSteppers/>*/}
    {/*<p>Blank</p>*/}
    {/*  <UserForm/>*/}
  </Wrapper>
);

export default Blank;
