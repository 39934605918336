import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {RefreshToken} from "../Signin/RefreshToken";

const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const FormJobDetailsAdd = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const [dataAdd,setDataAdd] = useState()
    const classes = useStyles();
    const validationSchema = yup.object({
        code: yup
            .string('Mã CD')
            .required('Dữ liệu bắt buộc nhập'),
        name: yup
            .string('Mã CD')
            .required('Dữ liệu bắt buộc nhập'),
        description: yup
            .string('Mã CD')
            .required('Dữ liệu bắt buộc nhập'),
        // password: yup
        //     .string('Enter your password')
        //     .min(8, 'Password should be of minimum 8 characters length')
        //     .required('Password is required'),
    });
    useEffect(async () => {
        try {

            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/jobtitle`
            const result = await axios.post(api, dataAdd,{headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                console.log(res);
                if (res.status === 201) {
                    // window.location.reload();
                    props.onClick()
                }
            else if (status.data.code === 401){
                    RefreshToken()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch(e) {

        }
    }, [dataAdd])
    const handleAdd = (values) => {
        const dataNew = {"message":{...values},"action":"create","type_message":"map"}
        // console.log(JSON.stringify(dataNew))
        setDataAdd(dataNew)
    }
    const formik = useFormik({
        initialValues: {
            code: '',
            name: '',
            description: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleAdd(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="code"
                            name="code"
                            label="Mã Chức Danh"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                            helperText={formik.touched.code && formik.errors.code}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="name"
                            name="name"
                            label="Tên Chức Danh"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            multiline
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="description"
                            label="Ghi chú"
                            name="description"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Thêm mới
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormJobDetailsAdd;
