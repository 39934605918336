import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {capitalize} from '../../helpers';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import useMountEffect from '../../mountEffect';
import {drawerMiniIcon} from "../../styleVariables";
import SimpleMenu from "../../components/Sidebar/SimpleMenu";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    badge: {
        width: '20px',
        height: '20px',
        display: 'flex',
        zIndex: 1,
        flexWrap: 'wrap',
        fontSize: '0.75rem',
        alignItems: 'center',
        borderRadius: '50%',
        alignContent: 'center',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    menuLink: {
        position: 'relative',
        display: 'block',
        paddingLeft: '20px',
        paddingRight: '20px',
        // paddingTop: theme.spacing(1.5),
        // paddingBottom: theme.spacing(1.5),
        height: '52px'
    },
    menuItem: {
        paddingLeft: '0px',
        paddingRight: '0px',
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5)
        // paddingLeft: 0,
        // paddingRight: 0,
        // paddingTop: theme.spacing(2),
        // paddingBottom: theme.spacing(2),
        // left:'10%'
    },
    menuIcon: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    menuSubItem: {
        paddingLeft: '55px',
        paddingRight: '55px',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),

    },
    menuCollapsed: {
        backgroundColor: theme.palette.action.hover
    },
    menuActive: {
        backgroundColor: theme.palette.action.hover
    },
    menuClosed: {
        backgroundColor: 'transparent'
    },
    caret: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: 0
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    // tooltip_menu: {
    //   marginLeft:"50px"
    // }
}));

const SidebarMiniItem = ({
                             route,
                             index,
                             activeRoute,
                             toggleMenu,
                             currentPath
                         }) => {
    const classes = useStyles();
    // console.log (route)
    useMountEffect(() => {
        if (!currentPath || activeRoute === index || route.path === '/') return;
        toggleMenu(index);
    });

    const badge = badge => {
        if (!badge) return;
        const badgeClassName = classNames(classes.badge, {
            [classes[`${badge.type}`]]: badge.type !== 'default'
        });
        return (
            <Typography
                className={classNames(classes.badge, badgeClassName)}
                component="div"
            >
                {badge.value}
            </Typography>
        );
    };

    if (route.type === 'external') {
        return (

            <a
                href={route.path}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                className={classes.menuLink}
            >

                <ListItem className={classes.menuItem} button>
                    <ListItemIcon>
                        <route.icon className={classes.menuIcon}/>
                    </ListItemIcon>

                </ListItem>

            </a>

        );
    }

    if (route.type === 'submenu') {
        return (
            <div
                className={
                    activeRoute === index ? classes.menuCollapsed : classes.menuClosed
                }
            >
                <ListItem
                    // className={classes.menuItem}
                    // button
                    key={index}
                    onClick={() => toggleMenu(index)}
                >
                    <SimpleMenu route={route}/>

                    {/*<Typography variant="body1" className="flexSpacer">*/}
                    {/*    {capitalize(route.name)}*/}
                    {/*</Typography>*/}
                    {/*{badge(route.badge)}*/}
                    {/*<ListItemIcon className={classes.caret}>*/}
                    {/*    {activeRoute === index ? (*/}
                    {/*        <ArrowDropUpIcon />*/}
                    {/*    ) : (*/}
                    {/*        <ArrowDropDownIcon />*/}
                    {/*    )}*/}
                    {/*</ListItemIcon>*/}
                </ListItem>
                {/*<Collapse*/}
                {/*    in={activeRoute === index}*/}
                {/*    timeout="auto"*/}
                {/*    unmountOnExit*/}
                {/*>*/}
                {/*    <List disablePadding>*/}
                {/*        {route.children.map((subitem, index) => (*/}
                {/*            <NavLink*/}
                {/*                to={`${route.path ? route.path : ''}${*/}
                {/*                    subitem.path ? subitem.path : ''*/}
                {/*                }`}*/}
                {/*                exact*/}
                {/*                className={classes.menuLink}*/}
                {/*                activeClassName={classes.menuActive}*/}
                {/*                key={index}*/}
                {/*            >*/}
                {/*                <ListItem className={classes.menuSubItem} button>*/}
                {/*                    /!*<Typography variant="body1" className="flexSpacer">*!/*/}
                {/*                    /!*    {capitalize(subitem.name)}*!/*/}
                {/*                    /!*</Typography>*!/*/}
                {/*                    {badge(subitem.badge)}*/}
                {/*                </ListItem>*/}
                {/*            </NavLink>*/}
                {/*        ))}*/}
                {/*    </List>*/}
                {/*</Collapse>*/}
            </div>
        );
    }

    return (
        <NavLink
            to={route.path}
            exact
            className={classes.menuLink}
            activeClassName={classes.menuActive}
            key={index}
        >
            <Tooltip title={route.name} arrow>
                <ListItem
                    className={classes.menuItem}
                    button
                    onClick={() => toggleMenu(index)}
                >
                    <ListItemIcon>
                        <route.icon className={classes.menuIcon}/>
                    </ListItemIcon>
                    {/*<Typography variant="body1" className="flexSpacer">*/}
                    {/*    {capitalize(route.name)}*/}
                    {/*</Typography>*/}
                    {badge(route.badge)}
                </ListItem>
            </Tooltip>
        </NavLink>
    );
};

SidebarMiniItem.prototypes = {
    route: PropTypes.object,
    index: PropTypes.number,
    activeRoute: PropTypes.number,
    toggleMenu: PropTypes.func
};

export default SidebarMiniItem;
