import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import React, {useRef} from "react";

const useStyles = makeStyles(theme => ({
    input: {
        fontFamily: "ROBOTO",
        fontWeight: "500",
        height: 'cover',
        marginTop: theme.spacing(1) * 2,
        cursor: 'pointer'
    }
}));

const Input = ({accept, onFiles, files, getFilesFromEvent}) => {
    const inputFileRef = useRef(null);
    const text = files.length > 0 ? 'Thêm File' : 'Chọn tệp tin'
    const classes = useStyles();
    const onBtnClick = () => {
        /*Collecting node-element and performing click*/
        inputFileRef.current.click();
    }
    return (
        // <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
        <>
            <input
                style={{display: 'none'}}
                type="file"
                ref={inputFileRef}
                accept={accept}
                multiple
                onChange={e => {
                    getFilesFromEvent(e).then(chosenFiles => {
                        onFiles(chosenFiles)
                    })
                }}
            />
            <Button color="primary" variant="contained" onClick={onBtnClick} className={classes.input}>
                <AddIcon/>
                {text}
            </Button>
        </>
    )
}
export default Input;
