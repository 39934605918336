import { combineReducers } from 'redux';
import loginReducer from './login.reducer';

//insert another reducers here to be combined

const reducers = combineReducers({
    loginReducer,

});

export default reducers
