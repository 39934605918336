import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import viLocale from "date-fns/locale/vi";
import moment from 'moment';
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
const format1 = "YYYY-MM-DD HH:mm:ss"
const SubordinateOvertimeApprove = (props) => {
    const [dataApprove, setDataApprove] = useState()
    const category = props.category;
    const [values, setValues] = useState(props.data)
    const listStatus = props.listStatus;
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const classes = useStyles();
    // console.log (initialFValues)
    // const isSameOrBefore = (start, end) => {
    //     return moment(start, 'HH:mm:ss').isSameOrBefore(moment(end, 'HH:mm:ss'));
    // }
    const validationSchema = yup.object().shape({
        notes: yup
            .string('Nhập vào mô tả')
            .required('Vui lòng nhập vào ghi chú'),
        // name: yup
        //     .string('Nhập vào loại phòng ban')
        //     .required('Dữ liệu bắt buộc nhập'),

    });

    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/changestatus/overtimerequest`
            const result = await axios.post(api, dataApprove, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataApprove])
    const handleAdd = (dataSubmit) => {
        // console.log(JSON.stringify(values))
        const temp = {};
        temp.request_id = props.data.id;
        temp.status = values.status;
        temp.notes = dataSubmit.notes;
        const dataNew = {...temp}
        setDataApprove(dataNew)
    }
    const formik = useFormik({
        initialValues: {
            notes: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleAdd(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={6} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="category"
                            name="category"
                            label="Loại làm thêm giờ"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={`${category.filter(x => x.id === props.data.category.id).map(x => x.name)}`}
                            disabled
                        >
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="category"
                            name="category"
                            label="Thời gian bắt đầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.start_time}
                            disabled
                        >
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="category"
                            name="category"
                            label="Thời gian kết thúc"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.end_time}
                            disabled
                        >
                        </TextField>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="status_request"
                            name="status_request"
                            label="Trạng thái hiện tại"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={`${listStatus.filter(x => x.id === props.data.status).map(x => x.name)}`}
                            disabled
                        >
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="notes_request"
                            name="notes_request"
                            label="Mô tả làm ngoài giờ"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.notes}
                            disabled
                        >
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid item>
                        {/*<TextField*/}
                        {/*    InputProps={{*/}
                        {/*        className: classes.multilineColor*/}
                        {/*    }}*/}
                        {/*    style={{minWidth: "250px"}}*/}
                        {/*    id="status"*/}
                        {/*    name="status"*/}
                        {/*    label="Trạng thái"*/}
                        {/*    className={classes.textField}*/}
                        {/*    margin="normal"*/}
                        {/*    variant="outlined"*/}
                        {/*    value={formik.values.status}*/}
                        {/*    // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}*/}
                        {/*    onChange={sdate => formik.setFieldValue('start_time', moment(sdate).format("YYYY-MM-DD HH:mm:ss"))}*/}
                        {/*    error={formik.touched.start_time && Boolean(formik.errors.start_time)}*/}
                        {/*    helperText={formik.touched.start_time && formik.errors.start_time}*/}
                        {/*/>*/}
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="status"
                            name="status"
                            label="Đổi trạng thái"
                            select
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={values.status==="Pending" ? "Approved" : values.status }
                            onChange={handleInputChange}
                            error={formik.touched.status && Boolean(formik.errors.status)}
                            helperText={formik.touched.status && formik.errors.status}
                            multiline
                        >

                            {listStatus.map((x) => (
                                (x.id !== 'Pending') ?
                                    <MenuItem key={x.id} value={x.id}>
                                        {x.name}
                                    </MenuItem> : null
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="notes"
                            name="notes"
                            label="Ghi chú"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            error={formik.touched.notes && Boolean(formik.errors.notes)}
                            helperText={formik.touched.notes && formik.errors.notes}
                            multiline
                        />
                    </Grid>
                </Grid>

            </Grid>

            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Hoàn tất
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default SubordinateOvertimeApprove;
