import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import moment from 'moment';
import 'moment-timezone';
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (
            this.parent
                .filter(v => v !== value)
                .some(v => v[propertyName] === value[propertyName])
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});

// yup.addMethod(yup.string, 'unique', function(message, mapper = a => a) {
//     return this.test('unique', message, function(list) {
//         return list.length  === new Set(list.map(mapper)).size;
//     });
// });
const FormEmployeeEducationAdd = (props) => {
    const [dataAdd, setDataAdd] = useState()
    const classes = useStyles();
    const isSameOrBefore = (start, end) => {
        return moment(start, 'HH:mm:ss').isSameOrBefore(moment(end, 'HH:mm:ss'));
    }
    const validationSchema = yup.object().shape({
        description: yup
            .string('Nhập vào mô tả'),
        name: yup
            .string('Nhập vào loại phòng ban')
            .required('Dữ liệu bắt buộc nhập'),

    });

    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            const api = `https://api-dev.hrms.com.vn/api/v1/educations`
            const result = await axios.post(api, dataAdd, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 201) {
                    // window.location.reload();
                    props.onClick()
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [dataAdd])
    const handleAdd = (values) => {
        // console.log(JSON.stringify(values))
        const dataNew = {"message": {...values}, "action": "create", "type_message": "map"}
        console.log(JSON.stringify(dataNew))
        setDataAdd(dataNew)
    }
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleAdd(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item>
                        <TextField
                            style={{minWidth:"400px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="name"
                            name="name"
                            label="Loại trình độ học vấn"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            style={{minWidth:"400px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="description"
                            name="description"
                            label="Mô Tả"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            multiline
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Thêm mới
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormEmployeeEducationAdd;
