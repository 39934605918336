const ColorHeader = '#edeceb'
const ColorButton = '#1A94FF' // Tông màu xanh chủ đạo
const ColorExcel = '#4a934a'
const ColorTextButton = 'white'
const ColorActionView = '#58AD69'
const ColorActionEdit = '#03a9f4'
const ColorActionDelete = '#FF0000'
const ColorActionUpdatePW = '#FFBD35'
//Color Button Action Form Dialog
const ColorButtonAdd = '#58AD69'
const ColorButtonEdit = '#03a9f4'
const ColorButtonHoverAdd = '#FFBD35'
const ColorButtonCancel = '#edeceb'
const ColorButtonDelete= 'red'
const ColorButtonHoverCancel = '#FFBD35'
//

export {
    ColorExcel,
    ColorHeader,
    ColorButton,
    ColorActionView,
    ColorActionEdit,
    ColorActionDelete,
    ColorActionUpdatePW,
    ColorTextButton,
    ColorButtonAdd,
    ColorButtonHoverAdd,
    ColorButtonCancel,
    ColorButtonHoverCancel,
    ColorButtonDelete,
    ColorButtonEdit
}
