import React, {useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import CustomToolbar from "../../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import viLocale from "date-fns/locale/vi";
import DateFnsUtils from "@date-io/date-fns";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    buttonsAdd: {
        marginTop: "15px",
        marginLeft: "20px",
    },
    lightText: {
        color: 'white'
    },
    dataGrid: {
        borderRadius: 3,
        border: 0,
        color: "inherit!important",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        width: "100%"
    },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    root: {
        padding: "20px",
        '& .super-app-theme--header': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: theme.palette.info.main,
            '&:hover': {
                backgroundColor: theme.palette.info.main,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.main,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        '& .MuiToolbar-root ': {
            color: theme.palette.text.primary
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        }
        // '& .MuiDataGrid-renderingZone': {
        //     maxHeight: 'none !important',
        // },
        // '& .MuiDataGrid-cell': {
        //     lineHeight: 'unset !important',
        //     maxHeight: 'none !important',
        //     whiteSpace: 'normal',
        // },
        // '& .MuiDataGrid-row': {
        //     maxHeight: 'none !important',
        // },
    },

}));
const format1 = "YYYY-MM-DD";
const initialFValues = {
    start_time: moment(new Date()).format(format1),
    end_time: moment(new Date()).format(format1)
}
const ListAttendance = (props) => {
    const classes = useStyles();
    const [dataAttandance, setDataAttandance] = useState([])
    const [startTime, setStartTime] = useState(initialFValues.start_time)
    const [endTime, setEndTime] = useState(initialFValues.end_time)
    useEffect(async () => {
        try {
            // console.log(JSON.stringify(dataUpdate))
            // const startTime = moment(values.startTime).format('YYYY-MM-DD')
            // const endTime = moment(values.endTime).format('YYYY-MM-DD')
            const api = `https://api-dev.hrms.com.vn/api/v1/attendance/me?start_date=${startTime}&end_date=${endTime}`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    if (res.data.message === null) {
                        res.data.message = {};
                    }
                    // window.location.reload();
                    setDataAttandance(res.data.message)
                }
            })
            // const imageAvatr = await getImageAvatar(result.image);

        } catch (e) {

        }
    }, [startTime, endTime])

    const data = {
        "columns":
            [{"field": "id", "hide": true},
                {
                    "field": "employee",
                    "headerName": "Tên nhân viên",
                    "type": "string",
                    flex: 1,
                    align: 'center',
                    "headerClassName": 'super-app-theme--header',
                    "headerAlign": 'center',
                    "valueFormatter": (params) => `${params.value.first_name} ${params.value.last_name}`,
                    "renderCell": (params) => {
                        return (`${params.value.first_name} ${params.value.last_name}`)
                    },
                },
                // {"field": "employee_name", "headerName": "Họ & tên", "width": 200, "editable": false},
                {
                    "field": "in_time",
                    "headerName": "Thời gian vào",
                    flex: 1,
                    align: 'center',
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center'
                },
                {
                    "field": "out_time",
                    "headerName": "Thời gian ra",
                    "type": "string",
                    flex: 1,
                    align: 'center',
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    "editable": false
                },
                {
                    "field": "note", "headerName": "Ghi chú", "type": "string", flex: 1,
                    headerClassName: 'super-app-theme--header',
                    align: 'center',
                    headerAlign: 'center', "editable": false
                }],
        "rows": dataAttandance,
        rowLength: 100,
        maxColumns: 6,
    }
    // console.log((data));
    return (
        <>
            <div style={{height: '800px', width: '97%'}} className={classes.root}>
                <Grid container className="Search"
                      style={{columnGap: "10px", rowGap: "15px", marginBottom: "10px", marginLeft: "10px"}}>
                    <Grid item>
                        <MuiPickersUtilsProvider locale={viLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{maxWidth: "190px"}}
                                className={classes.textField}
                                label="Thời gian bắt đầu"
                                name="start_date"
                                id="start_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy"
                                placeholder="ngày-tháng-năm"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                value={startTime}
                                onChange={edate => setStartTime(moment(edate).format(format1))}
                            />

                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider locale={viLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{maxWidth: "190px"}}
                                className={classes.textField}
                                label="Thời gian kết thúc"
                                name="end_date"
                                id="end_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy"
                                placeholder="ngày-tháng-năm"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                value={endTime}
                                // onChange={endTime => formik.setFieldValue('end_time', moment.tz(endTime, "Asia/Ho_Chi_Minh").format())}
                                onChange={sdate => setEndTime(moment(sdate).format(format1))}
                                // error={formik.touched.travel_date && Boolean(formik.errors.travel_date)}
                                // helperText={formik.touched.travel_date && formik.errors.travel_date}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>

                {/*{formik =>*/}
                {/*    <SubmitListener formik={formik} />*/}
                {/*}*/}
                <DataGrid style={{height: '75%', width: '100%', paddingLeft: "10px", marginTop: "20px"}}
                          className={classes.dataGrid}
                          disableExtendRowFullWidth={true}
                          {...data}
                          getRowClassName={(params) =>
                              `super-app-theme--${params.getValue(params.id, 'status')}`}
                          hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                          localeText={
                              {
                                  noRowsLabel: 'Không có dữ liệu',
                                  noResultsOverlayLabel: 'Không tìm thấy kết quả.',
                                  errorOverlayDefaultLabel: 'An error occurred.',
                                  // Density selector toolbar button text
                                  toolbarDensity: 'Độ cao dòng',
                                  toolbarDensityLabel: 'Độ cao dòng',
                                  toolbarDensityCompact: 'Thu gọn',
                                  toolbarDensityStandard: 'Bình thường',
                                  toolbarDensityComfortable: 'Mở rộng',

                                  // Columns selector toolbar button text
                                  toolbarColumns: 'Cột',
                                  toolbarColumnsLabel: 'Chọn cột',

                                  // Filters toolbar button text
                                  toolbarFilters: 'Lọc',
                                  toolbarFiltersLabel: 'Hiển thị Lọc',
                                  toolbarFiltersTooltipHide: 'Ẩn Lọc',
                                  toolbarFiltersTooltipShow: 'Hiển thị Lọc',
                                  toolbarFiltersTooltipActive: (count) =>
                                      count !== 1 ? `${count} Bộ lọc kích hoạt` : `${count} Bộ lọc kích hoạt`,

                                  // Export selector toolbar button text
                                  toolbarExport: 'Xuất dữ liệu',
                                  toolbarExportLabel: 'Xuất dữ liệu',
                                  toolbarExportCSV: 'Tải xuống định dạng CSV',
                                  toolbarExportPrint: 'In',

                                  // Columns panel text
                                  columnsPanelTextFieldLabel: 'Tìm kiếm cột',
                                  columnsPanelTextFieldPlaceholder: 'Tên cột',
                                  columnsPanelDragIconLabel: 'Reorder column',
                                  columnsPanelShowAllButton: 'Hiển thị tất cả',
                                  columnsPanelHideAllButton: 'Ẩn tất cả',

                                  // Filter panel text
                                  filterPanelAddFilter: 'Thêm lọc',
                                  filterPanelDeleteIconLabel: 'Xóa',
                                  filterPanelOperators: 'Lọc theo:',
                                  filterPanelOperatorAnd: 'Và',
                                  filterPanelOperatorOr: 'Hoặc',
                                  filterPanelColumns: 'Cột',
                                  filterPanelInputLabel: 'Giá trị',
                                  filterPanelInputPlaceholder: 'Giá trị lọc',

                                  // Filter operators text
                                  filterOperatorContains: 'chứa',
                                  filterOperatorEquals: 'bằng',
                                  filterOperatorStartsWith: 'bắt đầu với',
                                  filterOperatorEndsWith: 'kết thúc với',
                                  filterOperatorIs: 'is',
                                  filterOperatorNot: 'is not',
                                  filterOperatorAfter: 'is after',
                                  filterOperatorOnOrAfter: 'is on or after',
                                  filterOperatorBefore: 'is before',
                                  filterOperatorOnOrBefore: 'is on or before',
                                  filterOperatorIsEmpty: 'giá trị rỗng',
                                  filterOperatorIsNotEmpty: 'giá trị không rỗng',

                                  // Filter values text
                                  filterValueAny: 'any',
                                  filterValueTrue: 'true',
                                  filterValueFalse: 'false',

                                  // Column menu text
                                  columnMenuLabel: 'Danh mục',
                                  columnMenuShowColumns: 'Hiển thị cột',
                                  columnMenuFilter: 'Lọc',
                                  columnMenuHideColumn: 'Ẩn',
                                  columnMenuUnsort: 'Chưa sắp xếp',
                                  columnMenuSortAsc: 'Sắp xếp tăng dần',
                                  columnMenuSortDesc: 'Sắp xếp giảm dần',

                                  // Column header text
                                  columnHeaderFiltersTooltipActive: (count) =>
                                      count !== 1 ? `${count} active filters` : `${count} active filter`,
                                  columnHeaderFiltersLabel: 'Hiển thị lọc',
                                  columnHeaderSortIconLabel: 'Sắp xếp',

                                  // Rows selected footer text
                                  footerRowSelected: (count) =>
                                      count !== 1
                                          ? `${count.toLocaleString()} dòng đang chọn`
                                          : `${count.toLocaleString()} dòng đang chọn`,

                                  // Total rows footer text
                                  footerTotalRows: 'Tổng dòng:',

                                  // Total visible rows footer text
                                  footerTotalVisibleRows: (visibleCount, totalCount) =>
                                      `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
                                  // Checkbox selection text
                                  checkboxSelectionHeaderName: 'Checkbox selection',

                                  // Boolean cell text
                                  booleanCellTrueLabel: 'true',
                                  booleanCellFalseLabel: 'false',

                                  // Actions cell more text
                                  actionsCellMore: 'more',

                                  // Tree Data
                                  treeDataGroupingHeaderName: 'Nhóm',
                                  treeDataExpand: 'Hiện danh sách con',
                                  treeDataCollapse: 'Ẩn danh sách con',
                                  TablePagination: 'ok'
                              }
                          }
                          components={{
                              Toolbar: CustomToolbar,
                              title: "Danh sách chấm công"
                          }}
                />

            </div>

        </>
    );
}
export default ListAttendance;
