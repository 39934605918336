import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";

const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const MyLateAndEarlyDelete = (props) => {
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const initDataDelete = props.data
    const [dataDelete, setDataDelete] = useState(initDataDelete)
    const classes = useStyles();
    const [isExpired, setIsExpired] = useState(false)
    const validationSchema = yup.object({});
    const categories = props.categories
    const [count, setCount] = useState(0);
    useEffect(async () => {
        if (count) {


            try {


                // console.log(JSON.stringify(dataUpdate))
                const api = `https://api-dev.hrms.com.vn/api/v1/worktimerequest`
                const result = await axios.post(api, dataDelete, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                    if (res.status === 200) {
                        // window.location.reload();
                        props.onClick()
                    }
                })
                // const imageAvatr = await getImageAvatar(result.image);

            } catch (e) {

            }
        }
    }, [count])
    const handleDelete = (values) => {
        // console.log(JSON.stringify(values));
        const dataNew = {"message": {...{"id": values.id}}, "action": "delete", "type_message": "map"}
        // alert(JSON.stringify({dataNew}, localStorage.getItem("authToken")))
        setDataDelete(dataNew);
        setCount(1)
        // console.log(dataNew)
    }
    const formik = useFormik({
        initialValues: {
            id: props.data.id,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleDelete(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={10} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "200px"}}
                            id="type_request"
                            name="type_request"
                            label="Loại yêu cầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={`${categories.filter(x => x.id === props.data.type_request).map(x => x.name)}`}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "200px"}}
                            id="date_request"
                            name="date_request"
                            label="Ngày yêu cầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.date_request}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            style={{minWidth: "200px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="details"
                            name="details"
                            label="Mô tả chi tiết"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.details}
                            multiline
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "200px"}}
                            id="time_request"
                            name="time_request"
                            label="Thời gian yêu cầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.time_request}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Xóa
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default MyLateAndEarlyDelete;
