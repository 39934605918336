import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default [
  {
    id: 0,
    title: 'Có việc bận',
    allDay: true,
    start: new Date(2022, 21, 1),
    end: new Date(2022, 21, 12)
  },
  {
    id: 1,
    title: 'Bận việc gia đình',
    start: new Date(2022, 3, 7),
    end: new Date(2022, 3, 10)
  },

  {
    id: 2,
    title: 'Nhà rất bận',
    start: new Date(2022, 2, 13, 0, 0, 0),
    end: new Date(2022, 2, 20, 0, 0, 0)
  },

  {
    id: 3,
    title: 'Bận lắm',
    start: new Date(2022, 10, 6, 0, 0, 0),
    end: new Date(2022, 10, 13, 0, 0, 0)
  },

  {
    id: 4,
    title: 'Bận lắm nha',
    start: new Date(2022, 3, 9, 0, 0, 0),
    end: new Date(2022, 3, 9, 10, 0, 0)
  },
  {
    id: 5,
    title: 'Rất là bận đó',
    start: new Date(2022, 3, 11),
    end: new Date(2022, 3, 13),
    desc: 'Bận thở không nổi luôn đó nha'
  },
  {
    id: 6,
    title: 'Bao bận',
    start: new Date(2022, 3, 12, 10, 30, 0, 0),
    end: new Date(2022, 3, 12, 12, 30, 0, 0),
    desc: 'Bận thở không nổi luôn đó nha'
  },
  {
    id: 7,
    title: 'Bao bận',
    start: new Date(2022, 3, 12, 12, 0, 0, 0),
    end: new Date(2022, 3, 12, 13, 0, 0, 0),
    desc: 'Power lunch'
  },
  {
    id: 8,
    title: 'Bao bận',
    start: new Date(2022, 3, 12, 14, 0, 0, 0),
    end: new Date(2022, 3, 12, 15, 0, 0, 0)
  },
  {
    id: 9,
    title: 'Bao bận',
    start: new Date(2022, 3, 12, 17, 0, 0, 0),
    end: new Date(2022, 3, 12, 17, 30, 0, 0),
    desc: 'Most important meal of the day'
  },
  {
    id: 10,
    title: 'Bao bận',
    start: new Date(2022, 3, 12, 20, 0, 0, 0),
    end: new Date(2022, 3, 12, 21, 0, 0, 0)
  },
  {
    id: 11,
    title: 'Bao bận',
    start: new Date(2022, 3, 13, 7, 0, 0),
    end: new Date(2022, 3, 13, 10, 30, 0)
  },
  {
    id: 12,
    title: 'Bao bận',
    start: new Date(2022, 3, 17, 19, 30, 0),
    end: new Date(2022, 3, 18, 2, 0, 0)
  },
  {
    id: 13,
    title: 'Bao bận',
    start: new Date(2022, 3, 20, 19, 30, 0),
    end: new Date(2022, 3, 22, 2, 0, 0)
  },
  {
    id: 14,
    title: 'Bao bận',
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3))
  },
  // {
  //   id: 15,
  //   title: 'Today aaaaaa',
  //   start: new Date(new Date().setHours(new Date().getHours() - 3)),
  //   end: new Date(new Date().setHours(new Date().getHours() + 3))
  // }
];
