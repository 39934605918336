import {useFormik} from 'formik';
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import {CircularProgress} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import FormUpdateUser from "../EditEmployees/FormUpdateUser";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Moment from "react-moment";
import {ActivityStream, Wrapper} from "../index";
import {mockActivity} from "../../utils/mock";
import axios from "axios";
import {RefreshToken} from "../Signin/RefreshToken";

const useStyles = makeStyles(theme => ({
    avatar: {
        display: "inline-flex",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            width: 128,
            height: 128
        },
        [theme.breakpoints.down("xs")]: {
            width: 64,
            height: 64
        },
        marginBottom: theme.spacing(1),
        alignItems: "center"
    },
    tabRoot: {
        textTransform: "initial",
        color: theme.palette.text.primary
    },
    postInput: {
        border: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(3)
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2)
        },
        fontSize: "13px",
        outline: 0,
        backgroundColor: theme.palette.background.paper
    },
    profiles: {
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center"
    }
}));
const maritalStatus = [
    {"id": "married", "name": "Đã kết hôn"},
    {"id": "single", "name": "Độc thân"},
    {"id": "divorced", "name": "Ly hôn"},
    {"id": "widowed", "name": "Góa"},
    {"id": "other", "name": "Khác"}
]
const pay_grades = [{"id": "000000000000000000000000", "name": ""}]
const formatYmd = date => date.toISOString().slice(0, 10);
const FormEmployeesView = (props) => {
    // console.log(props.data);
    // const [openAddEdit, setOpenAddEdit] = useState(openAddEdit.isOpen)
    const [userInfo, setUserInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [supervisor, setSupervisor] = useState('');
    const [token, setToken] = useState(localStorage.getItem("authToken"));
    const [image, setImage] = useState('')
    const [dataAdd, setDataAdd] = useState()
    const classes = useStyles();
    const countries = props.countries
    const departments = props.departments
    const jobDetails = props.jobDetails
    console.log (jobDetails)
    const shiftTimes = props.shiftTimes
    const nationalities = props.nationalities
    const supervisors = props.dataEmployees
    const laborContracts = props.laborContracts
    const shiftTypes = props.shiftTypes

    const init = {
        id : props.data.id,
        country: props.data.country.name,
        approver1: `${props.data.approver1.last_name} ${props.data.approver1.first_name}`,
        approver2: `${props.data.approver2.last_name} ${props.data.approver2.first_name}`,
        approver3: `${props.data.approver3.last_name} ${props.data.approver3.first_name}`,
        department: props.data.department.name,
        nationality: props.data.nationality.name,
        job_title: props.data.job_title.name,
        shift_time: props.data.shift_time.name,
        shift_type: props.data.shift_type.name,
        supervisor: props.data.supervisor,
        employment_status: props.data.employment_status.name,
        marital_status: props.data.marital_status,
        pay_grade: props.data.pay_grade.name,
        address1: props.data.address1,
        address2: props.data.address2,
        birth_day: props.data.birth_day,
        gender: props.data.gender,
        city: props.data.city,
        confirmation_date: props.data.confirmation_date,
        driving_license: props.data.driving_license,
        driving_license_exp_date: props.data.driving_license_exp_date,
        employee_id: props.data.employee_id,
        ethnicity: props.data.ethnicity,
        first_name: props.data.first_name,
        hic_num: props.data.hic_num,
        home_phone: props.data.home_phone,
        image_profile: props.data.image_profile,
        immigration_status: props.data.immigration_status,
        joined_date: props.data.joined_date,
        last_name: props.data.last_name,
        mobile_phone: props.data.mobile_phone,
        nic_num: props.data.nic_num,
        notes: props.data.notes,
        other_id: props.data.other_id,
        postal_code: props.data.postal_code,
        private_email: props.data.private_email,
        province: props.data.province,
        ssn_num: props.data.ssn_num,
        termination_date: props.data.termination_date,
        tic_num: props.data.tic_num,
        work_email: props.data.work_email,
        work_phone: props.data.work_phone,

    }
    console.log(init)
    // useEffect(async () => {
    //     try {
    //         if (init.image_profile) {
    //            const result2 = await axios.get(init.image_profile, {headers: {"Authorization": `Bearer ${token}`}}).then(res2 => {
    //                if(res2.status === 200)
    //                 {
    //                     if(res2.data){
    //                         // let avatarBase64 = btoa(unescape(encodeURIComponent(res2.data)))
    //                         // console.log(avatarBase64)
    //                         setAvatar(res2.data);
    //                     }
    //                     // console.log (res2);
    //                 }
    //                 // if (res2.data.code === 401) {
    //                 //     RefreshToken()
    //                 // } else if (res2.data.message.image_profile) {
    //                 //
    //                 // }
    //             })
    //         }
    //         else setAvatar("");
    //     } catch (e) {
    //     }
    // }, [isExpired])
    const [isExpired, setIsExpired] = useState(false)
    const [tab, setTab] = useState(0);
    const TabContainer = props => (
        <Typography component="div" className="pa-0">
            {props.children}
        </Typography>
    );
    const handleTabToggle = (event, tab) => setTab(tab);
    return (

        <Wrapper>
            {loading &&
                // <p>It's loading...</p>
                <center>
                    <CircularProgress/>
                </center>
            }
            {
                !loading &&
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={5}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3} className={classes.profiles}>
                                    <Grid item xs={7} className={classes.profiles}>
                                        <Avatar
                                            alt ={`${init.id}`}
                                            src={`${init.image_profile}`}
                                            className={classNames(classes.avatar)}
                                        />
                                        <br/>
                                        <Typography>

                                        </Typography>
                                        <Typography variant="h6" gutterBottom>
                                            <b>{init.last_name} {init.first_name}</b>
                                            <br/>
                                        </Typography>
                                        {/*<br>*/}
                                        <Typography variant="caption" gutterBottom>
                                            {init.work_phone}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {init.job_title}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {init.work_email}
                                        </Typography>
                                        {/*<Typography variant="body1" gutterBottom>*/}
                                        {/*  <Link to="http://qi.com.vn">Website: https://qi.com.vn</Link>*/}
                                        {/*</Typography>*/}
                                        {/*<Button variant="contained" color="primary"  onClick={() => {editUser()}}>*/}
                                        {/*    Sửa thông tin cá nhân*/}
                                        {/*</Button>*/}
                                        <FormUpdateUser
                                            tittle="Sửa thông tin cá nhân"
                                        >
                                        </FormUpdateUser>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <div className="text-xs-center">

                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent className="text-xs-center">
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" gutterBottom>
                                            10
                                        </Typography>
                                        <Typography variant="caption">Ngày phép khả dụng</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" gutterBottom>
                                            2
                                        </Typography>
                                        <Typography variant="caption">Ngày phép đã sử dụng</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" gutterBottom>
                                            14
                                        </Typography>
                                        <Typography variant="caption">Ngày công hiện tại</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>Mô tả công việc: </b>
                                </Typography>
                                <Typography>
                                    {jobDetails.filter(x => x.name === init.job_title).map(x => x.description)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={7}>


                        <Card>
                            <Tabs value={tab} onChange={handleTabToggle}>
                                <Tab label="Tiểu sử" classes={{root: classes.tabRoot}}/>
                                <Tab label="Quá trình làm việc" classes={{root: classes.tabRoot}}/>
                                <Tab label="Chấm công" classes={{root: classes.tabRoot}}/>
                            </Tabs>
                            <Divider/>

                            {tab === 0 && (
                                <TabContainer>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Thông tin cơ bản
                                        </Typography>
                                        <Grid
                                            container
                                            spacing={3}
                                            // alignItems="flex-start"
                                            direction="row"
                                            justify="space-between"
                                        >
                                            <Grid item>
                                                <div className="mb-1">
                                                    <Typography variant="caption" gutterBottom>
                                                        SĐT:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="font-weight-bold"
                                                    >
                                                        {init.work_phone}
                                                    </Typography>
                                                </div>

                                                <div className="mb-1">
                                                    <Typography variant="caption" gutterBottom>
                                                        Ngày tháng năm sinh:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="font-weight-bold"
                                                    >
                                                        <Moment format="DD/MM/YYYY">{init.birth_day}</Moment>
                                                    </Typography>
                                                </div>

                                                <div className="mb-1">
                                                    <Typography variant="caption" gutterBottom>
                                                        Quốc gia
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="font-weight-bold"
                                                    >
                                                        {init.nationality}
                                                    </Typography>
                                                </div>

                                                <div>
                                                    <Typography variant="caption" gutterBottom>
                                                        Chức danh:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="font-weight-bold"
                                                    >
                                                        {init.job_title}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="mb-1">
                                                    <Typography variant="caption" gutterBottom>
                                                        Extension
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="font-weight-bold"
                                                    >
                                                        94
                                                    </Typography>
                                                </div>

                                                <div className="mb-1">
                                                    <Typography variant="caption" gutterBottom>
                                                        SĐT Cá Nhân:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="font-weight-bold"
                                                    >
                                                        {init.mobile_phone}
                                                    </Typography>
                                                </div>

                                                <div className="mb-1">
                                                    <Typography variant="caption" gutterBottom>
                                                        Tình trạng hôn nhân:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="font-weight-bold"
                                                    >
                                                        {maritalStatus.filter(x => x.id === init.marital_status).map(x => x.name)}
                                                    </Typography>
                                                </div>

                                                <div>
                                                    <Typography variant="caption" gutterBottom>
                                                        Người giám sát trực tiếp:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        className="font-weight-bold"
                                                    >
                                                        {(init.supervisor) ? `${init.supervisor.first_name} ${init.supervisor.last_name}` :""}
                                                        {/*<p>{userInfo.supervisor_name.first_name}`</p>*/}
                                                        {/*{userInfo.supervisor_name.first_name} {userInfo.supervisor_name.last_name}*/}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider/>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Số người phụ thuộc : 2
                                        </Typography>
                                        <Grid
                                            container
                                            spacing={3}
                                            // alignItems="flex-start"
                                            direction="row"
                                            justify="space-between"
                                        >
                                            <Grid item>
                                                <div className="mb-1">
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            className="font-weight-bold"
                                                        >
                                                            Họ và tên:
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="mb-1">
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            className="font-weight-bold"
                                                        >
                                                            Quan hệ với NLĐ:
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="mb-1">
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            className="font-weight-bold"
                                                        >
                                                            Số CMND/CCCD:
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={3}
                                            // alignItems="flex-start"
                                            direction="row"
                                            justify="space-between"
                                        >
                                            <Grid item
                                                // alignItems="center"
                                            >
                                                <div className="mb-1">
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            className="font-weight-bold"
                                                        >
                                                            NGUYỄN THỊ MAI A
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="mb-1">
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            className="font-weight-bold"
                                                        >
                                                            Mẹ
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="mb-1">
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            className="font-weight-bold"
                                                        >
                                                            123456789
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={3}
                                            // alignItems="flex-start"
                                            direction="row"
                                            justify="space-between"
                                        >
                                            <Grid item
                                                // alignItems="center"
                                            >
                                                <div className="mb-1">
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            className="font-weight-bold"
                                                        >
                                                            BÙI VĂN B
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="mb-1">
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            className="font-weight-bold"
                                                        >
                                                            Bố
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div className="mb-1">
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            className="font-weight-bold"
                                                        >
                                                            123456789
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </TabContainer>
                            )}
                            {tab === 1 && (
                                <TabContainer>
                                    <CardContent className="pb-0">
                                        <ActivityStream
                                            title="NĂM 2020"
                                            stream={mockActivity}
                                        />
                                        <ActivityStream
                                            title="NĂM 2019"
                                            stream={mockActivity}
                                        />
                                    </CardContent>
                                </TabContainer>
                            )}
                            {tab === 2 && (
                                <TabContainer>
                                    {/*<GridList cols={3} spacing={1} cellHeight={180}>*/}
                                    {/*    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(tile => (*/}
                                    {/*        <GridListTile key={tile}>*/}
                                    {/*            <img*/}
                                    {/*                src={`${process.env.PUBLIC_URL}/static/images/unsplash/${tile}.jpg`}*/}
                                    {/*                alt={tile}*/}
                                    {/*            />*/}
                                    {/*            <GridListTileBar*/}
                                    {/*                title={tile}*/}
                                    {/*                subtitle={<span>Gallery image: {tile}</span>}*/}
                                    {/*                actionIcon={*/}
                                    {/*                    <IconButton>*/}
                                    {/*                        <FavoriteIcon/>*/}
                                    {/*                    </IconButton>*/}
                                    {/*                }*/}
                                    {/*            />*/}
                                    {/*        </GridListTile>*/}
                                    {/*    ))}*/}
                                    {/*</GridList>*/}
                                </TabContainer>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            }
        </Wrapper>

    )

}
export default FormEmployeesView;
