import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import 'moment-timezone';
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));


const FormEmployeesCertificateView = (props) => {
    const classes = useStyles();
    const [data, setData] = useState(props.data)
    // if(!data){
    //   setData([{"id":"","name":"", "desscription":""}])
    // }
    return (
        <>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth:"400px"}}
                            id="name"
                            name="name"
                            label="Loại chứng chỉ"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={data.name}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth:"400px"}}
                            id="description"
                            name="description"
                            label="Mô Tả"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={data.description}
                            disabled
                        />
                    </Grid>
                </Grid>

            </Grid>
        </>
    )

}
export default FormEmployeesCertificateView;
