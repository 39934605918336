import dayjs from "dayjs";

const duration = require('dayjs/plugin/duration');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
import durationPlugin from 'dayjs/plugin/duration';
import axios from "axios";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";

dayjs.extend(durationPlugin);

export function RefreshToken() {
    const refresh = localStorage.getItem("refreshToken");
    if (refresh && refresh !=="") {
        let url = `https://api-dev.hrms.com.vn/api/v1/refeshtoken?refresh_token=${localStorage.getItem("refreshToken")}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        useEffect( () => {
            try {
                console.log(`RefreshToken`);
                const temp2 = axios.get(url, {headers: headers, dataType: "json"}).then(result => {
                    console.log(result)
                    if (result.status === 200) {
                        localStorage.removeItem("authToken");
                        localStorage.removeItem("refreshToken");
                        localStorage.setItem("authToken", result.data.message.access_token);
                        localStorage.setItem("refreshToken", result.data.message.refresh_token);

                    }
                    // else if (result.status === 401 || result.status === 400){
                    //     console.log(`Failed RefreshToken`);
                    //     window.location.href = "/";
                    //     // setIsError(true);
                    // }
                    else {
                        window.location.href = "/";
                        // setIsError(true);
                    }
                })


            } catch (e) {
            }
        })
    } else {
        localStorage.clear();
        window.location.href = "/";
    }
    // return localStorage.getItem("authToken");
}


