const lightBlue = require('@material-ui/core/colors/lightBlue');
const yellow = require('@material-ui/core/colors/yellow');

// Colors
const infoColor = lightBlue.default[500];
const warningColor = yellow.default[500];

// Spacing
const drawerMiniWidth = 80;
const drawerMiniIcon = 80;
const drawerWidth = 240;
const drawerWidthIcon = 60;
const notificationCenterWidth = 300;
const MobileBreakpoint = 960;

export {
  infoColor,
  warningColor,
  drawerWidth,
  drawerMiniWidth,
  drawerWidthIcon,
  drawerMiniIcon,
  notificationCenterWidth,
  MobileBreakpoint
};
