

import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
import CustomizedTimeline from "../../../Timeline/CustomizedTimeline";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));


const MyLateAndEarlyView = (props) => {
    const classes = useStyles();
    const arrStatus = props.arrStatus
    const categories = props.categories

    return (
        <>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="category"
                            name="category"
                            label="Loại yêu cầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={`${categories.filter(x => x.id === props.data.type_request).map(x => x.name)}`}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="date_request"
                            name="date_request"
                            label="Ngày yêu cầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.date_request}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <TextField
                            style={{minWidth: "250px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="details"
                            name="details"
                            label="Mô tả chi tiết"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.details}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            style={{minWidth: "250px"}}
                            id="time_request"
                            name="time_request"
                            label="Thời gian yêu cầu"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={props.data.time_request}
                            disabled
                        />
                    </Grid>

                </Grid>
                <CustomizedTimeline data={props.data.log_change_status} arrStatus={arrStatus}/>
                <br/>
            </Grid>
        </>
    )
}
export default MyLateAndEarlyView;



