import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import 'moment-timezone';
import {Card, MenuItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ColorButtonAdd, ColorButtonCancel, ColorButtonHoverAdd, ColorTextButton} from "../../MainStyleCSS/ColorHeader";
import Box from "@mui/material/Box";
import CardContent from "@material-ui/core/CardContent";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    PackageBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "75px",
        height: "75px"
    },

    labelInput: {
        maxWidth: '150px', display: "flex", justifyContent: 'flex-end', marginRight: '10px'
    },
    ButtonAdd: {
        background: ColorButtonAdd,
        color: ColorTextButton,
        '&:hover': {
            background: ColorButtonHoverAdd
        },
    },
    ButtonCancel: {
        backgroundColor: ColorButtonCancel,
        '&:hover': {
            background: ColorButtonHoverAdd,
            color: ColorTextButton
        },
    },

    ButtonAction: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));
const FormDetailsPackageView = (props) => {
    const classes = useStyles();
    const data = props.data;
    const modelNames = props.data3
    console.log(data.pack_info)
    console.log(modelNames)
    return (
        <>
            <form style={{width: "100%"}}>
                <Grid container spacing={2}
                      alignItems="center"
                      justifyContent="center">
                    {(data.pack_info ?
                        data.pack_info.map((x) => (
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Grid item>

                                    <Card style={{
                                        background: "white",
                                        width: "100%",
                                        height: "250px",
                                    }}>
                                        <Grid container spacing={0}
                                              style={{display: 'flex', justifyContent: 'center', height: "175px"}}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <div style={{
                                                    marginTop: "10px",
                                                    fontSize: "12px",
                                                    textTransform: "uppercase",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontWeight: "400",
                                                    fontFamily: "Roboto"
                                                }}>
                                                    mô đun:
                                                </div>
                                                <div style={{
                                                    color: "#EC9B3B",
                                                    fontSize: "24px",
                                                    fontWeight: "bold",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "10px"
                                                }}>
                                                    {modelNames.filter(y => y.id === x.model_name).map(y => y.name)}
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "5px"
                                                }}>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/static/images/PackageBox.png`}
                                                        alt="package"
                                                        className={classes.PackageBox}
                                                    />
                                                </div>

                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: "75px",
                                            background: "#EC9B3B",
                                            color: "white"
                                        }}>
                                            <Grid item xs={4} style={{
                                                borderRight: "1px solid #BD7C2F",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontFamily: " Roboto, Helvetica, Arial, sans-serif;"
                                            }}>
                                                <div style={{
                                                    position: "relative",
                                                    fontSize: "24px",
                                                    marginBottom: "5px",
                                                    fontWeight: "bold"
                                                }}>{x.limit}</div>
                                                <div style={{
                                                    textTransform: "uppercase",
                                                    fontSize: "12px",
                                                    fontWeight: 400
                                                }}>Giới hạn
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} style={{
                                                borderRight: "1px solid #BD7C2F",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                fontFamily: " Roboto, Helvetica, Arial, sans-serif;"
                                            }}>
                                                <div style={{
                                                    position: "relative",
                                                    fontSize: "24px",
                                                    marginBottom: "5px",
                                                    fontWeight: "bold"
                                                }}>{x.calculation_type}</div>
                                                <div style={{
                                                    textTransform: "uppercase",
                                                    fontSize: "12px",
                                                    fontWeight: 400
                                                }}>Đơn vị tính
                                                </div>
                                            </Grid>

                                            {/*<Grid item xs={4} style={{borderRight: "1px solid #BD7C2F", display: "flex", flexDirection:"column", alignItems: "center", justifyContent: "center"}}>*/}
                                            {/*    <div style={{position:"relative",fontSize:"24px",marginBottom:"5px", fontWeight:"bold"}}> 20</div>*/}
                                            {/*    <div style={{textTransform:"uppercase",fontSize:"12px",fontWeight:400}}>Test</div>*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        ))

                        : <div>Không có gói</div>)


                    }
                </Grid>


                <Grid container spacing={4} className={classes.ButtonAction}>
                    <Grid item>
                        <Button className={classes.ButtonCancel} variant="contained" onClick={props.onClick}>
                            Hủy
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}
export default FormDetailsPackageView;
