import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CardActionArea, CardMedia } from '@material-ui/core';
import {Link} from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
const useStyles = makeStyles(theme => ({
    content: {
        //position: 'relative',
        // padding: theme.spacing(1) * 2,
        // '&:last-child': {
        //     paddingBottom: theme.spacing(1) * 2
        // },
        // height: theme.spacing(1) * 18,
        // opacity: "1",
        // backgroundImage: "url(" + `${process.env.PUBLIC_URL}/static/images/backgroundHR.jpg` + ")",
        // backgroundPosition: "center",
        // backgroundSize:"500px",
        // borderRadius: "30px",
        // width:'100%',
        //  backgroundRepeat: "no-repeat"


    },
    icon: {
        display: "inline-block",
        width:"100%",
        // maxWidth:"50%",
        padding:"10%",
        '&:after': {
            content: "",
            display: "block",
            paddingBottom: "100%",
            }
    },
    containerIcon: {
        display: 'flex',
        // flexWrap: 'wrap',
        //borderRadius: '50px !important',
        // backgroundColor: 'blue'
        alignItems: "center",
        justifyContent: 'center',
        //backgroundColor:"#ffffff",
        background: 'linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%)',
        borderRadius:"15%",
        height: theme.spacing(1) * 17,
        width: theme.spacing(1) * 17,
        margin: '0 auto',
        opacity:1,
        boxShadow: theme.shadows[10],
        border: "none",
        '&:after': {
            content: "",
            position: "absolute",
            transition: "all 0.3s ease 0s",
            zIndex: -1,
            display: "inline-block",
            paddingBottom: "100%",
        },
        '&:hover' :{
            background: 'linear-gradient(-225deg, #FAD961 0%, #F7A21C 48%, #F48909 100%)',
            transform: "translateY(10px)"
        },
        '&:active' :{
            marginBot: -5,
            boxShadow: theme.shadows[1],
            background:"rgba(0,0,0,0.2)"
        }
    },
    containerIcon2: {
        display: 'flex',
        // flexWrap: 'wrap',
        //borderRadius: '50px !important',
        // backgroundColor: 'blue'
        alignItems: "center",
        justifyContent: 'center',
        //backgroundColor:"#ffffff",
        background: 'linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%)',
        borderRadius:"15%",
        height: theme.spacing(1) * 10,
        width: theme.spacing(1) * 10,
        margin: '0 auto',
        opacity:1,
        boxShadow: theme.shadows[10],
        border: "none",
        '&:after': {
            content: "",
            position: "absolute",
            transition: "all 0.3s ease 0s",
            zIndex: -1,
            display: "inline-block",
            paddingBottom: "100%",
        },
        '&:hover' :{
            background: 'linear-gradient(-225deg, #FAD961 0%, #F7A21C 48%, #F48909 100%)',
            transform: "translateY(10px)"
        },
        '&:active' :{
            marginBot: -5,
            boxShadow: theme.shadows[1],
            background:"rgba(0,0,0,0.2)"
        }
    },
    lightText: {
        color: 'white'
    },
    title: {
        boxSizing:'body-box',
        textAlign : 'center',
        fontFamily: 'roboto',
        flexWrap: 'wrap',
        marginTop: '9%',
        marginBottom: '10%',
        fontSize: '120%',
        fontWeight: 'normal',
        wordWrap: 'break-word',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    }


}));

const ItemMenu = ({ data }) => {

    const classes = useStyles();
    let before = null;
    let after = null;

    const ButtonMenu = () => (
        <Route  render={({ history}) => (
                   <button className={classes.containerIcon}
                           onClick={() => { history.push(`${data.link}`) }}
                   >
                       <div className={classes.icon}>
                           <img src={data.icon}/>
                       </div>

                   </button>
               )}

        />

        // <Route render={({ history}) => (
        //     <button className={classes.containerIcon}
        //         onClick={() => { history.push(`${data.link}`) }}
        //     >
        //         <div className={classes.icon}>
        //             <img src={data.icon}/>
        //         </div>
        //
        //     </button>
        // )} />
    )


    return (
        <>
        <Card style={{opacity:1,boxShadow:"none",backgroundColor:"transparent",whiteSpace:"pre-wrap"}}  >
           <ButtonMenu/>
            {/*<CardContent >*/}
                <Typography component={'span'} variant={'body2'}>
                    <div className={classes.title} >{data.title}</div>
                </Typography>
            {/*</CardContent>*/}
        </Card>
        {/* <Typography gutterBottom variant="h5" component="h2" align='center'>
                    {data.name}
                </Typography> */}
        </>
    );
};
ItemMenu.propTypes = {
    icon:PropTypes.element,
    name:PropTypes.string,
    link:PropTypes.string,
    permission:PropTypes.string
}
export default ItemMenu;
