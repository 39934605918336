import React, {useEffect, useState} from 'react'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from "react-dropzone-uploader";

type Position = {
    x: number;
    y: number;
};

// color: "white", fontSize: "1.75rem",
//     fontFamily: "Roboto, Helvetica, Arial, sans-serif",
//     fontWeight: 400,
//     lineHeight: 1.66,
//     letterSpacing: "0.03333em"
import Slider from "@material-ui/core/Slider";
import {useDropzone} from "react-dropzone";
import Input from "../../components/UploadFile/Input";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    text: {

        color: 'inherit', fontSize: "0.95rem",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: "0.03333em"
    },

}));
// function dataURItoBlob(dataURI) {
//     // convert base64/URLEncoded data component to raw binary data held in a string
//     let byteString;
//     if (dataURI.split(',')[0].indexOf('base64') >= 0)
//         byteString = atob(dataURI.split(',')[1]);
//     else
//         byteString = unescape(dataURI.split(',')[1]);
//
//     // separate out the mime component
//     const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
//
//     // write the bytes of the string to a typed array
//     let ia = new Uint8Array(byteString.length);
//     for (let i = 0; i < byteString.length; i++) {
//         ia[i] = byteString.charCodeAt(i);
//     }
//
//     return new Blob([ia], {type:mimeString});
// }
const AvatarEmployee = ({getAvatar, dataAdd, setAvatar}) => {
    const classes = useStyles();
    const [avatarPreview, setAvatarPreview] = React.useState(null);
    const [avatarScale, setAvatarScale] = React.useState(1);
    const [avatarPosition, setAvatarPosition] = React.useState({
        x: 0.5,
        y: 0.5
    });
    const [avatarImage, setAvatarImage] = React.useState("");
    const editorRef = React.useRef(null);

    const onDrop = React.useCallback(acceptedFiles => {
        // Do something with the files
        setAvatarImage(acceptedFiles[0]);
    }, []);
    const {getRootProps, getInputProps, open, isDragActive} = useDropzone({
        onDrop,
        noClick: true,
    });

    const handleScale = (_, newValue) => {
        setAvatarScale(newValue);
    };

    const handleSave = () => {
        const editor = editorRef.current;
        const img = editor.getImageScaledToCanvas().toDataURL();
        const rect = editor.getCroppingRect();
        setAvatarPreview({
            img,
            rect,
            scale: avatarScale,
            width: 250,
            height: 350,
            borderRadius: 0
        });
        console.log(avatarPreview)
    };
    useEffect(() => {
        setAvatar(avatarPreview)
        // if(avatarPreview){
        //     // const dataURL = avatarPreview.img.toDataURL('image/jpeg', 0.5);
        //     const blob = dataURItoBlob(avatarPreview.img);
        //     const fd = new FormData(document.forms[0]);
        //     console.log(fd)
        //     fd.append("file", blob);
        //     setAvatar(fd)
        // }
    },[avatarPreview])
    return (
        <div style={{width: 1200, height: 650, align: 'center'}}>
            <div {...getRootProps()}>

                <input {...getInputProps()} />
                {isDragActive ? (
                    <p className={classes.text}>Kéo và thả ảnh đại diện vào đây</p>
                ) : (
                    <p className={classes.text}>Kéo và thả ảnh đại diện vào khung này</p>
                )}

                <AvatarEditor
                    ref={editorRef}
                    scale={avatarScale}
                    width={400}
                    height={300}
                    border={[250, 100]}
                    position={avatarPosition}
                    onPositionChange={setAvatarPosition}
                    // onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
                    // onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
                    // onImageReady={this.logCallback.bind(this, "onImageReady")}
                    image={avatarImage}
                    className="editor-canvas"

                >
                </AvatarEditor>
                <div>
                    <button type="button" onClick={open} className={classes.text}>
                        + Chọn ảnh từ thiết bị
                    </button>
                </div>

            </div>
            <br/>
            <div className={classes.text}>Kích thước thu phóng: {Math.floor(avatarScale * 100)}%</div>
            <Slider
                min={0.5}
                max={2}
                step={0.01}
                defaultValue={1}
                value={avatarScale}
                onChange={handleScale}
                aria-labelledby="continuous-slider  "
            />
            {/*<input type="button" onClick={handleSave} value="HOÀN TẤT CHỌN ẢNH" style={{display: 'flex', flexDirection: 'rows', alignItems: 'center'}}/>*/}

            <Button color="primary" variant="contained" type="button" onClick={handleSave} value="HOÀN TẤT CHỌN ẢNH" style={{display: 'flex', flexDirection: 'rows', alignItems: 'center'}}>
                HOÀN TẤT CHỌN ẢNH
            </Button>

        </div>
    );
}
export default AvatarEmployee;
