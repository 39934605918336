import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import 'moment-timezone';
import {MenuItem} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ColorButtonAdd, ColorButtonCancel, ColorButtonHoverAdd, ColorTextButton} from "../../MainStyleCSS/ColorHeader";
import AddServicePackageInfo from "./AddServicePackageInfo";
import UpdateServicePackageInfo from "./UpdateServicePackageInfo";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    labelInput: {
        maxWidth: '100%', display: "flex", justifyContent: 'flex-end', marginRight: '10px'
    },
    ButtonAdd: {
        background: ColorButtonAdd,
        color: ColorTextButton,
        '&:hover': {
            background: ColorButtonHoverAdd
        },
    },
    ButtonCancel: {
        backgroundColor: ColorButtonCancel,
        '&:hover': {
            background: ColorButtonHoverAdd,
            color: ColorTextButton
        },
    },

    ButtonAction: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minWidth: "250px"
    },
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    },
    form:{
        width: "100%"
    }
}));
yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true;
        }

        if (
            this.parent
                .filter(v => v !== value)
                .some(v => v[propertyName] === value[propertyName])
        ) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            });
        }

        return true;
    });
});

const arrPermissions = [{"id":"61b9a478f799e2427113651c", "name":"System Admin"}]
const FormServicePackageUpdate = (props) => {
    const initvalueSelect = {
        name: props.data.name,
        service_type: props.data.service_type,
    }
    const [count, setCount] = useState(0)
    const [dataUpdate, setDataUpdate] = useState()
    const classes = useStyles();
    const [dataPackageInfoReturn, setDataPackageInfoReturn] = useState(props.data.pack_info)
    const [checkErrorPackInfo, setCheckErrorPackInfo] = useState(0)
    const [statusPackageInfo, setStatusPackageInfo] = useState(0)
    const types = props.data2
    const validationSchema = yup.object().shape({
        name: Yup.string().required('Dữ liệu bắt buộc nhập'),
        service_type: Yup.string().required('Dữ liệu bắt buộc chọn')
    });

    useEffect(async () => {
        if(count) {
            try {
                // console.log(JSON.stringify(dataUpdate))
                const api = `https://api-dev.hrms.com.vn/api/v1/servicepack`
                const result = await axios.post(api, dataUpdate, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                    if (res.status === 200) {
                        // window.location.reload();
                        props.onClick()
                    }
                })
                // const imageAvatr = await getImageAvatar(result.image);

            } catch (e) {

            }
        }
    }, [count])
    const handleUpdate = (dataSubmit) => {
        const temp = {...dataSubmit}
        temp.pack_info = dataPackageInfoReturn.map(({id,...rest}) => ({...rest}));
        temp.id = props.data.id
        const dataNew = {"message": temp, "action": "update", "type_message": "map"}
        console.log(JSON.stringify(dataNew))
        setDataUpdate(dataNew)
        setCount(count+1)
    }
    const formik = useFormik({
        initialValues: {
            name: props.data.name,
            service_type: props.data.service_type,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleUpdate(values);
        },
    });
    return (
        <form onSubmit={formik.handleSubmit} className={classes.form}>
            <Grid container spacing={3} style={{display: 'flex', flexDirection: 'column'}}>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4} style={{width: "100px"}}>
                        <Typography className={classes.labelInput}> <span style={{color: 'red'}}>*</span>Tên dịch vụ:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}
                          style={{minWidth: "300px", display: "flex", justifyContent: "center"}}>
                        <TextField
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            id="name"
                            name="name"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            multiline
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.row}>
                    <Grid item xs={12} sm={12} md={4} lg={4} style={{width: "100px"}}>
                        <Typography className={classes.labelInput}> Loại dịch vụ: </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}
                          style={{minWidth: "300px", display: "flex", justifyContent: "center"}}>
                        <TextField
                            style={{minWidth: "250px"}}
                            hiddenLabel
                            className={classes.field}
                            margin="normal"
                            variant="outlined"
                            select
                            name="service_type"
                            value={formik.values.service_type}
                            required
                            onChange={formik.handleChange}
                            error={formik.touched.service_type && Boolean(formik.errors.service_type)}
                            helperText={formik.touched.service_type && formik.errors.service_type}
                            onBlur={formik.handleBlur}
                        >
                            {props.data2?.map((arr, index) => {
                                return (
                                    <MenuItem value={arr.id} key={index}>{arr.name}</MenuItem>
                                )
                            })}
                        </TextField>
                        {/*<TextField*/}
                        {/*    id="service_type"*/}
                        {/*    name="service_type"*/}
                        {/*    style={{minWidth: "250px"}}*/}
                        {/*    hiddenLabel*/}
                        {/*    select*/}
                        {/*    className={classes.textField}*/}
                        {/*    margin="normal"*/}
                        {/*    variant="outlined"*/}
                        {/*    value={values.service_type}*/}
                        {/*    onChange={handleInputChange}*/}
                        {/*    error={formik.touched.service_type && Boolean(formik.errors.service_type)}*/}
                        {/*    helpertext={formik.touched.service_type && formik.errors.service_type}*/}
                        {/*    multiline*/}
                        {/*>*/}
                        {/*    {props.data2.map((x) => (*/}
                        {/*        <MenuItem key={x.id} value={x.id}>*/}
                        {/*            {x.name}*/}
                        {/*        </MenuItem>*/}
                        {/*    ))}*/}
                        {/*</TextField>*/}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
                    <UpdateServicePackageInfo props={props} setDataPackgeInfoReturn={setDataPackageInfoReturn}
                                           setStatusPackageInfo={setStatusPackageInfo}
                                           checkErrorPackInfo={checkErrorPackInfo}
                    />

                </Grid>
            </Grid>
            <Grid container spacing={4} className={classes.ButtonAction}>

                {(statusPackageInfo && Object.keys(dataPackageInfoReturn).length !== 0) ?
                    <Grid item>
                        <Button className={classes.ButtonAdd} variant="contained" type="submit">
                            Cập nhật
                        </Button>
                    </Grid>
                    :
                    <Grid item>
                        <Button className={classes.ButtonAdd} variant="contained" disabled>
                            Cập nhật
                        </Button>
                    </Grid>

                }

                <Grid item>
                    <Button className={classes.ButtonCancel} variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
            </Grid>
        </form>
    )

}
export default FormServicePackageUpdate;
