import React from 'react'
import { Button as MuiButton, makeStyles } from "@material-ui/core";
import styled from "@emotion/styled";


const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5),
        backgroundColor: '#3c52b2',

        // color: '#fff',
        '&:hover': {
            backgroundColor: 'orange',
            borderColor: 'rgba(0,0,0,0.2)',
            // color: 'black',
        }
    },
    label: {
        textTransform: 'none',
        color: "white"
    },
}))
export default function Button(props) {

    const { text, size, color, variant, onClick, ...other } = props
    const classes = useStyles();

    return (
        <MuiButton
            variant={variant || "contained"}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            {...other}
            classes={{ root: classes.root, label: classes.label }}>
            {text}
        </MuiButton>
    )
}
