import {Wrapper} from "../components";
// import {Helmet} from "react-helmet";
// import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
// import PropTypes from "prop-types";
import ItemMenu from "../components/ItemMenu";
import HeaderDashboardMenu from "../components/Header/HeaderDashboardMenu";
import clock from '../img/icons/clock.svg';
import userCircle from "../img/icons/user-circle.svg";
import laptopHouses from '../img/icons/laptop-house.svg';
import calendarTimes from '../img/icons/calendar-times.svg';
import city from '../img/icons/city.svg';
import cogs from '../img/icons/cogs.svg';
import fileCode from '../img/icons/file-code.svg';
import planeDeparture from '../img/icons/plane-departure.svg';
import userSecret from '../img/icons/user-secret.svg';
import users from '../img/icons/users.svg';
import {RefreshToken} from "../components/Signin/RefreshToken";

let id = 0;

function createData(name, date, progress) {
    id += 1;
    return {id, name, date, progress};
}

const bgMenu = {
    // backgroundImage: "url(" + `${process.env.PUBLIC_URL}/static/images/bghrOpacity.png` + ")",
    backgroundColor: "transparent",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%"
}
const body = {
    height: "100%",
    backgroundColor: "red"
}

const DashboardMenu = ({history}) => {
    if (localStorage.getItem("authToken") == null) {
        RefreshToken()
    }
        // const handleDrawerToggle = () => {
        //     setOpened(!opened);
        //     resizeDispatch();
    // };
    else {
        // const checkExpiredToken = CheckTokenExpired();
        const dataItem = [
            // {
            //     id: 1,
            //     title: 'Chấm công',
            //     icon: clock,
            //     // icon: <img src={
            //     //     `${process.env.PUBLIC_URL}/static/images/icon/clock.svg`
            //     // } width={"100px"}/>,
            //     link: "/antendance/listAttendance",
            //     //link: "google.com"
            //     style: 'classname = classes.containerIcon',
            // },
            {
                id: 1,
                title: 'PX1',
                icon: userCircle,
                link: "employees",
                style: 'classname = classes.containerIcon',
            },
            // {
            //     id: 8,
            //     title: 'Hồ sơ nhân viên',
            //     icon: users,
            //     link: "employees",
            //     style: 'classname = classes.containerIcon',
            // },
            // {
            //     id: 3,
            //     title: 'Báo cáo người dùng',
            //     icon: listAlt,
            //     link: "DashboardMenu",
            //     style: 'classname = classes.containerIcon',
            // },
            // {
            //     id: 4,
            //     title: 'Lương',
            //     icon: donate,
            //     link: "DashboardMenu",
            //     style: 'classname = classes.containerIcon',
            // },
            // {
            //     id: 5,
            //     title: 'Làm việc tại nhà',
            //     icon: laptopHouses,
            //     link: "DashboardMenu",
            //     style: 'classname = classes.containerIcon',
            // },

            // {
            //     id: 6,
            //     title: 'Thảo luận',
            //     icon: weixin,
            //     link: "DashboardMenu",
            //     style: 'classname = classes.containerIcon',
            // },
            // {
            //     id: 7,
            //     title: 'Nghĩ phép',
            //     icon: calendarTimes,
            //     link: "DashboardMenu",
            //     style: 'classname = classes.containerIcon',
            // },
            // {
            //     id: 9,
            //     title: 'Tuyển dụng',
            //     icon: handsHelping,
            //     link: "DashboardMenu",
            //     style: 'classname = classes.containerIcon',
            // },

            // {
            //     id: 10,
            //     title: 'Hồ sơ nhân viên',
            //     icon: fileAlt,
            //     link: "DashboardMenu",
            //     style: 'classname = classes.containerIcon',
            // },
            // {
            //     id: 11,
            //     title: 'Cấu trúc công ty',
            //     icon: city,
            //     link: "DashboardMenu",
            //     style: 'classname = classes.containerIcon',
            // },
            // {
            //     id: 12,
            //     title: 'Đào tạo',
            //     icon: userGraduated,
            //     style: 'classname = classes.containerIcon',
            //     link: "DashboardMenu",
            // },
            // {
            //     id: 13,
            //     title: 'Hiệu năng',
            //     icon: atom,
            //     style:'classname = classes.containerIcon',
            //     link: "DashboardMenu",
            // },
            // {
            //     id: 14,
            //     title: 'Quản lý công tác',
            //     icon: planeDeparture,
            //     style: 'classname = classes.containerIcon',
            //     link: "travel/TravelRequest"
            // },
            // {
            //     id: 15,
            //     title: 'Tài chính',
            //     icon: fileInvoiceDollar,
            //     style:'classname = classes.containerIcon'
            //     //link: "google.com"
            // },
            // {
            //     id: 16,
            //     title: 'Hệ thống',
            //     icon: cogs,
            //     style: 'classname = classes.containerIcon',
            //     link: "systems/users"
            //
            // },
            // {
            //     id: 17,
            //     title: 'Quản trị',
            //     icon: userSecret,
            //     style: 'classname = classes.containerIcon',
            //     link: "systems/permissions"
            // },
            // {
            //     id: 18,
            //     title: 'Báo cáo quản trị',
            //     icon: fileCode,
            //     style: 'classname = classes.containerIcon',
            //     link: "systems/permissions"
            // }
        ]
        return (
            <Wrapper>
                <Grid container spacing={0}>
                    <HeaderDashboardMenu style={{color: 'white'}} id="headerDashboardMenu"
                                         logoAltText=""
                                         logo={`${process.env.PUBLIC_URL}/static/images/logo.svg`}
                        // toggleDrawer={handleDrawerToggle}
                        // toogleNotifications={handleNotificationToggle}
                        // toggleFullscreen={handleFullscreenToggle}
                    />
                    <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                        {(dataItem.length === 1 ? history.push('dashboard') :

                            dataItem.map(item =>
                                <Grid item xs={12} sm={3} md={2} key={item.id}>
                                    <ItemMenu data={item}/>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </Wrapper>
        );
    }
}

export default DashboardMenu;
