import React, {useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import CustomToolbar from "../../components/CustomTableToolbar/CustomToolbar";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Moment from "react-moment";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {CustomLocaleText} from "../../components/CustomTableToolbar/CustomLocaleText";
import {
    ColorActionDelete,
    ColorActionEdit,
    ColorActionView,
    ColorButton,
    ColorHeader,
    ColorTextButton
} from "../../components/MainStyleCSS/ColorHeader";
import ExportExcel from "../../components/ExportExcel/ExportExcel";
import 'moment-timezone';
import FormServiceTypeView from "../../components/System/ServicePackage/FormServiceTypeView";
import FormServiceTypeUpdate from "../../components/System/ServicePackage/FormServiceTypeUpdate";
import FormServiceTypeDelete from "../../components/System/ServicePackage/FormServiceTypeDelete";
import FormServiceTypeAdd from "../../components/System/ServicePackage/FormServiceTypeAdd";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const rootEl = document.getElementById('root');
const initialFValues = {
    startTime: new Date(),
    endTime: new Date(),
}

const useStyles = makeStyles(theme => ({

    TitleDialog: {
        backgroundColor: ColorButton,
        color: ColorTextButton,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'bold; line-height: 20px',
        // background: 'linear-gradient(#9BC90D 0%, #79A70A 100%)',
        background: 'linear-gradient(#1A94FF 0%, #1977d4 100%)',
        boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
    },
    ContainerDialog: {
        maxWidth: "lg",
        maxHeight: "calc(100vh - 64px)",

    },
    paper: {borderRadius: 15},
    ContentDialog: {
        margin: "30px",
    },
    buttonsAdd: {
        color: '#1A94FF',
        padding: "10px",
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        right: 0,
        marginTop: '10px',
        marginRight: '10px',
        zIndex: 1,
    },
    buttonsExcel: {
        color: '#1A94FF',
        padding: "10px",
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        right: 145,
        marginTop: '10px',
        marginRight: '10px',
        zIndex: 1,
    },

    lightText: {
        color: 'white'
    },
    btnHandle: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    root: {

        clear: "both",
        padding: "10px",
        '& .super-app-theme--header': {
            backgroundColor: ColorHeader,
            // color: 'white'
        },
        '& .super-app-theme--Open': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: ColorHeader,
            '&:hover': {
                backgroundColor: ColorHeader,
            },
        },
        '& .MuiDataGrid-cell': {
            color: theme.palette.text.primary
        },
        '& .MuiToolbar-root ': {
            color: theme.palette.text.primary
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        }
    },

}));

let fileName = `DANH SÁCH THIẾT BỊ CHẤM CÔNG`
const dateFormater = (cell) => {
    return (
        <Moment format="DD-MM-YYYY HH:mm:ss">{cell}</Moment>
    );
};

function addOrEdit(thisRow) {

}

// const statusDevices = [{"id":true, "name":"Trực tuyến"},{"id":false,"name":"Ngoại tuyến"}]
export default function ServiceType() {

    const classes = useStyles();
    // const {addOrEdit, recordForEdit} = props
    const [data, setData] = useState([])
    // const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [dataRows, setDataRows] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [data2, setData2] = useState([])

    //     // console.log(dataUser)
    const handleEdit = () => {
        setOpenEdit(false);
    };
    const handleDelete = () => {
        setOpenDelete(false);
    };
    const handleAdd = () => {
        setOpenAdd(false);
    };
    const handleClose = () => {
        setOpen(false);
        // setDataRows({});
    };
    const handleExportExcel = () => {
        const temp = {}
        temp.dataGrid = dataGrid.rows
        temp.name = fileName
        temp.workbook = ''
        // console.log(temp)
        ExportExcel(temp)
        // <ExportExcelTest/>
    }
    useEffect(async () => {

        try {
            const api = `https://api-dev.hrms.com.vn/api/v1/servicetype`
            const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                if (res.status === 200) {
                    let newArray = []
                    if (res.data.message === null) {
                        res.data.message = {};
                        setData({})
                    } else {
                        setData(res.data.message)
                        console.log(res.data.message)
                    }
                }
            })

        } catch (e) {

        }
    }, [openAdd, openDelete, openEdit, isExpired])

    const dataGrid = {
        "columns":
            [
                {
                    "field": "id",
                    "headerName": "ID",
                    "hide": true,
                    "minWidth": 300,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "name",
                    "headerName": "Tên dịch vụ",
                    flex: 1,
                    "editable": false,
                    "minWidth": 150,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "description",
                    "headerName": "Mô tả",
                    "minWidth": 200,
                    flex: 1,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                },
                {
                    "field": "actions",
                    "headerName": "Thao tác",
                    "sortTable": false,
                    "type": "string",
                    "width": 160,
                    "editable": false,
                    headerClassName: 'super-app-theme--header',
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpen(true);
                                // viewRows(thisRow)
                                // return alert(JSON.stringify(thisRow, null, 4));
                            }

                            }>
                                <VisibilityIcon style={{color: ColorActionView}}/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenEdit(true);
                            }
                            }>
                                <EditIcon style={{color: ColorActionEdit}}/>
                            </IconButton>
                            <IconButton onClick={(e) => {
                                e.stopPropagation(); // don't select this row after clicking
                                const api: GridApi = params.api;
                                const thisRow: Record<string, GridCellValue> = {};
                                api
                                    .getAllColumns()
                                    .filter((c) => c.field !== "__check__" && !!c)
                                    .forEach(
                                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                    );
                                setDataRows(thisRow);
                                setOpenDelete(true);
                            }}>
                                <DeleteForeverIcon style={{color: ColorActionDelete}}/>
                            </IconButton>

                        </div>
                    )
                },
            ],
        "rows": data,
        rowLength: 100,
        maxColumns: 6,
    }

    return (
        <>
            <Grid container>
                <Grid item xs={2} className={classes.buttonsAdd}>
                    <div>
                        <Button variant="contained" style={{
                            backgroundColor: ColorButton,
                            color: 'white',
                            maxHeight: '37px',
                            minWidth: '140px'
                        }}
                                onClick={() => {
                                    setOpenAdd(true)
                                }}
                        >
                            <AddIcon/>
                            Thêm mới
                        </Button>
                    </div>
                </Grid>

            </Grid>


            <div style={{
                height: '100vh',
                width: 'calc(100vh -150px)',
                boxShadow: '0.25rem 0.25rem 0.6rem rgb(0 0 0 / 5%), 0 0.5rem 1.125rem rgb(75 0 0 / 5%)'
            }} className={classes.root}>
                <DataGrid
                    {...dataGrid}
                    className={classes.dataGrid}
                    disableExtendRowFullWidth={true}
                    getRowClassName={(params) =>
                        `super-app-theme--${params.getValue(params.id, 'status')}`}
                    hideFooterSelectedRowCount={true} // ẩn thông tin tổng số dòng đang chọn
                    localeText={
                        CustomLocaleText
                    }
                    components={{
                        Toolbar: CustomToolbar,
                        title: "KHÁCH HÀNG"
                    }}
                />
            </div>
            <Dialog open={open} className={`${classes.ContainerDialog}`}>
                <DialogTitle className={classes.TitleDialog}>CHI TIẾT LOẠI DỊCH VỤ</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormServiceTypeView data={dataRows}
                                         onClick={() => {
                                             setOpen(false)
                                         }}
                    />
                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} className={`${classes.ContainerDialog}`}>
                <DialogTitle className={classes.TitleDialog}>CẬP NHẬT LOẠI DỊCH VỤ</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormServiceTypeUpdate
                        data={dataRows}
                        data2={data2}
                        onClick={() => {
                            setOpenEdit(false)
                        }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openDelete} className={`${classes.ContainerDialog}`}>
                <DialogTitle className={classes.TitleDialog}>XÓA LOẠI DỊCH VỤ</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormServiceTypeDelete
                        data={dataRows}
                        data2={data2}
                        onClick={() => {
                            setOpenDelete(false)
                        }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openAdd} className={`${classes.ContainerDialog}`}>
                <DialogTitle className={classes.TitleDialog}>THÊM LOẠI DỊCH VỤ</DialogTitle>
                <DialogActions className={classes.ContentDialog}>
                    <FormServiceTypeAdd
                        data2={data2}
                        onClick={() => {
                            setOpenAdd(false)
                        }}/>
                    {/*</div>*/}
                </DialogActions>
            </Dialog>
            {/*<Dialog open={openPW} fullWidth={true} maxWidth={"xs"}*/}
            {/*>*/}
            {/*    <DialogTitle>ĐỔI MẬT KHẨU : {dataRows.user_name}</DialogTitle>*/}
            {/*    <DialogActions fullWidth={true} maxWidth={"xs"}>*/}
            {/*        <div style={{*/}
            {/*            display: 'flex',*/}
            {/*            flexDirection: 'column',*/}
            {/*            width: '100%',*/}
            {/*            alignItems: 'center',*/}
            {/*            justifyContent: 'center'*/}
            {/*        }}>*/}
            {/*            /!*<FormChangePassword*!/*/}
            {/*            /!*    data={dataRows}*!/*/}
            {/*            /!*    onClick={() => {*!/*/}
            {/*            /!*        setOpenPW(false)*!/*/}
            {/*            /!*    }}/>*!/*/}
            {/*        </div>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
        </>
    );
}

