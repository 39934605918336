import {useFormik} from 'formik';
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import axios from "axios";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import 'moment-timezone';
import viLocale from "date-fns/locale/vi";
import {MenuItem} from "@material-ui/core";
import MyWorkFromHomeAddDates from "./MyWorkFromHomeAddDates";
// const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    multilineColor: {
        fontWeight: "bold"
    },
    card: {
        overflow: "visible"
    },
    session: {
        position: "relative",
        zIndex: 4000,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    background: {
        backgroundColor: theme.palette.primary.main
    },
    content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    },
    wrapper: {
        flex: "none",
        maxWidth: "400px",
        width: "100%",
        margin: "0 auto"
    },
    fullWidth: {
        width: "100%"
    },
    logo: {
        display: "flex",
        flexDirection: "column"
    }
}));

const format1 = "YYYY-MM-DD"


const MyWorkFromHomeAdd = (props) => {
    const [dataAdd, setDataAdd] = useState()
    const classes = useStyles();
    const [endDate, setEndDate] = useState(moment(new Date()).format(format1));
    const [startDate, setStartDate] = useState(moment(new Date()).format(format1));
    const [dates, setDates] = useState([{}])
    const [count, setCount] = useState(0)
    const [countDates, setCountDates] = useState(0)
    const [statusDates,setStatusDates] = useState(0)
    const dataInitDate = dates.reduce((obj, item) => (obj[`${item.date}`] = `${item.type}`, obj) ,{})
    // console.log(dataInitDate)
    const [dataDatesReturn, setDataDatesReturn] = useState(dataInitDate)
    const [initialValues,setInitialValues] = useState({
        details:'',
    })
    const [values, setValues] = useState(initialValues)
    const handleInputChange = e => {
        const {name, value} = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const typeDate = props.typeDate
    const categories = props.categories
    // useEffect(async () => {
    //     let stringDate = ''
    //     if (dates && dates.length > 0) {
    //         dates.map((date) => {
    //                 stringDate !== '' ? stringDate = `${stringDate}, "${date.date}" : "${date.type}"` : stringDate = `"${date.date}" : "${date.type}"`
    //             }
    //         )
    //     }
    //     // temp = JSON.parse(`{${stringDate}}`)
    //     await setInitialValues(JSON.parse(`{${stringDate}}`))
    //     console.log(`Init: ${JSON.stringify(initialValues)}`)
    // }, [startDate, endDate])
    const validationSchema = yup.object().shape({
        details: yup
            .string('Nhập vào mô tả')
            .required('Dữ liệu bắt buộc nhập'),
        // travel_type: yup
        //     .string('Nhập vào loại phòng ban')
        //     .required('Dữ liệu bắt buộc nhập'),
        // travel_to: yup
        //     .string('Nhập vào loại phòng ban')
        //     .required('Dữ liệu bắt buộc nhập'),
        // travel_from: yup
        //     .string('Nhập vào loại phòng ban')
        //     .required('Dữ liệu bắt buộc nhập'),

    });
    useEffect(async () => {
        const initValues = {...initialValues}
        initValues.dates = dates
        setInitialValues(initValues)
    },[dates])
    useEffect(async () => {
        if (count) {
            try {
                // console.log(JSON.stringify(dataUpdate))
                const api = `https://api-dev.hrms.com.vn/api/v1/workfromhome`
                const result = await axios.post(api, dataAdd, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                    if (res.status === 201) {
                        // window.location.reload();
                        props.onClick()
                    }
                })
                // const imageAvatr = await getImageAvatar(result.image);

            } catch (e) {

            }
        }
    }, [count])

    useEffect(async () => {
        if (countDates) {
            try {
                const date2 = moment(endDate).format('YYYY-MM-DD');
                const date1 = moment(startDate).format('YYYY-MM-DD');
                // console.log(JSON.stringify(dataUpdate))
                const api = `https://api-dev.hrms.com.vn/api/v1/renderleavedate?start_date=${date1}&end_date=${date2}`
                const result = await axios.get(api, {headers: {"Authorization": `Bearer ${localStorage.getItem("authToken")}`}}).then(res => {
                    if (res.status === 200) {
                        // window.location.reload();
                        if (res.data.message === null) {
                            res.data.message = {};
                        }
                        // else {
                        //     // setDates(res.data.message)
                        //     const arr = []
                        //     res.data.message.map ((x) => (
                        //         arr.push({ id: `${x.date}`, name: `${x.type}` })
                        //     ))
                        //     setDates(arr)
                        // }
                        setDates(res.data.message)
                        setStatusDates(1)
                    }
                })
                // const imageAvatr = await getImageAvatar(result.image);
            } catch (e) {

            }
        }
    }, [countDates])
    useEffect(async () => {
        setCountDates(countDates + 1)
        setStatusDates(0)
    }, [startDate, endDate])
    const handleAdd = (dataSubmit) => {
        console.log(dataSubmit)

        const temp = Object.entries(dataDatesReturn).map((e) => (
            { "date": e[0], "type": e[1] }
        ))
        let temp2 = Object.assign( {},{"dates" : temp }, {"details": dataSubmit.details},{"start_date":moment(startDate).format('YYYY-MM-DD')},{"end_date":moment(endDate).format('YYYY-MM-DD')})
        let dataNew = {"message": {...temp2}, "action": "create", "type_message": "map"}
        console.log(dataNew)
        // console.log(JSON.stringify(dataNew))
        setDataAdd(dataNew)
        setCount(1)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleAdd(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardDatePicker
                                style={{minWidth: "200px"}}
                                InputProps={{
                                    className: classes.multilineColor
                                }}
                                InputLabelProps={{
                                    shrink: formik.values.date_request ? true : undefined,
                                }}
                                className={classes.textField}
                                label="Ngày bắt đầu"
                                name="start_date"
                                id="start_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy"
                                placeholder="ngày-tháng-năm"
                                mask="__/__/____"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                onChange={(date) => setStartDate(date)}
                                minDate={new Date()}
                                value={startDate}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                            <KeyboardDatePicker
                                style={{minWidth: "200px"}}
                                InputProps={{
                                    className: classes.multilineColor

                                }}
                                InputLabelProps={{
                                    shrink: formik.values.date_request ? true : undefined,
                                }}
                                className={classes.textField}
                                label="Ngày kết thúc"
                                name="end_date"
                                id="end_date"
                                margin="normal"
                                inputVariant="outlined"
                                openTo="date"
                                format="dd-MM-yyyy"
                                placeholder="ngày-tháng-năm"
                                mask="__/__/____"
                                okLabel="Chọn"
                                cancelLabel="Hủy"
                                // selected={formik.values.end_time}
                                onChange={(date) => setEndDate(date)}
                                minDate={new Date()}
                                value={endDate}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <TextField
                            style={{minWidth: "235px"}}
                            InputProps={{
                                className: classes.multilineColor
                            }}
                            id="details"
                            name="details"
                            label="Mô tả chi tiết"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={formik.values.details}
                            onChange={formik.handleChange}
                            error={formik.touched.details && Boolean(formik.errors.details)}
                            helperText={formik.touched.details && formik.errors.details}

                        />

                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                {(statusDates) ? <MyWorkFromHomeAddDates dates={dates} props={props} setDataDatesReturn={setDataDatesReturn} setStatusDates={setStatusDates}/> : <></>}
            </Grid>
            <Grid container spacing={5} style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                        Tiếp tục
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={props.onClick}>
                        Hủy
                    </Button>
                </Grid>
                {/*<Grid item xs={12} sm={6} md={4} lg={3}>*/}
                {/*    {}*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={6} md={4} lg={3}>*/}
                {/*</Grid>*/}
            </Grid>
        </form>
    )

}
export default MyWorkFromHomeAdd;
