import React from "react";
import {Button, Divider, InputLabel, MenuItem, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FieldArray, Form, Formik, getIn} from "formik";
import * as Yup from "yup";
import {
    ColorActionDelete,
    ColorButtonAdd,
    ColorButtonCancel,
    ColorButtonHoverAdd,
    ColorTextButton
} from "../../MainStyleCSS/ColorHeader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import {Select} from "@mui/material";

const validationSchema = Yup.object().shape({
    pack_info: Yup.array().of(Yup.object().shape({
        model_name: Yup.string().required("Dữ liệu bắt buộc"),
        limit: Yup.number().typeError('Dữ liệu phải là số nguyên').required("Dữ liệu bắt buộc"),
        calculation_type: Yup.string().required("Dữ liệu bắt buộc"),
    }))
});

const debug = true;

const useStyles = makeStyles(theme => ({
    labelInput: {
        maxWidth: '150px', display: "flex", justifyContent: 'flex-end', marginRight: '10px'
    }, ButtonAdd: {
        background: ColorButtonAdd, color: ColorTextButton, '&:hover': {
            background: ColorButtonHoverAdd
        },
    }, ButtonCancel: {
        backgroundColor: ColorButtonCancel, '&:hover': {
            background: ColorButtonHoverAdd, color: ColorTextButton
        },
    },

    ButtonAction: {
        display: 'flex', justifyContent: 'center', marginTop: '20px'
    }, row: {
        display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minWidth: "250px"
    }, multilineColor: {
        fontWeight: "bold"
    }, card: {
        overflow: "visible"
    }, session: {
        position: "relative", zIndex: 4000, minHeight: "100vh", display: "flex", flexDirection: "column"
    }, background: {
        backgroundColor: theme.palette.primary.main
    }, content: {
        padding: `40px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 0 auto",
        flexDirection: "column",
        minHeight: "100%",
        textAlign: "center"
    }, wrapper: {
        flex: "none", maxWidth: "400px", width: "100%", margin: "0 auto"
    }, fullWidth: {
        width: "100%"
    }, logo: {
        display: "flex", flexDirection: "column"
    }, container: {
        display: "flex", flexWrap: "wrap"
    }, button: {
        margin: theme.spacing(1)
    }, field: {
        margin: theme.spacing(1)
    }, form: {
        width: "100%"
    }
}));
const AddServicePackageInfo = (props) => {
    const classes = useStyles();

    return (<div className={classes.container}>
        <Formik
            initialValues={{
                pack_info: [{
                    model_name: props.props.data3?props.props.data3[0].id:"", limit: 0, calculation_type: "quantity",
                }]
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
                // console.log('are you ok')
                // props.setDataPackgeInfoReturn(values)

                // console.log("onSubmit", JSON.stringify(values, null, 2));
            }}
        >
            {({values, touched, errors, handleChange, handleBlur, isValid}) => (
                <Form autoComplete="on" className={classes.form}>
                    <Typography style={{
                        fontWeight: 'bold',
                        width: "20%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: '#1A94FF'
                    }}> THÔNG TIN GÓI </Typography>
                    <Divider style={{
                        marginTop: 20,
                        marginBottom: 20,
                        width: "20%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}/>

                    <FieldArray name="pack_info">
                        {({push, remove}) => (<div>
                            {values.pack_info.map((p, index) => {
                                const model_name = `pack_info[${index}].model_name`;
                                const touchedModelName = getIn(touched, model_name);
                                const errorModelName = getIn(errors, model_name);

                                const limit = `pack_info[${index}].limit`;
                                const touchedLimit = getIn(touched, limit);
                                const errorLimit = getIn(errors, limit);

                                const calculation_type = `pack_info[${index}].calculation_type`;
                                const touchedCalculationType = getIn(touched, calculation_type);
                                const errorCalculationType = getIn(errors, calculation_type);

                                return (<div key={p.id}
                                             style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    {/*<TextField*/}
                                    {/*    className={classes.field}*/}
                                    {/*    margin="normal"*/}
                                    {/*    variant="outlined"*/}
                                    {/*    label="Tên mô-đun"*/}
                                    {/*    name={model_name}*/}
                                    {/*    value={p.model_name}*/}
                                    {/*    required*/}
                                    {/*    helperText={touchedModelName && errorModelName ? errorModelName : ""}*/}
                                    {/*    error={Boolean(touchedModelName && errorModelName)}*/}
                                    {/*    onChange={handleChange}*/}
                                    {/*    onBlur={handleBlur}*/}
                                    {/*/>*/}
                                    <TextField
                                        className={classes.field}
                                        margin="normal"
                                        variant="outlined"
                                        label="Tên mô-đun"
                                        select
                                        name={model_name}
                                        value={p.model_name}
                                        required
                                        helperText={touchedModelName && errorModelName ? errorModelName : ""}
                                        error={Boolean(touchedModelName && errorModelName)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        {props.props.data3?.map((arr, index) => {
                                            return (
                                                <MenuItem value={arr.id} key={index}>{arr.name}</MenuItem>
                                            )
                                        })}
                                    </TextField>

                                    <TextField
                                        className={classes.field}
                                        margin="normal"
                                        variant="outlined"
                                        label="Giới hạn"
                                        name={limit}
                                        value={parseInt(p.limit)}
                                        required
                                        type="number"
                                        helperText={touchedLimit && errorLimit ? errorLimit : ""}
                                        error={Boolean(touchedLimit && errorLimit)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <TextField
                                        className={classes.field}
                                        margin="normal"
                                        variant="outlined"
                                        label="Đơn vị tính"
                                        name={calculation_type}
                                        value={p.calculation_type}
                                        select
                                        defaultValue={"quantity"}
                                        required
                                        helperText={touchedCalculationType && errorCalculationType ? errorCalculationType : ""}
                                        error={Boolean(touchedCalculationType && errorCalculationType)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <MenuItem key="quantity" value="quantity">Số lượng</MenuItem>
                                        <MenuItem key="capacity" value="capacity">Dung lượng</MenuItem>
                                    </TextField>
                                    <IconButton onClick={() => remove(index)}>
                                        <DeleteForeverIcon style={{color: ColorActionDelete}}/>
                                    </IconButton>
                                </div>);
                            })}

                            <Button className={classes.ButtonAdd} variant="contained" type="button"
                                    onClick={() => push({
                                        id: Math.random(),
                                        // model_name: `Gói ${values.pack_info.length + 1}`,
                                        model_name: props.props.data3?props.props.data3[0].id:"",
                                        limit: 0,
                                        calculation_type: "quantity"
                                    })}
                            >
                                <AddIcon/>
                            </Button>

                            {/*<Button*/}
                            {/*    className={classes.button}*/}
                            {/*    type="button"*/}
                            {/*    variant="outlined"*/}
                            {/*    onClick={() => push({*/}
                            {/*        id: Math.random(),*/}
                            {/*        model_name: "",*/}
                            {/*        limit: 0,*/}
                            {/*        calculation_type: ""*/}
                            {/*    })}*/}
                            {/*>*/}
                            {/*    Thêm gói*/}
                            {/*</Button>*/}
                        </div>)}
                    </FieldArray>
                    {_.isEmpty(errors) ?
                        (props.setStatusPackageInfo(1), props.setDataPackgeInfoReturn(values.pack_info))
                        :
                        props.setStatusPackageInfo(0)}
                </Form>)}
        </Formik>
    </div>);
};

export default AddServicePackageInfo;
